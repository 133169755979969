import React from 'react';
import io from 'socket.io-client';

import SocketContext from './SocketContext';
import { EUREKA_URL } from '../../config';
import { populateDefaultState } from '../../utils/store';

const withSocket = Component =>
    class WithSocket extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                socket: null,
                isReconnecting: false,
            };
        }

        componentDidMount() {
            this.initializeSocket();
        }

        componentWillUnmount() {
            if (this.state.socket) {
                this.state.socket.disconnect();
            }
        }

        initializeSocket = () => {
            const socket = io(EUREKA_URL, {
                autoConnect: true,
                reconnectionAttempts: 100,
                transports: ['websocket', 'polling'],
            });

            socket.on('connect', () => {
                this.setState({ isReconnecting: false });
                console.log('Socket connected ✅');
            });

            socket.on('disconnect', reason => {
                console.warn('Socket disconnected:', reason);
                this.setState({ isReconnecting: true });

                if (reason === 'io server disconnect') {
                    // If the server manually disconnects, reconnect manually
                    socket.connect();
                }
            });

            socket.on('connect_error', () => {
                console.warn('Connection error, retrying...');
                this.setState({ isReconnecting: true });
                setTimeout(() => socket.connect(), 500);
            });

            this.connectionChecker = setInterval(() => {
                if (!socket.connected) {
                    console.warn('Socket not connected, attempting to reconnect...');
                    this.setState({ isReconnecting: true });
                    socket.connect();
                }
            }, 3000);

            populateDefaultState({ socket });

            this.setState({ socket });
        };

        render() {
            const { socket, isReconnecting } = this.state;

            return (
                <SocketContext.Provider value={{ socket, isReconnecting }}>
                    <Component />
                </SocketContext.Provider>
            );
        }
    };

export default withSocket;
