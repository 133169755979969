import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { translateCCLinkUrl } from '../../../helper/CCLink';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import { getImageSourceAsync } from '../../../services/api/db';

const AStyled = styled.a`
    text-decoration: none !important;
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const SlideshowWrapper = styled.div`
    .react-slideshow-container {
        position: relative;
        width: 100%;
        height: fit-content;
        max-height: 100%;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
        overflow: hidden;

        &:hover {
            & .custom-arrow {
                opacity: 1;
            }
        }
    }

    .indicators {
        align-items: center;
    }

    .images-wrap {
        display: flex;
        align-items: center;
        height: 100%;
        margin-bottom: -6px;

        & > .active {
            position: relative;
            background: red;
        }
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const ArrowWrapper = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: calc(50% - 20px);
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
`;

const PrevArrowWrapper = styled(ArrowWrapper)`
    left: 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const NextArrowWrapper = styled(ArrowWrapper)`
    right: 0px;
`;

const StyledArrow = styled(FontIcon)`
    color: #ffffff !important;
    font-size: 24px !important;
`;

const HiddenDiv = styled.div`
    display: none;
`;

const Slideshow = ({
    data,
    autoplay = false,
    itemDuration = 5000,
    indicators = false,
    style = {},
    imagesAltText,
    ariaLabel,
}) => {
    const defaultIndex = 0;
    const [currentIndex, setCurrentIndex] = useState(defaultIndex);
    const [hoveredImage, setHoverImage] = useState(false);
    const [items, setItems] = useState([]);
    const oneImage = data && data.length === 1;

    const onChange = (oldIndex, newIndex) => {
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const loadSlides = async () => {
            if (data && data.length) {
                const images = [];
                for (const image of data) {
                    let url;
                    let navParams;
                    let navPath;
                    let imageSource;

                    if (image._id) {
                        imageSource = await getImageSourceAsync(image._id);
                    }

                    if (image.url) {
                        const ccLinkData = await translateCCLinkUrl(image.url);
                        if (!ccLinkData) {
                            url = image.url;
                        } else {
                            navPath = ccLinkData.navPath;
                            navParams = ccLinkData.navParams;
                        }
                    }

                    images.push({
                        src: image.src || imageSource,
                        url,
                        navPath,
                        navParams,
                    });
                }

                setItems(images);
            } else {
                setItems([]);
            }
        };

        loadSlides();
    }, [data]);

    const PrevArrow = (
        <PrevArrowWrapper className="custom-arrow">
            <StyledArrow>chevron_right</StyledArrow>
        </PrevArrowWrapper>
    );

    const NextArrow = (
        <NextArrowWrapper className="custom-arrow">
            <StyledArrow>chevron_right</StyledArrow>
        </NextArrowWrapper>
    );

    const additionalContent = data && data.length > 0 ? data[currentIndex].additionalContent : null;

    const getAltText = index =>
        imagesAltText ? `${imagesAltText} ${index + 1} of ${data.length}` : index;

    return (
        <SlideshowWrapper
            onMouseEnter={() => setHoverImage(true)}
            onMouseLeave={() => setHoverImage(false)}
            aria-label={ariaLabel}
        >
            <Slide
                defaultIndex={defaultIndex}
                currentIndex={currentIndex}
                onChange={onChange}
                transitionDuration={300}
                duration={itemDuration}
                autoplay={autoplay && data?.length > 1}
                prevArrow={!oneImage && hoveredImage ? PrevArrow : <HiddenDiv />}
                nextArrow={!oneImage && hoveredImage ? NextArrow : <HiddenDiv />}
                indicators={indicators && data?.length > 1}
                style={style}
            >
                {items.map((obj, index) => (
                    <>
                        {obj.url && obj.url.length > 0 && (
                            <AStyled target="_blank" href={obj.url} key={`${obj.url}_${index}`}>
                                <Image src={obj.src} alt={getAltText(index)} />
                            </AStyled>
                        )}
                        {!obj.url && (obj.navParams || obj.navPath) && (
                            <LinkStyled
                                key={'link_' + index}
                                params={obj.navParams}
                                path={obj.navPath}
                            >
                                <Image src={obj.src} alt={getAltText(index)} />
                            </LinkStyled>
                        )}
                        {!obj.url && !obj.navParams && !obj.navPath && (
                            <Image src={obj.src} alt={getAltText(index)} />
                        )}
                    </>
                ))}
            </Slide>
            {additionalContent}
        </SlideshowWrapper>
    );
};

export default Slideshow;
