import { useEffect } from 'react';
import Auth from '../../../services/api/auth';
import { useSocket } from '../../../components/Session/SocketContext';
import { useCheckinStore } from '../stores/checkinStore';
import { sync } from '../../../services/api/eureka';

const useCheckins = eventId => {
    const { getCheckIns, resetCheckIns, setCheckIns, setLoading } = useCheckinStore();
    const isAuthenticated = Auth.isUserAuthenticated();
    const currentUserId = Auth.getUser()?.id;
    const { socket, isReconnecting } = useSocket();

    useEffect(() => {
        if (currentUserId && eventId && socket && !isReconnecting) {
            const updateCheckins = async data => {
                if (parseInt(data.userId, 10) === parseInt(currentUserId, 10) && data?.checkins) {
                    if (data.legacySync) {
                        sync(async () => {
                            await getCheckIns(eventId);
                            setLoading(false);
                        });
                    } else {
                        await setCheckIns(data.checkins);
                        setLoading(false);
                    }
                }
            };
            socket.emit('joinCheckin', { userId: currentUserId, eventId });
            socket.on('update_checkins', updateCheckins);
            // TODO if there is incoming external sync set here a function to check every some minutes

            return () => {
                socket.emit('leaveCheckin', { userId: currentUserId, eventId });
                socket.removeAllListeners('update_checkins');
            };
        }

        return () => {};
    }, [currentUserId, eventId, socket, isReconnecting]);

    useEffect(() => {
        if (isAuthenticated && eventId) {
            getCheckIns(eventId);
        } else {
            resetCheckIns();
        }

        return () => {
            resetCheckIns();
        };
    }, [isAuthenticated, eventId]);
};

export default useCheckins;
