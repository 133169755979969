import { create } from 'zustand';
import { getInstitutionProposals } from '../services/api/eureka';
import { getItemAsync } from '../services/api/data';
import { sortBy } from 'lodash';

export const useInstitutionProposalsStore = create((set, get) => ({
    proposals: [],
    institutions: [],
    fetchProposals: async eventId => {
        const proposals = await getInstitutionProposals(eventId);
        const institutionsPromises = [];
        proposals.forEach(proposal => {
            institutionsPromises.push(getItemAsync('institution', proposal.reference));
        });
        const institutions = (await Promise.all(institutionsPromises)).filter(
            institution => !!institution,
        );

        set({
            proposals: proposals,
            institutions: sortBy(institutions, 'name'),
        });
        return proposals;
    },
    updateProposal: async (proposalId, proposal) => {
        const updatedProposals = get().proposals.map(p =>
            p.id === proposalId ? { ...p, ...proposal } : p,
        );
        set({ proposals: updatedProposals });
    },
}));

export const useProposalByInstitutionId = institutionId =>
    useInstitutionProposalsStore(state =>
        state.proposals.find(proposal => proposal.reference === institutionId),
    );
