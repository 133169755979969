import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../components/General/Loader';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { useInstitutionProposalsStore } from '../../../stores/InstitutionProposalStore';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import InstitutionListItem from '../../../components/ObjectListItem/Institution';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MySessionsText = styled.h2`
    font-family: Cabin, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
`;

const EventInstitutionProposals = ({ event }) => {
    const fetchProposals = useInstitutionProposalsStore(state => state.fetchProposals);
    const institutionsFromProposals = useInstitutionProposalsStore(state => state.institutions);
    const isPhone = useMediaQuery('(max-width:767px)');
    const [isLoading, setIsLoading] = useState(false);
    const displayInstitutions = institutionsFromProposals.length > 0 && !isLoading;

    useEffect(() => {
        const getProposals = async () => {
            if (!event || !event.id) {
                return;
            }
            setIsLoading(true);
            await fetchProposals(event.id);
            setIsLoading(false);
        };
        getProposals();
    }, [event]);

    return (
        <>
            {displayInstitutions && (
                <Wrapper>
                    <MySessionsText>My exhibitor profiles</MySessionsText>
                    <Stack paddingY={2}>
                        {institutionsFromProposals.map(institution => (
                            <Tooltip
                                title="No rights to edit this exhibitor's data"
                                disableHoverListener={institution?.allowEditByRepresentative}
                                followCursor={true}
                            >
                                <Stack>
                                    <NavigationLink
                                        path={
                                            institution?.allowEditByRepresentative
                                                ? `/event/${event.uniqueName}/exhibitordashboard/${institution.id}`
                                                : null
                                        }
                                    >
                                        <InstitutionListItem
                                            institution={institution}
                                            showBookmark={false}
                                            disabled={!institution?.allowEditByRepresentative}
                                        />
                                    </NavigationLink>
                                </Stack>
                            </Tooltip>
                        ))}
                    </Stack>
                </Wrapper>
            )}
            {isLoading && (
                <Wrapper style={{ marginTop: '100px', height: '60px' }}>
                    <Loader />
                </Wrapper>
            )}
        </>
    );
};

export default EventInstitutionProposals;
