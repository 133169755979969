import { eurekaRequest } from '../../../services/api/eureka';

export const getFavorites = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/favorites/me/${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const updateFavorites = (eventId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/favorites/me/${eventId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};
