import React, { useState } from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import { FormWrapper } from '../../style/modalsStyle';
import * as palette from '../../../../components/General/Variables';
import { StyledForm } from '../../style';
import { getString } from '../../../../services/api/store';
import { CONTACT_INFO_TITLE } from '../../constants';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import { Flex } from '../../../common/Flex';

const ContactInfoModal = ({
    theme,
    fieldValues,
    suggestedContactEmail,
    onClose,
    closeIcon,
    setCloseIcon,
    setFieldValues,
    afterSignUp,
}) => {
    const [fieldValuesContact, setFieldValuesContact] = useState(fieldValues);
    const [touched, setTouched] = useState(false);
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const onChange = (value, event) => {
        setTouched(true);
        const { name } = event.target;

        setFieldValuesContact({
            ...fieldValuesContact,
            [name]: value,
        });
    };

    const onSave = () => {
        const fieldValuesContactCopy = { ...fieldValuesContact };

        if (!touched && suggestedContactEmail && !fieldValuesContactCopy.email) {
            fieldValuesContactCopy.email = suggestedContactEmail;
        }

        setFieldValues(fieldValuesContactCopy);
        onClose();
    };

    return (
        <ComplexDialog
            title={
                <Flex>
                    <StyledComponent.BackButton
                        style={{
                            color: palette.COLOR_TEXT,
                            marginTop: -10,
                        }}
                        icon
                        onClick={onClose}
                        aria-label={'Go back'}
                        aria-hidden={true}
                        role={'presentation'}
                    >
                        arrow_back
                    </StyledComponent.BackButton>
                    <StyledComponent.ModalTitle>{CONTACT_INFO_TITLE}</StyledComponent.ModalTitle>
                </Flex>
            }
            visible={true}
            contentStyle={{ padding: 0 }}
            onClose={null}
            displayBoxShadow
            options={[
                {
                    title: getString('saveButton') || 'Save',
                    variant: 'text',
                    type: 'save',
                    onClick: onSave,
                },
            ]}
        >
            <StyledForm
                maincolor={theme.primary}
                horizontalMargin={isDesktop && '0px'}
                minHeight="100px"
            >
                <FormWrapper>
                    <StyledComponent.StyledTextField
                        id="profile-email"
                        name="email"
                        label={<div>{getString('emailHintText') || 'Email'}</div>}
                        value={
                            suggestedContactEmail && !touched && !fieldValuesContact.email
                                ? suggestedContactEmail
                                : fieldValuesContact.email
                        }
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        leftIcon={
                            <StyledComponent.LinkIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_MAIL}
                            </StyledComponent.LinkIcon>
                        }
                        onFocus={() => setTimeout(() => setCloseIcon('email'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        rightIcon={
                            closeIcon === 'email' && (
                                <StyledComponent.LinkIcon
                                    aria-hidden={true}
                                    role={'presentation'}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesContact({
                                            ...fieldValuesContact,
                                            email: '',
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.StyledTextField
                        id="profile-phone"
                        name="phone"
                        label={getString('profileContactPhone') || 'Phone number'}
                        value={fieldValuesContact.phone}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        type="tel"
                        leftIcon={
                            <StyledComponent.LinkIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_PHONE_OUTLINE}
                            </StyledComponent.LinkIcon>
                        }
                        onFocus={() => setTimeout(() => setCloseIcon('phone'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        rightIcon={
                            closeIcon === 'phone' && (
                                <StyledComponent.LinkIcon
                                    aria-hidden={true}
                                    role={'presentation'}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesContact({
                                            ...fieldValuesContact,
                                            phone: '',
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                </FormWrapper>
            </StyledForm>
        </ComplexDialog>
    );
};

export default ContactInfoModal;
