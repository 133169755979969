import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Divider, FontIcon, ListItem } from 'react-md';
import { getString } from '../../../services/api/store';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import { selectLikeUsersByPostId, selectPostById } from '../selectors';
import {
    addLike as addLikeAction,
    removeLike as removeLikeAction,
    removePost as removePostAction,
    reportPost as reportPostAction,
} from '../actions';
import ImageAvatar from '../../../components/Icons/ImageAvatar';
import Auth from '../../../services/api/auth';
import Dropdown from '../../../components/General/Dropdown';
import PostImagePreview from './PostImagePreview';
import PostDetail from '../containers/PostDetail';
import { getLocalTimeAgo } from '../common';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import UsersModal from './UsersModal';
import AuthenticationProtection from '../../../components/ProtectedPages/AuthenticationProtection';
import AnalyticsService from '../../../features/analytics/services';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import ReactHtmlParser from 'react-html-parser';
import { BLACK_60, COLOR_TEXT } from '../../../components/General/Variables';

const Container = styled.div`
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin: 24px 0;
    background-color: #f5f5f5;
`;

const PostHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TextContainer = styled.div`
    padding: 16px;
    overflow: hidden;
    word-break: break-word;
`;

const DividerStyled = styled(Divider)`
    margin: 0px !important;
`;

const StatusContainer = styled.div`
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
`;

const StyledButton = styled(Button)`
    color: #000000;
`;

const FontIconWrapper = styled.div`
    height: fit-content;

    .md-icon {
        color: ${props => props.color || 'inherit'};
    }
`;

const LikesContainer = styled.div`
    color: #000000;
    display: flex;
    padding: 8px;
    cursor: pointer;

    .md-icon {
        color: #000000;
    }
`;

const LikesLabel = styled.div`
    margin-left: 2px;
`;

const OPTION_TYPES = Object.freeze({
    EDIT: 'edit',
    DELETE: 'delete',
});

const InvisibleButton = styled.button`
    background: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
`;

const FlexContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const Post = ({
    post,
    likesInfo,
    addLike,
    removeLike,
    removePost,
    reportPost,
    match,
    history,
    location,
    index,
    postsSize,
}) => {
    const { users, label, yourLikeId } = likesInfo;
    const { user, time, comments, likes, id, isMine, message, image } = post;
    const usersWithDetails = users.map(user => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        subNameList: (
            <>
                {user.jobTitle && <div>{user.jobTitle}</div>}
                {user.companyName && <div>{user.companyName}</div>}
            </>
        ),
    }));

    const [expandComments, setExpandComments] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showUsersList, setShowUsersList] = useState(false);
    const { trackAchievement } = useAchievementActions();
    const { showSignIn } = useContext(AuthUserContext);

    const myDropdownOptions = [
        {
            text: getString('edit') || 'Edit',
            onClick: () => onMyOptionClick(OPTION_TYPES.EDIT),
        },
        {
            text: getString('deleteButton') || 'Delete',
            onClick: () => onMyOptionClick(OPTION_TYPES.DELETE),
        },
    ];
    const dropdownOptions = [
        {
            text: getString('socialWallReportPost', 'Report this post'),
            onClick: () => onPostReport(),
        },
    ];

    const onPressLike = () => {
        if (!Auth.isUserAuthenticated()) {
            showSignIn(null, {
                match,
                history,
                location,
            });
        }

        if (yourLikeId) {
            removeLike(post, yourLikeId);
        } else {
            addLike(post);
            AnalyticsService.addSample('socialWall', null, post.id);
            trackAchievement(AchievementType.LIKE_SOCIAL_POST, post.id);
        }
    };

    const ago = getLocalTimeAgo(time);
    const isAuthenticated = Auth.isUserAuthenticated();
    const showLikes = likes.length > 0;
    const commentCount = comments.length > 0 ? ` (${comments.length})` : '';
    const LIKE_TEXT = getString('socialWallLikeButton', 'Like');
    const COMMENT_TEXT = getString('socialWallCommentButton', 'Comment');
    const commentsTitle = `${COMMENT_TEXT}${commentCount}`;

    const onMyOptionClick = type => {
        switch (type) {
            case OPTION_TYPES.EDIT:
                navigateTo({
                    match,
                    history,
                    location,
                    subPath: `/edit/${post.id}`,
                });
                return;
            case OPTION_TYPES.DELETE:
                setShowDeleteConfirmation(true);
                return;
            default:
                return;
        }
    };

    const toggleComments = () => setExpandComments(!expandComments);

    const onPostReport = () => {
        reportPost(post);
    };

    const showUsers = () => setShowUsersList(true);

    const hideUsers = () => setShowUsersList(false);

    const onHeaderClick = userId =>
        navigateTo({
            match,
            history,
            location,
            params: {
                type: 'profile',
                userId,
            },
        });

    return (
        <Container role="article" aria-posinset={index} aria-setsize={postsSize}>
            <PostHeader>
                {user.id > 0 && (
                    <>
                        <ImageAvatar
                            onClick={() => onHeaderClick(user.id)}
                            imageId={user.imageUrl}
                            avatarType={'persons'}
                            altText={`${user.firstName} ${user.lastName}'s profile picture`}
                        />
                        <FlexContainer>
                            <FlexContainer
                                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                            >
                                <span style={{ color: COLOR_TEXT, fontSize: 15 }}>
                                    {`${user.firstName} ${user.lastName}`}{' '}
                                </span>
                                <span style={{ color: BLACK_60 }}>{ago}</span>
                            </FlexContainer>
                            {isAuthenticated && (
                                <div style={{ justifySelf: 'flex-end' }}>
                                    <Dropdown
                                        items={isMine ? myDropdownOptions : dropdownOptions}
                                    />
                                </div>
                            )}
                        </FlexContainer>
                    </>
                )}
            </PostHeader>
            {image && image.length > 0 && <PostImagePreview source={image} />}
            {message && message.length && (
                <TextContainer>{ReactHtmlParser(message.replaceAll('\n', '<br />'))}</TextContainer>
            )}

            <DividerStyled role={'presentation'} />
            <StatusContainer>
                <StyledButton
                    flat
                    iconEl={
                        <FontIconWrapper color={yourLikeId && 'red'}>
                            <FontIcon aria-hidden>
                                {yourLikeId ? 'favorite' : 'favorite_outline'}
                            </FontIcon>
                        </FontIconWrapper>
                    }
                    onClick={onPressLike}
                >
                    {LIKE_TEXT}
                </StyledButton>
                <StyledButton
                    flat
                    iconEl={
                        <FontIcon aria-hidden className="material-icons-outlined">
                            message
                        </FontIcon>
                    }
                    onClick={toggleComments}
                >
                    {commentsTitle}
                </StyledButton>
            </StatusContainer>
            {showLikes && (
                <>
                    <DividerStyled />
                    <LikesContainer onClick={showUsers}>
                        <FontIcon aria-hidden>favorite</FontIcon>
                        <LikesLabel>{label}</LikesLabel>
                    </LikesContainer>
                </>
            )}

            {expandComments && (
                <AuthenticationProtection goBack={() => setExpandComments(false)}>
                    <DividerStyled />
                    <PostDetail id={id} />
                </AuthenticationProtection>
            )}

            <ConfirmDialog
                visible={showDeleteConfirmation}
                options={{
                    cancelText: 'Cancel',
                    confirmText: getString('deleteButton') || 'Delete',
                    title: 'Delete post',
                    text: 'Are you sure you want to delete your post?',
                    cancel: () => {},
                    confirm: () => removePost(post),
                }}
                onHideDialog={() => setShowDeleteConfirmation(false)}
            />

            {showUsersList && (
                <AuthenticationProtection goBack={hideUsers}>
                    <UsersModal users={usersWithDetails} onClose={hideUsers} />
                </AuthenticationProtection>
            )}
        </Container>
    );
};

Post.propTypes = {
    post: PropTypes.shape({
        user: PropTypes.shape({}),
        time: PropTypes.string,
        comments: PropTypes.arrayOf(PropTypes.number),
        likes: PropTypes.arrayOf(PropTypes.number),
        image: PropTypes.string,
        message: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isMine: PropTypes.bool,
    }),
    likesInfo: PropTypes.shape({
        users: PropTypes.arrayOf(PropTypes.shape({})),
        label: PropTypes.string,
        yourLikeId: PropTypes.number,
    }),
    addLike: PropTypes.func,
    removeLike: PropTypes.func,
    removePost: PropTypes.func,
    reportPost: PropTypes.func,
};

Post.defaultProps = {
    post: {
        user: {},
    },
    likesInfo: {},
    addLike: () => {},
    removeLike: () => {},
    removePost: () => {},
    reportPost: () => {},
};

const mapStateToProps = (state, ownProps) => ({
    post: selectPostById(ownProps.postId, state),
    likesInfo: selectLikeUsersByPostId(ownProps.postId, state),
});

export default connect(mapStateToProps, {
    addLike: addLikeAction,
    removeLike: removeLikeAction,
    removePost: removePostAction,
    reportPost: reportPostAction,
})(withRouter(Post));
