import { useEffect } from 'react';
import Auth from '../../../services/api/auth';
import { useSocket } from '../../../components/Session/SocketContext';
import { useSessionRegistrationStore } from '../stores/SessionRegistrationStore';

const useSessionRegistration = eventId => {
    const isAuthenticated = Auth.isUserAuthenticated();
    const currentUserId = Auth.getUser()?.id;
    const { socket, isReconnecting } = useSocket();
    const { getRegisteredSessions, resetRegisteredSessions } = useSessionRegistrationStore();

    useEffect(() => {
        if (currentUserId && eventId && socket && !isReconnecting) {
            const updateSessions = data => {
                if (parseInt(data.userId, 10) === parseInt(currentUserId, 10)) {
                    getRegisteredSessions(eventId);
                }
            };

            socket.emit('joinSessionRegistration', { userId: currentUserId, eventId });
            socket.on('update_session_registrations', updateSessions);

            return () => {
                socket.emit('leaveSessionRegistration', { userId: currentUserId, eventId });
                socket.removeAllListeners('update_session_registrations');
            };
        }

        return () => {};
    }, [currentUserId, eventId, socket, isReconnecting]);

    useEffect(() => {
        if (isAuthenticated && eventId) {
            getRegisteredSessions(eventId);
        } else {
            resetRegisteredSessions();
        }
        return () => {
            resetRegisteredSessions();
        };
    }, [isAuthenticated, eventId]);
};

export default useSessionRegistration;
