import React from 'react';
import InfoBar from '../InfoBar';
import SectionsWrapper from '../SectionsWrapper';
import RepresentativesList from '../Representatives';
import Slideshow from '../../../components/Slideshow';
import { useEventStore } from '../../../stores/EventStore';
import { useInstitutionsStore } from '../../../stores/InstitutionsStore';

const InfoTab = props => {
    const [representatives, setRepresentatives] = React.useState([]);
    const eventId = useEventStore(state => state.id);
    const fetchInstitutions = useInstitutionsStore(state => state.fetchInstitutions);

    React.useEffect(() => {
        const getInstitutions = async () => {
            if (props?.item?.type === 'institution') {
                const institutions = await fetchInstitutions(eventId);
                const institution = institutions.find(
                    institution => institution.id === props?.item?.id,
                );
                setRepresentatives(institution?.representatives || []);
            }
        };
        getInstitutions();
    }, [props?.item?.type, eventId]);

    const items = props.item.sections.filter(
        i =>
            (i.isParent || i.type !== 'timeslot') &&
            i.type !== 'link' &&
            i.type !== 'place' &&
            i.type !== 'programelement',
    );

    const renderRepresentatives = React.useCallback(() => {
        if (representatives.length === 0) {
            return;
        }

        return <RepresentativesList representatives={representatives} />;
    }, [representatives]);

    return (
        <React.Fragment>
            {props.item.params &&
                props.item.params.slideshow &&
                props.item.params.slideshow.length > 0 && (
                    <Slideshow data={props.item.params.slideshow} />
                )}
            {props.item.info && props.item.info.text && (
                <InfoBar info={props.item.info} typeParams={props.item.typeParams} {...props} />
            )}
            {renderRepresentatives(props.item)}
            <SectionsWrapper item={{ sections: items }} clickOpenDetail={props.clickOpenDetail} />
        </React.Fragment>
    );
};

export default InfoTab;
