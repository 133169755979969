import React, { useContext, useEffect, useRef, useState } from 'react';
import { getLocalAppState } from '../../services/api';
import styled from 'styled-components';
import NavigationLink from '../../components/Navigation/NavigationLink';
import { Avatar, List } from 'react-md';
import throttle from 'lodash/throttle';
import { ImageAvatar } from '../../components/Icons';
import { getInitials } from '../VirtualSession/components/userInteraction/attendees/Attendees';
import {
    getCurrentEventAttendees,
    getCurrentEventAttendeesCountries,
} from '../VirtualSession/services/attendees';
import { useGlobalState } from '../../utils/container';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { getFullProfile, getUserSettings, updateUserSettings } from '../../services/api/eureka';
import { ConfirmDialog } from '../../components/Dialog';
import { getString } from '../../services/api/store';
import Search from '../../components/General/Search';
import Loader from '../../components/General/Loader';
import * as palette from '../../components/General/Variables';
import { StyledListItem } from '../../components/ObjectListItem/style/index';
import { MenuItem, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomDropdown from '../../components/Selectors/CustomDropdown';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const CustomAvatar = styled(Avatar)`
    background-color: #bfbfbf !important;
    border: none !important;
    width: 56px !important;
    height: 56px !important;

    .md-avatar-content {
        font-size: 14px !important;
    }
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${window.innerWidth}px;
`;

const SearchWrapper = styled.div`
    position: sticky;
    padding: 16px;
    height: 72px;
    background-color: ${palette.COLOR_WHITE};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    top: 0;
    z-index: 1;
`;

const Item = ({ item, params }) => {
    return (
        <StyledListItem
            component={Link}
            to={`?type=${params.type}&userId=${params.userId}`}
            height={'80px'}
            primaryText={`${item.User.firstName} ${item.User.lastName}`}
            tabIndex={-1}
            secondaryText={
                <>
                    <div>{item.User.jobTitle}</div>
                    <div>{item.User.companyName}</div>
                </>
            }
            leftAvatar={
                item.User.imageUrl ? (
                    <ImageAvatar
                        imageId={item.User.imageUrl}
                        avatarType={'persons'}
                        altText={`${item.User.firstName} ${item.User.lastName}'s profile picture`}
                    />
                ) : (
                    <CustomAvatar>
                        {getInitials(item.User.firstName, item.User.lastName)}
                    </CustomAvatar>
                )
            }
        />
    );
};

const AttendeeList = props => {
    const [allAttendees, setAllAttendees] = useState([]);
    const [filteredAttendees, setFilteredAttendees] = useState([]);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [eventId, setEventId] = useState('');
    const [loading, setLoading] = useState(false);
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const settings = useRef();
    const isSearching = useRef(false);
    const eventIdRef = useRef('');
    const { authUser } = useContext(AuthUserContext);

    useEffect(() => {
        props.setTitle();
    }, []);

    useEffect(() => {
        if (authUser) {
            getLocalAppState(async (err, localAppState) => {
                if (err || !localAppState) {
                    return;
                }
                settings.current = await getUserSettings(localAppState.eventId);
                settings.current = {
                    ...settings.current,
                    eventId: localAppState.eventId,
                    eventName: localAppState.eventName,
                };

                if (!settings.current.visible) {
                    setShowSettingsModal(true);
                }
                eventIdRef.current = localAppState.eventId;
                setEventId(localAppState.eventId);
                if (socket) {
                    socket.on(
                        `refreshCurrentAttendees_${localAppState.eventId}`,
                        getCurrentAttendees,
                    );
                }
            });
        }
    }, [authUser]);

    useEffect(() => {
        let active = true;

        if (eventId && active) {
            getCurrentAttendees();
        }

        return () => {
            active = false;
        };
    }, [country, eventId]);

    useEffect(() => {
        let active = true;

        if (eventId && active) {
            getCurrentCountries();
        }

        return () => {
            active = false;
        };
    }, [eventId]);

    const getCurrentCountries = async () => {
        if (eventId) {
            const resp = await getCurrentEventAttendeesCountries(eventId);
            if (resp) {
                setCountries(
                    resp.map(val => ({
                        id: val['CountryId'],
                        name: val['Country.name'],
                    })),
                );
            }
        }
    };

    const getCurrentAttendees = async () => {
        if (!isSearching.current) {
            setLoading(true);

            const allAttendees = await getCurrentEventAttendees(eventIdRef.current, country);
            const visibleAttendees = allAttendees && allAttendees.filter(i => i.visible);
            const sortedAttendees = sortAttendees(visibleAttendees);

            setAllAttendees(sortedAttendees);
            setFilteredAttendees(sortedAttendees);
            setLoading(false);
        }
    };

    const sortAttendees = attendees => {
        const sortedAttendeeList = attendees.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.User.lastName} ${attendee1.User.firstName}`;
            const name2 = `${attendee2.User.lastName} ${attendee2.User.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        return sortedAttendeeList;
    };

    const attendeeItems = () =>
        filteredAttendees.map((item, i) => {
            return (
                <React.Fragment key={'attendee_' + i}>
                    <Item
                        item={item}
                        i={i}
                        params={{
                            type: 'profile',
                            userId: item.User.id,
                        }}
                    />
                </React.Fragment>
            );
        });

    const onClose = () => {
        setShowSettingsModal(false);
    };

    const onSave = async () => {
        const userSettings = {
            visible: true,
            available: settings.current.available,
            eventId: settings.current.eventId,
            eventTitle: settings.current.eventName,
        };

        await updateUserSettings(userSettings);
        const userData = await getFullProfile();

        if (socket) {
            socket.emit('privacySettingsUpdated', {
                eventId: settings.current.eventId,
                userId: userData.id,
            });
        }

        onClose();
    };

    const onSearchValueChange = throttle(async value => {
        if (!value) {
            setFilteredAttendees(allAttendees);
        } else {
            const searchedAttendees = allAttendees.filter(attendee => {
                const { firstName, lastName, jobTitle, companyName } = attendee.User;
                const searchTerms = `${firstName} ${lastName} ${jobTitle} ${companyName}`.toLowerCase();

                return searchTerms.includes(value.toLowerCase());
            });
            setFilteredAttendees(searchedAttendees);
        }
        isSearching.current = value.length > 0;
        if (value.length === 0) {
            await getCurrentAttendees();
        }
    }, 500);

    const options = {
        title: getString('attendeesMessageInvisibleTitle') || 'You are invisible...',
        text:
            getString('attendeesMessageInvisible') ||
            'Other people cannot see you in this list and cannot contact you',
        confirmText: getString('attendeesConfirmInvisibleButton') || 'Go Visible',
        cancelText: getString('attendeesCancelInvisibleButton') || 'Stay Invisible',
        confirm: onSave,
        cancel: onClose,
    };

    const countryOptions = [
        { value: '', label: 'All countries' },
        ...countries.map(c => ({ value: c.name, label: c.name })),
    ];

    return (
        <>
            {authUser && (
                <React.Fragment>
                    {loading && <Loader />}
                    {!loading && filteredAttendees && (
                        <>
                            <SearchWrapper>
                                <Search
                                    action={onSearchValueChange}
                                    live={true}
                                    skey={props.pageId}
                                    isExpandableSearch={true}
                                    displayLeft={true}
                                />
                                <CustomDropdown
                                    ariaLabel={'Filter by country'}
                                    value={country}
                                    options={countryOptions}
                                    onChange={event => setCountry(event.target.value)}
                                    displayEmpty
                                    style={{
                                        left: 336,
                                        borderRadius: 25,
                                        minWidth: 100,
                                        height: 40,
                                        minHeight: 40,
                                        position: 'absolute',
                                        top: 16,
                                    }}
                                />
                            </SearchWrapper>
                            <ListStyled className="">{attendeeItems()}</ListStyled>
                        </>
                    )}
                    {showSettingsModal && (
                        <ConfirmDialog visible={true} options={options} onHideDialog={onClose} />
                    )}
                </React.Fragment>
            )}
        </>
    );
};

export default AttendeeList;
