import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { getLocalAppState } from '../../../../services/api';
import { fetchNewsFeed, getTargetedMessages } from '../../../Feed/service/newsfeed';
import Auth from '../../../../services/api/auth';
import styled from 'styled-components';
import { AWS_IMAGES_ENDPOINT, S3_ENDPOINT_IMAGES } from '../../../../config';
import * as palette from '../../../../components/General/Variables';
import { Button, FontIcon } from 'react-md';
import { FeedCard } from '../../../Feed';
import ReactHtmlParser from 'react-html-parser';
import { buttonTypes } from '../../../common/Button';
import { getString } from '../../../../services/api/store';
import AnalyticsService from '../../../../features/analytics/services';
import { useMediaQuery } from '@mui/material';
import { CCLinkTransform } from '../../../../helper/CCLink';
import { TimezoneContext } from '../../../Timezone/context';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import { DetailDrawer } from '../../../../components/General/DetailDrawer';
import NewsFeedDetailPage from '../../../Feed/NewsFeed/NewsFeedDetailPage';

const FeedDetailContainer = styled.div`
    padding: 12px 16px 16px 16px;
`;

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const TimeAndPinned = styled.div`
    font-family: Roboto, sans-serif;
    color: rgba(0, 0, 0, 0.6);
    margin: 8px 0 12px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
`;

export const Content = styled.div`
    ${props =>
        props.isPreview &&
        `
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    `}

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
        border: none;
    }

    & > * {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);

        a {
            text-decoration: none;
            color: ${props => props.color} !important;
        }
    }
`;

const HeaderBackground = styled.img`
    background-color: ${palette.COLOR_GREY};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    text-align: center;
    border-radius: 4px;
`;

export const PinIcon = styled(FontIcon)`
    font-size: 16px !important;
    color: ${palette.BLACK_60} !important;
    margin-right: 4px;
`;

export const Bullet = styled.div`
    display: inline-block;
    gap: 8px;
    width: 3px;
    height: 3px;
    margin: 12px 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.54);
`;

const NewsFeedWidget = () => {
    const READ_MORE_BUTTON = getString('announcementsReadMore') || 'Read More';

    const [eventId, setEventId] = useState(null);
    const [object, setObject] = useState(null);
    const [newsFeed, setNewsFeed] = useState([]);
    const [itemIdsWithViewSampleSent, setItemIdsWithViewSampleSent] = useState([]);

    const onShowMoreClick = item => {
        setObject(item);
        sendViewSample(item._id, true);
    };

    const match = useRouteMatch();
    const location = useLocation();
    const { theme } = useTheme();
    const { getUtcToSelectedTimezone } = useContext(TimezoneContext);
    const isMobile = useMediaQuery(`(max-width: ${palette.MAX_PHONE}px)`);

    const dateTime = date => getUtcToSelectedTimezone(date).format('D MMM YYYY, HH:mm');

    const sendViewSample = (itemId, triggeredByShowMore = false) => {
        if (itemIdsWithViewSampleSent.includes(itemId)) {
            return;
        }

        if (!triggeredByShowMore) {
            setItemIdsWithViewSampleSent([...itemIdsWithViewSampleSent, itemId]);
        }

        AnalyticsService.addSample('announcement', itemId, itemId);
    };

    useEffect(() => {
        getLocalAppState(async (err, appState) => {
            if (err) {
                console.log(err);
            } else {
                const isAuthenticated = Auth.isUserAuthenticated();
                let targetedMessages = [];

                setEventId(appState.eventId);
                const allNewsFeed = await fetchNewsFeed(appState.eventId, appState.configuratorUrl);

                if (isAuthenticated) {
                    targetedMessages = await getTargetedMessages(
                        appState.eventId,
                        appState.configuratorUrl,
                    );
                }
                const mergedNewsFeed = [...allNewsFeed, ...targetedMessages];
                const availableNewsFeed = mergedNewsFeed
                    .filter(feed => feed.deleted === 0)
                    .sort((item1, item2) => new Date(item2.date) - new Date(item1.date))
                    .sort((x, y) => (x.pinned ? -1 : y.pinned ? 1 : 0));

                setNewsFeed(availableNewsFeed);

                // itemId that comes from onesignal notification click
                if (location.state && location.state.itemId) {
                    const item = availableNewsFeed.find(item => item._id === location.state.itemId);

                    if (item) {
                        onShowMoreClick(item);
                    }
                }
            }
        });
    }, [eventId]);

    const transform = node => {
        return CCLinkTransform(node, match, history, location);
    };

    const renderCards = () =>
        newsFeed.map((item, index) => {
            const textElem = document.getElementById(`text-container-${item._id}`);
            const showMore = textElem && textElem.offsetHeight < textElem.scrollHeight;

            let bannerImage;
            if (item.mediaImage && AWS_IMAGES_ENDPOINT && S3_ENDPOINT_IMAGES) {
                bannerImage = item.mediaImage.replace(S3_ENDPOINT_IMAGES, AWS_IMAGES_ENDPOINT);
            }

            return (
                <div
                    key={item._id}
                    role="article"
                    aria-posinset={index + 1}
                    aria-setsize={newsFeed.length}
                    aria-labelledby={`title-${item._id}`}
                >
                    <FeedCard
                        item={item}
                        hasShowMore={showMore}
                        onSendAnalytics={sendViewSample}
                        onClick={() => onShowMoreClick(item)}
                    >
                        {bannerImage && (
                            <HeaderBackground
                                src={bannerImage}
                                alt={'News message image'}
                                isMobile={isMobile}
                            />
                        )}
                        {item.mediaVideoEmbed && item.mediaVideoRaw && (
                            <Content>
                                {ReactHtmlParser(item.mediaVideoEmbed, { transform })}
                            </Content>
                        )}
                        <FeedDetailContainer>
                            <TimeAndPinned>
                                {item.pinned && (
                                    <>
                                        <PinIcon aria-hidden>push_pin</PinIcon>
                                        Pinned
                                        <Bullet />
                                    </>
                                )}
                                {dateTime(item.date)}
                            </TimeAndPinned>

                            <Title id={`title-${item._id}`}>{item.subject}</Title>
                            <Content
                                color={theme.contrast}
                                isPreview={true}
                                id={`text-container-${item._id}`}
                            >
                                {ReactHtmlParser(item.body, { transform })}
                            </Content>
                            {showMore && (
                                <Button
                                    aria-label={'Read more'}
                                    onClick={() => onShowMoreClick(item)}
                                    type={buttonTypes.GREY}
                                    text={READ_MORE_BUTTON}
                                    style={{ marginTop: '20px' }}
                                />
                            )}
                        </FeedDetailContainer>
                    </FeedCard>
                </div>
            );
        });

    return (
        <section role="feed" aria-busy="false" aria-label={'News feed'}>
            {renderCards()}
            {object && (
                <DetailDrawer>
                    <NewsFeedDetailPage
                        item={object}
                        date={dateTime(object.date)}
                        onCloseModal={() => setObject(null)}
                        transform={transform}
                    />
                </DetailDrawer>
            )}
        </section>
    );
};

export default NewsFeedWidget;
