import React, { useEffect, useState } from 'react';
import { Divider, List, ListItem } from 'react-md';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import eventBus from '../../utils/eventBus';

import Header from '../../components/DetailHeader';
import { ImageAvatar } from '../../components/Icons';
import Slideshow from '../../components/Slideshow';
import ThemeContext from '../../components/Theme/ThemeContext';
import InteractivityBar from '../DetailPage/interactivityTab/InteractivityBar';
import { addFavoriteForEvent, removeFavorite } from '../../services/api/data';
import * as eureka from '../../services/api/eureka';
import { getSponsorPage } from './services/sponsor';
import AnalyticsService from '../../features/analytics/services';
import {
    AchievementType,
    useAchievementActions,
} from '../Achievements/hooks/useAchievementActions';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const AStyled = styled.a`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const SlideshowWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const Item = ({ item, i }) => (
    <ListItem
        tileStyle={{
            borderTop: i === 0 ? '0px solid rgba(0, 0, 0, 0.08)' : '1px solid rgba(0, 0, 0, 0.08)',
        }}
        primaryText={item.name}
        leftAvatar={item.icon ? <ImageAvatar imageId={item.icon} shape="square" /> : ''}
    />
);

const Sponsor = params => {
    const header = React.createRef();
    const objectId = params.pageId;
    const [isMounted, setIsMounted] = useState(true);
    const [page, setPage] = useState({
        links: [],
        params: { items: [] },
        institution: { hasNote: false, isFavorite: '' },
    });
    const interactivityInfo = {
        id: page.institution.id,
        name: page.institution.name,
        objectClass: 'institution',
        interactivity: page.institution.interactivity,
        hasNote: page.institution.hasNote,
    };

    useEffect(() => {
        getSponsorPage(objectId, (err, page) => {
            if (!isMounted) {
                return;
            }

            setPage(page);
            params.setTitle(page.params.title || '');
        });

        return () => {
            setIsMounted(false);
        };
    }, []);

    const renderSections = sections => {
        if (!sections || !sections.length) {
            return;
        }

        return sections.map((item, i) => {
            let navPath = null;
            let navParams = {
                type: 'detail',
                objectClass: item.linkType,
                objectId: item.linkObject,
            };

            if (item.linkType === 'floorplan') {
                navPath = `/floorplan/${item.floorplanId}/${item.linkObject}`;
                navParams = null;
            }

            if (item.linkType === 'webpage') {
                navParams = {
                    pageType: 'webpage',
                    pageId: item.linkObject,
                };
            }

            let externalLink = false;
            if (!item.linkType && !item.linkObject && item.url && item.url !== '') {
                externalLink = true;
            }

            let target = '_blank';
            if (item.url && (item.url.includes('mailto:') || false === item.isExternal)) {
                target = '_self';
            }
            const href = item.url ? item.url.replace('mailto://', 'mailto:') : '#';
            return (
                <React.Fragment key={'link_' + i}>
                    {!externalLink && (
                        <LinkStyled path={navPath} params={navParams}>
                            <Item item={item} i={i} />
                        </LinkStyled>
                    )}
                    {externalLink && (
                        <AStyled href={href} target={target}>
                            <Item item={item} i={i} />
                        </AStyled>
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            {page.params && page.params.images && (
                <SlideshowWrapper>
                    <Slideshow data={page.params.images} />
                </SlideshowWrapper>
            )}
            <div ref={header}>
                <Header
                    type={page.type}
                    time={page.time}
                    startTime={page.start}
                    endTime={page.end}
                    typeName={page.typeName}
                    title={page.institution.name || ''}
                    subtitle={page.subNameDetail}
                    picture={page.image}
                    params={page.params}
                    backgroundImage={page.backgroundImage}
                    typeParams={page.typeParams}
                />
            </div>

            {page.institution.interactivity && (
                <React.Fragment>
                    <Divider style={{ marginTop: '8px' }} />
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <React.Fragment>
                                {!theme.embed && !theme.kiosk && page.institution.interactivity && (
                                    <InteractivityBar item={interactivityInfo} />
                                )}
                            </React.Fragment>
                        )}
                    </ThemeContext.Consumer>
                </React.Fragment>
            )}

            <ListStyled>{renderSections(page.links)}</ListStyled>
        </React.Fragment>
    );
};

export default Sponsor;
