import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import { withRouter } from 'react-router-dom';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { useFavoritesStore } from '../stores/favoriteStore';
import { useAchievementActions } from '../../Achievements/hooks/useAchievementActions';

const StyledIconButton = styled(IconButton)(({ theme, top, right }) => ({
    position: 'absolute',
    top: top ? `${top}px` : '11px',
    right: right ? `${right}px` : '16px',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme?.transitions?.create('background-color', {
        duration: theme.transitions.duration.short,
    }),
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    [theme?.breakpoints?.up('md')]: {
        right: '8px',
    },
}));

const FavoriteButton = ({
    item,
    smallBookmarkButton,
    top,
    right,
    bookmark,
    match,
    history,
    location,
}) => {
    const { favorites, updateFavorite, loading } = useFavoritesStore();
    const { theme } = useTheme();
    const { embed, kiosk } = theme;
    const { authUser, showSignIn } = useContext(AuthUserContext);
    const { trackAchievement } = useAchievementActions();

    const isLoading = loading === item.id;

    const isFavorite = useMemo(() => {
        const found = favorites.find(ci => ci.objectId === item.id);
        return found && found.objectId;
    }, [favorites, item]);

    const onClick = async e => {
        e.preventDefault();
        if (authUser) {
            const currentEvent = await getLocalAppStateAsync();
            await updateFavorite(currentEvent.id, item, trackAchievement);
        } else {
            showSignIn(e, {
                match,
                history,
                location,
            });
        }
    };

    return (
        <React.Fragment>
            {!embed && !kiosk && bookmark && (
                <StyledIconButton
                    aria-label={isFavorite ? 'Remove from favourites' : 'Save to favourites'}
                    onClick={onClick}
                    onKeyDown={e => e.key === 'Enter' && onClick(e)}
                    top={top}
                    right={right}
                    disabled={isLoading}
                >
                    {isFavorite ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
                </StyledIconButton>
            )}
            {!embed && !kiosk && !bookmark && (
                <Button
                    type={smallBookmarkButton ? buttonTypes.GREY : buttonTypes.GREY_LONG}
                    style={
                        smallBookmarkButton
                            ? {
                                  width: '56px',
                              }
                            : {}
                    }
                    background={theme.primary}
                    icon={isFavorite ? 'bookmark' : 'bookmark_border'}
                    iconAriaLabel={'Save icon'}
                    onClick={onClick}
                    iconCustomColor={isFavorite && theme.primary}
                    tooltip={
                        isFavorite
                            ? getString('removeFromFavorites', 'Remove from favorites')
                            : getString('saveToFavorites', 'Save to favorites')
                    }
                    text={
                        smallBookmarkButton
                            ? ''
                            : isFavorite
                            ? getString('savedButton') || 'Saved'
                            : getString('saveButton') || 'Save'
                    }
                    ariaLabel={
                        isFavorite
                            ? getString('removeFromFavorites', 'Remove from favorites')
                            : getString('saveToFavorites', 'Save to favorites')
                    }
                    ariaHideTooltip={true}
                    enabled={!isLoading}
                />
            )}
        </React.Fragment>
    );
};

export default withRouter(FavoriteButton);
