import { create } from 'zustand';
import { useEffect } from 'react';
import { getItemAsync } from '../services/api/data';
import { executeQuery } from '../services/api/graphQlRepository';
import { getImageSourceAsync } from '../services/api/db';
import { getEventInstitutionProposals, getFullProfileById } from '../services/api/eureka';
import { useEventStore } from './EventStore';

const enrichInstitution = async institution => {
    if (!institution) return null;

    const institutionLinks = await Promise.all(
        institution?.links?.map(async link => {
            const linkItem = await getItemAsync('link', link._id);
            return { ...linkItem, _id: link._id };
        }) || [],
    );

    const slideshow = await Promise.all(
        institution?.slideshow?.map(async slide => {
            if (slide._id) {
                const slideItem = await getImageSourceAsync(slide._id);
                return { ...slide, _id: slide._id, imageUrl: slideItem };
            }
            return slide;
        }) || [],
    );

    return {
        ...institution,
        links: institutionLinks,
        typeEntity: await getItemAsync('types', institution.type),
        slideshow,
        bannerImageUrl: institution.bannerImage
            ? await getImageSourceAsync(institution.bannerImage)
            : null,
        cardImageUrl: institution.cardImage
            ? await getImageSourceAsync(institution.cardImage)
            : null,
        backgroundImageUrl: institution.backgroundImage
            ? await getImageSourceAsync(institution.backgroundImage)
            : null,
        imageUrl: institution.image ? await getImageSourceAsync(institution.image) : null,
    };
};

export const useInstitutionsStore = create((set, get) => ({
    institutionSet: {},
    allInsitutionsQueried: false,
    fetchInstitution: async id => {
        if (!id) return;

        const institutionExists = get().institutionSet[id];
        if (institutionExists) return institutionExists;

        const institution = await getItemAsync('institution', id);
        const institutionWithType = await enrichInstitution(institution);

        set({ institutionSet: { ...get().institutionSet, [id]: institutionWithType } });
        return institutionWithType;
    },
    fetchInstitutions: async eventId => {
        if (get().allInsitutionsQueried) return Object.values(get().institutionSet);

        const institutions = await executeQuery('findInstitutions', {
            event: eventId,
        });
        const proposals = await getEventInstitutionProposals(eventId);

        const enrichedInstitutions = await Promise.all(institutions.map(enrichInstitution));

        const institutionsMap = {};
        for (const inst of enrichedInstitutions) {
            if (!inst) continue;

            institutionsMap[inst._id || inst.id] = inst;

            const proposal = proposals.find(
                p => p.reference === inst._id || p.reference === inst.id,
            );
            const representativesFromParams = inst?.params?.representatives || [];
            const fullRepresentatives = await Promise.all(
                representativesFromParams
                    .filter(
                        representative =>
                            !!proposal?.institutionRepresentatives.find(
                                r => r.id === representative.id,
                            ),
                    )
                    .map(async representative => {
                        return await getFullProfileById(representative.id || representative.userId);
                    }),
            );

            institutionsMap[inst._id || inst.id].representatives = [
                ...(proposal?.institutionRepresentatives || []),
                ...fullRepresentatives,
            ];
        }

        set({ institutionSet: institutionsMap, allInsitutionsQueried: true });
        return Object.values(institutionsMap);
    },
}));

export const useInstitutionById = institutionId => {
    const fetchInstitution = useInstitutionsStore(state => state.fetchInstitution);

    useEffect(() => {
        fetchInstitution(institutionId);
    }, [institutionId]);

    return useInstitutionsStore(state => state.institutionSet[institutionId]);
};

export const useEventInstitutions = () => {
    const eventId = useEventStore(state => state.id);
    const fetchInstitutions = useInstitutionsStore(state => state.fetchInstitutions);

    useEffect(() => {
        if (!eventId) return;

        (async () => {
            await fetchInstitutions(eventId);
        })();
    }, [eventId]);

    return useInstitutionsStore(state => state.institutionSet);
};
