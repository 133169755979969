import { useEffect } from 'react';
import Auth from '../../../services/api/auth';
import { useSocket } from '../../../components/Session/SocketContext';
import { sync } from '../../../services/api/eureka';
import { useFavoritesStore } from '../stores/favoriteStore';

const useFavorites = eventId => {
    const { getFavorites, resetFavorites, setFavorites, setLoading } = useFavoritesStore();
    const isAuthenticated = Auth.isUserAuthenticated();
    const currentUserId = Auth.getUser()?.id;
    const { socket, isReconnecting } = useSocket();

    useEffect(() => {
        if (currentUserId && eventId && socket && !isReconnecting) {
            const updateFavoriteEvent = async data => {
                if (parseInt(data.userId, 10) === parseInt(currentUserId, 10) && data?.favorites) {
                    if (data.legacySync) {
                        sync(async () => {
                            await getFavorites(eventId);
                            setLoading('');
                        });
                    } else {
                        await setFavorites(data.favorites);
                        setLoading('');
                    }
                }
            };
            socket.emit('joinFavorites', { userId: currentUserId, eventId });
            socket.on('update_favorites', updateFavoriteEvent);
            // TODO if there is incoming external sync set here a function to check every some minutes

            return () => {
                socket.emit('leaveFavorites', { userId: currentUserId, eventId });
                socket.off('update_favorites');
            };
        }

        return () => {};
    }, [currentUserId, eventId, socket, isReconnecting]);

    useEffect(() => {
        if (isAuthenticated && eventId) {
            getFavorites(eventId);
        } else {
            resetFavorites();
        }

        return () => {
            resetFavorites();
        };
    }, [isAuthenticated, eventId]);
};

export default useFavorites;
