import React from 'react';
import Button from '@mui/material/Button';
import { FontIcon } from 'react-md';
import styled from 'styled-components';

import MaterialDialog from './MaterialDialog';
import useSocietySettings from '../../scenes/Society/hooks/useSocietySettings';
import {
    baseButtonStyle,
    cancelButtonStyle,
    saveButtonSocietyStyle,
    saveButtonStyle,
} from './styles';
import { useTheme } from '../Theme/ThemeContext';
import { getBlackOrWhiteCalculated } from '../General/Colors';

/**
 * @component
 * @description A basic small/medium/large dialog (modal) component based on MaterialDialog component with some modifications made to size
 * @param {Object} props
 * @param {JSX} props.children content rendered inside Dialog
 * @param {Object} props.contentStyle additional style added to content
 * @param {Function} props.onClose function called when the Dialog is dismissed by pressing the Esc key or the X button at the top
 * @param {Array.<{title: String, variant: String, color: String, onClick: Function, type: string('save' | 'cancel')}>} props.options
 * List of Objects to create this Dialog buttons. Variants can be: 'contained', 'outlined' or 'text' (default) and color 'primary', 'secondary' or 'default'
 * @param {String} props.size defines dialog size. It could be 'xs' (default), 'sm' or 'md'
 * @param {String} props.title title on the dialog. Can also be empty
 * @param {Boolean} props.visible when *false* the dialog is hidden and displayed when *true*
 * @param {Boolean} props.displayBoxShadow when *true* the Action Buttons Container has an upper shadow effect
 * @param {Object} props.extraStyles additional styles applied to the Material UI Dialog component (See: https://mui.com/api/dialog/#css)
 */
const Icon = styled(FontIcon)`
    margin: 0;
    width: 24px;
`;

const ComplexDialog = ({
    children,
    contentStyle,
    onClose = () => {},
    options = [],
    size = 'xs',
    title = '',
    visible = false,
    displayBoxShadow = false,
    extraStyles = {},
}) => {
    const { isSociety } = useSocietySettings();
    const { theme } = useTheme();

    const DialogButtons = () => {
        return options.map(op => {
            let buttonStyle = baseButtonStyle;
            let saveButtonFinalStyle = isSociety ? saveButtonSocietyStyle : saveButtonStyle;
            if (theme && theme.primary) {
                saveButtonFinalStyle = {
                    ...saveButtonFinalStyle,
                    backgroundColor: theme.primary,
                    color: getBlackOrWhiteCalculated(theme.primary),
                };
            }

            switch (op.type) {
                case 'save':
                    buttonStyle = { ...buttonStyle, ...saveButtonFinalStyle };
                    break;
                case 'cancel':
                    buttonStyle = { ...buttonStyle, ...cancelButtonStyle };
                    break;
                case 'delete':
                    buttonStyle = {
                        ...buttonStyle,
                        ...saveButtonStyle,
                        backgroundColor: '#ee1060',
                    };
                    break;
                default:
                    buttonStyle = { textTransform: 'none' };
                    break;
            }

            return (
                <Button
                    key={`bt_${op.title}`}
                    color={op.color}
                    disableElevation
                    onClick={op.onClick}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            op.onClick();
                        }
                    }}
                    style={buttonStyle}
                    variant={op.variant}
                    disabled={op.disabled}
                    startIcon={op.icon && <Icon inherit>{op.icon}</Icon>}
                >
                    {op.title}
                </Button>
            );
        });
    };

    return (
        <MaterialDialog
            contentStyle={contentStyle}
            maxWidth={size}
            onClose={onClose}
            options={DialogButtons()}
            title={title}
            visible={visible}
            isComplex
            displayBoxShadow={displayBoxShadow}
            extraStyles={extraStyles}
        >
            {children}
        </MaterialDialog>
    );
};

export default ComplexDialog;
