import React from 'react';
import {
    AvatarContainer,
    ButtonsContainer,
    Card,
    CloseCardIcon,
    CompanyText,
    EurekaAvatar,
    LoginCloseContainer,
    Subtitle,
    Title,
} from '../style/signIn';
import * as string from '../constants/strings';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';

const Welcome = ({
    theme,
    profileImage,
    firstName,
    lastName,
    company,
    goHome,
    handleProfileModal,
    jobTitle,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const NOT_NOW = getString('updateNotNowButton') || 'Not now';
    const TEXT_SIGNED_IN_DESCRIPTION =
        getString('signInSuccessSubtitle') || string.SIGNED_IN_DESCRIPTION;
    const TEXT_SIGNED_IN_PROFILE_TEXT =
        getString('signInSuccessMainButton') || string.SIGNED_IN_PROFILE_TEXT;
    const TEXT_WELCOME_USER = getString('signedIntroTitle') || 'Welcome';

    return (
        <Card isModal={isModal}>
            {!eurekaOnly && !attendingOnly && (
                <LoginCloseContainer>
                    <CloseCardIcon
                        aria-hidden={true}
                        role={'presentation'}
                        onClick={onClose}
                        aria-label={'Close'}
                    >
                        {palette.ICON_CLOSE}
                    </CloseCardIcon>
                </LoginCloseContainer>
            )}
            <AvatarContainer>
                <EurekaAvatar src={profileImage} role="presentation">
                    {`${firstName[0]}${lastName[0]}`}
                </EurekaAvatar>
            </AvatarContainer>

            <div>
                <Title>
                    {`${TEXT_WELCOME_USER},`}
                    <br />
                    {firstName} {lastName}
                </Title>
                <CompanyText>
                    <div>{jobTitle}</div>
                    <span>&nbsp;@&nbsp;</span>
                    <div>{company}</div>
                </CompanyText>
            </div>
            <Subtitle>{TEXT_SIGNED_IN_DESCRIPTION}</Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={TEXT_SIGNED_IN_PROFILE_TEXT}
                    onClick={handleProfileModal}
                    data-qa="button-check-profile-and-settings"
                />
                <Button
                    type={buttonTypes.GHOST_LONG}
                    background={theme.primary}
                    text={NOT_NOW}
                    onClick={goHome}
                    data-qa="button-not-now-check-profile-and-settings"
                    style={{ marginTop: '12px' }}
                />
            </ButtonsContainer>
        </Card>
    );
};

export default Welcome;
