import styled from 'styled-components';
import {
    AccessibleFakeButton,
    Cell,
    Drawer,
    DropdownMenu,
    FontIcon,
    ListItem,
    Tab,
    Tabs,
    TabsContainer,
} from 'react-md';
import * as palette from '../../../components/General/Variables';
import { Flex } from '../../common/Flex';
import noFavoritesImg from '../../../assets/images/no-favorites.png';
import noResultsImg from '../../../assets/images/no-results.png';
import {
    GRID_HEIGHT,
    GRID_WIDTH_LARGE,
    GRID_WIDTH_SMALL,
    programmeViewTypes,
} from '../constants/index';
import { CustomAvatar } from '../../UserProfile/style';
import NavigationLink from '../../../components/Navigation/NavigationLink';

export const SearchContainer = styled(Cell)`
    padding-left: 6px;
    padding-right: 12px;
    width: 100%;
`;

export const ProgramTypeButtonsContainer = styled(Cell)``;

export const TabsWrapper = styled(Tabs)`
    width: 100%;
    background-color: ${props => props.background || '#fff'} !important;
    overflow: scroll;
    transition: all cubic-bezier(0.1, 0.7, 1, 0.1) 900ms;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const TabsContainerStyled = styled(TabsContainer)`
    flex-grow: 1;
    transition: width 0.4s linear;
    flex-shrink: 1;

    ul {
        overflow-x: auto;
        box-shadow: unset;
        height: ${props => props.heght || '72px'};
        align-items: center;
        transition: all 0.4s linear;
        position: relative;
        background: transparent !important;
        ${props => props.paddingLeft && `padding-left: ${props.paddingLeft} !important`};
    }

    .md-tab-indicator {
        display: none !important;
    }

    ul:before {
        content: '';
        width: ${props => props.gridWidth + 'px'};
        transition: width 0.4s linear;

        height: 72px;
        position: fixed;
        ${props =>
            props.applyGrid &&
            `background: linear-gradient(to ${props.gridDirection}, #fff 0%, rgba(255, 255, 255, 0) 60%)`};
        z-index: 1;
        pointer-events: none;
    }
`;

export const ArrowButton = styled(AccessibleFakeButton)`
    padding: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: transparent !important;
    margin-right: 8px;
    flex-shrink: 0;

    ${props => !props.applyColor && 'display: none !important;'}
    &:hover {
        background-color: ${props => (props.applyColor ? '#f5f5f5 ' : 'transparent')} !important;
    }

    i {
        font-size: 24px !important;
        color: ${props => !props.applyColor && 'transparent'} !important;
    }
`;

export const ArrowButtonRight = styled(ArrowButton)`
    position: absolute !important;
    left: -2px;
    right: 0;
    z-index: 9;
`;

export const SearchAndArrowContainer = styled(Flex)`
    transition: width 0.4s linear;
    z-index: 2;
`;

export const ToolbarContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    flex-wrap: nowrap;
    position: sticky;
    top: 0;
    z-index: 3;

    & > * {
        min-width: 0 !important;
    }
`;

export const FilterLabels = styled(Flex)`
    background: ${palette.COLOR_WHITE};
    padding-left: 16px;
    width: 100%;
    overflow: auto;

    div {
        white-space: nowrap;
    }
`;

export const ToolbarIcon = styled.div`
    border: 1px solid #dde1e5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e1e1f !important;
    cursor: pointer;
    flex-shrink: 0;

    i {
        color: #1e1e1f !important;
        font-size: 24px !important;
    }
`;

export const ViewButton = styled.button`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #dde1e5;
    height: 40px;
    border-radius: 50px;
    padding: 0 16px;
    margin-right: 8px;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    outline: none;

    i {
        color: #1e1e1f !important;
        margin-left: 8px;
    }

    &:visited,
    &:focus {
        background: #ebedf0;
    }
`;

export const StyledListItem = styled(ListItem)`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
    .md-list {
        border-radius: 8px !important;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1);
        margin-top: 12px;
        padding: 8px;
        width: ${props => props.customWidth || 'fit-content'} !important;

        li {
            div {
                border-radius: 8px;

                &:hover {
                    background-color: #ebedf0;
                }
            }
        }
    }
`;

export const Timezone = styled.div`
    text-align: right;
    padding: 7px 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
`;

export const TimezoneInRuler = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(30, 30, 31, 0.38);
    cursor: pointer;
    margin: ${props =>
        props.viewType === programmeViewTypes.HORIZONTAL_VIEW ? '14px 0 0 24px' : '21px 0 0 -29px'};

    &:hover {
        text-decoration: underline;
    }
`;

export const SmallLink = styled.a`
    font-size: 10px;
    color: ${props => props.primary || palette.COLOR_PRIMARY};
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
`;

export const TabElement = styled(Tab)`
    background: ${props => (props.active ? props.primary + '20' : '#f5f5f5')} !important;
    color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.87)')} !important;
    border-radius: 50px;
    ${props => props.active && `border: 1px solid ${props.primary}`}
    width: fit-content !important;
    flex-grow: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    padding: 8px 16px !important;
    margin: ${props => (props.margin ? props.margin : '0 8px 0 0')} !important;
    height: 40px !important;
    text-transform: unset !important;
    justify-content: center !important;

    .md-tab {
        width: 0 !important;
    }

    .md-tab-label {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
    }

    ${props =>
        props.active
            ? ``
            : `
        &:hover {
            background-image: ${palette.HOVER_OVERLAY} !important;
        }
    `};
`;

export const DetailDrawer = styled(Drawer)`
    width: ${props => (props.width ? props.width : '100%')} !important;

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        width: 362px;
    }
`;

export const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

export const EmptyState = styled.div`
    text-align: center;
    padding-top: 50px;
    border-top: 1px solid #e5e5e5;

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }

    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

export const Indicator = styled.div`
    background-color: ${props => props.contrast};
    height: ${props => props.wHeight};
    width: 3px;
    position: absolute;
    top: 0px;
    z-index: 4;
    ${props => props.isVertical && 'transform: rotate(90deg)'};
`;

export const IndicatorVertical = styled.div`
    width: 100%;
    height: 3px;
    background-color: ${props => props.contrast};
    position: absolute;
    left: 0px;
    z-index: 4;
`;

export const Container = styled.div`
    background: ${palette.COLOR_WHITE};
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100% !important;
    max-width: ${props => window.innerWidth}px !important;
    box-sizing: border-box;
    height: calc(100% - 72px);

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    &::after {
        clear: both;
        content: '';
        display: table;
    }

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
`;

export const Section = styled.div`
    position: relative;
    float: left;
    flex-grow: 1;
    padding: 0 !important;
    min-width: 100%;
`;

export const ContainerDiv = styled.div`
    max-height: calc(
        100vh -
            ${props =>
                props.withBrandingBanner && props.bannerShown ? props.bannerHeight : '142px'}
    );
    max-width: 100%;
    width: 100%;
    overflow: scroll;
    background: #ffffff;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
    }
`;

export const Ruler = styled.div`
    position: sticky;
    ${props => (props.isVertical ? 'left: 0' : 'top: 0')};
    background-color: white;
    z-index: ${props => (props.isVertical ? 3 : 2)};
    white-space: nowrap;
    ${props => props.isVertical && 'width: fit-content !important'};

    li {
        height: 46px;
    }
`;

export const TimeHeader = styled.header`
    z-index: 10;
    position: relative;
    background-color: white;
    height: 46px;
    line-height: 46px;
    transform-style: preserve-3d;
    font-size: 0;
    ${props =>
        props.isVertical &&
        `
      height: 100%;
      width: fit-content;
      line-height: unset;

      ul {
          flex-direction: column;
          display: flex;
          margin-left: 20px !important;
      }
      li {
          height: 115px;
      }
      li:last-of-type .time-label {
        transform: translateX(0%);
      }
    `};

    &::after {
        clear: both;
        content: '';
        display: table;
    }

    li {
        background-color: white;
        display: inline-block;
        overflow: visible;
        width: 0;
        display: inline-block;
        position: relative;
    }

    li:not(:last-of-type) {
        width: ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE)}px;
    }

    li:first-of-type {
        width: ${props => (props.isVertical ? GRID_WIDTH_SMALL / 2 : GRID_WIDTH_LARGE / 2)}px;
    }

    li .time-label {
        display: block;
        position: absolute;
        left: 0;
    }

    li:not(:first-of-type) .time-label {
        transform: translateX(-50%);
    }

    li:last-of-type .time-label {
        transform: translateX(-100%);
    }
`;

export const TimeHeaderVertical = styled(TimeHeader)`
    height: 100%;
    width: fit-content;
    line-height: 0;

    ul {
        flex-direction: column;
        display: flex;
        margin-left: 20px !important;
    }

    li {
        height: 120px;
        width: 45px !important;

        span {
            margin-left: 10px;
        }
    }

    li:last-of-type {
        margin-left: -12px;
    }

    li:last-of-type .time-label {
        transform: unset;
    }
`;

export const VerticalHeader = styled.ul`
    display: flex;
    flex-direction: column;
    margin-left: 20px !important;
`;

export const TimerCell = styled.li`
    h6 {
        margin: 0;
        padding: 0;
        line-height: inherit;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: rgba(30, 30, 31, 0.38) !important;
    }
`;

export const TimerCellHalf = styled(TimerCell)`
    width: ${props => (props.isVertical ? GRID_WIDTH_SMALL / 2 : GRID_WIDTH_LARGE / 2)}px;
`;

export const RoomTimeline = styled.div`
    border-left: none;

    ul {
        height: ${GRID_HEIGHT + 26}px;
        position: relative;
        background-color: rgba(244, 245, 247, 1);
        transform: translateX(
            -${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE) / 2}px
        );
        width: calc(
            100% + ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE) / 2}px
        );
    }

    ul:first-of-type {
        border-top: 0px solid #e5e5e5;
    }

    ul:last-of-type {
        border-bottom: 0px solid #e5e5e5;
    }

    ul:not(:last-of-type) {
        border-bottom: none;
    }

    ul:first-child .time-entry {
        height: ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE) * 0.5}px;
    }

    ul:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    ul:after {
        background-image: linear-gradient(
            to right,
            rgba(30, 30, 31, 0.15),
            rgba(30, 30, 31, 0.15) 0.5px,
            ${palette.COLOR_WHITE} 0.5px,
            ${palette.COLOR_WHITE} 0.5px,
            rgba(30, 30, 31, 0.15) 0.5px,
            rgba(30, 30, 31, 0.15) 0.5px,
            transparent 1px,
            transparent
        );
        background-size: ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE)}px auto;
        background-position: -1px 0;
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        ul:first-child .time-entry {
            height: ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE) * 0.5}px;
        }

        ul:before {
            background-image: linear-gradient(to right, #e5e5e5 1px, transparent 1px);
            background-size: ${props =>
                    (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE) * 0.5}px
                auto;
        }

        ul:after {
            background-image: linear-gradient(
                to right,
                #e5e5e5,
                #e5e5e5 2px,
                ${palette.COLOR_WHITE} 1px,
                ${palette.COLOR_WHITE} 2px,
                #e5e5e5 2px,
                #e5e5e5 3px,
                transparent 3px,
                transparent
            );
            background-size: ${props => (props.isVertical ? GRID_WIDTH_SMALL : GRID_WIDTH_LARGE)}px
                auto;
            background-position: -1px 0;
        }
    }
`;

export const RoomSection = styled.div`
    position: sticky;
    top: 46px;
    z-index: 1;
    height: 32px;
    text-align: left;
    background-color: rgba(221, 225, 229, 1);
    line-height: 32px;
    margin: 0;
    padding-left: 20px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: rgba(30, 30, 31, 0.6);
    display: flex;
    align-items: center;
    h5 {
        margin: 0;
    }
`;

export const RoomSectionContainer = styled.section`
    &:last-of-type {
        min-height: 120px;
    }
`;

export const RoomTitle = styled.div`
    position: sticky;
    height: 100%;
    width: fit-content;
    right: 20px;
    left: 20px;
`;

export const PlaceholderImage = styled.div`
    background-image: url(${props => (props.myprogram ? noFavoritesImg : noResultsImg)});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    height: 500px;
`;

export const RoomSectionContainerVertical = styled.section`
    width: 512px;
    min-width: 350px;
    height: ${props => props.height};
    position: relative;

    ul {
        margin-left: 32px;
    }
`;

export const RoomNameContainer = styled.div`
    background: #fff;
    height: 56px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 56px;
    color: rgba(30, 30, 31, 0.38);
    padding-left: 32px;
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
`;

export const RoomNameFixedContainer = styled(RoomNameContainer)`
    position: fixed;
    top: 134px;
    width: 100%;
`;

export const HiddenContainer = styled.div`
    background: rgba(221, 225, 229, 0.5);
`;

export const RoomTimelineVertical = styled(Flex)`
    height: 100vh;
    ${props => props.fullWidth && 'min-width: 100vw !important;'};

    ul {
        height: 100%;
        background-color: rgba(244, 245, 247, 1);
    }

    ul:first-of-type {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    ul:first-of-type {
        &:after {
            background-image: linear-gradient(
                to bottom,
                rgba(30, 30, 31, 0.15),
                rgba(30, 30, 31, 0.15) 0.5px,
                ${palette.COLOR_WHITE} 0.5px,
                ${palette.COLOR_WHITE} 0.5px,
                rgba(30, 30, 31, 0.15) 0.5px,
                rgba(30, 30, 31, 0.15) 0.5px,
                transparent 1px,
                transparent
            ) !important;
            background-size: auto ${GRID_WIDTH_SMALL}px;
            background-position: -1px 0;
        }
    }
`;

export const SectionVertical = styled(Section)`
    height: inherit;
    display: flex;
`;

export const PendingLabel = styled.div`
    background: #ffffff;
    border-radius: 4px;
    width: fit-content;
    height: 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    color: #00a86b;
    padding: 6px 8px;
    border-radius: 4px;
`;

export const StyledAvatar = styled(CustomAvatar)`
    width: 24px !important;
    height: 24px !important;
    border: 2px solid #fff !important;

    .md-avatar-content {
        font-size: 12px !important;
        line-height: 24px;
    }

    &:nth-of-type(2) {
        margin-left: -5px;
        margin-right: 8px;
    }
`;

export const StyledLink = styled(NavigationLink)`
    text-decoration: none;
    background-color: ${props => (props.pendingColor ? '#F4F5F7' : '#fff')};
    transition: 200ms background-color;
    height: ${props =>
        props.height ? props.height : props.itemsContainsImages ? '160px' : '108px'} !important;
    width: ${props =>
        props.width
            ? props.width
            : props.isVertical && props.backgroundimage
            ? '335px'
            : '175px'} !important;
    min-height: 0;
    display: block;
    position: relative;
    z-index: 1;
    margin: ${props => (props.isVertical ? '2px' : '0')};
    white-space: normal;
    overflow: hidden;
    transform-style: preserve-3d;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${props => props.ismeeting && 'min-width: 76px'};

    ${props => props.isVertical && props.ismeeting && 'min-height: 32px'}
    h6 {
        font-size: 12px;
    }

    &:hover {
        background-color: #efefef;
        ${props =>
            props.favorite &&
            `
        background-color: #fff;
     `}
    }
`;

export const FavoritesContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 8px;
    padding: 8px 4px 8px 8px;

    ${props =>
        props.favorite &&
        `
        background-color: ${props.primary + 20};
        border: 1px solid ${props.primary};
     `};

    &:hover {
        ${props =>
            props.favorite &&
            `
        background-color: ${props.primary + 40};
     `};
    }
`;

export const TimeEntryContainer = styled(Flex)`
    width: 100%;
`;

export const DetailsWrapper = styled(Flex)`
    display: flex;
    ${props =>
        !props.isFlex ? 'flex-direction: column' : 'width: 100%; justify-content: space-between'};
`;

export const ClassifierLine = styled.div`
    width: 3px !important;
    min-width: 3px;
    border-radius: 4px;
    background: ${props => (props.classifiercolor ? props.classifiercolor : '#EBEDF0')};
    margin-right: ${props => props.marginRight || '8px'};
    ${props => props.minHeight && `min-height: ${props.minHeight}`};
`;

export const EntryLabel = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 20px;
    text-align: left;
    color: #1c1c1e;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props =>
        props.titleEightLines
            ? 8
            : props.titleFourLines
            ? 4
            : props.titleOneLine
            ? 1
            : props.hasSubtitle
            ? 1
            : 2};
    -webkit-box-orient: vertical;
    max-height: ${props =>
        props.titleEightLines || props.titleFourLines
            ? 'unset'
            : props.hasSubtitle
            ? '56px'
            : '86px'};
`;

export const Icon = styled(FontIcon)`
    color: ${props => props.color} !important;
`;

export const VideoIcon = styled(Icon)`
    border-radius: 4px;
    font-size: 24px !important;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.38) !important;
`;

export const SponsorLogo = styled.img`
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-right: 4px;
`;

export const EntryImage = styled.div`
    background-image: url(${props => props.backgroundimage});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 72px;
    margin: auto 0;
    border-radius: 8px;
    max-width: 147px;
`;

export const EntryImageVertical = styled(EntryImage)`
    width: 145px;
    height: 72px;
    margin: 0 5px 0 0;
`;

export const EntrySubLabel = styled.div`
    height: ${props => props.hasSubtitle && props.isHorizontal && '100%'};
    font-family: 'Roboto', sans-serif;
    margin-top: ${props => (props.hasSubtitle ? '4px' : '2px')};
    font-size: 13px;
    line-height: 18px;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    color: ${props => props.highlighted || '#1e1e1f'};
    opacity: 0.6;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${props => props.hasSubtitle && !props.isHorizontal && 'margin-bottom: 6px'};
    ${props => props.hasSubtitle && props.isHorizontal && 'margin-bottom: 8px'};
`;

export const AppointmentDetailsContainer = styled(Flex)`
    flex-wrap: wrap;
    margin-top: ${props => (props.smallAppointmentCard ? 0 : '4px')};
`;
