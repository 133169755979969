import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import * as StyledComponent from '../../style/modalsStyle';
import { getString } from '../../../../services/api/store';
import * as constants from '../../constants';
import * as palette from '../../../../components/General/Variables';
import * as string from '../../../SignInPage/constants/strings';
import InputLabel from '@mui/material/InputLabel';

export const PersonalInformation = ({
    fieldValues,
    onChange,
    countries,
    onSelectChange,
    afterSignUp,
    importedFields,
}) => {
    const PERSONAL_INFORMATION_TITLE =
        getString('personalInfoSubtitle') || constants.PERSONAL_INFORMATION;
    const isImportedField = field => importedFields && importedFields.indexOf(field) !== -1;

    const firstNameIsExternal = isImportedField('firstName');
    const lastNameIsExternal = isImportedField('lastName');
    const titleIsExternal = isImportedField('title');
    const profileJobIsExternal = isImportedField('jobTitle');
    const headlineIsExternal = isImportedField('headline');
    const companyNameIsExternal = isImportedField('companyName');
    const countryIsExternal = isImportedField('CountryId');

    const renderSelectedCountry = selected => {
        if (!selected) {
            return (
                <StyledComponent.DropdownPlaceholder>
                    {getString('profileCountry') || 'Country'}
                    {countryIsExternal && (
                        <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                            {palette.ICON_CLOUD_DOWNLOAD}
                        </StyledComponent.LabelIcon>
                    )}
                </StyledComponent.DropdownPlaceholder>
            );
        }

        const selectedCountry = countries.find(country => country.value === selected);
        return selectedCountry ? selectedCountry.label : '';
    };

    return (
        <StyledComponent.FormWrapper>
            <StyledComponent.SectionText noMarginTop={true} noMarginLeft={true}>
                {PERSONAL_INFORMATION_TITLE}
            </StyledComponent.SectionText>
            <StyledComponent.FlexContainerWithTwoChildren flex1={1} flex2={3}>
                <StyledComponent.StyledTextField
                    id="profile-title"
                    name="title"
                    label={
                        <div>
                            {getString('titlesHintText') || 'Title(s)'}
                            {titleIsExternal && (
                                <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                    {palette.ICON_CLOUD_DOWNLOAD}
                                </StyledComponent.LabelIcon>
                            )}
                        </div>
                    }
                    value={fieldValues.title}
                    onChange={onChange}
                    afterSignUp={afterSignUp}
                    disabled={titleIsExternal}
                />
                <StyledComponent.StyledTextField
                    id="profile-firstName"
                    name="firstName"
                    label={
                        <div>
                            {getString('firstNameHintText') || 'First name'}
                            {firstNameIsExternal && (
                                <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                    {palette.ICON_CLOUD_DOWNLOAD}
                                </StyledComponent.LabelIcon>
                            )}
                        </div>
                    }
                    value={fieldValues.firstName}
                    onChange={onChange}
                    required
                    error={!fieldValues.firstName || fieldValues.firstName.length === 0}
                    errorText="First name is required."
                    afterSignUp={afterSignUp}
                    disabled={firstNameIsExternal}
                />
            </StyledComponent.FlexContainerWithTwoChildren>
            <StyledComponent.StyledTextField
                id="profile-lastName"
                name="lastName"
                label={
                    <div>
                        {getString('lastNameHintText') || 'Last name'}
                        {lastNameIsExternal && (
                            <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.lastName}
                onChange={onChange}
                required
                error={!fieldValues.lastName || fieldValues.lastName.length === 0}
                errorText="Last name is required."
                afterSignUp={afterSignUp}
                disabled={lastNameIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-companyName"
                name="companyName"
                label={
                    <div>
                        {getString('profileCompany') || string.COMPANY_LABEL}
                        {companyNameIsExternal && (
                            <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.companyName}
                onChange={onChange}
                afterSignUp={afterSignUp}
                disabled={companyNameIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-jobTitle"
                name="jobTitle"
                label={
                    <div>
                        {getString('profileJobTitle') || 'Job title'}
                        {profileJobIsExternal && (
                            <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.jobTitle}
                onChange={onChange}
                afterSignUp={afterSignUp}
                disabled={profileJobIsExternal}
            />
            <StyledComponent.StyledTextField
                id="profile-headline"
                name="headline"
                label={
                    <div>
                        {getString('profileHeadline') || 'Bio'}
                        {headlineIsExternal && (
                            <StyledComponent.LabelIcon aria-hidden={true} role={'presentation'}>
                                {palette.ICON_CLOUD_DOWNLOAD}
                            </StyledComponent.LabelIcon>
                        )}
                    </div>
                }
                value={fieldValues.headline}
                onChange={onChange}
                rows={1}
                maxRows={5}
                afterSignUp={afterSignUp}
                disabled={headlineIsExternal}
                fixedHeight={112}
            />
            <FormControl fullWidth>
                {fieldValues.country ? (
                    <InputLabel
                        sx={{
                            color: 'rgba(0, 0, 0, 0.38)',
                            fontSize: '13px !important',
                            transform: 'translate3d(11px, 14px, 0)',
                        }}
                        shrink
                    >
                        Country
                    </InputLabel>
                ) : null}
                <Select
                    sx={{
                        marginTop: '8px',
                        borderRadius: '6px',
                        '&.MuiOutlinedInput-root': {
                            borderColor: '#ebedf0',
                            backgroundColor: '#ebedf0',
                        },
                        '& .MuiOutlinedInput-input': {
                            paddingLeft: '11px',
                            paddingBottom: '11px',
                            paddingTop: '22px',
                        },
                        maxHeight: '200px',
                    }}
                    name="country"
                    labelId="profile-country-label"
                    id="profile-country"
                    value={fieldValues.country}
                    displayEmpty
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 200,
                            },
                        },
                    }}
                    renderValue={renderSelectedCountry}
                    disabled={countryIsExternal}
                >
                    {Array.isArray(countries) &&
                        countries.map(country => (
                            <MenuItem
                                key={country.value}
                                value={country.value}
                                onClick={() => onSelectChange(country.value)}
                            >
                                {country.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </StyledComponent.FormWrapper>
    );
};
