import React, { useEffect, useState } from 'react';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import styled from 'styled-components';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CheckFontIcon from '@mui/icons-material/Check';
import { FontIcon } from 'react-md';

import AddEditRTMPModal from '../moderator/sessionView/preRecordingsAndVideos/AddEditRTMPModal';
import entities from '../../constants/entities';
import { useVMMutation, useVMState } from '../../containers/main';
import { deleteRtmpPlayer, createRtmpPlayer } from '../../services/VirtualEventSession';
import { LightTooltip } from '../moderator/common/styles';
import { colorTheme } from '../../constants/colors';
import useAccessibleElement from '../../../../../../hooks/useAccessibleElement';
import Stack from '@mui/material/Stack';
const { virtualEventUserEntity } = entities;

const OptionsButton = styled.i`
    cursor: pointer;
    margin: 0;
`;

const CaretWrapper = styled.i`
    cursor: pointer;
    margin: ${props => (props.alignright ? '0 8px 0 68px' : '0 8px 0 38px')};
`;

const EmptySpace = styled.div`
    width: ${props => (props.small ? '24px' : '40px')};
`;

const StyledCheckFontIcon = styled(CheckFontIcon)`
    padding-right: ${props => (props.nopadding ? '0' : '16px')};
    color: rgba(0, 0, 0, 0.6) !important;
`;

const SettingsWrapper = styled.div`
    #settings-list,
    #video-audio-input-list {
        display: flex;
        flex-direction: column;
    }

    [id*='-toggle'] {
        background: #fff !important;
        color: #000 !important;
        box-shadow: none !important;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 15px !important;

        &:hover {
            background: rgba(0, 0, 0, 0.12) !important;
            box-shadow: none !important;
        }
    }

    [id^='settings-toggle'] {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
    }

    #settings-toggle {
        min-width: 40px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    #video-audio-input,
    #rtmp-input,
    #video-settings,
    #audio-settings,
    #rtmp-stream,
    #rtmp-active-settings {
        height: 48px !important;
    }

    #video-audio-input-toggle,
    #video-settings-toggle,
    #audio-settings-toggle,
    #rtmp-active-settings-toggle {
        display: flex;
        align-items: center;
        height: inherit !important;
        margin: 0 4px;
        border-radius: 4px;
    }

    [id^='camera-option'],
    [id^='audio-option'],
    #rtmp-stream,
    #rtmp-stream-properties {
        display: flex;
        align-items: center;
        height: inherit !important;
        margin: 0 4px;
        border-radius: 4px;
    }

    .md-list-item {
        height: 48px;
    }

    .md-tile-content {
        padding-left: 16px;
    }

    .md-list-tile {
        padding-left: 16px !important;
    }

    .md-btn--text {
        text-transform: none !important;
    }

    .md-tile-text--primary {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 15px !important;
    }
`;

const HostedSessionSettingsWrapper = styled(SettingsWrapper)`
    #settings-toggle {
        background-color: ${colorTheme.SECONDARY} !important;
    }
`;

const StyledFontIcon = styled(FontIcon)`
    color: #323232 !important;
    padding-right: 11px;
`;

const menuListStyle = {
    borderRadius: 4,
};

const SelectSourceMenu = ({ isHostedSession }) => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { config, cameraList, microphoneList, virtualEventUser, socket, sessionId } = stateCtx;
    const { role: userRole } = virtualEventUser.data;
    const isModerator = userRole === virtualEventUserEntity.role.moderator;
    const [isVideoAudioInputSelected, setIsVideoAudioInputSelected] = useState(
        !virtualEventUser.data.rtmpInfo,
    );
    const [isVideoSourceHovered, setIsVideoSourceHovered] = useState(false);
    const [isAudioSourceHovered, setIsAudioSourceHovered] = useState(false);
    const [showAddRtmpModal, setShowRtmpModal] = useState(false);

    const cameraOptions = cameraList.map((camera, index) => ({
        id: `camera-option-${index}`,
        primaryText: camera.label,
        onClick: () => {
            mutationCtx.changeCamera(camera.deviceId);
            if (virtualEventUser.data.rtmpInfo) {
                removeRtmpStreams();
            }
        },
        onKeyDown: e => {
            if (e.key === 'Enter') {
                mutationCtx.changeCamera(camera.deviceId);
                if (virtualEventUser.data.rtmpInfo) {
                    removeRtmpStreams();
                }
            }
        },
        leftIcon:
            config.cameraId === camera.deviceId ? (
                <StyledCheckFontIcon nopadding={1} />
            ) : (
                <EmptySpace small={1} />
            ),
        onMouseEnter: () => {
            setIsVideoSourceHovered(true);
        },
        onMouseLeave: () => {
            setIsVideoSourceHovered(false);
        },
    }));
    const microphoneOptions = microphoneList.map((microphone, index) => ({
        id: `audio-option-${index}`,
        primaryText: microphone.label,
        onClick: () => {
            mutationCtx.changeMicrophone(microphone.deviceId);
            if (virtualEventUser.data.rtmpInfo) {
                removeRtmpStreams();
            }
        },
        onKeyDown: e => {
            if (e.key === 'Enter') {
                mutationCtx.changeMicrophone(microphone.deviceId);
                if (virtualEventUser.data.rtmpInfo) {
                    removeRtmpStreams();
                }
            }
        },
        leftIcon:
            config.microphoneId === microphone.deviceId ? (
                <StyledCheckFontIcon nopadding={1} />
            ) : (
                <EmptySpace small={1} />
            ),
        onMouseEnter: () => {
            setIsAudioSourceHovered(true);
        },
        onMouseLeave: () => {
            setIsAudioSourceHovered(false);
        },
    }));

    const onAddRtmpInput = async rtmpInfo => {
        await createRtmpPlayer(sessionId, {
            playUrl: rtmpInfo.rtmpURL,
            uid: rtmpInfo.uid,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        isMicrophoneOn: false,
                        isVideoOn: false,
                        rtmpInfo,
                    },
                ],
            },
            userId: virtualEventUser.data.UserId,
            updateCall: true,
        });
    };

    const removeRtmpStreams = async () => {
        await deleteRtmpPlayer(sessionId, {
            uid: virtualEventUser.data.rtmpInfo.uid,
        });

        socket.emit('updateData', {
            objectId: sessionId,
            virtualEventSession: {
                VirtualEventUsers: [
                    {
                        id: virtualEventUser.data.id,
                        rtmpInfo: null,
                    },
                ],
            },
            userId: virtualEventUser.data.UserId,
            updateCall: true,
        });
    };

    useEffect(() => {
        setIsVideoAudioInputSelected(!virtualEventUser.data.rtmpInfo);
    }, [virtualEventUser.data.rtmpInfo]);

    useAccessibleElement({
        inputElementId: 'settings-toggle',
    });
    useAccessibleElement({
        inputElementId: 'video-settings-toggle',
    });
    useAccessibleElement({
        inputElementId: 'audio-settings-toggle',
    });

    if (isModerator) {
        return (
            <SettingsWrapper className="eureka-react">
                <MenuButton
                    adjusted={0}
                    raised
                    primary
                    id="settings"
                    position={MenuButton.Positions.BOTTOM_LEFT}
                    anchor={{
                        x: MenuButton.HorizontalAnchors.RIGHT,
                        y: MenuButton.VerticalAnchors.TOP,
                    }}
                    listStyle={menuListStyle}
                    simplifiedMenu={false}
                    repositionOnScroll={false}
                    menuItems={[
                        <MenuButton
                            adjusted={0}
                            raised
                            primary
                            key="video-audio-input"
                            id="video-audio-input"
                            menuItems={[
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="video-settings"
                                    id="video-settings"
                                    menuItems={cameraOptions}
                                    simplifiedMenu={false}
                                    listStyle={menuListStyle}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.OVERLAP,
                                    }}
                                    position={MenuButton.Positions.BOTTOM_LEFT}
                                    repositionOnScroll={false}
                                    defaultVisible={isVideoSourceHovered}
                                    onMouseEnter={() => {
                                        setIsVideoSourceHovered(true);
                                    }}
                                    onMouseLeave={() => {
                                        setIsVideoSourceHovered(false);
                                    }}
                                >
                                    Video source
                                    <CaretWrapper className="icon-caret-right" />
                                </MenuButton>,
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="audio-settings"
                                    id="audio-settings"
                                    menuItems={microphoneOptions}
                                    simplifiedMenu={false}
                                    listStyle={menuListStyle}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.OVERLAP,
                                    }}
                                    position={MenuButton.Positions.BOTTOM_LEFT}
                                    repositionOnScroll={false}
                                    defaultVisible={isAudioSourceHovered}
                                    onMouseEnter={() => {
                                        setIsAudioSourceHovered(true);
                                    }}
                                    onMouseLeave={() => {
                                        setIsAudioSourceHovered(false);
                                    }}
                                >
                                    Audio source
                                    <CaretWrapper className="icon-caret-right" />
                                </MenuButton>,
                            ]}
                            simplifiedMenu={false}
                            listStyle={menuListStyle}
                            anchor={{
                                x: MenuButton.HorizontalAnchors.RIGHT,
                                y: MenuButton.VerticalAnchors.OVERLAP,
                            }}
                            position={MenuButton.Positions.BOTTOM_LEFT}
                            repositionOnScroll={false}
                            defaultVisible={isVideoSourceHovered}
                            onMouseEnter={() => {
                                setIsVideoSourceHovered(true);
                            }}
                            onMouseLeave={() => {
                                setIsVideoSourceHovered(false);
                            }}
                        >
                            {isVideoAudioInputSelected ? <StyledCheckFontIcon /> : <EmptySpace />}
                            Video/Audio input
                            <CaretWrapper className="icon-caret-right" />
                        </MenuButton>,
                        isVideoAudioInputSelected ? (
                            {
                                id: `rtmp-stream`,
                                primaryText: 'RTMP stream',
                                onClick: () => setShowRtmpModal(true),
                                leftIcon: <EmptySpace small={1} />,
                            }
                        ) : (
                            <MenuButton
                                adjusted={0}
                                raised
                                primary
                                key="rtmp-active-settings"
                                id="rtmp-active-settings"
                                simplifiedMenu={false}
                                listStyle={menuListStyle}
                                menuItems={[
                                    {
                                        id: `rtmp-stream-properties`,
                                        primaryText: 'View stream properties',
                                        onClick: () => setShowRtmpModal(true),
                                        leftIcon: <StyledFontIcon>cast</StyledFontIcon>,
                                    },
                                ]}
                                anchor={{
                                    x: MenuButton.HorizontalAnchors.RIGHT,
                                    y: MenuButton.VerticalAnchors.OVERLAP,
                                }}
                                position={MenuButton.Positions.BOTTOM_LEFT}
                                repositionOnScroll={false}
                                defaultVisible={isAudioSourceHovered}
                                onMouseEnter={() => {
                                    setIsAudioSourceHovered(true);
                                }}
                                onMouseLeave={() => {
                                    setIsAudioSourceHovered(false);
                                }}
                            >
                                <StyledCheckFontIcon />
                                RTMP stream
                                <CaretWrapper className="icon-caret-right" alignright={1} />
                            </MenuButton>
                        ),
                    ]}
                    aria-label="Camera and microphone settings"
                    role="button"
                >
                    <OptionsButton className="icon-caret-down" />
                </MenuButton>
                {showAddRtmpModal && (
                    <AddEditRTMPModal
                        onAdd={onAddRtmpInput}
                        onClose={() => setShowRtmpModal(false)}
                        item={virtualEventUser.data.rtmpInfo}
                    />
                )}
            </SettingsWrapper>
        );
    }

    if (isHostedSession) {
        return (
            <LightTooltip title="Select source">
                <Stack>
                    <HostedSessionSettingsWrapper>
                        <MenuButton
                            adjusted={0}
                            raised
                            primary
                            id="settings"
                            position={MenuButton.Positions.BOTTOM_LEFT}
                            listStyle={menuListStyle}
                            menuItems={[
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="video-settings"
                                    id="video-settings"
                                    menuItems={cameraOptions}
                                    simplifiedMenu={false}
                                    listStyle={menuListStyle}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.OVERLAP,
                                    }}
                                    position={MenuButton.Positions.BOTTOM_LEFT}
                                    repositionOnScroll={false}
                                    defaultVisible={isVideoSourceHovered}
                                    onMouseEnter={() => {
                                        setIsVideoSourceHovered(true);
                                    }}
                                    onMouseLeave={() => {
                                        setIsVideoSourceHovered(false);
                                    }}
                                >
                                    Video source
                                    <CaretWrapper className="icon-caret-right" />
                                </MenuButton>,
                                <MenuButton
                                    adjusted={0}
                                    raised
                                    primary
                                    key="audio-settings"
                                    id="audio-settings"
                                    menuItems={microphoneOptions}
                                    simplifiedMenu={false}
                                    listStyle={menuListStyle}
                                    anchor={{
                                        x: MenuButton.HorizontalAnchors.RIGHT,
                                        y: MenuButton.VerticalAnchors.OVERLAP,
                                    }}
                                    position={MenuButton.Positions.BOTTOM_LEFT}
                                    repositionOnScroll={false}
                                    defaultVisible={isAudioSourceHovered}
                                    onMouseEnter={() => {
                                        setIsAudioSourceHovered(true);
                                    }}
                                    onMouseLeave={() => {
                                        setIsAudioSourceHovered(false);
                                    }}
                                >
                                    Audio source
                                    <CaretWrapper className="icon-caret-right" />
                                </MenuButton>,
                            ]}
                            simplifiedMenu={false}
                            anchor={{
                                x: MenuButton.HorizontalAnchors.RIGHT,
                                y: MenuButton.VerticalAnchors.TOP,
                            }}
                            repositionOnScroll={false}
                            aria-label="Camera and microphone settings"
                            role="button"
                        >
                            <MoreHorizOutlinedIcon />
                        </MenuButton>
                    </HostedSessionSettingsWrapper>
                </Stack>
            </LightTooltip>
        );
    }

    return (
        <SettingsWrapper>
            <MenuButton
                adjusted={0}
                raised
                primary
                id="settings"
                position={MenuButton.Positions.BOTTOM_LEFT}
                listStyle={menuListStyle}
                menuItems={[
                    <MenuButton
                        adjusted={0}
                        raised
                        primary
                        key="video-settings"
                        id="video-settings"
                        menuItems={cameraOptions}
                        simplifiedMenu={false}
                        listStyle={menuListStyle}
                        anchor={{
                            x: MenuButton.HorizontalAnchors.RIGHT,
                            y: MenuButton.VerticalAnchors.OVERLAP,
                        }}
                        position={MenuButton.Positions.BOTTOM_LEFT}
                        repositionOnScroll={false}
                        defaultVisible={isVideoSourceHovered}
                        onMouseEnter={() => {
                            setIsVideoSourceHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsVideoSourceHovered(false);
                        }}
                    >
                        Video source
                        <CaretWrapper className="icon-caret-right" />
                    </MenuButton>,
                    <MenuButton
                        adjusted={0}
                        raised
                        primary
                        key="audio-settings"
                        id="audio-settings"
                        menuItems={microphoneOptions}
                        simplifiedMenu={false}
                        listStyle={menuListStyle}
                        anchor={{
                            x: MenuButton.HorizontalAnchors.RIGHT,
                            y: MenuButton.VerticalAnchors.OVERLAP,
                        }}
                        position={MenuButton.Positions.BOTTOM_LEFT}
                        repositionOnScroll={false}
                        defaultVisible={isAudioSourceHovered}
                        onMouseEnter={() => {
                            setIsAudioSourceHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsAudioSourceHovered(false);
                        }}
                    >
                        Audio source
                        <CaretWrapper className="icon-caret-right" />
                    </MenuButton>,
                ]}
                simplifiedMenu={false}
                anchor={{
                    x: MenuButton.HorizontalAnchors.RIGHT,
                    y: MenuButton.VerticalAnchors.TOP,
                }}
                repositionOnScroll={false}
            >
                <OptionsButton className="icon-caret-down" />
            </MenuButton>
        </SettingsWrapper>
    );
};

export default SelectSourceMenu;
