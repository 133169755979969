import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { selectTitle } from '../../scenes/ExhibitorPage/selectors';
import AuthButton from '../../scenes/User/containers/AuthButton';
import * as palette from '../General/Variables';
import { Flex } from '../../scenes/common/Flex';

const Container = styled.div`
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    border-bottom: ${props => (props.background ? 'unset' : '1px solid #efefef')};
    height: 57px !important;
    width: 100%;
    background: ${props => props.background || palette.COLOR_WHITE};

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        background-color: #ffffff;
        margin: 0;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.54);
    border-radius: 6px;
    padding: 0 16px;
    margin-left: 5px;
    height: 32px;
    text-align: center;
    color: #ffffff;
    font-family: Cabin, sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.46px;

    &,
    .md-icon {
        color: #ffffff !important;
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding-left: 8px;
        &,
        md-icon {
            color: #000000 !important;
        }
    }
`;

const Icon = styled(FontIcon)`
    vertical-align: top;
    color: rgba(0, 0, 0, 0.6) !important;
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: ${props => (props.isExhibitorPage ? '21px' : '17px')};
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    ${props => props.isExhibitorPage && 'margin-left: 16px'};
`;

const AuthWrapper = styled.div`
    border-radius: 32px;
    margin-right: 16px;
`;

const Header = ({
    goBack,
    title,
    children,
    customBackground,
    light,
    newVersionAvailable,
    onServiceWorkerUpdate,
    updateServiceWorker,
    waitingWorker,
    isExhibitorPage,
}) => {
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

    return (
        <Container background={customBackground}>
            {goBack ? (
                <Flex alignItems={'center'}>
                    <Icon onClick={goBack} aria-label={'Go back'}>
                        {palette.ICON_BACK}
                    </Icon>
                    {(isMobile || isExhibitorPage) && (
                        <Title isExhibitorPage={isExhibitorPage}>{title}</Title>
                    )}
                </Flex>
            ) : (
                <>{(isMobile || isExhibitorPage) && <Title>{title}</Title>}</>
            )}
            {children}
            <AuthWrapper>
                <AuthButton
                    light={light}
                    newVersionAvailable={newVersionAvailable}
                    onServiceWorkerUpdate={onServiceWorkerUpdate}
                    updateServiceWorker={updateServiceWorker}
                    waitingWorker={waitingWorker}
                />
            </AuthWrapper>
        </Container>
    );
};

const mapStateToProps = state => ({
    title: selectTitle(state.exhibitorBooth),
});
export default connect(mapStateToProps)(Header);
