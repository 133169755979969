import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getString } from '../../../services/api/store';
import {
    BLACK_60,
    RED_3,
    UI_GREY_6,
    WHITE,
    COLOR_TEXT_DISABLED,
} from '../../../components/General/Variables';
import { useSessionRegistrationStore } from '../stores/SessionRegistrationStore';
import Auth from '../../../services/api/auth';
import { useTheme } from '../../../components/Theme/ThemeContext';
import Button, { buttonTypes } from '../../common/Button';
import { convertHexToRGBA } from '../../../components/General/Colors';
import BasicDialog from '../../../components/Dialog/BasicDialog';
import { executeListQuery } from '../../../services/api/graphQlRepository';
import { getLocalAppStateAsync } from '../../../services/api/db';
import AuthUserContext from '../../../components/Session/AuthUserContext';

const IndicatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    right: -12px;
`;

const IndicatorCounter = styled.p`
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    margin-top: -1px;
`;

const IndicatorCounterFull = styled.p`
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
`;

const IndicatorText = styled.div`
    font-size: 10px;
    margin: 0;
    margin-top: -4px;
`;

const TextWrapper = styled.div`
    display: flex;
`;

const RequiredLabelWrapper = styled.div`
    padding: 2px 6px;
    background-color: ${UI_GREY_6};
    border-radius: 4px;
    display: inline-block;
`;

const RequiredLabel = styled.p`
    font-size: 10px;
    color: ${BLACK_60};
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 500;
`;

export const SessionRegistrationCapacityIndicator = ({ capacity, disabled }) => {
    const registerSeatsAvailable = getString('registerSeatsAvailable');
    const registerFull = getString('registerFull');

    const {
        theme: { primary },
    } = useTheme();

    const { isFull, enabled, total, registeredUsersCount } = capacity;

    const activeButtonColorWithOpacity = useMemo(() => convertHexToRGBA(primary, 0.1), [primary]);

    return enabled ? (
        <IndicatorWrapper
            style={{ backgroundColor: disabled ? WHITE : activeButtonColorWithOpacity }}
        >
            {!isFull && (
                <>
                    <TextWrapper>
                        <IndicatorCounter style={{ color: primary }}>
                            {`${total - registeredUsersCount} / ${total}`}
                        </IndicatorCounter>
                    </TextWrapper>
                    <TextWrapper>
                        <IndicatorText style={{ color: primary }}>
                            {registerSeatsAvailable}
                        </IndicatorText>
                    </TextWrapper>
                </>
            )}
            {isFull && (
                <TextWrapper>
                    <IndicatorCounterFull
                        style={{ color: disabled ? COLOR_TEXT_DISABLED : primary }}
                    >
                        {registerFull}
                    </IndicatorCounterFull>
                </TextWrapper>
            )}
        </IndicatorWrapper>
    ) : null;
};

const INIT_DIALOG_INFO = {
    show: false,
    title: '',
    description: '',
    cancel: '',
    confirm: '',
};

const getProgrammeItemsSameStartTime = async (id, start) => {
    const timeslots = await executeListQuery(
        'findTimeslots',
        {
            start: {
                eq: start,
            },
        },
        false,
        false,
        null,
        1,
    );
    return timeslots.filter(timeslot => {
        return timeslot.id !== id && timeslot.registrationRequired === true;
    });
};

const SessionRegistrationButtonComponent = ({
    item,
    fixAbsoluteChip,
    virtual,
    match,
    history,
    location,
    unregister,
}) => {
    const [dialogInfo, setDialogInfo] = useState(INIT_DIALOG_INFO);
    const [enableSync, setEnableSync] = useState(false);
    const confirmRegistrationTitle = getString('confirmRegistrationTitle');
    const confirmRegistrationSubtitle = getString('confirmRegistrationSubtitle');
    const confirmRegistrationConfirmButton = getString('confirmRegistrationConfirmButton');
    const confirmRegistrationCancelButton = getString('confirmRegistrationCancelButton');
    const confirmUnregistrationTitle = getString('confirmUnregistrationTitle');
    const confirmUnregistrationSubtitle = getString('confirmUnregistrationSubtitle');
    const confirmUnregistrationConfirmButton = getString('confirmUnregistrationConfirmButton');
    const confirmUnregistrationCancelButton = getString('confirmUnregistrationCancelButton');

    const registerButton = getString('registerButton');
    const registeredButton = getString('registeredButton');
    const registerButtonVirtual =
        getString('registerButtonVirtual') || 'Register for virtual session';

    const registerNotPossibleTitle = getString('registerNotPossibleTitle');
    const registerNotPossibleSubtitle = getString('registerNotPossibleSubtitle');
    const registerNotPossibleButton = getString('registerNotPossibleButton');

    const isAuthenticated = Auth.isUserAuthenticated();
    const {
        theme: { primary },
    } = useTheme();

    const {
        updateSessionRegistration,
        registeredSessions,
        setRegistrationSessionCapacity,
        activeSessionCapacity,
        resetRegistrationSessionCapacity,
        syncExternalRegistrations,
    } = useSessionRegistrationStore();

    const { authUser, showSignIn } = useContext(AuthUserContext);

    const syncExternal = useCallback(async () => {
        const { event, registrationUrl } = item;
        if (event && registrationUrl) {
            const appState = await getLocalAppStateAsync();

            const urlObj = new URL(registrationUrl);
            const client = urlObj.searchParams.get('client');
            const externalClient = appState?.externalEurekaLogin || client;

            if (externalClient) {
                syncExternalRegistrations(item.event, externalClient);
            }
        }

        setEnableSync(false);
    }, [item]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                syncExternal();
            }
        };

        if (enableSync) {
            // Add event listener for visibility change
            document.addEventListener('visibilitychange', handleVisibilityChange);
        } else {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }

        // Clean up the event listener
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [enableSync, syncExternal]);

    const isRegistered = useMemo(
        () => !!registeredSessions.find(session => session.reference === item.id),
        [item.id, registeredSessions],
    );

    const updateRegistration = useCallback(
        async e => {
            if (!authUser) {
                return showSignIn(e, {
                    match,
                    history,
                    location,
                });
            }

            const { registrationType, registrationUrl, start } = item;

            const items = await getProgrammeItemsSameStartTime(item.id, start);
            const hasRegisteredItem = items.some(session =>
                registeredSessions.some(
                    registeredSession => registeredSession.reference === session.id,
                ),
            );

            if (registrationType === 'external' && registrationUrl && registrationUrl.length > 0) {
                window.open(registrationUrl, '_blank');
                setEnableSync(true);
                return;
            }

            if (hasRegisteredItem && !isRegistered) {
                return setDialogInfo({
                    show: true,
                    title: registerNotPossibleTitle,
                    description: registerNotPossibleSubtitle,
                    cancel: registerNotPossibleButton,
                    confirm: '',
                });
            }

            const alertTitle = isRegistered ? confirmUnregistrationTitle : confirmRegistrationTitle;
            const alertText = isRegistered
                ? confirmUnregistrationSubtitle
                : confirmRegistrationSubtitle;
            const confirmText = isRegistered
                ? confirmUnregistrationConfirmButton
                : confirmRegistrationConfirmButton;
            const cancelText = isRegistered
                ? confirmUnregistrationCancelButton
                : confirmRegistrationCancelButton;

            setDialogInfo({
                show: true,
                title: alertTitle,
                description: alertText,
                cancel: cancelText,
                confirm: confirmText,
            });
        },
        [isRegistered, item, registeredSessions, authUser, showSignIn],
    );

    useEffect(() => {
        if (isAuthenticated && item) {
            const { event, id, registrationRoomCapacity } = item;
            setRegistrationSessionCapacity(event, id, registrationRoomCapacity);
        }
    }, [registeredSessions, isAuthenticated, item]);

    useEffect(() => {
        return () => {
            resetRegistrationSessionCapacity();
        };
    }, [item]);

    const registrationIsDisabled = activeSessionCapacity?.isFull && !isRegistered;

    let title = isRegistered ? registeredButton : registerButton;
    if (virtual) {
        title = isRegistered ? 'Registered for virtual session' : registerButtonVirtual;
    }

    const onClose = useCallback(() => {
        setDialogInfo(INIT_DIALOG_INFO);
    }, []);

    const update = useCallback(() => {
        if (item) {
            updateSessionRegistration(item.event, item.id);
            onClose();
        }
    }, [item]);

    return (
        <>
            {!unregister && (
                <Button
                    text={title}
                    type={buttonTypes.GREY_LONG}
                    background={primary}
                    iconCustomColor={isRegistered && primary}
                    icon={isRegistered ? 'how_to_reg' : 'person_add'}
                    outlinedIcon={!isRegistered}
                    enabled={!registrationIsDisabled}
                    onClick={e => {
                        updateRegistration(e);
                    }}
                    customTextColor={isRegistered && primary}
                    chip={
                        <SessionRegistrationCapacityIndicator
                            disabled={registrationIsDisabled}
                            capacity={activeSessionCapacity}
                        />
                    }
                    fixAbsoluteChip={activeSessionCapacity.enabled && fixAbsoluteChip}
                    ariaLabel={'Register'}
                ></Button>
            )}
            {unregister && (
                <Button
                    text={''}
                    type={buttonTypes.GREY}
                    icon={'person_remove'}
                    outlinedIcon
                    enabled={!registrationIsDisabled}
                    onClick={e => {
                        updateRegistration(e);
                    }}
                    style={{ width: '56px' }}
                    ariaLabel={'Unregister'}
                ></Button>
            )}

            <BasicDialog
                visible={dialogInfo.show}
                onClose={onClose}
                title={dialogInfo.title}
                options={[
                    ...(dialogInfo.confirm
                        ? [
                              {
                                  title: dialogInfo.confirm,
                                  variant: 'text',
                                  type: 'save',
                                  color: 'primary',
                                  onClick: update,
                              },
                          ]
                        : []),
                    {
                        title: dialogInfo.cancel,
                        variant: 'text',
                        type: 'cancel',
                        onClick: onClose,
                    },
                ]}
            >
                <div>{dialogInfo.description}</div>
            </BasicDialog>
        </>
    );
};

export const SessionRegistrationButton = withRouter(SessionRegistrationButtonComponent);

export const SessionRegistrationLabel = ({ id, mb }) => {
    const registeredLabel = getString('registeredLabel') || 'Registered';
    const registerLabel = getString('registerLabel') || 'Registration required';

    const { registeredSessions } = useSessionRegistrationStore();

    const isRegistered = useMemo(
        () => !!registeredSessions.find(session => session.reference === id),
        [id, registeredSessions],
    );

    return (
        <RequiredLabelWrapper
            style={{ backgroundColor: isRegistered ? RED_3 : UI_GREY_6, marginBottom: `${mb}px` }}
        >
            <RequiredLabel style={{ color: isRegistered ? WHITE : BLACK_60 }}>
                {isRegistered ? registeredLabel : registerLabel}
            </RequiredLabel>
        </RequiredLabelWrapper>
    );
};

SessionRegistrationLabel.propTypes = {
    id: PropTypes.string.isRequired,
    mb: PropTypes.number,
};

SessionRegistrationLabel.defaultProps = {
    mb: 8,
};

SessionRegistrationButton.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        start: PropTypes.string,
        id: PropTypes.string,
        event: PropTypes.string,
        registrationRoomCapacity: PropTypes.number,
        registrationType: PropTypes.string,
        registrationUrl: PropTypes.string,
    }).isRequired,
    fixAbsoluteChip: PropTypes.bool,
    virtual: PropTypes.bool,
};

SessionRegistrationButton.defaultProps = {
    buttonStyle: {},
    virtual: false,
};

SessionRegistrationCapacityIndicator.propTypes = {
    capacity: PropTypes.shape({
        enabled: PropTypes.bool,
        registeredUsersCount: PropTypes.string,
        total: PropTypes.string,
        isFull: PropTypes.bool,
    }).isRequired,
    disabled: PropTypes.bool.isRequired,
};

SessionRegistrationButton.defaultProps = {};
