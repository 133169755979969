import React, { useEffect, useRef, useState } from 'react';
import { AccessibleFakeButton, Button, FontIcon, IconSeparator } from 'react-md';
import styled from 'styled-components';

import { getString } from '../../../services/api/store';
import { InteractivityContext } from '../../../components/Interactivity';
import * as palette from '../../../components/General/Variables';
import { getBgAndFontColorForAllDifferentStates } from '../../../components/General/Colors';
import { useTheme } from '../../../components/Theme/ThemeContext';
import {
    LeftIcon,
    RightIcon,
    StyledDropdownMenu,
    StyledIconSeparator,
    StyledListItem,
} from '../style/modalsStyle';
import PlatformInfoTitle from './platformInfo/PlatformInfoTitle';
import PlatformInfoContent from './platformInfo/PlatformInfoContent';
import { connect } from 'react-redux';
import {
    displayPlatformInfo as displayPlatformInfoAction,
    displaySettingsModal as displaySettingsModalAction,
    hidePlatformInfo as hidePlatformInfoAction,
} from '../actions';
import { NewNotficationsBadge } from '../../Notifications/components/NotificationButton';
import * as string from '../constants';

const StyledButton = styled(Button)`
    margin: 0 !important;
    padding: 4px 0 !important;
    border-radius: 20px !important;
    min-width: unset !important;

    i {
        font-size: 32px !important;
    }

    .md-icon-separator {
        padding: 4px !important;
    }

    .md-icon-text {
        display: none;
    }
`;

const KioskUserButton = props => {
    const { theme } = useTheme();
    const [visible, setVisible] = useState(false);
    const showSignInFunction = useRef(null);

    const TITLE_ABOUT_THIS_PLATFORM = getString('aboutThisPlatformTitle', 'About this platform');
    const SUBTITLE_ABOUT_THIS_PLATFORM = getString(
        'aboutThisPlatformSubtitle',
        'Updates, Legal information, Release notes',
    );
    const TITLE_SETTINGS = getString('sideMenuSettingsTitle') || 'Settings';
    const SUBTITLE_SETTINGS =
        getString('settingsTimezoneMessage') || string.EVENT_TIMEZONE_SUBTITLE;

    useEffect(() => {
        if (!visible && props.showPlatformInfo) {
            props.hidePlatformInfo();
        }
    }, [visible]);

    const onVisibilityChange = newVisible => setVisible(newVisible);

    const {
        contrastBlackOrWhite,
        defaultBg,
        hoverBg,
        activeBg,
        fontColor,
    } = getBgAndFontColorForAllDifferentStates(theme, props.light);

    let menuItems;
    if (props.showPlatformInfo) {
        menuItems = [
            <PlatformInfoTitle key="platform-info-title" />,
            <PlatformInfoContent
                key="platform-info-content"
                updateAvailable={props.newVersionAvailable}
                updateServiceWorker={props.updateServiceWorker}
                waitingWorker={props.waitingWorker}
            />,
        ];
    } else {
        menuItems = [
            <StyledListItem
                key="kiosk-dropdown-item-settings"
                primaryText={TITLE_SETTINGS}
                secondaryText={SUBTITLE_SETTINGS}
                leftIcon={
                    <LeftIcon
                        aria-hidden={true}
                        role={'presentation'}
                        iconClassName="material-icons-outlined"
                    >
                        settings
                    </LeftIcon>
                }
                rightIcon={
                    <RightIcon aria-hidden={true} role={'presentation'}>
                        chevron_right
                    </RightIcon>
                }
                onClick={props.displaySettingsModal}
            />,
            <StyledListItem
                key="kiosk-dropdown-about-this-platform"
                primaryText={TITLE_ABOUT_THIS_PLATFORM}
                secondaryText={SUBTITLE_ABOUT_THIS_PLATFORM}
                leftIcon={
                    <AccessibleFakeButton label={''} component={StyledIconSeparator} iconBefore>
                        <LeftIcon
                            aria-hidden={true}
                            role={'presentation'}
                            iconClassName="material-icons-outlined"
                        >
                            info
                        </LeftIcon>
                        {props.newVersionAvailable && (
                            <NewNotficationsBadge
                                color={theme.contrast}
                                right={'10px'}
                                top={'-10px'}
                                border={'solid 2px #f5f5f5;'}
                            ></NewNotficationsBadge>
                        )}
                    </AccessibleFakeButton>
                }
                rightIcon={
                    <RightIcon aria-hidden={true} role={'presentation'}>
                        chevron_right
                    </RightIcon>
                }
                onClick={e => {
                    e.stopPropagation();
                    props.displayPlatformInfo();
                }}
            />,
        ];
    }

    return (
        <InteractivityContext.Consumer>
            {({ showSignIn }) => {
                if (!showSignInFunction.current) {
                    showSignInFunction.current = showSignIn;
                }
                return (
                    <StyledDropdownMenu
                        id="authenticated-user-dropdown-menu"
                        menuItems={menuItems}
                        animationPosition="below"
                        sameWidth
                        simplifiedMenu={false}
                        onVisibilityChange={onVisibilityChange}
                        visible={visible}
                        light={props.light}
                        width={props.showPlatformInfo && '375px'}
                        primary={theme.primary}
                        backgroundColor={defaultBg}
                        hoverColor={hoverBg}
                        activeColor={activeBg}
                        fontColor={fontColor}
                        contrastBlackOrWhite={props.light ? palette.BLACK_87 : contrastBlackOrWhite}
                        isAuthenticated={false}
                    >
                        <StyledButton
                            flat
                            iconChildren={
                                props.newVersionAvailable ? (
                                    <AccessibleFakeButton
                                        label={''}
                                        component={IconSeparator}
                                        iconBefore
                                    >
                                        <FontIcon
                                            aria-hidden={true}
                                            role={'presentation'}
                                            iconClassName="material-icons material-icons-round"
                                        >
                                            person
                                        </FontIcon>
                                        {props.newVersionAvailable && (
                                            <NewNotficationsBadge
                                                color={theme.contrast}
                                                right={'0px'}
                                            >
                                                1
                                            </NewNotficationsBadge>
                                        )}
                                    </AccessibleFakeButton>
                                ) : (
                                    <FontIcon
                                        aria-hidden={true}
                                        role={'presentation'}
                                        iconClassName="material-icons material-icons-round"
                                    >
                                        person
                                    </FontIcon>
                                )
                            }
                        />
                    </StyledDropdownMenu>
                );
            }}
        </InteractivityContext.Consumer>
    );
};

const mapStateToProps = state => ({
    showPlatformInfo: state.auth.showPlatformInfo,
});

export default connect(mapStateToProps, {
    displaySettingsModal: displaySettingsModalAction,
    hidePlatformInfo: hidePlatformInfoAction,
    displayPlatformInfo: displayPlatformInfoAction,
})(KioskUserButton);
