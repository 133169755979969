import React from 'react';
import {
    ButtonsContainer,
    Card,
    CardImage,
    CardWrapper,
    CloseCardIcon,
    Subtitle,
    Title,
} from '../style/signIn';
import attendeeListImage from '../../../assets/images/illustration-attendee-list-web.png';
import * as string from '../constants/strings';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';

const AttendeesSettings = ({
    theme,
    goNextFromAttendeesSettings,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => (
    <CardWrapper>
        <Card
            customHeight={'unset'}
            customTop={'unset'}
            overflow={'scroll'}
            largeCardWithCustomHeight={true}
            isModal={isModal}
        >
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title largeCard={true}>
                    {getString('attendeeListPrivacyTitle') || string.ATTENDEES_SETTINGS_TITLE}
                </Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon
                        aria-hidden={true}
                        role={'presentation'}
                        onClick={onClose}
                        aria-label={'Close'}
                    >
                        {palette.ICON_CLOSE}
                    </CloseCardIcon>
                )}
            </Flex>

            <Subtitle largeCard={true}>
                {getString('attendeeListPrivacySubtitle') || string.ATTENDEES_SETTINGS_DESCRIPTION}
            </Subtitle>
            <CardImage src={attendeeListImage} alt={attendeeListImage} />
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={
                        getString('attendeeListPrivacyAccept') ||
                        string.ATTENDEES_SETTINGS_YES_BUTTON
                    }
                    onClick={() => goNextFromAttendeesSettings(true)}
                    data-qa="button-make-visible-in-attendee-list"
                />
                <Button
                    type={buttonTypes.GHOST_LONG}
                    background={theme.primary}
                    text={
                        getString('attendeeListPrivacyDecline') ||
                        string.ATTENDEES_SETTINGS_NO_BUTTON
                    }
                    onClick={() => goNextFromAttendeesSettings(false)}
                    data-qa="button-keep-invisible-in-attendee-list"
                    style={{ marginTop: '12px' }}
                />
            </ButtonsContainer>
        </Card>
    </CardWrapper>
);

export default AttendeesSettings;
