import React from 'react';
import { connect } from 'react-redux';

import { hidePlatformInfo as hidePlatformInfoAction } from '../../actions';
import { TitleContainer, TitleIcon, TitleText } from '../details';
import IconButton from '@mui/material/IconButton';
import { getString } from '../../../../services/api/store';

const PlatformInfoTitle = ({ hidePlatformInfo }) => {
    const PLATFORM_TEXT = getString('aboutThisPlatformTitle') || 'About this platform';
    return (
        <TitleContainer>
            <IconButton
                onClick={e => {
                    e.stopPropagation();
                    hidePlatformInfo();
                }}
            >
                <TitleIcon
                    aria-hidden={true}
                    role={'presentation'}
                    iconClassName="material-icons-outlined"
                    aria-label="Go back"
                >
                    arrow_back
                </TitleIcon>
            </IconButton>
            <TitleText>{PLATFORM_TEXT}</TitleText>
            <TitleIcon hidden />
        </TitleContainer>
    );
};

export default connect(null, {
    hidePlatformInfo: hidePlatformInfoAction,
})(PlatformInfoTitle);
