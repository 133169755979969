import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const VideoWidget = ({ widget }) => {
    const isYoutube = widget.url && widget.url.includes('youtube');
    const isYoutuDotbe = widget.url && widget.url.includes('youtu.be');
    const isVimeo = widget.url && widget.url.includes('vimeo');
    const embedId = isYoutuDotbe
        ? widget.url?.split('youtu.be/')[1]?.split('?')[0]
        : widget.url?.split('v=')[1]?.split('&')[0];
    const vimeoEmbedId = widget.url?.split('vimeo.com/')[1]?.split('&')[0];

    return (
        <Stack
            spacing={1}
            height="336px"
            style={{ zIndex: 10, boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)', borderRadius: 8 }}
            aria-label={'Video'}
        >
            <Box
                height="100%"
                style={{ backgroundColor: '#000', borderRadius: 8, overflow: 'hidden' }}
            >
                {(isYoutube || isYoutuDotbe) && embedId && (
                    <iframe
                        src={`https://www.youtube.com/embed/${embedId}`}
                        title={`YouTube video player ${embedId}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            border: 'none',
                            overflow: 'hidden',
                        }}
                    />
                )}
                {isVimeo && vimeoEmbedId && (
                    <iframe
                        src={`https://player.vimeo.com/video/${vimeoEmbedId}`}
                        title={`Vimeo video player ${vimeoEmbedId}`}
                        allow="autoplay; encrypted-media"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            border: 'none',
                            overflow: 'hidden',
                        }}
                    ></iframe>
                )}
            </Box>
        </Stack>
    );
};

export default VideoWidget;
