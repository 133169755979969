import React, { useEffect, useState } from 'react';
import { List, ListItem } from 'react-md';
import { Link, useHistory, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import NavigationLink, { makeNavigationData } from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import { ImageAvatar } from '../../components/Icons';
import { getMenuPage } from './services/menuPage';
import AccessRestrictions from '../AccessRestrictions';
import AttendeeList from '../Attendees';
import AuthenticationProtection from '../../components/ProtectedPages/AuthenticationProtection';
import Auth from '../../services/api/auth';
import BrandingBanner from '../../components/General/BrandingBanner';
import { translateCCLinkUrl } from '../../helper/CCLink';
import { useTheme } from '../../components/Theme/ThemeContext';
import StudioIcon from '../../components/Icons/StudioIcon';
import { usePagesStore } from '../../stores/PagesStore';
import Box from '@mui/material/Box';
import Slideshow from '../ExhibitorPage/containers/Slideshow';

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const AStyled = styled.a`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const getUrlParameter = (name, url) => {
    name = name.replace(/[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const removeURLParameter = (url, parameter) => {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        return url;
    } else {
        return url;
    }
};

const StudioMenuLeftAvatar = ({ item }) => {
    const { theme } = useTheme();
    return <StudioIcon icon={item?.icon} color={theme.primary} size={32} style={{ height: 32 }} />;
};

const StyledListItem = styled(ListItem)`
    .md-tile-addon--avatar {
        height: 32px;
    }
`;

const Item = ({ item, i, path }) => {
    const hasIcon = item.isStudio && item.icon ? true : !!item?.imageId && !item.isExternal;

    return (
        <StyledListItem
            {...(path ? { component: Link, to: path } : {})}
            tileStyle={{
                borderTop:
                    i === 0 ? '0px solid rgba(0, 0, 0, 0.08)' : '1px solid rgba(0, 0, 0, 0.08)',
            }}
            primaryText={item.title}
            contentStyle={{
                paddingLeft: !hasIcon ? 0 : 16,
            }}
            leftAvatar={
                item.isStudio ? (
                    <StudioMenuLeftAvatar item={item} />
                ) : !item.isStudio && item?.imageId ? (
                    <ImageAvatar imageId={item?.imageId} shape="square" />
                ) : null
            }
        />
    );
};

const Menu = ({ loading, items }) => (
    <React.Fragment>
        {loading && <Loader />}
        {!loading && <ListStyled className="">{items}</ListStyled>}
    </React.Fragment>
);

const MenuPage = props => {
    const { closeLink, pageId, updateTitle, setTitle, navigationType, visibleFullSideMenu } = props;
    const [menuData, setMenuData] = useState({
        menuData: [],
        loading: true,
        closeLinkPath: closeLink ? closeLink : '/programme',
        banner: null,
        titleOnBanner: null,
        slideshow: [],
    });

    const pages = usePagesStore(state => state.pages);
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    useEffect(() => {
        const getPageData = async () => {
            getMenuPage(pageId, pages, async (err, result) => {
                let navItems = result.studioNavItems || [];

                if (navItems && navItems.length > 0) {
                    const { pageBanner, title, showPageNameInBanner, slideshow, subtitle } = result;

                    setMenuData({
                        menuData: navItems,
                        loading: false,
                        titleOnBanner: showPageNameInBanner && title,
                        subtitleOnBanner: showPageNameInBanner && subtitle,
                        banner: pageBanner,
                        slideshow: slideshow || [],
                    });
                } else if (
                    result &&
                    result.params &&
                    result.params.items &&
                    result.params.items.length
                ) {
                    setMenuData({
                        menuData: result.params.items,
                        loading: false,
                        titleOnBanner: result.params?.showPageNameInBanner && result.params?.title,
                        banner: result.params?.bannerImage,
                    });

                    if (updateTitle) {
                        updateTitle(result.params.title);
                    }

                    if (setTitle && result.params?.showPageNameInBanner) {
                        setTitle('');
                    } else {
                        setTitle();
                    }
                }

                if (result && result.name === 'attendee_list') {
                    setMenuData({
                        isAttendeeList: true,
                    });
                }
            });
        };

        if (!(pageId && pages)) {
            return;
        }

        getPageData();
    }, [pageId, pages]);

    const linkWithToken = (type, tokenLabel, i, item, target) => {
        let link = item.url;
        if (type && type === 'access_token') {
            const user = Auth.getUser();
            let label = tokenLabel;
            if (!label) {
                label = 'token';
            }
            if (user && user.accessToken) {
                link = item.url + '&' + label + '=' + user.accessToken;
                link = `${item.url}&${label}=${user.accessToken}`;
            }
            link = removeURLParameter(link, 'authType');
            link = removeURLParameter(link, 'tokenLabel');
        } else if (type && type === 'login_only') {
            link = removeURLParameter(link, 'authType');
            link = removeURLParameter(link, 'tokenLabel');
        }
        return (
            <AStyled href={link} target={target}>
                <Item item={item} i={i} />
            </AStyled>
        );
    };

    const createExternalLink = (i, item, target) => {
        const { url } = item;
        const type = getUrlParameter('authType', url);
        const tokenLabel = getUrlParameter('tokenLabel', url);

        if (type && type.length) {
            return (
                <AuthenticationProtection>
                    {linkWithToken(type, tokenLabel, i, item, target)}
                </AuthenticationProtection>
            );
        } else {
            return (
                <AStyled href={item.url} target={target}>
                    <Item item={item} i={i} />
                </AStyled>
            );
        }
    };

    const menuItems = items =>
        items.map((item, i) => {
            let navigationParams = {};
            if (item.isStudio) {
                navigationParams = {
                    pageType: item.type,
                    pageId: item.objectid || item.pageId,
                };
            } else {
                navigationParams = {
                    pageType: item.screen ? item.screen : item.link?.screen,
                    pageId: item.screen ? item.page : pageId,
                };
            }

            let externalLink = false;
            if (!item.navParams && !item.screen && !item.page && item.url && item.url !== '') {
                externalLink = true;
            }

            let target = '_blank';
            if (item.url && (item.url.includes('mailto:') || false === item.isExternal)) {
                target = '_self';
            }
            const navigationData = makeNavigationData(
                match,
                history,
                location,
                '',
                null,
                navigationParams,
                null,
                null,
            );
            return (
                <React.Fragment key={'link_' + i}>
                    {!externalLink && !item.url ? (
                        <Item item={item} i={i} path={navigationData} />
                    ) : (
                        createExternalLink(i, item, target)
                    )}
                </React.Fragment>
            );
        });

    const {
        isAttendeeList,
        loading,
        banner,
        titleOnBanner,
        subtitleOnBanner,
        menuData: items,
    } = menuData;

    return (
        <>
            {isAttendeeList ? (
                <AttendeeList isMenu {...props} />
            ) : (
                <AccessRestrictions pageId={pageId}>
                    <BrandingBanner
                        banner={banner}
                        visibleFullSideMenu={visibleFullSideMenu}
                        navigationType={navigationType}
                        title={titleOnBanner}
                        subtitle={subtitleOnBanner}
                        showInContentContainer={!!navigationType}
                        setTitleOnHeader={setTitle}
                    >
                        {menuData.slideshow && menuData.slideshow?.length > 0 && (
                            <Slideshow
                                data={menuData.slideshow}
                                itemDuration={3000}
                                autoplay
                                indicators
                                style={{ aspectRatio: '4', padding: 16, borderRadius: 16 }}
                            />
                        )}
                        <Menu loading={loading} items={menuItems(items || [])} />
                    </BrandingBanner>
                </AccessRestrictions>
            )}
        </>
    );
};

export default withRouter(MenuPage);
