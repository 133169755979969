import React from 'react';
import styled from 'styled-components';
import { ImageAvatar } from '../Icons';
import { StyledListItem } from './style/index';
import ClassifierIconLabelComponent from '../Icons/ClassifierIconLabelComponent';
import { Link } from 'react-router-dom';
import FavoriteButton from '../../scenes/Favorites/containers/FavoriteButton';

const IconsContainer = styled.div`
    margin-top: 10px;
    display: block;
`;

const ClassifierContainer = styled.div`
    margin: ${props => (props.withPadding ? '16px 24px' : 0)};
`;

class Simple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            favoriteStatus: props.item.favorite ? 'checked' : '',
        };
    }

    render() {
        const { item } = this.state;
        const { path } = this.props;

        if (item.mimeType && item.mimeType.length > 0) {
            return (
                <StyledListItem
                    contentStyle={{ fontFamily: "'Cabin', sans-serif" }}
                    key={'link_' + item.id}
                    tabIndex={-1}
                    primaryText={item.name}
                    secondaryText={item.url}
                    leftAvatar={<ImageAvatar avatarType={item.mimeType} />}
                    maxwidth={'390px'}
                />
            );
        }

        if (this.props.type === 'classifier' && this.props.isDetailPage) {
            return (
                <li>
                    <Link
                        to={this.props.path}
                        style={{ textDecoration: 'none' }}
                        className="md-fake-btn md-pointer--hover md-fake-btn--no-outline"
                    >
                        <ClassifierContainer withPadding={!this.props.isDetailPage}>
                            <ClassifierIconLabelComponent
                                params={item.params}
                                label={item.name}
                                displayFullName={!this.props.isDetailPage}
                            />
                        </ClassifierContainer>
                    </Link>
                </li>
            );
        }

        return (
            <StyledListItem
                component={'a'}
                {...(path ? { component: Link, to: path } : {})}
                {...(item.url ? { href: item.url } : {})}
                {...(this.props.target ? { target: this.props.target } : {})}
                className={'md-fake-btn md-pointer--hover md-fake-btn--no-outline'}
                key={'timeslot_' + item.id}
                primaryText={item.name}
                tabIndex={-1}
                secondaryText={item.subNameList || item.url}
                maxwidth={this.props.type !== 'classifier' && '390px'}
                onClick={item.onClick}
                iconCustomHeight={this.props.type === 'classifier' && '40px'}
                leftAvatar={
                    this.props.type === 'classifier' && (
                        <ClassifierIconLabelComponent
                            classifierId={item.id}
                            displayTransparentForMissedColor={true}
                            displayFullName={true}
                        />
                    )
                }
            >
                {this.props.icon && <IconsContainer>{this.props.icon}</IconsContainer>}
                {this.props.type !== 'link' ? (
                    <FavoriteButton bookmark item={item} />
                ) : (
                    <FavoriteButton bookmark item={item} disableClick />
                )}
            </StyledListItem>
        );
    }
}

export default Simple;
