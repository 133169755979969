import React, { useEffect, useState } from 'react';
import { getImageSourceAsync } from '../../../../../services/api/db';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import AnalyticsService from '../../../../../features/analytics/services';
import ComplexDialog from '../../../../../components/Dialog/ComplexDialog';
import { navigateTo } from '../../../../../components/Navigation/NavigationLink';

const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: 12px;
    margin-top: 16px;
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`;

const VirtualBannerImage = ({ params, match, history, location }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [showLeaveConfirmationDialog, setShowLeaveConfirmationDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        virtualBannerImage,
        virtualBannerLinkPageId,
        virtualBannerLinkPageName,
        virtualBannerLinkPageType,
        virtualBannerLinkUrl,
        virtualBannerType,
    } = params || {};

    const isObjectLinkType = virtualBannerLinkPageType === 'object';
    const isPageLinkType = !!virtualBannerLinkPageType && !isObjectLinkType;
    const isUrlLinkType = !!virtualBannerLinkUrl;

    const isImageClickable = isObjectLinkType || isPageLinkType || isUrlLinkType;

    useEffect(() => {
        if (virtualBannerType && virtualBannerImage) {
            fetchData();
        }
    }, [params]);

    const sendClickBannerSample = () => {
        const sessionId = match.params.objectId || match.params.timeslotId;

        AnalyticsService.addSample('clickVirtualSponsorBanner', sessionId, sessionId);
    };

    const fetchData = async () => {
        setLoading(true);
        const image = await getImageSourceAsync(virtualBannerImage);
        setImageUrl(image);
        setLoading(false);
    };

    const onImageClick = () => {
        if (!isImageClickable) {
            return;
        }

        if (isObjectLinkType) {
            navigateTo({
                match,
                history,
                location,
                params: {
                    pageType: 'object',
                    pageId: virtualBannerLinkPageId,
                },
            });
            sendClickBannerSample();
        } else if (isUrlLinkType) {
            window.open(virtualBannerLinkUrl, '_blank');
            sendClickBannerSample();
        } else if (isPageLinkType) {
            setShowLeaveConfirmationDialog(true);
        }
    };

    if (loading) {
        return null;
    }

    return (
        <>
            <Image src={imageUrl} onClick={onImageClick} clickable={isImageClickable} />
            <ComplexDialog
                title="Open new page and leave this virtual session?"
                visible={showLeaveConfirmationDialog}
                onClose={() => setShowLeaveConfirmationDialog(false)}
                options={[
                    {
                        title: 'Cancel',
                        variant: 'contained',
                        onClick: () => setShowLeaveConfirmationDialog(false),
                    },
                    {
                        title: 'Open new page',
                        variant: 'contained',
                        color: 'primary',
                        onClick: () => {
                            navigateTo({
                                match,
                                history,
                                location,
                                path: `/${virtualBannerLinkPageName}`,
                            });
                            sendClickBannerSample();
                        },
                    },
                ]}
            >
                By clicking this link, a new page will be opened and you will leave the current
                virtual session.
            </ComplexDialog>
        </>
    );
};

export default withRouter(VirtualBannerImage);
