import React, { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmailIcon from '@mui/icons-material/Email';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import Box from '@mui/material/Box';
import { Avatar, FontIcon } from 'react-md';
import styled from 'styled-components';
import * as palette from '../../components/General/Variables.js';
import { getImageSource } from '../../services/api/db';

const ConversationAvatar = styled(Avatar)`
    background-color: ${palette.COLOR_GREY} !important;
    color: ${palette.COLOR_WHITE} !important;
    border: 0px solid rgba(0, 0, 0);
    border-radius: 50% !important;
`;

const PersonAvatar = styled(Avatar)`
    background-color: transparent;
    color: ${palette.COLOR_GREY};
    border: 0px solid rgba(0, 0, 0);
    ${props =>
        props.shape === 'square' &&
        `
        border-radius: unset !important;
      `};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        ${props =>
            props.shape === 'square' &&
            `
            width: 40px !important;
            height: 40px !important;
      `};
    }
`;

const PlaceAvatar = styled(Avatar)`
    background-color: transparent;
    color: ${palette.COLOR_GREY};
    border: 0px solid rgba(0, 0, 0);
`;

const InstitutionAvatar = styled(Avatar)`
    background-color: ${palette.COLOR_GREY} !important;
    color: ${palette.COLOR_WHITE} !important;
    border: 0px solid rgba(0, 0, 0);
    border-radius: 0%;
`;

const Container = styled.div`
    width: ${props => props.customSize || '56px'} !important;
    height: ${props => props.customSize || '56px'} !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;

    & .md-icon {
        ${props =>
            props.avatarType === 'institutions' &&
            `
              background: #BFBFBF;
              color: #fff !important;
            `};
    }

    & .md-avatar {
        border: none !important;
        width: ${props => props.customSize || '56px'} !important;
        height: ${props => props.customSize || '56px'} !important;
        ${props =>
            props.avatarType === 'institutions' &&
            `
          border-radius: 4px;
        `};
        ${props =>
            props.imageUrl ||
            props.avatarType === 'persons' ||
            props.avatarType === 'institutions' ||
            props.avatarType === 'conversation'
                ? ''
                : `
        width: 27px !important;
        height: 50px !important;
      `}
    }

    & .md-avatar--default {
        background: transparent;
        color: #bfbfbf;
    }
`;

const ConversationIcon = () => (
    <ConversationAvatar
        icon={
            <FontIcon aria-hidden={true} role={'presentation'} forceSize={40} forceFontSize={true}>
                {palette.ICON_CONVERSATION}
            </FontIcon>
        }
    />
);
const PersonsIcon = ({ customSize, ariaLabel }) => (
    <PersonAvatar
        icon={
            <FontIcon
                aria-hidden={true}
                role={'presentation'}
                forceSize={customSize || 66}
                forceFontSize={true}
                aria-label={ariaLabel}
            >
                {palette.ICON_PERSON}
            </FontIcon>
        }
    />
);
const PlacesIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon aria-hidden={true} role={'presentation'} forceSize={20} forceFontSize={true}>
                {palette.ICON_PLACE}
            </FontIcon>
        }
    />
);
const InstitutionsIcon = ({ imageUrl }) => (
    <InstitutionAvatar
        icon={
            <FontIcon
                aria-hidden={true}
                role={'presentation'}
                forceSize={imageUrl ? 66 : 56}
                forceFontSize={true}
            >
                {palette.ICON_INSTITUTION}
            </FontIcon>
        }
    />
);

const renderLinksIcons = mimeType => {
    switch (mimeType) {
        case 'application/pdf':
            return <PictureAsPdfIcon />;
        case 'text/html':
            return <LanguageIcon />;
        case 'ask_speaker/custom':
            return <HelpIcon />;
        case 'evaluation/custom':
            return <AssignmentIcon />;
        case 'ThumbUpIcon':
            return <ThumbUpIcon />;
        case 'interactive/custom':
            return <QuestionAnswerIcon />;
        case 'send_email_to_speaker/custom':
            return <EmailIcon />;
        case 'eposter/custom':
            return <InsertDriveFileIcon />;
        case 'voting/custom':
            return <CheckBoxIcon />;
        case 'video/mp4':
            return <SmartDisplayOutlinedIcon />;
        default:
            return <Box sx={{ width: '24px', height: '24px' }} />;
    }
};

const ImageAvatar = ({ avatarType, imageId, customSize, altText }) => {
    const [imageUrl, setImageUrl] = useState('');
    const shape = avatarType === 'persons' || avatarType === 'institutions' ? 'round' : 'square';

    useEffect(() => {
        if (imageId) {
            getImageSource(imageId, (err, result) => {
                if (result) {
                    setImageUrl(result);
                }
            });
        } else {
            setImageUrl('');
        }
    }, [imageId, avatarType]);

    const hasMimeType = !['conversation', 'institutions', 'persons', 'places'].includes(avatarType);

    return (
        <Container imageUrl={imageUrl} avatarType={avatarType} customSize={customSize}>
            {!imageUrl && avatarType === 'conversation' && <ConversationIcon />}
            {!imageUrl && avatarType === 'institutions' && <InstitutionsIcon imageUrl={imageUrl} />}
            {!imageUrl && avatarType === 'persons' && (
                <PersonsIcon
                    aria-hidden={true}
                    role={'presentation'}
                    customSize={customSize}
                    ariaLabel={altText}
                />
            )}
            {!imageUrl && avatarType === 'places' && <PlacesIcon />}
            {!imageUrl && hasMimeType && renderLinksIcons(avatarType)}
            {imageUrl && (
                <PersonAvatar
                    src={imageUrl}
                    role="presentation"
                    className={shape}
                    shape={shape}
                    alt={altText}
                />
            )}
        </Container>
    );
};

export default ImageAvatar;
