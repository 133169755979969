import React from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import backgroundPlaceholder from '../../../../assets/images/actorbg.png';
import * as palette from '../../../../components/General/Variables';
import useAccessibleElement from '../../../../hooks/useAccessibleElement';

export const BackgroundAndAvatar = ({ theme, fieldValues, onImageChange, authUser }) => {
    useAccessibleElement({
        ariaControls: 'background-image-upload',
        inputElementId: 'background-image-upload',
    });
    useAccessibleElement({
        ariaControls: 'profile-image-upload',
        inputElementId: 'profile-image-upload',
    });

    return (
        <React.Fragment>
            <StyledComponent.BackgroundImageWrapper>
                <StyledComponent.BackgroundImage
                    src={fieldValues.coverUrl ? fieldValues.coverUrl : backgroundPlaceholder}
                    alt="profile background"
                />
                <StyledComponent.BackgroundInputWrapper>
                    <label htmlFor="background-image-upload">
                        <span role="button" aria-controls="background-image-upload" tabIndex="0">
                            <StyledComponent.AddImage
                                primarycolor={theme.primary}
                                aria-label="Edit profile background"
                            >
                                <span aria-hidden={true} role={'presentation'}>
                                    {palette.ADD_PHOTO}
                                </span>
                            </StyledComponent.AddImage>
                        </span>
                    </label>
                    <input
                        id="background-image-upload"
                        name="coverUrl"
                        type="file"
                        accept="image/*"
                        onChange={onImageChange}
                    />
                </StyledComponent.BackgroundInputWrapper>
            </StyledComponent.BackgroundImageWrapper>

            <StyledComponent.ProfileImageWrapper>
                <StyledComponent.EurekaAvatar
                    avatarcolor={authUser.userColor}
                    src={fieldValues.imageUrl}
                    alt="profile picture"
                >
                    {`${authUser.firstName[0]}${authUser.lastName[0]}`}
                </StyledComponent.EurekaAvatar>
                <StyledComponent.ProfileInputWrapper>
                    <label htmlFor="profile-image-upload">
                        <span role="button" aria-controls="profile-image-upload" tabIndex="0">
                            <StyledComponent.AddImage
                                primarycolor={theme.primary}
                                aria-label="Edit profile picture"
                            >
                                <span aria-hidden={true} role={'presentation'}>
                                    {palette.ADD_PHOTO}
                                </span>
                            </StyledComponent.AddImage>
                        </span>
                    </label>
                    <input
                        id="profile-image-upload"
                        name="imageUrl"
                        type="file"
                        accept="image/*"
                        onChange={onImageChange}
                    />
                </StyledComponent.ProfileInputWrapper>
            </StyledComponent.ProfileImageWrapper>
        </React.Fragment>
    );
};
