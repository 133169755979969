import React, { useEffect, useState, useRef } from 'react';
import async from 'async';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';

import NavigationLink from '../../components/Navigation/NavigationLink';
import ConversationListItem from '../../components/ObjectListItem/Conversation/';
import {
    loadGroupConversations as loadGroupConversationsAction,
    leaveRooms as leaveAllRoomsAction,
    setStatus as setStatusAction,
} from '../Talk/actions';
import {
    getParticipatingConversations,
    getIsLoadingConversations,
    getServerOnline,
} from '../Talk/selectors';
import { getObjectClassWithId } from '../../services/api/db';
import eventBus from '../../utils/eventBus';
import Loader from '../../components/General/Loader';
import Placeholder from './placeholder';
import { getString } from '../../services/api/store';
import NotificationButton from '../Notifications/components/NotificationButton';
import NotificationContentHeader from '../Notifications/components/NotificationContentHeader';
import { useEventInstitutions } from '../../stores/InstitutionsStore';
const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
    list-style: none;
`;
const MyConversations = ({
    loadGroupConversations,
    conversations,
    leaveAllRooms,
    loading,
    setStatus,
    chatServerOnline,
    location,
    match,
    isSociety,
    light = false,
}) => {
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const CONVERSATIONS_TEXT = getString('talkWindowTitle', 'Conversations');

    const [visible, setVisible] = useState(false);

    const [extendedConversations, setExtendedConversations] = useState([]);
    const [extendedConversationsLoaded, setExtendedConversationsLoaded] = useState(false);
    const [loadRequested, setLoadRequested] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const institutions = useEventInstitutions();
    const notificationButtonRef = useRef(null);

    const makeVisible = () => {
        setVisible(true);
        if (notificationButtonRef.current) {
            notificationButtonRef.current.focus();
        }
    };

    useEffect(() => {
        eventBus.on('openChatNotifications', makeVisible);

        return () => eventBus.removeListener('openChatNotifications', makeVisible);
    }, []);

    useEffect(() => {
        if (chatServerOnline) {
            setStatus(true);
            getGroupConversations();
        }

        // leave joined rooms on unmount
        return () => {
            leaveAllRooms();
        };
    }, [chatServerOnline]);

    const getGroupConversations = async () => {
        loadGroupConversations();
        setLoadRequested(true);
    };

    useEffect(() => {
        if (loadRequested === true && loading === false) {
            if (conversations && conversations.length) {
                // Gonna extend conversations with their objects props
                const extendedItems = [];
                async.eachSeries(
                    conversations,
                    (item, cb) => {
                        if (item.kind === 'chat') {
                            extendedItems.push(item);
                            cb();
                        }
                        if (item.roomId) {
                            // Filters out group chats where the linked object is not on the event release anymore
                            getObjectClassWithId(item.roomId, (err, objectClass, objectItem) => {
                                if (!err) {
                                    extendedItems.push({
                                        ...item,
                                        externalObjectClass: objectClass,
                                        externalObject: objectItem,
                                    });
                                }
                                cb();
                            });
                        }
                    },
                    err => {
                        if (!err) {
                            setExtendedConversations(extendedItems);
                            setExtendedConversationsLoaded(true);
                        }
                    },
                );
            } else {
                setExtendedConversationsLoaded(true);
            }
            eventBus.emit('updateConversationsCount');
        }
    }, [conversations, loading]);

    const onVisibilityChange = newVisible => setVisible(newVisible);

    const sortConversationsByDate = conversations =>
        conversations.sort((x, y) => y.lastMessage.timestamp - x.lastMessage.timestamp);

    const filterConversationsByText = (conversations, text) =>
        conversations.filter(
            conversation =>
                conversation.title &&
                conversation.title.toLowerCase().includes(text.toLowerCase().trim()),
        );

    const ChatLink = ({ isPrivate = false, conversation, item }) => {
        return (
            <li key={conversation.id}>
                <LinkStyled
                    params={{
                        type: isPrivate ? 'privateChat' : 'talkRoom',
                        userId: isPrivate ? conversation.id : null,
                        conversationId: isPrivate ? conversation.id : null,
                        objectId: isPrivate ? null : item.id,
                    }}
                    state={
                        isPrivate
                            ? { conversation, openedFromNotificationCenter: true }
                            : { item, openedFromNotificationCenter: true }
                    }
                >
                    <ConversationListItem conversation={conversation} />
                </LinkStyled>
            </li>
        );
    };

    const renderConversation = conversation => {
        if (conversation.kind === 'chat') {
            return (
                <ChatLink
                    key={`private_${conversation.id}`}
                    isPrivate
                    conversation={conversation}
                />
            );
        }

        if (!conversation.lastMessage) {
            return;
        }
        const externalObjectId = get(conversation, 'externalObject.id', null);
        const institution = institutions?.[externalObjectId];
        const representativesList = get(institution, 'representatives') || [];
        const representativesListFromParams =
            get(conversation, 'externalObject.params.representatives') || [];
        const representatives = [...representativesList, ...representativesListFromParams].map(
            user => ({
                id: user.userId || user.id,
                title: `${TEXT_REPRESENTATIVE_ROLE} ${conversation.externalObject.name}`,
            }),
        );

        const item = {
            id: conversation.roomId,
            name: conversation.title,
            objectClass: conversation.externalObjectClass || 'webapp_group_chat',
            type: conversation.externalObjectClass || 'webapp_group_chat',
            representatives,
        };

        return (
            <ChatLink key={`group_${conversation.id}`} conversation={conversation} item={item} />
        );
    };

    const getMenuItems = () => {
        if (loading || !extendedConversationsLoaded) {
            return [<Loader key={'loader'} />];
        }

        if (extendedConversations.length === 0) {
            return [<Placeholder key={'placeholder'} />];
        }

        const filteredConversations = filterConversationsByText(extendedConversations, searchValue);
        const sortedConversations = sortConversationsByDate(filteredConversations);
        const conversationItems = sortedConversations.map(renderConversation);

        return [
            <NotificationContentHeader
                key="conversations-content-header"
                title={CONVERSATIONS_TEXT}
                displayClearAll={false}
                hasSearch={true}
                onSearchChange={text => setSearchValue(text)}
                searchValue={searchValue}
                onMenuClose={() => setVisible(false)}
            />,
            ...conversationItems,
        ];
    };

    const getUnreadCount = () => {
        const { pathname } = location;
        const isVirtualSessionRoom = pathname.includes('/virtual-session');
        const isRoundTableRoom = pathname.includes('/virtual-session-round');
        let currentSessionId = null;

        if (isVirtualSessionRoom || isRoundTableRoom) {
            currentSessionId = get(match, 'params.timeslotId', null);
        }

        return (extendedConversations || []).reduce((acc, conversation) => {
            const conversationSessionId = get(conversation, 'externalObject.id', null);

            // If the user is visiting a session's virtual room,
            // do not increase unread messages count for that session
            if (
                conversationSessionId &&
                currentSessionId &&
                conversationSessionId === currentSessionId
            ) {
                return acc;
            }

            return acc + conversation.unreadCount;
        }, 0);
    };

    const unreadCount = getUnreadCount();

    return (
        <div ref={notificationButtonRef} tabIndex={-1}>
            <NotificationButton
                chat={true}
                isSociety={isSociety}
                icon={'forum'}
                items={getMenuItems()}
                unreadNotificationsCount={unreadCount}
                visible={visible}
                onVisibilityChange={onVisibilityChange}
                light={light}
            />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    conversations: getParticipatingConversations(state),
    loading: getIsLoadingConversations(state),
    chatServerOnline: getServerOnline(state),
    isSociety: ownProps.isSociety,
});

export default connect(mapStateToProps, {
    loadGroupConversations: loadGroupConversationsAction,
    leaveAllRooms: leaveAllRoomsAction,
    setStatus: setStatusAction,
})(withRouter(MyConversations));
