import React, { useMemo } from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import get from 'lodash/get';

import { useGlobalState } from '../../../../../utils/container';
import StreamPlayer from '../../common/StreamPlayer';
import * as palette from '../../../../../components/General/Variables';

const VirtualRoomWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ProfileImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    ${props => !props.isVideoOn && 'background: #000'};
    border-radius: 8px;
`;

const UnactiveWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${props => !props.isVideoOn && 'background: #000'};
    border-radius: 8px;
`;

const VirtualUserInfoWrapper = styled.div`
    display: flex;
    align-items: start;
    height: fit-content;
    position: absolute;
    bottom: 0px;
`;

const VirtualUserTitle = styled.p`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
`;

const MicSwitchWrapper = styled.div`
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px;
`;

const ActionButtonsWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 42px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #ffffff;
    opacity:0.6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 42px;
    height: 42px;
    border-radius: 50%;
`;

const Presenter = ({ virtualEventUser, muted }) => {
    const stateCtx = useGlobalState();
    const user = virtualEventUser.User;
    const { isMicrophoneOn, isVideoOn } = virtualEventUser;
    const { streams, screenShareStream, shareType } = stateCtx;
    const isScreenSharing = screenShareStream && shareType === 'screen';

    const stream = useMemo(() => {
        return streams.find(st => st.streamId === user.id);
    }, [user, streams, virtualEventUser]);

    const screenSharingStream = useMemo(() => {
        if (!streams.length) {
            return null;
        }

        //const foundStream = streams[0];
        const foundStream = (streams || []).find(
            stream => stream && user && stream.streamId === user.id + 10000000,
        );

        if (foundStream) {
            return {
                stream: foundStream,
                virtualEventUser,
            };
        } else {
            return null;
        }
    }, [user, streams]);

    return (
        <VirtualRoomWrapper key={`stream-${get(stream, 'streamId')}`}>
            {!isScreenSharing && stream && (
                <StreamPlayer
                    key={`stream-${stream.getId()}`}
                    local={false}
                    stream={stream}
                    isPlaying={stream.isPlaying()}
                    isVideoOn={true}
                    uid={stream.getId()}
                    domId={`stream-player-${stream.getId()}`}
                    muted={muted}
                />
            )}
            {isScreenSharing && screenSharingStream && (
                <StreamPlayer
                    key={`screenSharingStream-${screenSharingStream.stream.getId()}`}
                    local={false}
                    stream={screenSharingStream.stream}
                    isPlaying={screenSharingStream.stream.isPlaying()}
                    isVideoOn={true}
                    uid={screenSharingStream.stream.getId()}
                    domId={`screenSharingStream-player-${screenSharingStream.stream.getId()}`}
                    muted={muted}
                />
            )}
            <ProfileImageWrapper isVideoOn={isVideoOn}>
                <VirtualUserInfoWrapper>
                    <MicSwitchWrapper>
                        <FontIcon
                            aria-hidden={true}
                            role={'presentation'}
                            style={{ fontSize: '18px', color: '#ffffff' }}
                        >
                            {isMicrophoneOn ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                        </FontIcon>
                    </MicSwitchWrapper>
                    <VirtualUserTitle>
                        {user.firstName} {user.lastName}
                    </VirtualUserTitle>
                </VirtualUserInfoWrapper>
                {!isVideoOn && (
                    <ActionButtonsWrapper>
                        <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                            {!user.imageUrl && (
                                <FontIcon
                                    aria-hidden={true}
                                    role={'presentation'}
                                    style={{ fontSize: '30px', display: 'flex', color: 'grey' }}
                                >
                                    {palette.ICON_PROFILE}
                                </FontIcon>
                            )}
                        </AccountCircleIconWrapper>
                    </ActionButtonsWrapper>
                )}
                {!isVideoOn && !isMicrophoneOn && <UnactiveWrapper isVideoOn={isVideoOn} />}
            </ProfileImageWrapper>
        </VirtualRoomWrapper>
    );
};

export default Presenter;
