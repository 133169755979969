import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Cell, Grid, ListItem } from 'react-md';
import moment from 'moment';

import Button from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import Auth from '../../../services/api/auth';
import { getString } from '../../../services/api/store';
import { Flex } from '../../common/Flex';
import useMessageKeyNavigation from '../../../hooks/useMessageKeyNavigation';
import { buttonTypes } from '../../common/Button';

const InteractivityCell = styled(Cell)`
    text-align: left;
    width: 100% !important;
`;

const InteractivityGrid = styled(Grid)`
    padding-left: 10px;
    padding-right: 10px;
`;

const SectionTitle = styled.h3`
    font-weight: bold;
    text-align: left;
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
    letter-spacing: normal;
    margin-bottom: 0;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionText = styled.div`
    white-space: pre-wrap;
    word-break: break-word;
    font-family: Roboto, sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
`;

const ChatContainer = styled.div`
    padding: 16px;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: ${palette.COLOR_WHITE};
    font-family: Roboto, sans-serif;

    & > div:nth-child(2) {
        margin: 8px 0;
        width: 100%;
    }
`;

const Divider = styled.div`
    margin 8px 0;
    width: 100%
    height: 1px;
    background: #ebedf0;
`;

const CustomAvatar = styled(Avatar)`
    background-color: ${props => props.avatarcolor || '#616161'};
    border: 0px solid rgba(0, 0, 0);
`;

const Item = styled(ListItem)`
    height: 56px;
    margin-top: 4px;
    margin-bottom: 8px;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }

    & .md-list-tile {
        padding-left: 0;
    }

    & .md-list-tile--active {
        background-color: transparent;

        &:hover {
            background-image: ${palette.HOVER_OVERLAY};
            border-radius: 8px;
        }
    }

    & .md-ink-container {
        display: none;
    }

    & .md-tile-text--primary {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${palette.BLACK_87};
    }

    & .md-tile-text--secondary {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${palette.BLACK_60};
        margin-top: 6px;
    }
`;

const FlexPrimaryText = styled(Flex)`
    div:nth-of-type(2) {
        font-family: Roboto, sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${palette.BLACK_60};
        margin-top: 6px;
    }
`;

const getInitials = (firstName, lastName) => firstName[0].toUpperCase() + lastName[0].toUpperCase();

const ChatCard = ({ clickHandler, messages = [], isProfileChatCard, isGroupChatCard }) => {
    const CHAT_TITLE = getString('groupChatScreenTitle') || 'Chat';

    const [hasMessages, setHasMessages] = useState(false);
    const placeholder = getString('groupChatNoMessages') || 'No messages yet';
    const authUser = Auth.isUserAuthenticated();
    const currentUser = Auth.getUser();

    useEffect(() => {
        setHasMessages(messages && messages.length);
    }, [messages]);

    useMessageKeyNavigation({ messages });

    const calcTimeDifference = start => {
        const timedifference = new Date(start).getTimezoneOffset();
        const end = moment().utcOffset(timedifference);
        const timeDifference = moment.duration(end.diff(start));
        const days = timeDifference.days();
        if (days > 0) {
            return `${days}d ago`;
        }

        return moment(start).format('HH:mm');
    };

    const renderMessages = () => {
        if (hasMessages) {
            return messages.map((m, index) => {
                if (!m.lastMessage || !m.lastMessage.participant) {
                    return;
                }
                const { firstName, lastName, imageUrl } = m.lastMessage.participant;
                let { txt: messageBody } = m.lastMessage;
                const avatar = imageUrl ? (
                    <Avatar src={imageUrl} alt={`${firstName} ${lastName}'s profile picture`} />
                ) : (
                    <CustomAvatar>{getInitials(firstName, lastName)}</CustomAvatar>
                );
                try {
                    const textObj = JSON.parse(messageBody);
                    if (textObj.type === 'appointment') {
                        const { name, action } = textObj.data;
                        messageBody = `${name} ${action}`;
                    }
                } catch (e) {
                    // message is plain text
                    if (messageBody === '__removed__') {
                        messageBody = 'Message deleted';
                    }
                }
                const name =
                    m.lastMessage.participant.id === currentUser.id
                        ? 'Me'
                        : `${firstName} ${lastName}`;
                return (
                    <Item
                        key={`groupchat-attendee-item-${index}`}
                        leftAvatar={avatar}
                        primaryText={
                            <FlexPrimaryText justifyContent={'space-between'} alignItems={'center'}>
                                <div>{name}</div>
                                <div>{calcTimeDifference(m.lastMessage.created_at)}</div>
                            </FlexPrimaryText>
                        }
                        secondaryText={messageBody}
                    />
                );
            });
        } else {
            return (
                <>
                    {authUser ? (
                        <Section>
                            <SectionText>{placeholder}</SectionText>
                            {isGroupChatCard ? (
                                <Button
                                    onClick={clickHandler}
                                    type={buttonTypes.GREY}
                                    text={'Start conversation'}
                                    style={{ width: '100%', marginTop: '16px' }}
                                />
                            ) : null}
                        </Section>
                    ) : null}
                </>
            );
        }
    };

    const Chat = () => (
        <ChatContainer
            onClick={e => {
                if (clickHandler) {
                    clickHandler(e);
                }
            }}
        >
            <SectionTitle>{CHAT_TITLE}</SectionTitle>
            {!!hasMessages && <Divider />}
            {renderMessages()}
        </ChatContainer>
    );

    if (isProfileChatCard) {
        if (!messages || (messages && messages.length === 0)) {
            return null;
        }
        return (
            <InteractivityGrid>
                <InteractivityCell size={1} key="chat">
                    <Chat />
                </InteractivityCell>
            </InteractivityGrid>
        );
    }

    return <Chat />;
};

export default ChatCard;
