import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import {
    ArrowButton,
    ArrowButtonRight,
    FilterLabels,
    SearchAndArrowContainer,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
    ToolbarIcon,
} from '../style/style';
import { AccessibleFakeButton, FontIcon, IconSeparator } from 'react-md';
import { useMediaQuery } from '@mui/material';
import { Flex } from '../../common/Flex';

import CircularProgress from '@mui/material/CircularProgress';
import ThemeContext from '../../../components/Theme/ThemeContext';
import Search from '../../../components/General/Search';
import { programmeViewTypes } from '../constants/index';
import { getString } from '../../../services/api/store';
import { NewNotficationsBadge } from '../../Notifications/components/NotificationButton';
import flatten from 'lodash/flatten';
import ClassifierIconLabelComponent from '../../../components/Icons/ClassifierIconLabelComponent';
import * as palette from '../../../components/General/Variables';
import { LightTooltip } from '../../VirtualModeration/features/virtualFeature/components/moderator/common/styles';
import Stack from '@mui/material/Stack';
import CustomDropdown from '../../../components/Selectors/CustomDropdown';

const StyledToolbarIcon = styled(ToolbarIcon)`
    width: fit-content;
    border-radius: 20px;
    padding: 0 11px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
`;

const LoaderText = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    margin-top: 24px;
`;

const HORIZONTAL_VIEW_TEXT = getString('horizontalView', 'Horizontal view');
const VERTICAL_VIEW_TEXT = getString('verticalView', 'Vertical view');
const LIST_VIEW_TEXT = getString('listView', 'List view');
const LOADING_PAGE_TEXT = getString('loadingPage', "Hold on, we're loading the page");

const viewOptions = [
    { value: programmeViewTypes.HORIZONTAL_VIEW, label: HORIZONTAL_VIEW_TEXT },
    { value: programmeViewTypes.VERTICAL_VIEW, label: VERTICAL_VIEW_TEXT },
    { value: programmeViewTypes.LIST_VIEW, label: LIST_VIEW_TEXT },
];

const Toolbar = ({
    activeTab,
    tabs,
    isSidepanelOpened,
    renderListView,
    renderTimetable,
    renderVerticalTimetable,
    loading,
    setShowFilter,
    renderTimezoneInfo,
    action,
    selectedFilters,
    viewType,
    changeViewType,
    numberOfAppliedFilters = 0,
}) => {
    const [filterString, setFilterString] = useState(null);
    const [extendSearch, setExtendSearch] = useState(false);
    const [smallSearchIcon, setSmallSearchIcon] = useState(false);
    const [applyGrid, setApplyGrid] = useState(false);
    const [gridWidth, setGridWidth] = useState(false);
    const [gridDirection, setGridDirection] = useState('left');
    const [tabsScrollLeft, setTabsScrollLeft] = useState(0);
    const [isScrolledToRight, setIisScrolledToRight] = useState(false);
    const [displayClassifiers, setDisplayClassifiers] = useState(true);
    const [isTablet, setIsTablet] = useState(window.innerWidth < palette.MAX_TABLET_INT);
    const searchRef = useRef(null);
    const toolbarRef = useRef(null);
    const initialGridWidth = useRef(null);
    const initialShowGrid = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const tabsContainer = document.getElementsByClassName('md-tabs');
    const scorllableContainer = document.getElementById(
        `timetable-scrollable-container-${viewType?.toLowerCase()}`,
    );

    const isPhone = useMediaQuery('(max-width:767px)');

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (scorllableContainer) {
            scorllableContainer.addEventListener('scroll', handleScrollForClassifiers);
        }

        return () => {
            if (scorllableContainer) {
                scorllableContainer.removeEventListener('scroll', handleScrollForClassifiers);
            }
        };
    }, [scorllableContainer, viewType]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        });
    }, [loading]);

    useEffect(() => {
        const addGridDirection = () => {
            if (
                tabsContainer[0].scrollLeft + tabsContainer[0].offsetWidth <
                tabsContainer[0].scrollWidth
            ) {
                setGridDirection('left');
            } else {
                setGridDirection('right');
            }
            setIisScrolledToRight(
                tabsContainer[0].offsetWidth ===
                    tabsContainer[0].scrollWidth - tabsContainer[0].scrollLeft,
            );
            setTabsScrollLeft(tabsContainer[0].scrollLeft);
        };

        tabsContainer &&
            tabsContainer[0] &&
            tabsContainer[0].addEventListener('scroll', addGridDirection);

        let applyGrid =
            tabsContainer &&
            tabsContainer[0] &&
            tabsContainer[0].offsetWidth < tabsContainer[0].scrollWidth;

        if (!extendSearch) {
            applyGrid = !!initialShowGrid.current;
        }

        setApplyGrid(applyGrid);

        setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth);

        if (!initialShowGrid.current) {
            initialShowGrid.current = applyGrid;
        }

        if (!initialGridWidth.current) {
            initialGridWidth.current =
                tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth;
        }

        return () => {
            tabsContainer &&
                tabsContainer[0] &&
                tabsContainer[0].removeEventListener('scroll', addGridDirection);
        };
    }, [
        tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth,
        tabsContainer && tabsContainer[0] && tabsContainer[0].scrollLeft,
        isSidepanelOpened,
        extendSearch,
    ]);

    useEffect(() => {
        if (extendSearch) {
            setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth - 100);
        } else {
            const addittionalWidth = smallSearchIcon ? 250 : 100;
            setGridWidth(
                tabsContainer &&
                    tabsContainer[0] &&
                    tabsContainer[0].offsetWidth + addittionalWidth,
            );
        }
    }, [extendSearch, smallSearchIcon, isSidepanelOpened]);

    const handleScrollForClassifiers = () => {
        setDisplayClassifiers(scorllableContainer.scrollTop < 5);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScroll = scrollDirection => {
        const content = tabsContainer && tabsContainer[0];
        if (content && content.children && content.children.length) {
            const firstTab = document.getElementById(`program-0`);
            if (scrollDirection === 'right') {
                content.scrollLeft += firstTab.offsetWidth;
            } else if (scrollDirection === 'left') {
                content.scrollLeft -= firstTab.offsetWidth;
            }
        }
    };
    const handleResize = debounce(() => {
        setApplyGrid(
            tabsContainer &&
                tabsContainer[0] &&
                tabsContainer[0].offsetWidth < tabsContainer[0].scrollWidth,
        );

        setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth);
        setIsTablet(window.innerWidth < palette.MAX_TABLET_INT);
        initialGridWidth.current =
            tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth;
    }, 300);

    const handleClickOutside = () => {};

    const onSearch = str => {
        action({
            filters: selectedFilters.filters,
            text: str,
            myProgram: selectedFilters.myProgram,
        });
    };

    const clearSearch = () => {
        setFilterString(null);
        action({
            filters: {},
            text: '',
            myProgram: selectedFilters.myProgram,
        });
    };

    const clearGivenFilter = item => {
        Object.keys(selectedFilters.filters).map(key => {
            selectedFilters.filters[key] = selectedFilters.filters[key].filter(
                i => i.id !== item.id,
            );
            if (!selectedFilters.filters[key] || !selectedFilters.filters[key].length) {
                delete selectedFilters.filters[key];
            }
        });

        action({
            myProgram: selectedFilters.myProgram,
            filters: selectedFilters.filters,
            text: selectedFilters.text,
        });
    };

    const getTextByType = type => {
        switch (type) {
            case programmeViewTypes.HORIZONTAL_VIEW:
                return HORIZONTAL_VIEW_TEXT;
            case programmeViewTypes.VERTICAL_VIEW:
                return VERTICAL_VIEW_TEXT;
            case programmeViewTypes.LIST_VIEW:
                return LIST_VIEW_TEXT;
            default:
                return '';
        }
    };
    let selectedFiltersArray = [];
    let selectedFiltersWithDetails = [];
    if (selectedFilters?.filters && Object.keys(selectedFilters?.filters).length) {
        Object.keys(selectedFilters?.filters).map(key =>
            selectedFiltersArray.push(selectedFilters.filters[key]),
        );
        selectedFiltersWithDetails = flatten(selectedFiltersArray);
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <ToolbarContainer innerRef={toolbarRef}>
                        <Stack
                            direction={isPhone ? 'column-reverse' : 'row'}
                            paddingTop={isPhone ? 1 : 0}
                            justifyContent="space-between"
                            flex={1}
                        >
                            <Stack direction="row" position="relative">
                                <ArrowButton
                                    label={''}
                                    component={IconSeparator}
                                    iconBefore
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleScroll('left');
                                    }}
                                    applyColor={applyGrid && tabsScrollLeft > 0}
                                    style={isPhone ? { left: 0, top: 16 } : {}}
                                >
                                    <FontIcon aria-hidden={true} role={'presentation'}>
                                        chevron_left
                                    </FontIcon>
                                </ArrowButton>
                                <TabsContainerStyled
                                    className="tabs__page-layout"
                                    colored
                                    panelClassName="md-grxid"
                                    activeTabIndex={activeTab}
                                    applyGrid={applyGrid}
                                    gridDirection={gridDirection}
                                    gridWidth={
                                        applyGrid && gridWidth > 0
                                            ? gridWidth
                                            : initialGridWidth.current
                                    }
                                >
                                    <TabsWrapper tabId="program" mobile alignToKeyline={false}>
                                        {tabs}
                                    </TabsWrapper>
                                </TabsContainerStyled>
                                {isPhone && (
                                    <ArrowButtonRight
                                        label={''}
                                        component={IconSeparator}
                                        iconBefore
                                        onClick={() => handleScroll('right')}
                                        applyColor={applyGrid && !isScrolledToRight}
                                        extendSearch={!extendSearch}
                                        style={{ right: 0, top: 16, left: 'unset' }}
                                    >
                                        <FontIcon aria-hidden={true} role={'presentation'}>
                                            chevron_right
                                        </FontIcon>
                                    </ArrowButtonRight>
                                )}
                            </Stack>
                            <Flex
                                justifyContent={isPhone ? 'flex-start' : 'flex-end'}
                                width={'unset'}
                                flexShrink={'0'}
                            >
                                <SearchAndArrowContainer
                                    position={'relative'}
                                    innerRef={searchRef}
                                    width={
                                        isPhone
                                            ? viewType === programmeViewTypes.LIST_VIEW &&
                                              !!renderTimezoneInfo
                                                ? '176px'
                                                : '216px'
                                            : !extendSearch
                                            ? smallSearchIcon
                                                ? '88px'
                                                : '250px'
                                            : '348px'
                                    }
                                >
                                    {!isPhone && (
                                        <ArrowButtonRight
                                            label={''}
                                            component={IconSeparator}
                                            iconBefore
                                            onClick={() => handleScroll('right')}
                                            applyColor={applyGrid && !isScrolledToRight}
                                            extendSearch={!extendSearch}
                                        >
                                            <FontIcon aria-hidden={true} role={'presentation'}>
                                                chevron_right
                                            </FontIcon>
                                        </ArrowButtonRight>
                                    )}
                                    <Search
                                        live={false}
                                        action={onSearch}
                                        startingtext={selectedFilters.text}
                                        clear={clearSearch}
                                        isDailySchedule={true}
                                        collapsible={!isPhone}
                                        isSidePanelOpened={isSidepanelOpened}
                                        externalCollapsedSearch={val => {
                                            setExtendSearch(val);
                                        }}
                                        customTop={isPhone ? '0px' : '16px'}
                                        placeholderText={getString(
                                            'programmeFiltersSearchPlaceholder',
                                            'Search',
                                        )}
                                        customWidth={isPhone ? '100%' : undefined}
                                        exernalSmallSearch={val => setSmallSearchIcon(val)}
                                    />
                                </SearchAndArrowContainer>
                                {viewType === programmeViewTypes.LIST_VIEW && renderTimezoneInfo()}
                                <CustomDropdown
                                    ariaLabel="Select view"
                                    style={{
                                        alignSelf: 'center',
                                        marginRight: '6px',
                                        padding: '0 8px',
                                    }}
                                    value={viewType}
                                    onChange={event => {
                                        changeViewType(event.target.value);
                                        handleClose();
                                    }}
                                    options={viewOptions}
                                    renderValue={viewType => getTextByType(viewType)}
                                    showIconOnMobile
                                />
                                <AccessibleFakeButton
                                    label={''}
                                    component={IconSeparator}
                                    iconBefore
                                    style={isTablet ? { width: 48 } : {}}
                                    onClick={setShowFilter}
                                >
                                    <LightTooltip title="Filter" disableHoverListener={!isTablet}>
                                        <Stack>
                                            <StyledToolbarIcon>
                                                <FontIcon
                                                    aria-hidden={true}
                                                    role={'presentation'}
                                                    style={{ marginRight: isTablet ? 0 : 11 }}
                                                >
                                                    tune
                                                </FontIcon>
                                                {!isTablet && 'Filter'}
                                            </StyledToolbarIcon>
                                        </Stack>
                                    </LightTooltip>
                                    {numberOfAppliedFilters > 0 && (
                                        <NewNotficationsBadge
                                            color={theme.contrast}
                                            top={'0px'}
                                            right={'10px'}
                                        >
                                            {numberOfAppliedFilters}
                                        </NewNotficationsBadge>
                                    )}
                                </AccessibleFakeButton>
                            </Flex>
                        </Stack>
                    </ToolbarContainer>
                    {!!selectedFiltersWithDetails?.length && displayClassifiers && (
                        <FilterLabels>
                            {selectedFiltersWithDetails.map(item => (
                                <ClassifierIconLabelComponent
                                    params={item.params}
                                    label={item.name}
                                    onClose={() => clearGivenFilter(item)}
                                />
                            ))}
                        </FilterLabels>
                    )}
                    {loading && (
                        <LoaderContainer>
                            <CircularProgress />
                            <LoaderText>{LOADING_PAGE_TEXT}</LoaderText>
                        </LoaderContainer>
                    )}
                    {!loading &&
                        viewType === programmeViewTypes.LIST_VIEW &&
                        renderListView(handleClickOutside)}
                    {!loading &&
                        viewType === programmeViewTypes.HORIZONTAL_VIEW &&
                        renderTimetable(handleClickOutside)}
                    {!loading &&
                        viewType === programmeViewTypes.VERTICAL_VIEW &&
                        renderVerticalTimetable(handleClickOutside)}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default Toolbar;
