import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { getFormatTime, makeItem } from '../../services/api/data';
import { canJoinRoundTable, getVirtualEventSession } from '../../services/api/eureka';
import HappeningNow from '../General/HappeningNow';
import LiveLabel from '../General/LiveLabel';
import StartTime from '../General/StartTime';
import { batchGet, getItem } from '../../services/api/graphQlRepository';
import { getString } from '../../services/api/store';
import * as palette from '../General/Variables';
import ThemeContext from '../Theme/ThemeContext';
import isEmpty from 'lodash/isEmpty';
import { useGlobalState } from '../../utils/container';
import Auth from '../../services/api/auth';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../../scenes/Appointments/constants';
import useResize from '../../scenes/VirtualSession/hooks/useResize';
import { VideoIcon } from '../../scenes/Programme/style/style';
import { Flex } from '../../scenes/common/Flex';
import { TimezoneContext } from '../../scenes/Timezone/context';
import sortBy from 'lodash/sortBy';
import TimeslotGridListItem from '../General/TimeslotGridListItem';
import ClassifierIconsGroup from '../Icons/ClassifierIconsGroup';
import { PosterIcon } from '../../scenes/Programme/components/TimeEntry';
import { SessionRegistrationLabel } from '../../scenes/sessionRegistration/containers/sessionRegistrationButton';
import { useMediaQuery } from '@mui/material';
import FavoriteButton from '../../scenes/Favorites/containers/FavoriteButton';

const ListItem = styled.div`
    min-height: 80px !important;
    padding-left: 12px;
    background: transparent !important;
    border-radius: 8px;
    margin: 0 8px;

    &:hover {
        background: #f5f5f5 !important;
    }

    @media (max-width: 480px) {
        padding-left: 0;
    }
`;

const ContentListItem = styled.div.attrs({
    className: 'md-tile-content',
})`
    padding-right: 56px;
    margin-top: 10px;
    opacity: ${props => (props.disabled ? 0.5 : 1)};

    @media (max-width: 480px) {
        padding-right: 40px;
    }
`;

const SecondaryContent = styled.div.attrs({
    className: 'md-tile-text--secondary md-text--secondary',
})`
    fontfamily: Roboto, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 15px !important;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6) !important;
    ${props => (props.customMargin ? `margin: ${props.customMargin}` : 'margin-top: 4px')};

    span {
        color: ${props => props.highlighted || 'rgba(0, 0, 0, 0.6)'} !important;
    }
`;

const Bullet = styled.div`
    display: inline-block;
    gap: 8px;
    width: 4px;
    height: 4px;
    margin: 4px 8px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
`;

const ContainerTimeslot = styled.div`
    margin-top: 0px !important;
`;

export const IconContainer = styled(Flex)`
    margin-top: ${props => props.customMarginTop || '2px'};
`;

const Description = styled.div`
    font-size: 15px !important;
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    display: -webkit-box !important;
    max-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset !important;
`;

const FlexWithPaddingTop = styled.div`
    display: flex;
    padding: 8px 0;
`;

const SponsorBannerWrapper = styled.div`
    width: 100%;
    max-width: 360px;
    height: 60px;
  ${props =>
      props.renderBannerInDetailPage
          ? `
        display: flex;
        align-items: center;
        margin-bottom: 0;`
          : `
          margin-bottom: 5px;
          `} 
    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
`;

const SponsorBannerImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const TYPE_EXTERNAL = 'External';
const TYPE_APPOINTMENT_VIRTUAL_ROOT = 'Appointment virtual room';

const getDuration = ({ hours, minutes, locale = `en-GB` }) => {
    if (hours === 0 && minutes > 0) {
        return `${minutes}m`;
    }

    if (minutes === 0 || minutes === '00') {
        return `${hours}${locale === 'nl' ? 'u' : 'h'}`;
    }

    switch (locale) {
        case `nl`:
            return `${hours}u${minutes}m`;
        default:
            return `${hours}h${minutes}m`;
    }
};

const getDurationAriaLabel = (hours, minutes) => {
    if (hours === 0 && minutes > 0) {
        return `${minutes} minutes`;
    }

    if (minutes === 0 || minutes === '00') {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minutes`;
};

const TimeslotListItem = props => {
    const [timeslot, setTimeslot] = useState(props.timeslot);
    const [room, setRoom] = useState(props?.timeslot?.room);
    const [sponsorBanner, setSponsorBanner] = useState(null);
    const [virtualEventSession, setVirtualEventSession] = useState(null);
    const [hostPresentation, setHostPresentation] = useState(null);
    const [classifications, setClassifications] = useState(props.timeslot?.classifications || []);
    const [isFull, setIsFull] = useState(false);
    const [posterAvailableToAttendees, setPosterAvailableToAttendees] = useState(false);
    const [displayPosterIcon, setDisplayPosterIcon] = useState(false);
    const currentUser = Auth.getUser();
    const bannerWrapperRef = useResize({ ratio: 6 / 1 });
    const isMobile = useMediaQuery('(max-width: 480px)');

    const { getUtcToSelectedTimezone, getUnixToSelectedTimezone } = useContext(TimezoneContext);
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;

    useEffect(() => {
        (async () => {
            if (!props.timeslot.id) {
                return;
            }
            const virtualEventSession = await refreshVirtualEventSession();
            const { socket } = stateCtx;
            if (!room && props.timeslot?.locations?.length) {
                getItem('places', props.timeslot.locations[0]._id, (err, place) => {
                    if (place) {
                        setRoom(place.name);
                    }
                });
            }

            if (classifications?.length === 0) {
                makeItem(props.timeslot.id, 'timeslot', (err, item) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    const classifications = item?.classifications;
                    const orderingString =
                        (classifications &&
                            classifications[0] &&
                            Object.keys(classifications[0]).find(key => key.startsWith('order'))) ||
                        'ordering';
                    const sortedClassifications =
                        classifications?.length && sortBy(classifications, [orderingString]);
                    setClassifications(sortedClassifications);
                });
            } else {
                setClassifications(props.timeslot?.classifications);
            }

            if (props.timeslot.type === 'programelement') {
                getItem('programelement', props.timeslot.id, (err, item) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    getItem('types', item.type, (err, type) => {
                        setPosterAvailableToAttendees(type?.posterAvailableToAttendees);
                        setDisplayPosterIcon(type?.displayPosterIcons);
                    });
                });
            } else if (props.timeslot?.type === 'timeslot') {
                getItem('timeslot', props.timeslot.id, (err, item) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    setTimeslot({ ...props.timeslot, ...item });
                    getItem('types', item.type, (err, type) => {
                        setPosterAvailableToAttendees(type?.posterAvailableToAttendees);
                        setDisplayPosterIcon(type?.displayPosterIcons);
                    });
                });
            } else {
                getItem('types', props.timeslot.type, (err, item) => {
                    setPosterAvailableToAttendees(item?.posterAvailableToAttendees);
                    setDisplayPosterIcon(item?.displayPosterIcons);
                });
            }

            if (
                virtualEventSession &&
                virtualEventSession.roomType === 'roundTable' &&
                virtualEventSession.status === 'broadcasting'
            ) {
                setTimeout(() => checkCapacity(), 2000);
            }
            if (!socket) {
                return;
            }
            socket.on(`updateData_${props.timeslot.id}`, updateData);
            socket.on(`updateRoundTableCount_${props.timeslot.id}`, () =>
                setTimeout(() => checkCapacity(), 2000),
            );
        })();
        return () => {
            if (socket) {
                socket.off(`updateRoundTableCount_${props.timeslot.id}`, checkCapacity);
                socket.off(`updateData_${props.timeslot.id}`, updateData);
            }
        };
    }, [props.timeslot.id]);

    useEffect(() => {
        (async () => {
            const { image, imageUrl } = props.timeslot;
            if (imageUrl || image) {
                let sponsorBanner = imageUrl;

                if (!sponsorBanner) {
                    const input = {
                        data: [
                            {
                                target: 'images',
                                ids: [image],
                            },
                        ],
                    };

                    const { images } = await batchGet(input);
                    sponsorBanner = images && images[0] ? images[0].imageUrl : null;
                }

                setSponsorBanner(sponsorBanner);
            }
        })();
    }, []);

    const checkCapacity = async () => {
        try {
            await canJoinRoundTable(props.timeslot.id, currentUser.id);
            setIsFull(false);
        } catch (err) {
            setIsFull(true);
        }
    };

    const refreshVirtualEventSession = async () => {
        const virtualEventSession = await getVirtualEventSession(props.timeslot.id);
        if (virtualEventSession?.VirtualEventUsers?.length) {
            const userWithPresentationUrl = virtualEventSession?.VirtualEventUsers.find(
                user => !!user.uploadedPresentationUrl,
            );
            setHostPresentation(userWithPresentationUrl);
        }
        updateData({ virtualEventSession });
    };

    const updateData = data => {
        const { virtualEventSession } = data;

        if (
            virtualEventSession &&
            virtualEventSession.message !== 'Not Found' &&
            !isEmpty(virtualEventSession)
        ) {
            setVirtualEventSession(virtualEventSession);
        } else {
            setVirtualEventSession(null);
        }
        return virtualEventSession;
    };

    let isSessionOpen;
    if (props.timeslot && props.timeslot.params) {
        const { virtualSession, virtualSessionType, onDemandRecType } = props.timeslot.params;

        const isExternalLink =
            virtualSessionType === TYPE_EXTERNAL || onDemandRecType === TYPE_EXTERNAL;
        const isExternalOpen = !!(isExternalLink && virtualSession !== '');
        const isAppointmentVirtualRoom = virtualSessionType === TYPE_APPOINTMENT_VIRTUAL_ROOT;

        isSessionOpen = !!(
            isExternalOpen ||
            isAppointmentVirtualRoom ||
            virtualSession === 'on-demand' ||
            (virtualEventSession && virtualEventSession.status !== 'closed' && virtualSession)
        );
    }

    const calcTimeDifference = (start, end) => {
        const timeDifference = moment.duration(end.diff(start));
        const hours = timeDifference.hours();
        const minutes =
            timeDifference.minutes() < 10
                ? `0${timeDifference.minutes()}`
                : timeDifference.minutes();
        return { hours, minutes };
    };

    const isAppointmentWaitingForResponse = appointment =>
        appointment &&
        appointment.members &&
        appointment.members.length &&
        appointment.members[0].AppointmentGroup &&
        appointment.members[0].AppointmentGroup.status === APPOINTMENT_PARTICIPANT_STATUS.WAITING;

    let height = 68;
    if (props.timeslot.subNameList && props.timeslot.subNameList !== '') {
        height += 25;
    }
    if (props.timeslot.room && props.timeslot.room !== '') {
        height += 25;
    }
    let color;

    if (props.timeslot && props.timeslot.params) {
        color =
            typeof props.timeslot.params === 'string'
                ? JSON.parse(props.timeslot.params).color
                : props.timeslot.params.color;
    }
    let classifierIcons = [];

    if (classifications?.length && props.timeslot.type) {
        height += 25;
        classifierIcons = (
            <ClassifierIconsGroup
                classifications={classifications}
                type={props.timeslot.type}
                inlist
            />
        );
    }
    if (sponsorBanner) {
        height += 80;
    }

    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    const isAppointment = props.timeslot && props.timeslot.type === 'appointment';
    let startTime;
    let endTime;
    if (isAppointment) {
        startTime = getUnixToSelectedTimezone(props.timeslot.start);
        endTime = getUnixToSelectedTimezone(props.timeslot.end);
    } else {
        startTime = getUtcToSelectedTimezone(props.timeslot.start);
        endTime = getUtcToSelectedTimezone(props.timeslot.end);
    }

    const timeFormat = getFormatTime();
    const timetoDisplay = startTime.format(timeFormat);
    const dayMonthDateFormat =
        dateSettings && dateSettings.dayMonthDateFormat
            ? dateSettings.dayMonthDateFormat
            : 'MMMM D';
    const dayToDisplay = startTime.locale(locale).format(dayMonthDateFormat);

    const datePattern =
        dateSettings && dateSettings.shortDateFormat && dateSettings.timeFormat
            ? `${dateSettings.shortDateFormat} ${dateSettings.timeFormat}`
            : 'ddd, HH:mm';

    let start = startTime.format(datePattern);

    let end = endTime.format(datePattern);

    const time = `${start} - ${end}`;
    const selectors = '[id^="timeslot-list-item-"]';
    const listItemElement = document.querySelector(selectors);
    let timeDifference = calcTimeDifference(startTime, endTime);
    let hours = timeDifference.hours;
    let minutes = timeDifference.minutes;
    let duration = !isNaN(hours) && hours >= 0 ? getDuration({ hours, minutes, locale }) : time;
    const durationAriaLabel = getDurationAriaLabel(hours, minutes);

    if (props.timeslot.isScheduleChild) {
        duration = null;
    }
    const broadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const isRoundTable = virtualEventSession && virtualEventSession.roomType === 'roundTable';
    let showLive = isSessionOpen && broadcasting;
    const isAppointmentPendingResponse =
        isAppointment && isAppointmentWaitingForResponse(props.timeslot);

    const isPoster = props.timeslot.type === 'programelement' || !props.timeslot.start;

    if (props.cardView) {
        return (
            <TimeslotGridListItem
                timeslot={{
                    ...timeslot,
                    posterPdf:
                        typeof props.timeslot.posterPdf === 'string'
                            ? JSON.parse(props.timeslot.posterPdf)
                            : props.timeslot.posterPdf,
                    type: timeslot.type !== 'programelement' ? 'timeslot' : timeslot.type,
                }}
                hostPresentation={hostPresentation}
                posterAvailableToAttendees={posterAvailableToAttendees}
                childrenId={props.childrenId}
                favoriteStatus={timeslot.favoriteStatus}
                classifierIcons={classifierIcons}
                showLive={showLive}
                isFull={isFull}
                isRoundTable={isRoundTable}
            />
        );
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <ListItem
                    className={'md-fake-btn md-pointer--hover md-fake-btn--no-outline'}
                    key={props.key || 'timeslot_' + props.timeslot.id}
                    height={height}
                    id={`timeslot-list-item-${props.timeslot.id}`}
                >
                    <ContainerTimeslot>
                        {props.timeslot.type !== 'appointment' && (
                            <FavoriteButton
                                bookmark
                                item={props.timeslot}
                                top={isMobile ? 16 : 18}
                                right={isMobile ? 0 : 16}
                            />
                        )}
                        <FlexWithPaddingTop>
                            <StartTime
                                displayOnlyTime={
                                    props.displayOnlyTime || props.timeslot.isScheduleChild
                                }
                                customColor={isPoster && 'transparent'}
                                text={timetoDisplay || ''}
                                start={dayToDisplay || ''}
                            />

                            <ContentListItem
                                disabled={isAppointmentPendingResponse}
                                maxwidth={props.containerWidth}
                            >
                                {props.timeslot.registrationRequired && (
                                    <SessionRegistrationLabel id={props.timeslot.id} />
                                )}
                                {sponsorBanner && (
                                    <SponsorBannerWrapper
                                        renderInDetailPage={
                                            props.childrenId ||
                                            (listItemElement && listItemElement.offsetWidth === 480)
                                        }
                                        innerRef={bannerWrapperRef}
                                    >
                                        <SponsorBannerImage src={sponsorBanner} alt="Banner" />
                                    </SponsorBannerWrapper>
                                )}
                                <Description className="md-tile-text--primary md-text">
                                    {props.timeslot.name}
                                </Description>
                                {isPoster ? (
                                    <SecondaryContent>
                                        {props.timeslot.subNameList}
                                    </SecondaryContent>
                                ) : (
                                    <>
                                        {props.timeslot.subNameList && (
                                            <SecondaryContent
                                                highlighted={
                                                    props.timeslot?.params?.highlighted
                                                        ? theme.textSponsor
                                                        : null
                                                }
                                            >
                                                <span>{props.timeslot.subNameList}</span>
                                            </SecondaryContent>
                                        )}
                                        <SecondaryContent customMargin={'4px 0'}>
                                            <span aria-label={durationAriaLabel}>{duration}</span>
                                            {room ? (
                                                <>
                                                    {duration && <Bullet />}
                                                    {room}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </SecondaryContent>
                                    </>
                                )}

                                <IconContainer>
                                    {showLive && isRoundTable && isFull && (
                                        <>
                                            {currentUser ? (
                                                <LiveLabel
                                                    customColor={'rgba(0, 0, 0, 0.38)'}
                                                    customText={'FULL'}
                                                />
                                            ) : (
                                                <LiveLabel />
                                            )}
                                        </>
                                    )}
                                    {showLive && !isFull ? (
                                        <LiveLabel />
                                    ) : (
                                        <>
                                            {props.timeslot &&
                                                props.timeslot.params &&
                                                props.timeslot.params.virtualSession && (
                                                    <VideoIcon
                                                        aria-hidden={true}
                                                        role={'presentation'}
                                                        color={'rgba(0, 0, 0, 0.38)'}
                                                    >
                                                        videocam
                                                    </VideoIcon>
                                                )}
                                        </>
                                    )}
                                    <HappeningNow
                                        start={props.timeslot.start}
                                        end={props.timeslot.end}
                                        color={theme.contrast}
                                        isTimelineTag={true}
                                    />
                                    <Flex>
                                        {!!classifications?.length > 0 ? classifierIcons : null}
                                        {displayPosterIcon && posterAvailableToAttendees && (
                                            <PosterIcon
                                                virtualEventSession={virtualEventSession}
                                                item={timeslot}
                                            />
                                        )}
                                    </Flex>
                                </IconContainer>
                                {/*{props.timeslot && props.timeslot.sponsorLogo && (*/}
                                {/*    <SponsorLogo*/}
                                {/*        src={props.timeslot.sponsorLogo}*/}
                                {/*        alt={'sponsor-logo'}*/}
                                {/*    />*/}
                                {/*)}*/}
                            </ContentListItem>
                        </FlexWithPaddingTop>
                    </ContainerTimeslot>
                </ListItem>
            )}
        </ThemeContext.Consumer>
    );
};

export default TimeslotListItem;
