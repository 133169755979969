import React from 'react';
import Auth from '../../../services/api/auth';
import { ButtonsContainer, Card, CloseCardIcon, Subtitle, Title } from '../style/signIn';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import {
    UNAUTHORIZED_BUTTON_LABEL,
    UNAUTHORIZED_DESCRIPTION,
    UNAUTHORIZED_TITLE,
} from '../../../components/ProtectedEvents/strings';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';

const UnauthorizedAttendeeContent = ({
    theme,
    onHide,
    eurekaOnly,
    attendingOnly,
    groupCanSignOnly,
    onClose,
    isModal,
}) => {
    let title = getString('attendeesOnlyInstallEventWarningTitle', UNAUTHORIZED_TITLE);
    let description = getString(
        'attendeesOnlyInstallEventWarningMessage',
        UNAUTHORIZED_DESCRIPTION,
    );
    let label = getString(
        'attendeesOnlyInstallEventWarningConfirmButton',
        UNAUTHORIZED_BUTTON_LABEL,
    );

    if (groupCanSignOnly) {
        title = getString('attendeesOnlySigninWarningTitle') || 'Signing in not allowed';
        description =
            getString('attendeesOnlySigninWarningSubtitle') ||
            'The email address you specified is not allowed to sign in. Check with the event organisation if you think it should.';
        label =
            getString('attendeesOnlySigninWarningConfirmButton') || 'Sign in with another account';
    }

    const onClick = () => {
        if (onHide) {
            onHide();
        } else {
            Auth.signOut();
        }
    };

    return (
        <Card customHeight={'240px'} isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{title}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon
                        aria-hidden={true}
                        role={'presentation'}
                        onClick={onClose}
                        aria-label={'Close'}
                    >
                        {palette.ICON_CLOSE}
                    </CloseCardIcon>
                )}
            </Flex>
            <Subtitle>{description}</Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={label}
                    onClick={onClick}
                    data-qa="button-sign-in-with-another-account"
                />
            </ButtonsContainer>
        </Card>
    );
};

export default UnauthorizedAttendeeContent;
