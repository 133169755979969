import React, { useEffect, useState } from 'react';
import { default as MUITabs } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';

const TabPanel = props => {
    const { children, value, index, zeroPadding, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box sx={{ p: zeroPadding ? 0 : 3 }}>{children}</Box>
        </div>
    );
};

const Tabs = ({
    data,
    activeIndex,
    onChange,
    badges,
    TabBarRightComponent,
    zeroPadding = false,
    renderOnlyActiveTab = false,
    sticky = false,
}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (activeIndex !== undefined) {
            setValue(activeIndex);
        }
    }, [activeIndex]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChange?.(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    position: sticky ? 'sticky' : 'relative',
                    top: sticky ? 0 : undefined,
                    zIndex: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    padding: '0px 16px',
                    bgcolor: 'background.default',
                }}
            >
                <MUITabs value={value} onChange={handleChange}>
                    {data
                        .sort((t1, t2) => (t1.order && t2.order ? t1.order - t2.order : 0))
                        .map((item, index) => (
                            <Tab
                                key={`tab-${index}`}
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: '500',
                                }}
                                label={
                                    <>
                                        {(item.path && badges && badges[item.path] > 0) ||
                                        item.hasBadge ? (
                                            <Badge
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        right: '-10px',
                                                    },
                                                }}
                                                color="error"
                                                variant="dot"
                                            >
                                                {item.label}
                                            </Badge>
                                        ) : (
                                            item.label
                                        )}
                                    </>
                                }
                            />
                        ))}
                    {TabBarRightComponent && (
                        <Box
                            style={{
                                position: 'absolute',
                                right: 16,
                                top: 0,
                            }}
                        >
                            {TabBarRightComponent}
                        </Box>
                    )}
                </MUITabs>
            </Box>
            {data.map((item, index) => (
                <React.Fragment key={`tab-panel-${index}`}>
                    {item.component && (index === activeIndex || !renderOnlyActiveTab) && (
                        <TabPanel value={value} index={index} zeroPadding={zeroPadding}>
                            {item.component}
                        </TabPanel>
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default Tabs;
