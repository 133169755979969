import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { getVirtualEventSession } from '../../services/api/eureka';
import { useGlobalState } from '../../utils/container';
import ClassifierIconLabelComponent from '../Icons/ClassifierIconLabelComponent';
import InstitutionDetailHeader from './Institution';
import PersonDetailHeader from './Person';
import TimeslotVirtualSession from './TimeslotVirtualSession';
import TimeslotDetailHeader from './Timeslot';
import NavigationLink from '../../components/Navigation/NavigationLink';
import { useTheme } from '../Theme/ThemeContext';
import { Flex } from '../../scenes/common/Flex';
import { FontIcon } from 'react-md';
import HappeningNow from '../General/HappeningNow';
import entities from '../../scenes/VirtualSession/constants/entities';
import HostedSessionMediaPlayer from '../../scenes/VirtualModeration/features/virtualFeature/components/common/HostedSessionMediaPlayer';
import MoreOptionsShareButton from '../General/MoreOptionsShareButton';
import { useVMState } from '../../scenes/VirtualModeration/features/virtualFeature/containers/main';
import AnalyticsService from '../../features/analytics/services';
import { useLocation } from 'react-router-dom';
import { openExternalLink } from '../../scenes/ExhibitorPage/common';
import InstitutionDetailLinkItem from '../../scenes/ExhibitorPage/components/content/InstitutionDetailLinkItem';
import { SessionRegistrationLabel } from '../../scenes/sessionRegistration/containers/sessionRegistrationButton';
import FavoriteButton from '../../scenes/Favorites/containers/FavoriteButton';

const HeaderWrapper = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    line-height: 28px;
    position: relative;
`;

const HeaderTitle = styled.h2`
    padding-top: ${props =>
        props.type === 'timeslot' ? '16px' : props.type === 'person' ? '46px' : '16px;'};
    padding-left: 16px;
    padding-bottom: 8px;
    padding-right: ${props =>
        props.type !== 'person' && props.type !== 'institution' && props.type !== 'timeslot'
            ? '44px;'
            : '16px;'};
    text-align: ${props =>
        props.type === 'person' || props.type === 'institution' ? 'center;' : 'left;'};
    margin-top: ${props => (props.type === 'person' ? '30px' : '0px')};
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    line-height: 24px;
    font-style: normal;
    font-weight: bold;
    color: #1e1e1f;
`;

const HeaderSubtitle = styled.div`
    color: rgba(0, 0, 0, 0.6);
    text-align: ${props =>
        props.type === 'person' || props.type === 'institution' ? 'center;' : 'left;'};
    padding: 8px 16px 0px 16px;
    font-size: 15px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
`;

const Banner = styled.div`
    width: 100%;
    height: ${props => (props.fitcontent ? 'fit-content' : '224px')};
    padding: 0 16px;
    cursor: ${props => (props.pointer ? 'pointer' : 'auto')};
    margin-bottom: ${props => (props.marginbottom ? 24 : 0)}px;

    img {
        width: 100%;
        border-radius: 8px;
    }
`;

const ContentWrapper = styled.div`
    position: relative;
`;

export const BookmarkIconStyled = styled.div`
    top: 48px;
    top: ${props => props.top};
    position: absolute;
    right: 0px;
`;

const PlaceContainer = styled(Flex)`
    color: rgba(28, 28, 30, 1);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;

    i {
        color: rgba(28, 28, 30, 1) !important;
        margin-right: 10px;
    }
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;

    div {
        div:hover {
            color: ${props => props.primary};
            text-decoration: underline;
        }
    }
`;

const NoLink = styled.div`
    padding-bottom: 0px;
    padding-top: 0px;
`;

export const ButtonsContainer = styled(Flex)`
    padding: ${props => props.padding || '0 16px 15px 16px'} !important;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 16px;
    padding-top: 8px;
`;

const PlacesWrapper = styled.div`
    padding-left: 16px;
    padding-bottom: 16px;
`;

const Header = props => {
    const {
        backgroundImage,
        endTime,
        id,
        params,
        picture,
        startTime,
        subtitle,
        title,
        type,
        typeParams,
        currentUserGroups,
        item,
        backgroundType,
        hasCheckin,
        notesButton,
        notesButtonLarge,
        notesTextWithAction,
        notePage,
        parentHasCustomWrapper,
        pdf,
    } = props;
    const stateCtx = useGlobalState();
    const { playing: introductionVideoPlaying } = useVMState();
    const { socket } = stateCtx;
    const [virtualEventSession, setVirtualEventSession] = useState(null);
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();
    const location = useLocation();
    const virtualSessionVideoUrls = virtualEventSession && virtualEventSession.videoUrls;
    const hostedSessionIntroductionVideo =
        virtualEventSession &&
        virtualEventSession.roomType === entities.virtualEventSessionEntity.roomType.roundTable &&
        virtualSessionVideoUrls &&
        virtualSessionVideoUrls.length &&
        virtualSessionVideoUrls[0];

    const isOnDemand =
        location && location.pathname && location.pathname.includes('/on-demand-poster-room');

    const { registrationRequired } = item;

    const refreshVirtualEventSession = async () => {
        setLoading(true);
        const virtualEventSession = await getVirtualEventSession(id);

        updateData({ virtualEventSession });
        setLoading(false);
    };

    const updateData = data => {
        const { virtualEventSession } = data;

        if (
            virtualEventSession &&
            virtualEventSession.message !== 'Not Found' &&
            !isEmpty(virtualEventSession)
        ) {
            setVirtualEventSession(virtualEventSession);
        } else {
            setVirtualEventSession(null);
        }
    };

    useEffect(() => {
        (async () => {
            if (!id) {
                return;
            }
            await refreshVirtualEventSession();
            const { socket } = stateCtx;

            if (!socket) {
                return;
            }

            socket.on(`updateData_${id}`, updateData);
        })();
        return () => {
            if (socket) {
                socket.off(`updateData_${id}`, updateData);
            }
        };
    }, [id]);

    useEffect(() => {
        let active = true;
        let interval = null;
        let timeout = null;

        if (
            id &&
            introductionVideoPlaying &&
            active &&
            hostedSessionIntroductionVideo &&
            backgroundType === 'hostedVideo'
        ) {
            timeout = setTimeout(() => {
                AnalyticsService.addSample(
                    isOnDemand ? 'viewPosterPitchRecordingOnDemand' : 'viewPosterPitchRecording',
                    hostedSessionIntroductionVideo,
                    id,
                );
            }, 3000);
            interval = setInterval(() => {
                AnalyticsService.addSample(
                    isOnDemand ? 'viewPosterPitchRecordingOnDemand' : 'viewPosterPitchRecording',
                    hostedSessionIntroductionVideo,
                    id,
                );
            }, 60000);
        }

        return () => {
            active = false;
            if (interval) {
                clearInterval(interval);
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [introductionVideoPlaying, id, backgroundType, hostedSessionIntroductionVideo, isOnDemand]);

    const classifierColor = params && params.color ? params.color : '';

    const renderPlaces = (theme, places) =>
        places && places.items
            ? places.items.map(function (it) {
                  let LinkRouter = LinkStyled;
                  let navPath = '';
                  let navParams = {
                      objectClass: 'place',
                      objectId: it.id,
                  };

                  if (it.floorplan && !theme.embed) {
                      navPath = `/floorplan/${it.floorplan}/${it.id}`;
                      navParams = null;
                  } else {
                      LinkRouter = NoLink;
                  }

                  return (
                      <LinkRouter key={it.id} path={navPath} params={navParams}>
                          <PlaceContainer primary={theme.primary}>
                              <FontIcon>room</FontIcon>
                              <div style={{ color: theme.primary }}>{it.name}</div>
                          </PlaceContainer>
                      </LinkRouter>
                  );
              })
            : [];

    const PlacesList = () =>
        props.places &&
        props.places.length &&
        renderPlaces(theme, props && props.places && props.places[0]);

    return (
        <HeaderWrapper>
            {(type === 'timeslot' || type === 'programelement' || type === 'timeslots') && (
                <>
                    {backgroundType === 'image' && backgroundImage && (
                        <Banner
                            fitcontent={parentHasCustomWrapper ? 1 : 0}
                            pointer={!!(params && params.backgroundUrl)}
                            onClick={
                                params && params.backgroundUrl
                                    ? () => window.open(params.backgroundUrl, '_blank')
                                    : null
                            }
                        >
                            <img src={backgroundImage} alt="BackgroundImg" />
                        </Banner>
                    )}
                    {backgroundType === 'hostedVideo' &&
                        hostedSessionIntroductionVideo &&
                        !loading && (
                            <Banner marginbottom fitcontent={parentHasCustomWrapper ? 1 : 0}>
                                <HostedSessionMediaPlayer
                                    primary
                                    url={hostedSessionIntroductionVideo}
                                />
                            </Banner>
                        )}
                    {!parentHasCustomWrapper && (
                        <>
                            {registrationRequired && (
                                <div style={{ marginLeft: '16px' }}>
                                    <SessionRegistrationLabel id={id} mb={0} />
                                </div>
                            )}
                            <HappeningNow
                                start={startTime}
                                end={endTime}
                                color={theme.contrast}
                                margin={'16px 16px 0 16px'}
                            />
                            <HeaderTitle type={type}>{title}</HeaderTitle>
                            {subtitle && subtitle !== '' && (
                                <HeaderSubtitle type={type}>{subtitle}</HeaderSubtitle>
                            )}
                            {(type === 'timeslot' || type === 'timeslots') && startTime && (
                                <TimeslotDetailHeader start={startTime} end={endTime} />
                            )}
                        </>
                    )}
                    {props?.places && props?.places.length > 0 && (
                        <PlacesWrapper>
                            <PlacesList />
                        </PlacesWrapper>
                    )}
                </>
            )}

            <ContentWrapper>
                {type === 'person' && (
                    <PersonDetailHeader
                        picture={picture}
                        backgroundImage={typeParams.backgroundImage}
                    />
                )}
                {type === 'institution' && (
                    <InstitutionDetailHeader
                        picture={picture}
                        backgroundImage={backgroundImage}
                        showClickBanner={typeParams.showClickBanner}
                        params={params}
                    />
                )}
                {type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    type !== 'classifier' && <HeaderTitle type={type}>{title}</HeaderTitle>}
                {!theme.kiosk &&
                    !parentHasCustomWrapper &&
                    (type === 'timeslot' || type === 'programelement' || type === 'timeslots') && (
                        <TimeslotVirtualSession
                            item={item}
                            start={startTime}
                            end={endTime}
                            id={id}
                            params={params || {}}
                            virtualEventSession={virtualEventSession}
                            type={type}
                            notesButton={notesButton}
                            notesButtonLarge={notesButtonLarge}
                            typeParams={typeParams}
                            currentUserGroups={currentUserGroups}
                            hasCheckin={hasCheckin}
                            pdf={pdf}
                            moreButton={
                                <MoreOptionsShareButton
                                    type={type}
                                    notesTextWithAction={notesTextWithAction}
                                    notePage={notePage}
                                    item={{
                                        name: title,
                                        locations: props?.places && props.places[0],
                                        startTime,
                                        endTime,
                                    }}
                                />
                            }
                        />
                    )}
                {type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    subtitle &&
                    subtitle !== '' && <HeaderSubtitle type={type}>{subtitle}</HeaderSubtitle>}
                {type === 'institution' && (
                    <div style={{ paddingBottom: 24 }}>
                        <LinkWrapper style={{ paddingBottom: 0 }}>
                            <PlacesList />
                        </LinkWrapper>
                        {params && params.websiteUrl && (
                            <LinkWrapper style={{ paddingBottom: 0 }}>
                                <InstitutionDetailLinkItem
                                    style={{ justifyContent: 'center' }}
                                    icon="link"
                                    onClick={() => openExternalLink(params.websiteUrl)}
                                    primary={theme.primary}
                                    text={params.websiteName || params.websiteUrl}
                                />
                            </LinkWrapper>
                        )}
                    </div>
                )}

                {!theme.kiosk &&
                    type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    type !== 'classifier' && (
                        <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                            <FavoriteButton item={item} />
                            {type === 'institution' ? (
                                <MoreOptionsShareButton
                                    type={type}
                                    notesTextWithAction={notesTextWithAction}
                                    notePage={notePage}
                                    item={{
                                        name: title,
                                        locations: props?.places && props.places[0],
                                        startTime,
                                        endTime,
                                    }}
                                />
                            ) : (
                                <>{notesButtonLarge}</>
                            )}
                        </ButtonsContainer>
                    )}
                {type === 'classifier' && (
                    <>
                        <Flex width={'unset'} margin={'0 0 26px 30px'}>
                            {(params?.icon || classifierColor) && (
                                <ClassifierIconLabelComponent classifierId={id} iconSize={'32px'} />
                            )}
                            <HeaderTitle type={type}>{title}</HeaderTitle>
                        </Flex>
                        <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                            <FavoriteButton item={item} />
                            <MoreOptionsShareButton
                                type={type}
                                notesTextWithAction={notesTextWithAction}
                                notePage={notePage}
                                item={{
                                    name: title,
                                    locations: props?.places && props.places[0],
                                    startTime,
                                    endTime,
                                }}
                            />
                        </ButtonsContainer>
                    </>
                )}
                {/*{imageUrl && type !== 'person' && type !== 'institution' && (*/}
                {/*    <SponsorBannerWrapper>*/}
                {/*        <SponsorBanner src={imageUrl} alt="Sponsor" />*/}
                {/*    </SponsorBannerWrapper>*/}
                {/*)}*/}
                {/*{classifierIcons.length > 0 &&*/}
                {/*    (type === 'timeslot' || type === 'programelement') && (*/}
                {/*        <IconsContainer>{classifierIcons}</IconsContainer>*/}
                {/*    )}*/}
            </ContentWrapper>
        </HeaderWrapper>
    );
};

export default Header;
