import styled from 'styled-components';
import { Flex } from '../../common/Flex';
import { Avatar, FontIcon, ListItem } from 'react-md';
import * as palette from '../../../components/General/Variables';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

export const HeaderBackground = styled.img`
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
    width: 100%;
    text-align: center;
    border-radius: 8px;
`;

export const HeaderImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    margin: auto;
    margin-top: -50px;
    background-color: ${palette.COLOR_WHITE} !important;
    border-radius: 50%;
`;

export const HeaderImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #fff !important;
`;

export const MainProfileDetails = styled.div`
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 15px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.54);

    div:first-of-type {
        font-family: 'Cabin', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const CustomAvatar = styled(Avatar)`
    margin-top: -5px;
    background-color: ${props => props.avatarcolor || '#bfbfbf'};
    width: 150px !important;
    height: 150px !important;
    border: 4px solid #fff !important;

    & > * {
        font-size: 60px !important;
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        margin-top: -3px;
    }
`;

export const FlexButtonsWrapper = styled(Flex)`
    & > :first-child {
        margin-right: 8px;
    }
`;

export const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    font-size: 17px;
    margin: 24px 0 12px 0;
    font-family: 'Cabin', sans-serif;
`;

export const ContactItem = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: ${props => (props.noMargin ? 0 : '16px')};
`;

export const AddressContactItem = styled.div`
    margin: 16px 0;
    border-radius: 4px;
    border: solid 1px #dcdee2;
    padding: 16px 14px;

    span:first-of-type {
        ${props => (props.bold ? 'font-weight: bold;' : '')};
    }
`;

export const Icon = styled(FontIcon)`
    margin-right: 12px;
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.87) !important;
`;

export const IconWrapper = styled.a`
    text-decoration: none;
    &:not(:first-of-type) {
        margin-left: 16px;
    }
`;

export const LinkLogo = styled.img`
    width: 24px;
    height: 24px;
`;

export const SocialLink = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 1px #e2e4e7;
`;

export const LinkIcon = styled(FontIcon)`
    color: ${props => (props.color ? props.color : 'rgba(0, 0, 0, 0.87)')} !important;
    font-size: 24px !important;
`;

export const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const RectangleContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
`;

export const GeneralProfileDetails = styled.div`
    padding: 0 0 18px 0;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

export const DropdownRelativeContainer = styled.div`
    position: relative;
`;

export const DropdownWrapper = styled.div`
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87) !important;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #ebedf0;
    float: right;
    right: 16px !important;
    bottom: 0;
    position: absolute;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        right: 24px;
    }
    i {
        font-size: 24px !important;
    }
`;

export const UserProfileWrapper = styled(DetailDrawer)``;

export const Item = styled(ListItem)`
    height: 56px;
    margin-left: -18px;
    margin-top: 4px;
    margin-bottom: 8px;
    list-style: none;

    & .md-list-tile--avatar {
        height: 56px;
    }

    & .md-avatar {
        border: none;
    }

    & .md-list-tile {
        padding-right: 0;
    }

    & .md-tile-text--primary {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const PersonContainer = styled.div`
    margin-top: 24px;
`;

export const GeneralImagesContainer = styled.div`
    width: 100%;
    padding: 0 16px;
`;
