import React from 'react';
import {
    ButtonsContainer,
    ButtonStyled,
    Card,
    CloseButton,
    CloseCardIcon,
    StyledError,
    StyledTextField,
    Subtitle,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as string from '../constants/strings';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';
import useEscapeKeyDown from '../../../hooks/useEscapeKeyDown';

const ForgotPassword = ({
    email,
    updateEmail,
    isValid,
    onKeyDown,
    resetPassword,
    error,
    theme,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const TEXT_ERROR_WRONG_EMAIL = getString(
        'signInEmailErrorMessage',
        string.ERROR_MESSAGE_WRONG_EMAIL,
    );
    const TEXT_ERROR_DEFAULT =
        getString('loginSignInErrorDialogMessage') || string.ERROR_MESSAGE_DEFAULT;
    const TEXT_EMAIL_INFO =
        getString('profileForgotPasswordSubtitle') || string.INPUT_FORGOT_PASSWORD_MESSAGE;
    const TEXT_TITLE_FORGOT_PASS =
        getString('profileForgotPasswordScreenTitle') || string.TEXT_TITLE_FORGOT_PASS;
    const CONTINUE_BUTTON_LABEL =
        getString('profileForgotPasswordButtonSend') || string.RESET_PASSWORD_BUTTON_LABEL;

    useEscapeKeyDown({ handler: onClose });

    let errorMessage;
    if (error) {
        if (error.type) {
            switch (error.type) {
                case 'email':
                    errorMessage = TEXT_ERROR_WRONG_EMAIL;
                    break;
                default:
                    errorMessage = TEXT_ERROR_DEFAULT;
                    break;
            }
        } else if (error.message) {
            errorMessage = TEXT_ERROR_DEFAULT;
        }
    }

    return (
        <Card isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{TEXT_TITLE_FORGOT_PASS}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseButton onClick={onClose} aria-label="Close">
                        <CloseCardIcon aria-hidden={true} role={'presentation'}>
                            {palette.ICON_CLOSE}
                        </CloseCardIcon>
                    </CloseButton>
                )}
            </Flex>
            <Subtitle>{TEXT_EMAIL_INFO}</Subtitle>
            <StyledTextField
                id="signin-email"
                required
                value={email}
                onChange={mail => {
                    updateEmail(mail);
                }}
                label={'Email'}
                type="email"
                errorText={getString('signInEmailErrorMessage', string.ERROR_MESSAGE_WRONG_EMAIL)}
                onKeyDown={e => {
                    if (isValid) {
                        onKeyDown(e, resetPassword);
                    }
                }}
                error={error}
            />
            <ButtonsContainer primarycolor={theme.primary}>
                <ButtonStyled flat primary onClick={resetPassword} data-qa="button-reset-password">
                    {CONTINUE_BUTTON_LABEL}
                </ButtonStyled>
            </ButtonsContainer>
            <StyledError>{errorMessage}</StyledError>
        </Card>
    );
};

export default ForgotPassword;
