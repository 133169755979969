import React, { useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';
import throttle from 'lodash/throttle';
import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import { Bullet, Content, PinIcon, TimeAndPinned, Title } from '../index';
import ThemeContext from '../../../components/Theme/ThemeContext';
import { AWS_IMAGES_ENDPOINT, S3_ENDPOINT_IMAGES } from '../../../config';

const HeaderBackground = styled.img`
    background-color: ${palette.COLOR_GREY};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    text-align: center;
`;

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
`;

const Container = styled.div`
    width: 100%;
    padding: 16px;
`;

const NeewsFeedDetailPage = props => {
    const navBar = useRef();
    const header = useRef();
    let scroll = 0;
    let scrollDirection = 'UP';
    const _handleScroll = throttle(() => {
        if (!navBar.current || !header.current) {
            return;
        }

        let newScroll = document.getElementById('scrollable').scrollTop;

        if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
            navBar.current.className = 'fixed-navbar';
            header.current.className = 'fixed-navbar-header';
            scrollDirection = 'UP';
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            navBar.current.className = 'auto-navbar';
            header.current.className = 'auto-navbar-header';
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    let bannerImage;
    if (props.item.mediaImage && AWS_IMAGES_ENDPOINT && S3_ENDPOINT_IMAGES) {
        bannerImage = props.item.mediaImage.replace(S3_ENDPOINT_IMAGES, AWS_IMAGES_ENDPOINT);
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Scrollable id="scrollable" onScroll={_handleScroll}>
                    <div ref={navBar}>
                        <NavigationBar
                            scrollDirection={scrollDirection}
                            onClose={props.onCloseModal}
                            typeName={'Message'}
                            background={palette.COLOR_WHITE}
                        />
                    </div>
                    {bannerImage && (
                        <HeaderBackground src={bannerImage} alt="News message image" ref={header} />
                    )}
                    {props.item.mediaVideoEmbed && props.item.mediaVideoRaw && (
                        <Content>
                            {ReactHtmlParser(props.item.mediaVideoEmbed, {
                                transform: props.transform,
                            })}
                        </Content>
                    )}
                    <Container>
                        <TimeAndPinned>
                            {props.item.pinned && (
                                <>
                                    <PinIcon>push_pin</PinIcon>
                                    Pinned
                                    <Bullet />
                                </>
                            )}

                            {props.date}
                        </TimeAndPinned>

                        <Title>{props.item.subject}</Title>
                        <Content color={theme.contrast}>
                            {ReactHtmlParser(props.item.body, {
                                transform: props.transform,
                            })}
                        </Content>
                    </Container>
                </Scrollable>
            )}
        </ThemeContext.Consumer>
    );
};

export default NeewsFeedDetailPage;
