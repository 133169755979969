import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';

import * as palette from '../../components/General/Variables.js';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getString } from '../../services/api/store';

const HeaderBar = styled.div`
    height: 48px;
    width: 100%;
    background-color: ${props => props.background || props.secondary} !important;
    font-size: 16px;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)};
    line-height: 48px;
    text-align: center;
    ${props => props.background && 'border-radius: 12px'};
`;

const HeaderBarTitle = styled.div`
    display: inline-block;
    padding-left: ${props => (props.hasBackButton ? '0px' : '48px')};
    max-width: 80%;
    vertical-align: middle;
    height: 48px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)};
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
`;

const HeaderBarRight = styled.div`
    display: inline-block;
    float: right;
    margin-right: 5px;
    height: 48px;
    vertical-align: middle;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
`;

const HeaderBarBack = styled.div`
    display: inline-block;
    float: left;
    margin-left: 5px;
    height: 48px;
    vertical-align: middle;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
`;

const Icon = styled(FontIcon)`
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
    vertical-align: top;
    font-size: 24px !important;
`;

const ButtonStyled = styled(Button)`
    margin-top: 4px;
    margin-bottom: 4px;
    height: 40px !important;
    width: 40px !important;
    padding: 10px !important;
    border-radius: 50%;
`;

const NavigationBar = ({
    typeName,
    goBack,
    background,
    onClose,
    closePath,
    closeDataQa,
    customRightButton,
}) => {
    const history = useHistory();
    const { theme } = useContext(ThemeContext);

    let title = typeName === 'Profile' ? getString('profileTitle') || typeName : typeName;

    return (
        <HeaderBar background={background} secondary={theme.primary}>
            {goBack && (
                <HeaderBarBack background={background}>
                    <ButtonStyled
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (typeof goBack === 'function') {
                                goBack();
                            }
                        }}
                        aria-label={'Go back'}
                    >
                        <Icon aria-hidden={true} role={'presentation'} background={background}>
                            {palette.ICON_BACK}
                        </Icon>
                    </ButtonStyled>
                </HeaderBarBack>
            )}
            <HeaderBarTitle hasBackButton={goBack} background={background}>
                {title}
            </HeaderBarTitle>
            {(typeof onClose === 'function' || closePath) && (
                <HeaderBarRight background={background}>
                    {closePath ? (
                        <ButtonStyled
                            icon
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(closePath);
                            }}
                            aria-label={'Close'}
                        >
                            <Icon aria-hidden={true} role={'presentation'} background={background}>
                                {palette.ICON_CLOSE}
                            </Icon>
                        </ButtonStyled>
                    ) : (
                        <ButtonStyled
                            icon
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (typeof onClose === 'function') {
                                    onClose();
                                }
                            }}
                            data-qa={closeDataQa}
                            aria-label={'Close'}
                        >
                            <Icon aria-hidden={true} role={'presentation'} background={background}>
                                {palette.ICON_CLOSE}
                            </Icon>
                        </ButtonStyled>
                    )}
                </HeaderBarRight>
            )}
            <HeaderBarRight background={background}>{customRightButton}</HeaderBarRight>
        </HeaderBar>
    );
};

export default NavigationBar;
