import React, { useEffect, useState } from 'react';
import isUrl from 'is-url';
import LanguageIcon from '@mui/icons-material/Language';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmailIcon from '@mui/icons-material/Email';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useFormik, useFormikContext } from 'formik';
import FloqTextInput from '../../../components/FloqTextInput';
import Modal from '../../../components/Modal';
import FloqFileUploader from '../../../components/FloqFileUploader';
import FloqSelect from '../../../components/FloqSelect';
import { v4 as uuidv4 } from 'uuid';

export const renderLinksIcons = mimeType => {
    switch (mimeType) {
        case 'application/pdf':
            return <PictureAsPdfIcon />;
        case 'text/html':
            return <LanguageIcon />;
        case 'ask_speaker/custom':
            return <HelpIcon />;
        case 'evaluation/custom':
            return <AssignmentIcon />;
        case 'ThumbUpIcon':
            return <ThumbUpIcon />;
        case 'interactive/custom':
            return <QuestionAnswerIcon />;
        case 'send_email_to_speaker/custom':
            return <EmailIcon />;
        case 'eposter/custom':
            return <InsertDriveFileIcon />;
        case 'voting/custom':
            return <CheckBoxIcon />;
        case 'like_abstract/custom':
            return <ThumbUpIcon />;
        case 'video/mp4':
            return <SmartDisplayOutlinedIcon />;
        default:
            return <Box sx={{ width: '24px', height: '24px' }} />;
    }
};

const EditLinksModal = ({ handleClose, link, onChange }) => {
    const institutionFormik = useFormikContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [actionTitle, setActionTitle] = useState('');

    const EMPTY_LINK = {
        url: '',
        mimeType: '',
        name: '',
        _id: '',
    };

    const formik = useFormik({
        initialValues: EMPTY_LINK,
        validate: values => {
            const errors = {};
            if (!values.url) {
                errors.url = 'Enter a url';
            } else if (!isUrl(values.url)) {
                errors.url = 'Enter a valid URL starting with http:// or https://';
            }
            if (values.uploadPdf && !values.name) {
                errors.name = 'Enter a name';
            }
            return errors;
        },
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            let data = { ...values };
            if (!data._id) {
                data._id = uuidv4();
                institutionFormik.setFieldValue('links', [
                    ...(institutionFormik.values.links || []),
                    data,
                ]);
            } else {
                institutionFormik.setFieldValue(
                    'links',
                    institutionFormik.values.links.map(link => {
                        if (link._id === data._id) {
                            return data;
                        }
                        return link;
                    }),
                );
            }

            console.log('LOGGER data', data);

            handleClose();
        },
    });

    const ICONS_MENU = [
        {
            label: <PictureAsPdfIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'application/pdf',
        },
        {
            label: <LanguageIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'text/html',
        },
        {
            label: <HelpIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'ask_speaker/custom',
        },
        {
            label: <AssignmentIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'evaluation/custom',
        },
        {
            label: <ThumbUpIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'like_abstract/custom',
        },
        {
            label: <QuestionAnswerIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'interactive/custom',
        },
        {
            label: <EmailIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'send_email_to_speaker/custom',
        },
        {
            label: <InsertDriveFileIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'eposter/custom',
        },
        {
            label: <CheckBoxIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'voting/custom',
        },
        {
            label: <SmartDisplayOutlinedIcon sx={{ top: '2px', position: 'relative' }} />,
            value: 'video/mp4',
        },
    ];

    useEffect(() => {
        formik.setErrors({});
        if (link) {
            formik.setErrors({});
            if (link.mode === 'create') {
                setActionTitle(link.uploadPdf ? 'Upload' : 'Add');
                setTitle(link.uploadPdf ? 'Upload PDF' : 'Add link');
            } else {
                setActionTitle('Save');
                setTitle(link.poster ? 'Edit PDF' : 'Edit resource');
            }

            const setLink = { ...link };
            if (!link.poster && setLink.name === setLink.url) {
                setLink.name = '';
            }
            formik.setValues(setLink);
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [link]);

    return (
        <Modal
            title={title}
            open={open}
            onClose={handleClose}
            actions={[
                {
                    label: actionTitle,
                    variant: 'contained',
                    color: 'primary',
                    onClick: () => {
                        formik.submitForm();
                    },
                    loading,
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                        handleClose();
                    },
                    variant: 'contained',
                    color: 'secondary',
                },
            ]}
            size="xs"
            loading={false}
            actionsPosition="stretch"
        >
            {formik.values.uploadPdf && (
                <FloqFileUploader
                    value={formik.values.url || ''}
                    onChange={value => {
                        formik.setErrors({ ...formik.errors, url: '' });
                        formik.setFieldValue('url', value.link);
                    }}
                    tag="institution-proposal"
                    extError={formik.errors.url ? 'Upload a file' : ''}
                    maxMB={20}
                />
            )}
            {!formik.values.uploadPdf && (
                <FloqTextInput
                    label="URL"
                    value={formik.values.url || ''}
                    error={formik.errors.url}
                    onChange={value => {
                        formik.setErrors({ ...formik.errors, url: '' });
                        formik.setFieldValue('url', value);
                    }}
                    disabled={false}
                />
            )}

            <Box sx={{ mb: '10px' }} />
            <Stack direction="row" spacing={3}>
                <Stack sx={{ width: '104px' }}>
                    <FloqSelect
                        label={''}
                        placeholder={'Icon'}
                        inputPadding={'12px 12px 12px 12px'}
                        value={formik.values.mimeType || ''}
                        error={''}
                        options={ICONS_MENU}
                        onChange={value => {
                            formik.setFieldValue('mimeType', value);
                        }}
                        onBlur={() => {}}
                    />
                </Stack>

                <FloqTextInput
                    label={`Name ${formik.values.uploadPdf ? '' : ' (optional)'}`}
                    value={formik.values.name || ''}
                    error={formik.errors.name}
                    onChange={value => {
                        formik.setErrors({ ...formik.errors, name: '' });
                        formik.setFieldValue('name', value);
                    }}
                    disabled={false}
                />
            </Stack>
        </Modal>
    );
};
export default EditLinksModal;
