import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getPLacesByFloorplan } from '../services/floorplan';
import * as palette from '../../../components/General/Variables.js';
import Marker from './marker';
import { Button } from 'react-md';
import ThemeContext from '../../../components/Theme/ThemeContext';
import { withRouter, generatePath } from 'react-router-dom';
import { usePlacesByFloorplan, usePlacesStore } from '../../../stores/PlacesStore';
import { useInstitutionsStore } from '../../../stores/InstitutionsStore';
import { useMediaQuery } from '@mui/material';
import eventBus from '../../../utils/eventBus';
const SNAP_TOLERANCE = 0.001;
const OVER_TRANSFORMATION_TOLERANCE = 0.05;
const DOUBLE_TAP_THRESHOLD = 300;
const ANIMATION_SPEED = 0.1;

const MapContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const Sticky = styled.div`
    position: sticky;
    top: 50px;
    z-index: 10;
    margin-bottom: -70px;
    margin-left: calc(100% - 78px);
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        margin-left: calc(100% - 70px);
    }
`;

const SearchButton = styled(Button)`
    background-color: ${props => props.bgcolor};
    color: ${palette.COLOR_WHITE};
    box-shadow: ${palette.ELEVATION};
    border-radius: 2px;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
    }
`;

const ZoomButtonBox = styled.div`
    position: absolute;
    bottom: 50px;
    height: 96px;
    right: 30px;
    z-index: 10;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        height: 80px;
    }
`;

const ZoomButton = styled(Button)`
    position: absolute;
    box-shadow: ${palette.ELEVATION};
    outline: 2px solid ${props => props.bordercolor};
    border-radius: 0px;
    top: 48px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    color: ${props => props.color};
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        top: 40px;
    }
    &:focus {
        outline: 2px solid ${props => props.bordercolor};
    }
`;

const ZoomButtonPlus = styled(ZoomButton)`
    top: 0px;
    color: ${props => props.bordercolor};
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        top: 0px;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const ClickableArea = styled.div`
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    position: absolute;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;

const snapToTarget = (value, target, tolerance) => {
    const withinRange = Math.abs(target - value) < tolerance;
    return withinRange ? target : value;
};

const rangeBind = (lowerBound, upperBound, value) =>
    Math.min(upperBound, Math.max(lowerBound, value));

const invert = value => value * -1;

const getRelativePosition = ({ clientX, clientY }, relativeToElement) => {
    const rect = relativeToElement.getBoundingClientRect();
    return {
        x: clientX - rect.left,
        y: clientY - rect.top,
    };
};

const getMidpoint = (pointA, pointB) => ({
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
});

const getDistanceBetweenPoints = (pointA, pointB) =>
    Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2));

const PinchZoomPan = props => {
    const [state, setState] = useState({
        scale: undefined,
        top: 0,
        left: 0,
        prevId: null,
        selectedPlace: null,
        updatePlaces: false,
        updateSelected: false,
        initialTop: props.initialTop,
        initialLeft: props.initialLeft,
        initialScale: props.initialScale,
    });

    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const animationRef = useRef(null);
    const lastPanPointerPositionRef = useRef(null);
    const lastPinchLengthRef = useRef(null);
    const lastPinchMidpointRef = useRef(null);
    const lastPointerUpTimeStampRef = useRef(null);
    const mouseDownRef = useRef(false);
    const minScaleRef = useRef(1);
    const lastUnzoomedNegativeSpaceRef = useRef(null);
    const places = usePlacesByFloorplan(props.match.params?.floorplan) || [];
    const getPlaceById = usePlacesStore(state => state.getPlaceById);
    const fetchInstitution = useInstitutionsStore(state => state.fetchInstitution);
    const isTablet = useMediaQuery(`(max-width: ${palette.MIN_TABLET_INT}px)`);
    const searchRef = useRef(null);

    // Helper functions remain the same but moved outside component
    const calculateNegativeSpace = (scale = state.scale) => {
        if (!imageRef.current || !containerRef.current) return { width: 0, height: 0 };
        const width = containerRef.current.offsetWidth - scale * imageRef.current.width;
        const height = containerRef.current.offsetHeight - scale * imageRef.current.height;
        return { width, height };
    };

    const calculateAutofitScale = () => {
        if (!imageRef.current || !containerRef.current) return 1;
        let autofitScale = 1;
        if (imageRef.current.width > 0) {
            autofitScale = Math.min(
                containerRef.current.offsetWidth / imageRef.current.width,
                autofitScale,
            );
        }
        if (imageRef.current.height > 0) {
            autofitScale = Math.min(
                containerRef.current.offsetHeight / imageRef.current.height,
                autofitScale,
            );
        }
        return autofitScale * 0.95;
    };

    const getValidTransform = (top, left, scale, tolerance) => {
        const transform = {
            scale: scale || 1,
            top: top || 0,
            left: left || 0,
        };
        const lowerBoundFactor = 1.0 - tolerance;
        const upperBoundFactor = 1.0 + tolerance;

        transform.scale = rangeBind(
            minScaleRef.current * lowerBoundFactor,
            props.maxScale * upperBoundFactor,
            transform.scale,
        );

        const negativeSpace = calculateNegativeSpace(transform.scale);
        const overflow = {
            width: Math.max(0, invert(negativeSpace.width)),
            height: Math.max(0, invert(negativeSpace.height)),
        };

        transform.top = rangeBind(
            invert(overflow.height) * upperBoundFactor,
            overflow.height * upperBoundFactor - overflow.height,
            transform.top,
        );
        transform.left = rangeBind(
            invert(overflow.width) * upperBoundFactor,
            overflow.width * upperBoundFactor - overflow.width,
            transform.left,
        );

        return transform;
    };

    const applyTransform = (requestedTop, requestedLeft, requestedScale, tolerance, speed = 0) => {
        const { top, left, scale } = getValidTransform(
            requestedTop,
            requestedLeft,
            requestedScale,
            tolerance,
        );

        if (state.scale === scale && state.top === top && state.left === left) {
            return;
        }

        let values = {};
        if (imageRef.current && imageRef.current.height && imageRef.current.width) {
            values.originalHeight = imageRef.current.height;
            values.originalWidth = imageRef.current.width;
            values.ratio = imageRef.current.height / imageRef.current.width;
        }

        if (speed > 0) {
            const frame = () => {
                const translateY = top - state.top;
                const translateX = left - state.left;
                const translateScale = scale - state.scale;

                const nextTransform = {
                    top: snapToTarget(state.top + speed * translateY, top, SNAP_TOLERANCE),
                    left: snapToTarget(state.left + speed * translateX, left, SNAP_TOLERANCE),
                    scale: snapToTarget(
                        state.scale + speed * translateScale,
                        scale,
                        SNAP_TOLERANCE,
                    ),
                };

                setState(prev => ({
                    ...prev,
                    ...nextTransform,
                }));

                animationRef.current = requestAnimationFrame(frame);
            };
            animationRef.current = requestAnimationFrame(frame);
        } else {
            setState(prev => ({
                ...prev,
                top,
                left,
                scale,
            }));
        }
    };

    // Event handlers
    const handleTouchStart = event => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        const touches = event.touches;
        if (touches.length === 2) {
            pinchStart(touches);
            lastPanPointerPositionRef.current = null;
        } else if (touches.length === 1) {
            pointerDown(touches[0]);
        }
    };

    const handleTouchMove = event => {
        const touches = event.touches;
        if (touches.length === 2) {
            event.preventDefault();
            pinchChange(touches);
        } else if (touches.length === 1) {
            const swipingDown = pan(touches[0]) > 0;
            if (swipingDown && state.top < 0) {
                event.preventDefault();
            }
        }
    };

    const handleTouchEnd = event => {
        if (event.touches && event.touches.length > 0) return null;
        ensureValidTransform(ANIMATION_SPEED);
        pointerUp(event.timeStamp);
        event.preventDefault();
    };

    const handleMouseDown = event => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        mouseDownRef.current = true;
        pointerDown(event);
    };

    const handleMouseMove = event => {
        if (!mouseDownRef.current) return null;
        pan(event);
    };

    const handleMouseUp = event => {
        pointerUp(event.timeStamp);
        if (mouseDownRef.current) {
            mouseDownRef.current = false;
        }
    };

    const handleMouseWheel = event => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        const point = getRelativePosition(event, containerRef.current);
        if (event.deltaY > 0) {
            if (state.scale > minScaleRef.current) {
                zoomOut(point);
                event.preventDefault();
            }
        } else if (event.deltaY < 0) {
            if (state.scale < props.maxScale) {
                zoomIn(point);
                event.preventDefault();
            }
        }
    };

    const handleWindowResize = () => {
        props.resize();
        ensureConstraints();
    };

    const handleImageLoad = () => {
        props.process({
            imageRatio: imageRef.current.height / imageRef.current.width,
        });
        ensureConstraints();
    };

    // Action handlers
    const pointerDown = clientPosition => {
        lastPanPointerPositionRef.current = getRelativePosition(
            clientPosition,
            containerRef.current,
        );
    };

    const pan = pointerClientPosition => {
        const pointerPosition = getRelativePosition(pointerClientPosition, containerRef.current);
        const translateX = pointerPosition.x - lastPanPointerPositionRef.current.x;
        const translateY = pointerPosition.y - lastPanPointerPositionRef.current.y;
        const top = state.top + translateY;
        const left = state.left + translateX;

        move(top, left, 0);
        lastPanPointerPositionRef.current = pointerPosition;
        return translateY > 0 ? 1 : translateY < 0 ? -1 : 0;
    };

    const pointerUp = timeStamp => {
        if (
            lastPointerUpTimeStampRef.current &&
            lastPointerUpTimeStampRef.current + DOUBLE_TAP_THRESHOLD > timeStamp
        ) {
            transformToProps(ANIMATION_SPEED);
        }

        lastPointerUpTimeStampRef.current = timeStamp;
    };

    const move = (top, left, tolerance, speed = 0) => {
        applyTransform(top, left, state.scale, tolerance, speed);
    };

    const pinchStart = touches => {
        const pointA = getRelativePosition(touches[0], containerRef.current);
        const pointB = getRelativePosition(touches[1], containerRef.current);
        lastPinchLengthRef.current = getDistanceBetweenPoints(pointA, pointB);
    };

    const pinchChange = touches => {
        const pointA = getRelativePosition(touches[0], containerRef.current);
        const pointB = getRelativePosition(touches[1], containerRef.current);
        const length = getDistanceBetweenPoints(pointA, pointB);
        const scale = (state.scale * length) / lastPinchLengthRef.current;
        const midpoint = getMidpoint(pointA, pointB);

        zoom(scale, midpoint, OVER_TRANSFORMATION_TOLERANCE);

        lastPinchMidpointRef.current = midpoint;
        lastPinchLengthRef.current = length;
    };

    const zoomIn = (midpoint, fromButton) => {
        midpoint = midpoint || {
            x: containerRef.current.offsetWidth / 2,
            y: containerRef.current.offsetHeight / 2,
        };
        zoom(state.scale * (fromButton ? 1.2 : 1.05), midpoint, 0);
    };

    const zoomOut = (midpoint, fromButton) => {
        midpoint = midpoint || {
            x: containerRef.current.offsetWidth / 2,
            y: containerRef.current.offsetHeight / 2,
        };
        zoom(state.scale * (fromButton ? 0.8 : 0.95), midpoint, 0);
    };

    const zoom = (scale, midpoint, tolerance, speed = 0) => {
        scale = getValidTransform(0, 0, scale, tolerance).scale;

        const incrementalScalePercentage = (state.scale - scale) / state.scale;
        const translateY = (midpoint.y - state.top) * incrementalScalePercentage;
        const translateX = (midpoint.x - state.left) * incrementalScalePercentage;

        const top = state.top + translateY;
        const left = state.left + translateX;

        applyTransform(top, left, scale, tolerance, speed);
    };

    const transformToProps = (speed = 0) => {
        const scale = props.initialScale === 'auto' ? calculateAutofitScale() : props.initialScale;
        applyTransform(props.initialTop, props.initialLeft, scale, 0, speed);
    };

    const ensureValidTransform = (speed = 0) => {
        const initScale =
            props.initialScale === 'auto' ? calculateAutofitScale() : props.initialScale;
        let scale = state.scale ? state.scale : initScale;

        applyTransform(state.top, state.left, scale, 0, speed);
    };

    const ensureConstraints = () => {
        if (imageRef.current.width && imageRef.current.height) {
            const negativeSpace = calculateNegativeSpace(1);
            if (
                !lastUnzoomedNegativeSpaceRef.current ||
                negativeSpace.height !== lastUnzoomedNegativeSpaceRef.current.height ||
                negativeSpace.width !== lastUnzoomedNegativeSpaceRef.current.width
            ) {
                applyConstraints();
                transformToProps();
            }
        }
    };

    const applyConstraints = () => {
        let minScale = 1;
        if (props.minScale === 'auto') {
            minScale = calculateAutofitScale();
        } else {
            minScale = props.minScale;
        }

        if (minScaleRef.current !== minScale) {
            minScaleRef.current = minScale;
            ensureValidTransform();
        }

        lastUnzoomedNegativeSpaceRef.current = calculateNegativeSpace(1);
    };

    const renderClickableArea = (item, imageSizes) => {
        const { top, left, scale } = state;
        const { width: imageWidth, height: imageHeight } = imageSizes;
        const xOffsetPercentage = (left * 100) / imageWidth;
        const yOffsetPercentage = (top * 100) / imageHeight;
        const clickableAreaSize = scale * 100;

        const onItemClick = async () => {
            const { floorplan } = props.match.params;
            const place = await getPlaceById(floorplan, item.id);
            const institution = await fetchInstitution(place.exhibitorId);
            if (institution?.typeEntity?.detailPageLayout === 'panel' && !isTablet) {
                return props.history.push(
                    `${generatePath(props.match.path, {
                        floorplan,
                        place: item.id,
                    })}?objectClass=institution&objectId=${place.exhibitorId}&type=detail`,
                );
            }

            props.history.push(
                generatePath(props.match.path, {
                    floorplan,
                    place: item.id,
                }),
            );
        };

        return (
            <ClickableArea
                key={`place-${item.id}`}
                x={item.positionX * 100 + xOffsetPercentage}
                y={item.positionY * 100 + yOffsetPercentage}
                onClick={onItemClick}
                size={clickableAreaSize}
            />
        );
    };

    const getImageSizes = () => {
        if (!imageRef.current) {
            return {
                width: 0,
                height: 0,
            };
        }

        return {
            width: imageRef.current.offsetWidth * state.scale,
            height: imageRef.current.offsetHeight * state.scale,
        };
    };

    // Effects
    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.addEventListener('touchmove', handleTouchMove, {
                passive: false,
            });
            containerRef.current = imageRef.current.parentNode.parentNode;
        }
        window.addEventListener('resize', handleWindowResize);

        getPLacesByFloorplan(props.id, (err, places) => {
            setState(prev => ({
                ...prev,
                places,
                prevId: props.id,
                updatePlaces: false,
            }));
        });

        if (imageRef.current && imageRef.current.width && imageRef.current.height) {
            applyConstraints();
            transformToProps();
        }

        return () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener('touchmove', handleTouchMove);
            }
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (state.updateSelected === true) {
            transformToProps();
            setState(prev => ({
                ...prev,
                updateSelected: false,
            }));
        }
    }, [state.updateSelected]);

    useEffect(() => {
        if (imageRef.current && imageRef.current.width && imageRef.current.height) {
            ensureConstraints();

            if (typeof state.scale === 'undefined') {
                transformToProps();
            }
        }
    }, [imageRef.current?.width, imageRef.current?.height]);

    // Static getDerivedStateFromProps equivalent
    useEffect(() => {
        let newState = {};
        let shouldUpdate = false;

        if (props.id !== state.prevId) {
            newState = {
                prevId: props.id,
                places: [],
                updatePlaces: true,
            };
            shouldUpdate = true;
        }

        if (props.selectedPlace !== state.selectedPlace) {
            newState = {
                ...newState,
                selectedPlace: props.selectedPlace,
                updateSelected: true,
            };
            shouldUpdate = true;
        }

        if (
            props.initialTop !== state.initialTop ||
            props.initialLeft !== state.initialLeft ||
            props.initialScale !== state.initialScale
        ) {
            newState = {
                ...newState,
                initialTop: props.initialTop,
                initialLeft: props.initialLeft,
                initialScale: props.initialScale,
            };
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            setState(prev => ({
                ...prev,
                ...newState,
            }));
        }
    }, [props.id, props.selectedPlace, props.initialTop, props.initialLeft, props.initialScale]);

    useEffect(() => {
        eventBus.on('closedFloorDrawer', setFocus);

        return () => eventBus.removeListener('closedFloorDrawer', setFocus);
    }, []);

    const setFocus = () => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    };

    // Render
    const Markers = places.map(place => {
        const visible = place.id === state.selectedPlace;
        if (!visible) {
            return null;
        }
        const scale = state.scale;
        const left = state.left;
        const top = state.top;
        const x = imageRef.current.width * place.positionX;
        const y = imageRef.current.height * place.positionY;

        var data = {
            y: y * scale,
            x: x * scale,
            scale: scale,
            name: place.name,
            top: top,
            left: left,
            visible: visible,
        };
        return <Marker key={place.id} data={data} />;
    });

    const childElement = React.Children.only(props.children);
    const { ref: originalRef } = childElement;
    const composedRef = element => {
        imageRef.current = element;
        if (typeof originalRef === 'function') {
            originalRef(element);
        }
    };
    const imageSizes = getImageSizes();

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <Sticky>
                        <div ref={searchRef} tabIndex={-1}>
                            <SearchButton
                                bgcolor={theme.primary}
                                icon
                                onClick={props.searchButtonAction}
                                aria-label="Open details"
                            >
                                {palette.ICON_SEARCH}
                            </SearchButton>
                        </div>
                    </Sticky>

                    <MapContainer>
                        {Markers}

                        <ImageWrapper height={imageSizes.height} width={imageSizes.width}>
                            {React.cloneElement(childElement, {
                                onTouchStart: handleTouchStart,
                                onTouchEnd: handleTouchEnd,
                                onMouseDown: handleMouseDown,
                                onMouseMove: handleMouseMove,
                                onMouseUp: handleMouseUp,
                                onWheel: handleMouseWheel,
                                onDragStart: event => event.preventDefault(),
                                onLoad: handleImageLoad,
                                ref: composedRef,
                                style: {
                                    transform: `translate3d(${state.left}px, ${state.top}px, 0) scale(${state.scale})`,
                                    transformOrigin: '0 0',
                                    transition: 'transform 20ms ease-in-out',
                                },
                            })}
                            {places.map(place => renderClickableArea(place, imageSizes))}
                        </ImageWrapper>

                        {props.zoomButtons && (
                            <ZoomButtonBox bordercolor={theme.primary}>
                                <ZoomButtonPlus
                                    bordercolor={theme.primary}
                                    onClick={() => zoomIn(null, true)}
                                    disabled={state.scale >= props.maxScale}
                                    icon
                                    aria-label="Zoom in"
                                >
                                    add
                                </ZoomButtonPlus>
                                <ZoomButton
                                    color={theme.primary}
                                    onClick={() => zoomOut(null, true)}
                                    disabled={state.scale <= minScaleRef.current}
                                    icon
                                    aria-label="Zoom out"
                                >
                                    remove
                                </ZoomButton>
                            </ZoomButtonBox>
                        )}
                    </MapContainer>
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

PinchZoomPan.defaultProps = {
    initialTop: 0,
    initialLeft: 0,
    initialScale: 'auto',
    minScale: 'auto',
    maxScale: 1,
    zoomButtons: true,
};

PinchZoomPan.propTypes = {
    children: PropTypes.element.isRequired,
};

export default withRouter(PinchZoomPan);
