import { create } from 'zustand';
import AnalyticsService from '../../../features/analytics/services';
import Auth from '../../../services/api/auth';
import { getFavorites, updateFavorites } from '../services/favorites';
import { AchievementType } from '../../Achievements/hooks/useAchievementActions';
import ReminderService from '../../Reminders/ReminderService';
import { getItemAsync } from '../../../services/api/graphQlRepository';

const markParentsAsFavorites = async (items, set) => {
    const parents = [];
    for (const item of items) {
        const timeslot = await getItemAsync('timeslots', item.objectId);
        if (timeslot && timeslot.start && timeslot.parent) {
            parents.push(timeslot.parent);
        }
    }
    set({ parents });
};

export const useFavoritesStore = create((set, get) => ({
    loading: false,
    parents: [],
    favorites: [],
    getFavorites: async eventId => {
        const favorites = await getFavorites(eventId);
        // const timeslot = await getItemAsync('timeslots', checkIns[0].objectId);
        await markParentsAsFavorites(favorites, set);
        set({ favorites, loading: '' });
    },
    setFavorites: async items => {
        await markParentsAsFavorites(items, set);
        set({ favorites: items });
    },
    resetFavorites: () => {
        set({ parents: [], favorites: [], loading: '' });
    },
    setLoading: value => {
        set({ loading: value });
    },
    updateFavorite: async (eventId, item, trackAchievement) => {
        set({ loading: item.id });
        setTimeout(() => {
            set({ loading: '' });
        }, 6000);
        let type = item?.typeEntity;
        if (!type) {
            type = await getItemAsync('types', item.id);
        }
        const kind = type?.target;
        const favorites = [...get().favorites];
        const found = favorites.find(check => check.objectId === item.id);
        const action = found?.objectId ? 'Delete' : 'Add';

        await AnalyticsService.addSample('favorite', action === 'Add', item.id);
        ReminderService.addItemToScheduledList(item, { isFavorite: true });

        const { enabled } = Auth.getCustomSyncSettings();
        const data = {
            action,
            objectId: item?.id,
            title: item?.name,
            externalId: item?.externalId || undefined,
            legacySync: enabled,
            kind: kind,
        };

        await updateFavorites(eventId, data);

        if (action === 'Add') {
            await trackAchievement(AchievementType.FAVORITE, item.id);
        }
    },
}));
