import { create } from 'zustand';
import { getCheckins, updateCheckins } from '../services/checkin';
import AnalyticsService from '../../../features/analytics/services';
import Auth from '../../../services/api/auth';

export const useCheckinStore = create((set, get) => ({
    loading: false,
    checkIns: [],
    getCheckIns: async eventId => {
        const checkIns = await getCheckins(eventId);
        // const timeslot = await getItemAsync('timeslots', checkIns[0].objectId);
        set({ checkIns });
    },
    setCheckIns: async items => {
        set({ checkIns: items });
    },
    resetCheckIns: () => {
        set({ checkIns: [], loading: '' });
    },
    setLoading: value => {
        set({ loading: value });
    },
    updateCheckIn: async (eventId, session) => {
        set({ loading: true });
        setTimeout(() => {
            set({ loading: '' });
        }, 6000);
        const checked = [...get().checkIns];
        const found = checked.find(check => check.objectId === session.id);
        const action = found?.objectId ? 'Delete' : 'Add';

        AnalyticsService.addSample('checkin', action === 'Add', session.id);

        const { enabled } = Auth.getCustomSyncSettings(true);
        const data = {
            action,
            objectId: session?.id,
            title: session?.name,
            externalId: session?.externalId || undefined,
            legacySync: enabled,
        };

        await updateCheckins(eventId, data);
    },
}));
