import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { useLayoutStore } from '../../../stores/LayoutStore';
import {
    computeDurationInHours,
    computeEventBlock,
    computeEventBlockOffset,
    computeEventBlockOffsetDif,
    computeTimerStyle,
    EmptyFilter,
    getMaxNumberOfOverlappingItems,
    getRulerRange,
    happeningNow,
    hasFilters,
    PlaceholderEmptyList,
    PlaceholderEmptyProgramme,
} from '../utils';
import {
    Container,
    ContainerDiv,
    Indicator,
    RoomSection,
    RoomSectionContainer,
    RoomTimeline,
    RoomTitle,
    Ruler,
    Section,
    TimeHeader,
    TimerCell,
    TimerCellHalf,
} from '../style/style';
import * as palette from '../../../components/General/Variables';
import flatten from 'lodash/flatten';
import TimeEntry from './TimeEntry';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import ThemeContext from '../../../components/Theme/ThemeContext';
import ResizeListener from '../../../components/General/ResizeListener';
import { GRID_HEIGHT, GRID_WIDTH_LARGE, GRID_WIDTH_SMALL } from '../constants/index';
import { getFormatTime } from '../../../services/api/data';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

const ProgramItems = ({
    filters,
    items,
    getAbsoluteOverlappingItemsAndMaxTop,
    secondary,
    timeElements,
    computeTimerStyleStart,
}) => {
    var propsItems = [];

    if (items) {
        propsItems = items;
    }

    let hasContent = false;
    let itemsArray = [];
    if (propsItems && propsItems.length) {
        propsItems.map(i => itemsArray.push(i.items));
        const items = flatten(itemsArray);
        const appointmentPropsItems = items.filter(i => i.type === 'appointment');

        if (appointmentPropsItems && appointmentPropsItems.length > 0) {
            const appointmentsObject = propsItems.find(i => i.group.id === 'appointments');
            if (appointmentsObject) {
                appointmentsObject.items = Array.from(
                    new Set(appointmentsObject.items.concat(appointmentPropsItems)),
                );
            } else {
                propsItems.unshift({
                    group: { id: 'appointments', name: 'Meetings' },
                    items: appointmentPropsItems,
                });
            }
        }
    }

    const result = propsItems.map((item, i) => {
        const room = item.group;
        const sessions = sortBy(item.items, ['start', 'orderingName', 'name', 'end']);
        if (sessions && sessions.length) {
            hasContent = true;
            const maxNumberOfOverlappingElements = getMaxNumberOfOverlappingItems(sessions);
            const itemsContainsImages = false;
            // item.items && item.items.find(i => i.params && i.params.backgroundImage);
            const iconsLine =
                item.items && item.items.find(i => i.params && i.params.virtualSession);
            const numberOfOverlappingElems =
                maxNumberOfOverlappingElements > 1
                    ? maxNumberOfOverlappingElements
                    : maxNumberOfOverlappingElements + 1;
            const initialLineHeight =
                numberOfOverlappingElems *
                (GRID_HEIGHT + 56 + (itemsContainsImages ? 72 : 0) + (iconsLine ? 24 : 0));
            let maxTop = 0;

            sessions.map(item => {
                const itemsContainsImages = false;
                // items && items.find(i => i.params && i.params.backgroundImage);
                item.top = computeEventBlockOffset(
                    item,
                    sessions,
                    GRID_HEIGHT + 52 + (itemsContainsImages ? 75 : 0),
                );
                const max = getAbsoluteOverlappingItemsAndMaxTop(item, sessions, room.id);
                if (max > maxTop) {
                    maxTop = max;
                }
            });
            const lineHeight = maxTop > initialLineHeight ? maxTop + 120 : initialLineHeight;

            return (
                <RoomSectionContainer>
                    {room.id !== 'noGroup' && (
                        <RoomSection secondary={secondary}>
                            <h5>{room.name}</h5>
                        </RoomSection>
                    )}
                    <ul key={room.id} style={{ height: `${lineHeight}px` }}>
                        {timeElements(sessions, room.id)}
                    </ul>
                </RoomSectionContainer>
            );
        } else {
            return null;
        }
    });

    if (hasContent) {
        return result;
    } else {
        if (
            (filters.myProgram === 'my_program' &&
                isEmpty(filters.filters) &&
                (!filters.text || filters.text === '')) ||
            !computeTimerStyleStart
        ) {
            return <PlaceholderEmptyList />;
        } else {
            return <EmptyFilter />;
        }
    }
};

const Timetable = ({
    items,
    rangeInterval,
    filters,
    selectedTimezone,
    renderTimezoneInfo,
    handleClickOutside,
    activeTab,
    getUtcToSelectedTimezone,
    getTimeslotUtc,
}) => {
    const bannerImageDisplayed = useLayoutStore(state => state.bannerImageDisplayed);
    const hasBrandingBanner = useLayoutStore(state => state.hasBrandingBanner);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [cellWidth, setCellWidth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [sessionOnGoing, setSessionOnGoing] = useState(false);
    const nowTimeline = useRef(null);
    const scorllableContainer = document.getElementById(
        `timetable-scrollable-container-horizontal`,
    );

    useEffect(() => {
        setTimeout(() => {
            _handleResize(GRID_WIDTH_SMALL);
        }, 2000);
        window.addEventListener('resize', updateImageHeight);

        return () => {
            window.removeEventListener('resize', updateImageHeight);
        };
    }, []);

    useEffect(() => {
        if (!currentDate) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentDate]);

    useEffect(() => {
        if (scorllableContainer && nowTimeline.current && sessionOnGoing) {
            const firstSessionLeft =
                sessionOnGoing?.left &&
                parseInt(sessionOnGoing?.left.replace(/[^0-9\.]/g, ''), 10) - 160;

            scorllableContainer.scrollTo({
                left: firstSessionLeft || nowTimeline.current?.offsetLeft,
                behavior: 'smooth',
            });
        }
    }, [scorllableContainer, nowTimeline.current]);

    useEffect(() => {
        const arrayItems = [];
        if (items?.length) {
            items.map(i => arrayItems.push(i.items));
            const flattenItems = flatten(arrayItems);
            const sortedItemsByTime = sortBy(flattenItems, 'start');
            const sessionOnGo = sortedItemsByTime?.find(item =>
                happeningNow(item, selectedTimezone, getTimeslotUtc),
            );
            setSessionOnGoing(sessionOnGo);
        }
    }, [items]);

    const updateImageHeight = debounce(() => {
        const imageElement = document.getElementById('header-image-container');
        const bannerHeight = imageElement?.offsetHeight;
        setImageHeight(bannerHeight);
    }, 400);

    const _handleResize = wWidth => {
        const ww = GRID_WIDTH_LARGE;
        setCellWidth(ww);
        setWindowWidth(wWidth);
        updateImageHeight();
    };

    const RulerTimeHeader = () => {
        const rulerRange = getRulerRange(rangeInterval);
        const timeFormat = getFormatTime();

        return [
            <TimerCellHalf key="k-empty">
                <span className="time-label">{renderTimezoneInfo()}</span>
            </TimerCellHalf>,
            ...rulerRange.map(n => {
                const hours = n >= 24 ? n - 24 : n;
                const date = new Date();
                date.setHours(hours, 0, 0, 0);
                const timetoDisplay = moment(date).format(timeFormat);
                return (
                    <TimerCell key={'k' + hours}>
                        <span className="time-label">
                            <h6>{timetoDisplay}</h6>
                        </span>
                    </TimerCell>
                );
            }),
        ];
    };

    const w = cellWidth * (getRulerRange(rangeInterval).length - 2) + cellWidth * 0.5;
    const wstyle = { width: '100%' };
    const workwidth = windowWidth < palette.MAX_TABLET_INT ? windowWidth : windowWidth - 256;

    let computeTimerStyleStart = null;
    if (items && items.length) {
        const allItemsWithStart = items.filter(item => item.items.length > 0);
        if (allItemsWithStart) {
            let itemsArray = [];
            allItemsWithStart.map(i => itemsArray.push(i.items));
            const items = flatten(itemsArray);
            items.sort((a, b) => new Date(a.start) - new Date(b.start));
            computeTimerStyleStart = items[0] && items[0].start;
        }
    }
    const timelineDif = computeTimerStyle(
        getRulerRange(rangeInterval),
        computeTimerStyleStart,
        selectedTimezone,
        rangeInterval,
        getUtcToSelectedTimezone,
    );
    const indicatorStyle = timelineDif.display
        ? timelineDif
        : {
              left: timelineDif * cellWidth + 'px',
          };
    const getCardHeight = (item, items) => {
        const itemsWithSameTop = items.filter(i => i.top === item.top);
        const itemsContainsImages =
            itemsWithSameTop && itemsWithSameTop.find(i => i.params && i.params.backgroundImage);
        return itemsContainsImages ? 'large' : 'small';
    };

    const getAbsoluteOverlappingItemsAndMaxTop = (currentItem, items, roomId) => {
        let finished = false;
        let initialCurrentTop = currentItem.top;

        while (!finished) {
            const maxInterval = initialCurrentTop + 10;
            const minInterval = initialCurrentTop - 10;
            const found = items.find(
                item =>
                    item.roomId === roomId &&
                    ((item.top >= minInterval && item.top <= maxInterval) ||
                        (item.newTop >= minInterval && item.newTop <= maxInterval)) &&
                    item.id !== currentItem.id &&
                    item.end >= currentItem.start,
            );
            if (found) {
                initialCurrentTop += 116;
            } else {
                finished = true;
            }
        }

        return initialCurrentTop;
    };

    const avoidOverlappingItems = (currentItem, items, roomId) => {
        const maxInterval = currentItem.top + 10;
        const minInterval = currentItem.top - 10;
        const itemsStartingAfterCurrentItem = items.filter(
            item =>
                item.roomId === roomId &&
                item.top === currentItem.top &&
                ((item.top >= minInterval && item.top <= maxInterval) ||
                    (item.newTop >= minInterval && item.newTop <= maxInterval)) &&
                item.id !== currentItem.id &&
                item.start >= currentItem.start &&
                item.start < currentItem.end,
        );
        const itemsWithSmallDuraction = items.filter(
            item => computeDurationInHours(item.start, item.end) <= 0.25,
        );
        let arrayItemsSmallDuratiion = [];
        if (itemsWithSmallDuraction.includes(currentItem)) {
            arrayItemsSmallDuratiion = items.filter(
                item =>
                    item.roomId === roomId &&
                    ((item.top >= minInterval && item.top <= maxInterval) ||
                        (item.newTop >= minInterval && item.newTop <= maxInterval)) &&
                    item.id !== currentItem.id &&
                    item.start === currentItem.end,
            );
        }

        const itemsNewTop = Array.from(
            new Set(itemsStartingAfterCurrentItem.concat(arrayItemsSmallDuratiion)),
        ).map(item => {
            const emptyPosition = getAbsoluteOverlappingItemsAndMaxTop(item, items, roomId);
            const itemNextToSmallDuration = arrayItemsSmallDuratiion.find(it => it.id === item.id);
            const sameLeft = itemsWithSmallDuraction.find(
                it => parseInt(itemNextToSmallDuration?.left) === parseInt(it?.left) + 76,
            );
            const calculateNewTop = !(arrayItemsSmallDuratiion?.length && sameLeft);
            item.calculateNewTop = calculateNewTop;
            if (calculateNewTop) {
                if (
                    currentItem.top &&
                    currentItem.newTop &&
                    currentItem.top !== currentItem.newTop
                ) {
                    item.newTop = emptyPosition || currentItem.newTop + 88;
                } else {
                    item.newTop = emptyPosition || currentItem.top + 88;
                }
            } else {
                item.newTop = null;
            }

            return item;
        });
        return itemsNewTop || [];
    };

    const timeElements = (items, roomId) => {
        return items.map(item => {
            item.roomId = roomId;
            const w = computeEventBlock(item, cellWidth);
            const startTime = getUtcToSelectedTimezone(item.start);
            const l = computeEventBlockOffsetDif(startTime, rangeInterval, cellWidth);
            item.left = l;
            const isLargeCard = getCardHeight(item, items) === 'large';
            avoidOverlappingItems(item, items, roomId);

            const objectClass = item.type === 'appointment' ? 'appointment' : 'timeslot';
            if (objectClass === 'appointment' && roomId !== 'appointments') return null;
            item.customWidth = w;
            return (
                <TimeEntry
                    key={`horizontal-${item.id}`}
                    objectClass={objectClass}
                    item={item}
                    entryWidth={w}
                    entryLeft={l}
                    entryTop={item.newTop || item.top}
                    isLargeCard={isLargeCard}
                    isHighlighted={item?.params?.highlighted}
                    handleClickOutside={handleClickOutside}
                />
            );
        });
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Container key={'main'}>
                    <ResizeListener onResize={_handleResize} />
                    <ContainerDiv
                        id={'timetable-scrollable-container-horizontal'}
                        bannerHeight={`${imageHeight + 104}px`}
                        style={{
                            overflowY:
                                hasBrandingBanner && bannerImageDisplayed ? 'hidden' : 'auto',
                        }}
                    >
                        {computeTimerStyleStart ? (
                            <Section wWidth={workwidth}>
                                <Indicator
                                    innerRef={nowTimeline}
                                    contrast={theme.contrast}
                                    wHeight={'100%'}
                                    style={indicatorStyle}
                                />
                                <Ruler style={wstyle}>
                                    <TimeHeader>
                                        <ul>
                                            <RulerTimeHeader />
                                        </ul>
                                    </TimeHeader>
                                </Ruler>
                                <RoomTimeline style={wstyle}>
                                    <ProgramItems
                                        secondary={theme.secondary}
                                        filters={filters}
                                        items={items}
                                        getAbsoluteOverlappingItemsAndMaxTop={
                                            getAbsoluteOverlappingItemsAndMaxTop
                                        }
                                        timeElements={timeElements}
                                        computeTimerStyleStart={computeTimerStyleStart}
                                    />
                                </RoomTimeline>
                            </Section>
                        ) : (
                            <>
                                {hasFilters(filters) ? (
                                    <PlaceholderEmptyList />
                                ) : (
                                    <PlaceholderEmptyProgramme />
                                )}
                            </>
                        )}
                    </ContainerDiv>
                </Container>
            )}
        </ThemeContext.Consumer>
    );
};

export default Timetable;
