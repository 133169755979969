import React from 'react';
import styled from 'styled-components';
import * as palette from '../General/Variables.js';
import { Button as ReactButton, Chip, Divider, FontIcon, SelectionControl } from 'react-md';
import ThemeContext from '../Theme/ThemeContext';
import { getString } from '../../services/api/store.js';
import { Flex } from '../../scenes/common/Flex';
import Button, { buttonTypes } from '../../scenes/common/Button';
import NavigationBar from '../DetailHeader/NavigationBar';
import { DetailDrawer } from '../General/DetailDrawer';
import IconButton from '@mui/material/IconButton';

const ButtonDropdown = styled(ReactButton)`
    border: 1px solid #cccccc;
    margin-right: 8px;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        width: 100%;
    }
`;
const SelectionControlStyled = styled(SelectionControl)`
    min-height: 40px !important;
    height: unset !important;
    i {
        color: ${props => props.primarycolor} !important;
    }

    label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;

        span {
            overflow: hidden;
            max-width: 48ch;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }
    }

    .md-btn--icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .md-icon {
        font-size: 18px !important;
    }
`;
const ButtonStyled = styled(ReactButton)`
    border: 1px solid ${props => props.primarycolor};
    color: ${props => (props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE)};
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    float: ${props => props.position};
    display: block;
    height: 36px !important;

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;
const MenuButtons = styled.div`
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        min-width: 300px;
        padding: 16px;
    }
`;
const Clearfix = styled.div`
    clear: both;
`;
const DividerStyled = styled(Divider)`
    margin: 0 !important;
`;

const ChipStyled = styled(Chip)`
    border-radius: 2px;
    margin-right: 8px;
    background-color: #e6e6e6;
    border: solid 1px #b3b3b3;
    margin-bottom: 8px;
    &:hover i {
        color: ${palette.COLOR_WHITE} !important;
    }
`;
const ChipText = styled.div`
    margin-right: 20px;
`;
const ChipIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
`;
const MenuDiv = styled.div`
    position: absolute;
    top: 33px;

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        max-height: 300px;
        width: 500px;
    }

    ul {
        @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
            max-height: 200px;
            overflow-y: scroll;
            overflow-x: scroll;
            padding-top: 0px;
            padding-left: 0px;
            margin-left: 14px;
            margin-right: 14px;
            white-space: nowrap;
        }
    }
`;

const MenuContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const FilterSectionTitle = styled(Flex)`
    font-family: 'Cabin', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    margin: 12px 0;
    i {
        color: #000000 !important;
        cursor: pointer;
    }
`;

const FlexButtonsWrapper = styled(Flex)`
    padding: 16px;
    & > :first-child {
        margin-right: 8px;
    }
`;

const FilterSectionContainer = styled.div`
    padding: 0 24px;
`;

export const Scrollable = styled.section.attrs({
    className: '',
})`
    height: calc(100vh - 216px);
    overflow: auto;
`;

const ShowOptionsContainer = styled(Flex)`
    margin-bottom: 24px;
`;

const ShowOption = styled.button`
    padding: 7px 16px;
    ${props => props.active && 'box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)'};
    border-radius: 50px;
    background-color: ${props => (props.active ? props.primary : '#F4F5F7')};
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: ${props => (props.active ? '#fff' : '#1C1C1E')};
    flex: none;
    width: fit-content;
    cursor: pointer;
    height: 32px;
    border: none;

    &:first-of-type {
        margin-right: 8px;
    }
`;

const APPLY_FILTER = 'Apply';
const CLEAR_FILTER = 'Clear all';

class Expanded extends React.Component {
    constructor(props) {
        super(props);
        let menuOpen = {};
        this.props.filters.forEach(function (item) {
            menuOpen[item.type] = false;
        });

        this.state = {
            selectedFilters: this.props.selectedFilters ? this.props.selectedFilters : {},
            currentSelectedFilters: this.props.selectedFilters ? this.props.selectedFilters : {},
            menuOpen: menuOpen,
            filterString: this.props.filterText ? this.props.filterText : '',
            selectedShowFilter: this.props.myProgram || 'all',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.filters !== this.props.filters &&
            Object.keys(this.props.filters).length > 0
        ) {
            this.setState(
                {
                    filters: this.props.filters,
                    currentSelectedFilters: this.props.selectedFilters
                        ? this.props.selectedFilters
                        : {},
                },
                () => {
                    let selectedFilters = this.state.selectedFilters;
                    let allFilters = this.state.filters;

                    this.state.filters.map(item => {
                        const groupID = item.id;

                        let currentFilters = this.state.currentSelectedFilters[groupID];
                        selectedFilters[groupID] = [];
                        allFilters.forEach(function (group) {
                            if (group.id === groupID) {
                                selectedFilters[groupID] = group.options.filter(function (opt) {
                                    return (
                                        currentFilters &&
                                        (currentFilters.find(item => item.id === opt.id) ||
                                            currentFilters.indexOf(opt.id) > -1)
                                    );
                                });
                            }
                        });

                        if (!selectedFilters[groupID].length) {
                            delete selectedFilters[groupID];
                        }
                    });
                    this.setState({
                        selectedFilters: selectedFilters,
                        currentSelectedFilters: selectedFilters,
                    });
                },
            );
        }
    }

    //apply filters selected
    applyFilters() {
        let selectedFilters = this.state.selectedFilters;
        let allFilters = this.state.filters;

        if (this.state.filters && Array.isArray(this.state.filters)) {
            this.state.filters.map(item => {
                const groupID = item.id;

                let currentFilters = this.state.currentSelectedFilters[groupID];

                selectedFilters[groupID] = [];
                allFilters.forEach(function (group) {
                    if (group.id === groupID) {
                        selectedFilters[groupID] = group.options.filter(function (opt) {
                            return (
                                currentFilters &&
                                (currentFilters.indexOf(opt.id) > -1 ||
                                    currentFilters.includes(opt))
                            );
                        });
                    }
                });
                if (!selectedFilters[groupID].length) {
                    delete selectedFilters[groupID];
                }
            });
        }
        this.setState({
            selectedFilters: selectedFilters,
            currentSelectedFilters: this.state.currentSelectedFilters,
        });
        this.props.action({
            filters: selectedFilters,
            text: this.state.filterString,
            myProgram: this.state.selectedShowFilter,
        });
        this.props.closeFilter();
    }

    //clear current selection
    clearFilters() {
        let currentSelectedFilters = this.state.currentSelectedFilters;
        if (currentSelectedFilters && Object.keys(currentSelectedFilters).length) {
            Object.keys(currentSelectedFilters).forEach(i => (currentSelectedFilters[i] = []));
        }
        this.setState({
            ...this.state,
            currentSelectedFilters: currentSelectedFilters,
            selectedShowFilter: 'all',
        });
    }

    //remove filter clickin on chip
    clearItem(groupID, itemID) {
        let currentSelectedFilters = this.state.currentSelectedFilters;
        let selectedFilters = this.state.selectedFilters;

        //remove from current
        let index = currentSelectedFilters[groupID]
            ? currentSelectedFilters[groupID].indexOf(itemID)
            : -1;
        if (index > -1) {
            currentSelectedFilters[groupID].splice(index, 1);
        }
        //remove from applied
        if (selectedFilters[groupID]) {
            selectedFilters[groupID].forEach((item, idx) => {
                if (item.id === itemID) {
                    index = idx;
                }
            });

            if (index > -1) {
                selectedFilters[groupID].splice(index, 1);
            }
        }
        if (!selectedFilters[groupID].length) {
            delete selectedFilters[groupID];
        }
        this.setState({
            currentSelectedFilters: currentSelectedFilters,
            selectedFilters: selectedFilters,
        });
        this.props.action({
            filters: selectedFilters,
            text: this.state.filterString,
            myProgram: this.props.myProgram,
        });
    }

    //select item with checkbox (not applied)
    selectItem(groupID, itemID) {
        let currentSelectedFilters = this.state.currentSelectedFilters;

        if (!currentSelectedFilters[groupID]) {
            currentSelectedFilters[groupID] = [];
        }

        let index =
            currentSelectedFilters[groupID].indexOf(itemID) > -1 ||
            currentSelectedFilters[groupID].findIndex(item => item.id === itemID);
        if (index > -1) {
            if (currentSelectedFilters[groupID].indexOf(itemID) === 0) {
                currentSelectedFilters[groupID] = [];
            } else {
                currentSelectedFilters[groupID].splice(index, 1);
            }
        } else {
            currentSelectedFilters[groupID].push(itemID);
        }
        this.setState({ currentSelectedFilters: currentSelectedFilters });
    }

    //check which filters were applied to mark as current selected
    initializeFilters(visible, groupID) {
        let currentSelectedFilters = this.state.currentSelectedFilters;

        currentSelectedFilters[groupID] = [];
        if (visible && this.state.selectedFilters[groupID]) {
            this.state.selectedFilters[groupID].forEach(option => {
                currentSelectedFilters[groupID].push(option.id);
            });
        }

        this.setState({ currentSelectedFilters: currentSelectedFilters });
    }

    //initialize filters when menu opens
    onChangeVisibilityMenu(groupID) {
        let menuOpen = this.state.menuOpen;
        menuOpen[groupID] = !menuOpen[groupID];

        this.setState({
            menuOpen: menuOpen,
        });
    }

    buildFilters(theme) {
        let selected = this.state.selectedFilters;
        let filters = { dropdowns: [], chips: [] };
        let TEXT_CLEAR_FILTER = getString('clearAllFilters') || CLEAR_FILTER;
        let TEXT_APPLY_FILTER = getString('applyFilter') || APPLY_FILTER;

        filters.dropdowns = this.state.filters
            ? this.state.filters.map(function (item) {
                  let options = [];
                  item.options.forEach(function (opt) {
                      options.push(
                          <SelectionControlStyled
                              id={opt.id}
                              name="simple-checkboxes[]"
                              label={opt.name}
                              type="checkbox"
                              value={opt.id}
                              key={item.id + '-' + opt.id}
                              onChange={() => this.selectItem(item.id, opt.id)}
                              checked={
                                  this.state.currentSelectedFilters[item.id] &&
                                  this.state.currentSelectedFilters[item.id].indexOf(opt.id) > -1
                              }
                              primarycolor={theme.primary}
                          />,
                      );
                  }, this);

                  if (selected[item.id]) {
                      selected[item.id].forEach(function (opt) {
                          let chipLabel = (
                              <ChipText key={'chiptext-' + opt.id}>
                                  {opt.name}
                                  <ChipIcon key={'chipicon-' + opt.id}>
                                      <FontIcon
                                          aria-hidden={true}
                                          role={'presentation'}
                                          aria-label={'Close'}
                                          key={'chip-close-' + opt.id}
                                      >
                                          {palette.ICON_CLOSE}
                                      </FontIcon>
                                  </ChipIcon>
                              </ChipText>
                          );

                          filters.chips.push(
                              <ChipStyled
                                  label={chipLabel}
                                  key={'chip-' + opt.id}
                                  onClick={() => this.clearItem(item.id, opt.id)}
                              />,
                          );
                      }, this);
                  }

                  let iconDropdown = palette.ICON_EXPAND_MORE;
                  if (
                      this.state.selectedFilters[item.id] &&
                      this.state.selectedFilters[item.id].length > 0
                  ) {
                      iconDropdown = palette.ICON_CHECK;
                  }

                  return (
                      <React.Fragment key={'frag-' + item.id}>
                          <MenuContainer key={'container-' + item.id}>
                              <ButtonDropdown
                                  flat
                                  iconBefore={false}
                                  key={'button-' + item.id}
                                  onClick={() => this.onChangeVisibilityMenu(item.id)}
                                  iconEl={
                                      <FontIcon
                                          aria-hidden={true}
                                          role={'presentation'}
                                          key={'checkbox-' + item.id}
                                      >
                                          {iconDropdown}
                                      </FontIcon>
                                  }
                              >
                                  {item.name}
                              </ButtonDropdown>
                              {this.state.menuOpen[item.id] && (
                                  <MenuDiv
                                      key={'menudiv-' + item.id}
                                      className="md-list md-layover-child md-layover-child--below md-layover-child--simplified md-list--menu md-list--menu-restricted md-list--menu-below md-paper md-paper--2"
                                  >
                                      <ul key={'ul-' + item.id}>{options}</ul>
                                      <DividerStyled key={'divider-' + item.id} />
                                      <MenuButtons key={'buttons-' + item.id}>
                                          <ButtonStyled
                                              key={'clear-' + item.id}
                                              position="left"
                                              raised
                                              primary
                                              primarycolor={theme.primary}
                                              onClick={() => this.clearFilters(item.id)}
                                              theming="inversed"
                                          >
                                              {TEXT_CLEAR_FILTER}
                                          </ButtonStyled>
                                          <ButtonStyled
                                              key={'apply-' + item.id}
                                              position="right"
                                              raised
                                              primary
                                              primarycolor={theme.primary}
                                              onClick={() => this.applyFiltersOld(item.id)}
                                          >
                                              {TEXT_APPLY_FILTER}
                                          </ButtonStyled>
                                          <Clearfix key={'clearfix-' + item.id} />
                                      </MenuButtons>
                                  </MenuDiv>
                              )}
                          </MenuContainer>
                      </React.Fragment>
                  );
              }, this)
            : [];

        return filters;
    }

    render() {
        const TEXT_CLEAR_FILTER = getString('clearAllFilters') || CLEAR_FILTER;
        const TEXT_APPLY_FILTER = getString('applyFilter') || APPLY_FILTER;
        return (
            <React.Fragment>
                <ThemeContext.Consumer>
                    {({ theme }) => {
                        return (
                            <DetailDrawer>
                                <NavigationBar
                                    background={palette.COLOR_WHITE}
                                    typeName={getString('filtersScreenTitle') || 'Filters'}
                                    onClose={this.props.closeFilter}
                                />
                                <Scrollable>
                                    {this.props.filters.map(item => (
                                        <>
                                            <DividerStyled key={'divider-' + item.id} />
                                            <FilterSectionContainer
                                                key={`filter-section-${item.id}`}
                                            >
                                                <FilterSectionTitle
                                                    justifyContent={'space-between'}
                                                    alignitems={'center'}
                                                >
                                                    <div>{item.name}</div>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.onChangeVisibilityMenu(item.id)
                                                        }
                                                    >
                                                        <FontIcon
                                                            aria-hidden={true}
                                                            role={'presentation'}
                                                            style={{
                                                                transform: this.state.menuOpen[
                                                                    item.id
                                                                ]
                                                                    ? 'rotate(90deg)'
                                                                    : 'rotate(0deg)',
                                                                transition: '300ms',
                                                            }}
                                                        >
                                                            keyboard_arrow_down
                                                        </FontIcon>
                                                    </IconButton>
                                                </FilterSectionTitle>
                                                {!this.state.menuOpen[item.id] &&
                                                    item.options.map(opt => (
                                                        <SelectionControlStyled
                                                            id={opt.id}
                                                            name="simple-checkboxes[]"
                                                            label={opt.name}
                                                            type="checkbox"
                                                            value={opt.id}
                                                            labelBefore={true}
                                                            key={item.id + '-' + opt.id}
                                                            onChange={() =>
                                                                this.selectItem(item.id, opt.id)
                                                            }
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    this.selectItem(
                                                                        item.id,
                                                                        opt.id,
                                                                    );
                                                                }
                                                            }}
                                                            checked={
                                                                this.state.currentSelectedFilters[
                                                                    item.id
                                                                ] &&
                                                                (this.state.currentSelectedFilters[
                                                                    item.id
                                                                ].find(
                                                                    item => item.id === opt.id,
                                                                ) ||
                                                                    this.state.currentSelectedFilters[
                                                                        item.id
                                                                    ].indexOf(opt.id) > -1)
                                                            }
                                                            primarycolor={theme.primary}
                                                        />
                                                    ))}
                                            </FilterSectionContainer>
                                        </>
                                    ))}
                                </Scrollable>
                                <FlexButtonsWrapper>
                                    <Button
                                        type={buttonTypes.GREEN_LONG}
                                        background={theme.primary}
                                        text={TEXT_APPLY_FILTER}
                                        onClick={this.applyFilters}
                                    />
                                    <Button
                                        type={buttonTypes.GREY_LONG}
                                        background={theme.primary}
                                        text={TEXT_CLEAR_FILTER}
                                        onClick={this.clearFilters}
                                    />
                                </FlexButtonsWrapper>
                            </DetailDrawer>
                        );
                    }}
                </ThemeContext.Consumer>
            </React.Fragment>
        );
    }
}

export default Expanded;
