import React from 'react';
import { AccessibleFakeButton, DropdownMenu, FontIcon, IconSeparator } from 'react-md';
import styled from 'styled-components';
import * as palette from '../../../components/General/Variables';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { getBgAndFontColorForAllDifferentStates } from '../../../components/General/Colors';

const StyledDropdownMenu = styled(DropdownMenu)`
    .md-list {
        width: 375px !important;
        max-height: calc(100vh - 80px) !important;
        padding: 16px !important;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        top: 52px !important;
        right: 16px !important;
        left: unset !important;

        @media only screen and (max-width: ${palette.MAX_PHONE}) {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            width: 100vw !important;
            height: 100vh !important;
            max-height: unset !important;
        }
    }

    .md-icon-separator {
        padding: 8px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: ${props =>
            props.isSociety ? 'rgba(255, 255, 255, 0.16)' : props.backgroundColor};
        margin-right: 8px;

        &:hover {
            background-color: ${props =>
                props.isSociety ? 'rgba(255, 255, 255, 0.32)' : props.hoverColor};
        }

        ${props => props.visible && `background-color: ${props.activeColor}`};

        .md-icon {
            width: 24px;
            color: ${props =>
                props.visible ? props.fontColor : props.contrastBlackOrWhite} !important;
        }
    }

    .md-icon-text {
        display: none !important;
    }

    .md-list-tile {
        border-radius: 8px;
    }

    .md-list-tile--active {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const NotificationIcon = styled(FontIcon)`
    font-size: 24px !important;
`;

export const NewNotficationsBadge = styled.div`
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: absolute;
    top: ${props => props.top || '-4px'};
    right: ${props => props.right || '-4px'};
    border: ${props => props.border};
    color: #ffffff;
    font-size: 12px;
    text-align: center;
`;

const NotificationButton = ({
    chat,
    icon,
    unreadNotificationsCount,
    items,
    visible,
    onVisibilityChange,
    isSociety,
    light,
}) => {
    const { theme } = useTheme();
    const {
        contrastBlackOrWhite,
        defaultBg,
        hoverBg,
        activeBg,
        fontColor,
    } = getBgAndFontColorForAllDifferentStates(theme, light);

    return (
        <StyledDropdownMenu
            id={`${chat ? 'chat' : 'notifications'}-dropdown-menu`}
            isSociety={isSociety ? 1 : 0}
            menuItems={items}
            animationPosition="below"
            sameWidth
            simplifiedMenu={false}
            onVisibilityChange={onVisibilityChange}
            visible={visible}
            backgroundColor={defaultBg}
            hoverColor={hoverBg}
            activeColor={activeBg}
            fontColor={fontColor}
            contrastBlackOrWhite={light ? palette.BLACK_87 : contrastBlackOrWhite}
        >
            <AccessibleFakeButton
                label={''}
                component={IconSeparator}
                iconBefore
                aria-haspopup="true"
                aria-expanded={visible}
                aria-label={chat ? 'Conversations' : 'Notifications'}
                onKeyDown={event => {
                    if (event.key === 'Escape') {
                        onVisibilityChange(false);
                    }
                }}
            >
                <NotificationIcon aria-hidden>{icon}</NotificationIcon>
                {unreadNotificationsCount > 0 && (
                    <NewNotficationsBadge color={theme.contrast}>
                        {unreadNotificationsCount}
                    </NewNotficationsBadge>
                )}
            </AccessibleFakeButton>
        </StyledDropdownMenu>
    );
};

export default NotificationButton;
