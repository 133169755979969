import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ThemeContext, { useTheme } from '../../../components/Theme/ThemeContext';
import { getItemAsync } from '../../../services/api/data';
import { getImageSource } from '../../../services/api/db';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../../Appointments/constants';
import { Flex } from '../../common/Flex';
import HappeningNow from '../../../components/General/HappeningNow';
import Auth from '../../../services/api/auth';
import {
    AppointmentDetailsContainer,
    DetailsWrapper,
    EntryLabel,
    EntrySubLabel,
    FavoritesContainer,
    PendingLabel,
    SponsorLogo,
    StyledAvatar,
    StyledLink,
    TimeEntryContainer,
    VideoIcon,
} from '../style/style';
import { computeDurationInHours } from '../utils';
import isEmpty from 'lodash/isEmpty';
import { useGlobalState } from '../../../utils/container';
import LiveLabel from '../../../components/General/LiveLabel';
import { getVirtualEventSession } from '../../../services/api/eureka';
import sortBy from 'lodash/sortBy';
import ClassifierIconsGroup from '../../../components/Icons/ClassifierIconsGroup';
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';
import entities from '../../VirtualModeration/features/virtualFeature/constants/entities';
import useFavorites from '../../Favorites/hooks/useFavorites';
import { useFavoritesStore } from '../../Favorites/stores/favoriteStore';

const TYPE_EXTERNAL = 'External';
const TYPE_APPOINTMENT_VIRTUAL_ROOT = 'Appointment virtual room';

const LiveContainer = styled.div`
    margin-top: ${props => (props.isVertical ? '0' : '2px')};
    margin-right: 6px;
`;

const VirtualIcon = ({ item, displayIconDetails, showLive, props, isItemHovered, theme }) => (
    <>
        {item && item.params && item.params.virtualSession && displayIconDetails && (
            <>
                {showLive ? (
                    <LiveContainer isVertical={props.isVertical}>
                        <LiveLabel />
                    </LiveContainer>
                ) : (
                    <VideoIcon
                        aria-hidden={true}
                        role={'presentation'}
                        background={
                            item.favorite
                                ? isItemHovered
                                    ? theme.primary + 40
                                    : theme.primary + 30
                                : isItemHovered && 'rgba(0, 0, 0, 0.38)'
                        }
                    >
                        videocam
                    </VideoIcon>
                )}
            </>
        )}
    </>
);

export const PosterIcon = ({ item, virtualEventSession }) => {
    const { theme } = useTheme();
    const posterPdf = item && item.posterPdf && JSON.parse(item.posterPdf);
    const host = virtualEventSession?.VirtualEventUsers.find(
        vUser => vUser.role === entities.virtualEventUserEntity.role.roundTableHost,
    );
    const speakerWithPoster = virtualEventSession?.VirtualEventUsers.find(
        vUser =>
            vUser.role === entities.virtualEventUserEntity.role.presenter &&
            vUser.uploadedPresentationUrl,
    );
    const url =
        virtualEventSession?.uploadedPresentationUrl ||
        host?.uploadedPresentationUrl ||
        speakerWithPoster?.uploadedPresentationUrl ||
        posterPdf?.url;

    if (!url || !item) {
        return null;
    }

    return (
        <Tooltip title={'Poster/Slides attached'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20px',
                    height: '20px',
                    borderRadius: '6px',
                    backgroundColor: theme.primary,
                }}
            >
                <DescriptionIcon style={{ color: 'white', fontSize: 16 }} />
            </div>
        </Tooltip>
    );
};

const TimeEntry = props => {
    const [item, setItem] = useState({
        ...props.item,
        customWidth: 0,
        entryTop: 0,
        entryLeft: 0,
    });
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [isItemHovered, setIsItemHovered] = useState(false);
    const [sponsorLogo, setSponsorLogo] = useState(false);
    const [virtualEventSession, setVirtualEventSession] = useState(null);
    const [classifications, setClassifications] = useState([]);
    const [displayPosterIcon, setDisplayPosterIcon] = useState(false);
    const [posterAvailableToAttendees, setPosterAvailableToAttendees] = useState(false);

    const favorites = useFavoritesStore(state => state.favorites);
    const parents = useFavoritesStore(state => state.parents);

    const isFavorite = useMemo(() => {
        if (item) {
            const favoriteIds = favorites.map(f => f.objectId);
            const allFavorites = [...new Set([...favoriteIds, ...parents])];
            return allFavorites.includes(item.id);
        } else {
            return false;
        }
    }, [favorites, parents, item]);

    const to = props.to;
    const title = props.item.name;
    const subtitle = props.item.subNameList;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;

    useEffect(() => {
        if (
            item.customWidth !== props.customWidth ||
            item.entryTop !== props.item.entryTop ||
            item.entryLeft !== props.item.entryLeft
        ) {
            setItem({
                ...item,
                customWidth: props.customWidth || item.customWidth,
                entryTop: props.entryTop || props.item.entryTop,
                entryLeft: props.entryLeft || props.item.newLeft || props.item.left,
            });
        }
    }, [props.item.customWidth, props.item.entryTop, props.item.entryLeft]);

    useEffect(() => {
        (async () => {
            await refreshVirtualEventSession();

            if (item && item.params && item.params.backgroundImage) {
                getImageSource(item.params.backgroundImage, (err, img) => {
                    setBackgroundImage(img);
                });
            }

            if (item && item.params && item.params.sponsorLogo) {
                getImageSource(item.params.sponsorLogo, (err, img) => {
                    setSponsorLogo(img);
                });
            }
            const type = await getItemAsync('types', item.type);
            if (type) {
                setPosterAvailableToAttendees(item?.posterAvailableToAttendees);
                setDisplayPosterIcon(type.displayPosterIcons);
            }

            let currentItem = props.item;
            setItem(currentItem);

            if (socket) {
                socket.on(`updateData_${item.id}`, updateData);
            }
        })();

        if (item?.classifications?.length) {
            const orderingString =
                (item?.classifications &&
                    item?.classifications[0] &&
                    Object.keys(item?.classifications[0]).find(key => key.startsWith('order'))) ||
                'ordering';
            const sortedClassifications = sortBy(item?.classifications, [orderingString]);
            setClassifications(sortedClassifications);
        }

        return () => {
            if (socket) {
                socket.off(`updateData_${item.id}`, updateData);
            }
        };
    }, []);

    const refreshVirtualEventSession = async () => {
        const virtualEventSession = await getVirtualEventSession(item.id);

        updateData({ virtualEventSession });
    };

    const updateData = data => {
        const { virtualEventSession } = data;

        if (
            virtualEventSession &&
            virtualEventSession.message !== 'Not Found' &&
            !isEmpty(virtualEventSession)
        ) {
            setVirtualEventSession(virtualEventSession);
        } else {
            setVirtualEventSession(null);
        }
        return virtualEventSession;
    };

    const isAppointmentWaitingForResponse = appointment => {
        return (
            appointment &&
            appointment.members &&
            appointment.members.length &&
            appointment.members[0].AppointmentGroup &&
            appointment.members[0].AppointmentGroup.status ===
                APPOINTMENT_PARTICIPANT_STATUS.WAITING
        );
    };

    const getAppointmentParticipantName = appointment => {
        const currentUser = Auth.getUser();

        if (appointment && appointment.User && appointment.User.id === currentUser.id) {
            const member =
                appointment.members && appointment.members.length && appointment.members[0];
            return member && `${member.firstName} ${member.lastName}`;
        } else {
            return appointment.User && `${appointment.User.firstName} ${appointment.User.lastName}`;
        }
    };

    const getInitials = (firstName, lastName) => {
        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    };

    let isSessionOpen;
    if (item?.params) {
        const { virtualSession, virtualSessionType, onDemandRecType } = item.params;

        const isExternalLink =
            virtualSessionType === TYPE_EXTERNAL || onDemandRecType === TYPE_EXTERNAL;
        const isExternalOpen = !!(isExternalLink && virtualSession !== '');
        const isAppointmentVirtualRoom = virtualSessionType === TYPE_APPOINTMENT_VIRTUAL_ROOT;

        isSessionOpen = !!(
            isExternalOpen ||
            isAppointmentVirtualRoom ||
            virtualSession === 'on-demand' ||
            (virtualEventSession && virtualEventSession.status !== 'closed')
        );
    }

    const broadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const showLive = isSessionOpen && broadcasting;
    const isAppointment = item && item.type === 'appointment';
    const isAppointmentPendingResponse = isAppointment && isAppointmentWaitingForResponse(item);
    const appointmentPartnerName = isAppointment && getAppointmentParticipantName(item);
    const itemContainer = document.getElementById(`item-container-${item.id}`);
    const smallAppointmentCard =
        isAppointment && props.isVertical && itemContainer && itemContainer.offsetHeight === 32;
    const durationInHours = computeDurationInHours(item.start, item.end);
    let displayIconDetails;
    if (props.isVertical) {
        displayIconDetails = subtitle ? !!(durationInHours > 0.7) : !!(durationInHours > 0.583);
    } else {
        displayIconDetails = durationInHours > 0.25;
    }
    const verticalSmallIcon = props.isVertical && !displayIconDetails && !subtitle;
    const horizontalSmallIcon = !props.isVertical && !displayIconDetails && !subtitle;
    const titleOneLine = props.isVertical && durationInHours < 0.58;
    const titleFourLines = props.isVertical && durationInHours >= 1.5;
    const titleEightLines = props.isVertical && durationInHours >= 2;

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <li
                    id={`item-container-${item.id}`}
                    style={{
                        position: 'absolute',
                        left: item.entryLeft || props.entryLeft,
                        top: item.entryTop || props.entryTop,
                        width:
                            item.customWidth ||
                            props.customWidth ||
                            (horizontalSmallIcon
                                ? '72px'
                                : props.entryWidth && `calc(${props.entryWidth} - 4px)`),
                    }}
                >
                    <StyledLink
                        path=""
                        params={{
                            type: isAppointment ? 'appointment' : 'detail',
                            objectClass: props.objectClass,
                            objectId: item.id,
                        }}
                        pendingColor={isAppointmentPendingResponse}
                        height={
                            props.isVertical
                                ? verticalSmallIcon
                                    ? '38px'
                                    : props.entryHeight && `calc(${props.entryHeight} - 4px)`
                                : null
                        }
                        width={
                            item.customWidth ||
                            props.customWidth ||
                            (horizontalSmallIcon
                                ? '72px'
                                : props.entryWidth && `calc(${props.entryWidth} - 4px)`)
                        }
                        ismeeting={isAppointment}
                        isVertical={props.isVertical}
                        favorite={!!item.favorite}
                        primary={theme.primary}
                        onMouseEnter={event => {
                            props.handleClickOutside(event);
                            setIsItemHovered(true);
                        }}
                        onMouseLeave={() => setIsItemHovered(false)}
                    >
                        <FavoritesContainer favorite={isFavorite} primary={theme.primary}>
                            <TimeEntryContainer>
                                {/*{this.props.isVertical &&*/}
                                {/*    backgroundImage &&*/}
                                {/*    displayBannerOnVerticalItem && (*/}
                                {/*        <EntryImageVertical backgroundimage={backgroundImage} />*/}
                                {/*    )}*/}
                                <DetailsWrapper
                                    isFlex={smallAppointmentCard}
                                    justifyContent={smallAppointmentCard && 'space-between'}
                                >
                                    {/*{!this.props.isVertical && backgroundImage && (*/}
                                    {/*    <EntryImage backgroundimage={backgroundImage} />*/}
                                    {/*)}*/}
                                    <Flex justifyContent={'space-between'}>
                                        <EntryLabel
                                            hasSubtitle={subtitle}
                                            titleOneLine={titleOneLine}
                                            titleFourLines={titleFourLines}
                                            titleEightLines={titleEightLines}
                                        >
                                            {title}
                                        </EntryLabel>
                                        <Flex width={'unset'}>
                                            {props.isVertical && (
                                                <VirtualIcon
                                                    item={item}
                                                    isItemHovered={isItemHovered}
                                                    showLive={showLive}
                                                    theme={theme}
                                                    displayIconDetails={displayIconDetails}
                                                    props={props}
                                                />
                                            )}
                                            {props.isVertical && displayIconDetails && (
                                                <HappeningNow
                                                    start={item.start}
                                                    end={item.end}
                                                    color={theme.contrast}
                                                    isTimelineTag={true}
                                                />
                                            )}
                                            {props.isVertical &&
                                                displayPosterIcon &&
                                                posterAvailableToAttendees && (
                                                    <PosterIcon
                                                        item={item}
                                                        virtualEventSession={virtualEventSession}
                                                    />
                                                )}
                                        </Flex>
                                    </Flex>
                                    {!smallAppointmentCard && (
                                        <EntrySubLabel
                                            hasSubtitle={subtitle}
                                            isHorizontal={!props.isVertical}
                                            highlighted={
                                                props.isHighlighted ? theme.textSponsor : null
                                            }
                                        >
                                            {isAppointment ? appointmentPartnerName : subtitle}
                                        </EntrySubLabel>
                                    )}
                                    <Flex alignItems={'center'}>
                                        {!props.isVertical && displayIconDetails && (
                                            <HappeningNow
                                                start={item.start}
                                                end={item.end}
                                                color={theme.contrast}
                                                isTimelineTag={true}
                                            />
                                        )}
                                        {isAppointment && (
                                            <AppointmentDetailsContainer
                                                justifyContent={'flex-start'}
                                                alignItems={'center'}
                                                smallAppointmentCard={smallAppointmentCard}
                                            >
                                                {!smallAppointmentCard && (
                                                    <StyledAvatar src={item.User.imageUrl}>
                                                        {!item.User.imageUrl &&
                                                            getInitials(
                                                                item.User.firstName,
                                                                item.User.lastName,
                                                            )}
                                                    </StyledAvatar>
                                                )}
                                                {!smallAppointmentCard && (
                                                    <StyledAvatar src={item.members[0].imageUrl}>
                                                        {!item.members[0].imageUrl &&
                                                            getInitials(
                                                                item.members[0].firstName,
                                                                item.members[0].lastName,
                                                            )}
                                                    </StyledAvatar>
                                                )}
                                                {isAppointmentPendingResponse && (
                                                    <PendingLabel>Pending</PendingLabel>
                                                )}
                                            </AppointmentDetailsContainer>
                                        )}
                                        <Flex>
                                            {!props.isVertical && (
                                                <VirtualIcon
                                                    item={item}
                                                    isItemHovered={isItemHovered}
                                                    showLive={showLive}
                                                    theme={theme}
                                                    displayIconDetails={displayIconDetails}
                                                    props={props}
                                                />
                                            )}
                                            {sponsorLogo && displayIconDetails && (
                                                <SponsorLogo
                                                    src={sponsorLogo}
                                                    alt={'sponsor-logo'}
                                                />
                                            )}
                                            {displayIconDetails && !!classifications?.length && (
                                                <>
                                                    <ClassifierIconsGroup
                                                        classifications={classifications}
                                                        type={item.type}
                                                        programmeItem
                                                    />
                                                </>
                                            )}
                                            {!props.isVertical &&
                                                displayPosterIcon &&
                                                posterAvailableToAttendees && (
                                                    <PosterIcon
                                                        item={item}
                                                        virtualEventSession={virtualEventSession}
                                                    />
                                                )}
                                        </Flex>
                                    </Flex>
                                </DetailsWrapper>
                            </TimeEntryContainer>
                        </FavoritesContainer>
                    </StyledLink>
                </li>
            )}
        </ThemeContext.Consumer>
    );
};

export default TimeEntry;
