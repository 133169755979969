import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as palette from '../General/Variables';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import Typography from '@mui/material/Typography';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { default as MUIButton } from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '../Theme/ThemeContext';
import { uploadFileToS3 } from '../../services/api/eureka';

const ImageFrame = styled(MUIButton)`
    width: 100%;
    flex-grow: 1;
    border-radius: 6px;
`;

const HelpText = styled(Typography)`
    color: ${palette.COLOR_TEXT_DISABLED};
    font-size: 13px;
    line-height: 20px;
`;

const ImageHelpText = styled(Typography)`
    color: ${palette.COLOR_TEXT_DISABLED};
    font-weight: 500;
    line-height: 24px;
`;

export const getFilenameFromUrl = url => {
    return url.substring(url.lastIndexOf('/') + 1);
};

const FloqFileUploader = ({
    value,
    maxMB = 2,
    onChange,
    extError,
    invalidFileError = '',
    height = 120,
    fileType = 'pdf',
    tag,
    label,
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();

    const onDrop = React.useCallback(async acceptedFiles => {
        setLoading(true);
        const result = await uploadFileToS3(acceptedFiles[0], tag);
        onChange(result);
        setLoading(false);
    }, []);

    const fileSizeValidator = file => {
        if (file.size > 1024 ** 2 * maxMB) {
            return {
                code: 'size-too-large',
                message: `File is larger than ${maxMB} MB`,
            };
        }
        return null;
    };

    const accept = fileType === 'pdf' ? ['.pdf'] : ['.xlsx', '.xls', '.xlsb'];

    const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept,
        onDrop,
        validator: fileSizeValidator,
    });

    let errorInvalidFile = fileType === 'pdf' ? 'File must be a pdf' : 'Select an XLS or XLSX file';
    if (invalidFileError) {
        errorInvalidFile = invalidFileError;
    }

    useEffect(() => {
        if (fileRejections && fileRejections.length) {
            const { errors } = fileRejections[0];
            const error = errors[0];
            setError(error.code === 'file-invalid-type' ? errorInvalidFile : errors[0].message);
        } else {
            setError('');
        }
    }, [fileRejections]);

    const getFrameBackgroundColor = (errors, isDragging) => {
        if (errors) {
            return palette.COLOR_ERROR_SELECTED;
        }
        return isDragging ? palette.COLOR_SELECTED : 'transparent';
    };

    const getFrameBorderColor = (errors, isDragging) => {
        if (errors) {
            return palette.COLOR_ERROR;
        }
        return isDragging ? theme.primary : palette.SECONDARY_DARK;
    };

    const displayError = error || extError || '';

    return (
        <>
            {value && (
                <Box
                    sx={{
                        width: '100%',
                        backgroundColor: palette.UI_GREY_2,
                        borderRadius: '6px',
                        border: `2px solid ${palette.COLOR_WHITE}`,
                        padding: '8px 8px 8px 16px',
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent={'space-between'}
                        spacing={0}
                        justifyItems={'center'}
                        alignItems={'center'}
                    >
                        <Stack direction="row" alignItems={'center'}>
                            {fileType === 'excel' && (
                                <IconButton sx={{ mr: '16px' }} color="primary" aria-label="excel">
                                    <BorderAllIcon
                                        sx={{ width: '24px', length: '24px' }}
                                        fontSize="inherit"
                                    />
                                </IconButton>
                            )}
                            {getFilenameFromUrl(value)}
                        </Stack>

                        <IconButton
                            onClick={() => {
                                onChange({ link: '' });
                            }}
                            aria-label="delete"
                        >
                            <DeleteOutlineIcon
                                sx={{ width: '24px', length: '24px' }}
                                fontSize="inherit"
                            />
                        </IconButton>
                    </Stack>
                </Box>
            )}
            {!value && (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <ImageFrame
                        sx={{
                            backgroundColor: getFrameBackgroundColor(displayError, isDragActive),
                            border: `2px dashed ${getFrameBorderColor(displayError, isDragActive)}`,
                            height: `${height}px`,
                            '&:hover': {
                                backgroundColor: palette.COLOR_SELECTED,
                            },
                            padding: '16px',
                        }}
                        color="primary"
                        aria-label="load icon"
                        onClick={() => {}}
                    >
                        <Box>
                            <CloudUploadOutlinedIcon
                                sx={{
                                    color: isDragActive
                                        ? theme.primary
                                        : palette.COLOR_TEXT_DISABLED,
                                    fontSize: 30,
                                }}
                            />
                            <ImageHelpText variant="body2">
                                Drag and drop or <Link>browse</Link>
                            </ImageHelpText>
                            <HelpText sx={{ lineHeight: '24px' }} variant="body2">
                                {label ? label : 'PDF' + `, max ${maxMB}MB`}
                            </HelpText>
                        </Box>
                    </ImageFrame>
                    {displayError.length > 0 && (
                        <FormHelperText error>{displayError}</FormHelperText>
                    )}
                </div>
            )}
        </>
    );
};

export default FloqFileUploader;
