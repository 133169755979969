import React from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import muiStyled from '@mui/material/styles/styled';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import { Dialog } from '@mui/material';

import NavigationLink from '../../../components/Navigation/NavigationLink';
import { cancelButtonStyle, saveButtonSocietyStyle } from '../../../components/Dialog/styles';
import * as palette from '../../../components/General/Variables';

const StyledDialog = muiStyled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        width: 560,
        height: 648,
        borderRadius: 12,
    },
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
}));

const dialogActionsStyle = {
    padding: 16,
    justifyContent: 'flex-start',
};

const contentStyle = {
    padding: 24,
};

const baseButtonStyle = {
    textTransform: 'none',
    height: 40,
    borderRadius: 6,
    fontSize: 15,
};

const hoverOverlayStyle = {
    '&:hover': {
        backgroundImage: palette.HOVER_OVERLAY,
    },
};

const StyledIcon = styled(FontIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    color: rgba(0, 0, 0, 0.6) !important;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;

    :hover {
        background-color: ${palette.COLOR_HOVER};
    }
`;

const Img = styled.img`
    height: 240px;
    width: calc(100% - 48px);
    margin: 56px 24px 16px;
    border-radius: 8px;
`;

const Title = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.87);
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px;
`;

const Subtitle = styled.div`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 8px;
`;

const DialogActionsWrapper = styled.div`
    box-shadow: 0px -12px 9px -12px #ccc;
    z-index: 2;
`;

const EventInfoModal = ({
    open,
    handleClose,
    imageUrl,
    title,
    subtitle,
    content,
    route,
    theme,
    canGoToEvent,
}) => {
    const DialogButtons = () => [
        <>
            {canGoToEvent === 'open' && (
                <NavigationLink
                    key="go-to-event-modal-button-link"
                    path={route}
                    style={{ marginRight: '8px' }}
                >
                    <Button
                        key={`bt-${title}-open`}
                        disableElevation
                        onClick={() => {}}
                        tabIndex={-1}
                        style={{
                            ...baseButtonStyle,
                            ...saveButtonSocietyStyle,
                            backgroundColor: theme.primary,
                            padding: '0 12px',
                        }}
                        variant="text"
                        sx={hoverOverlayStyle}
                    >
                        Go to event
                    </Button>
                </NavigationLink>
            )}
            {canGoToEvent === 'soon' && (
                <Button
                    key={`bt-${title}-open`}
                    disableElevation
                    onClick={() => {}}
                    style={{
                        ...baseButtonStyle,
                        ...saveButtonSocietyStyle,
                        backgroundColor: 'rgba(0, 0, 0, 0.38)',
                        padding: '0 12px',
                    }}
                    variant="text"
                >
                    Coming soon
                </Button>
            )}
        </>,
        <Button
            key={`bt-${title}-close`}
            disableElevation
            onClick={handleClose}
            style={{ ...baseButtonStyle, ...cancelButtonStyle, padding: '0 12px' }}
            sx={hoverOverlayStyle}
            variant="text"
        >
            Close
        </Button>,
    ];

    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <StyledIcon onClick={handleClose} sx={hoverOverlayStyle}>
                close
            </StyledIcon>
            <Img src={imageUrl} alt={'Event banner'} />
            <div style={{ overflowY: 'scroll' }}>
                <TitleContainer>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleContainer>
                <DialogContent style={{ ...contentStyle, overflow: 'auto' }}>
                    {content}
                </DialogContent>
            </div>
            <DialogActionsWrapper>
                <MuiDialogActions style={dialogActionsStyle}>
                    <DialogButtons />
                </MuiDialogActions>
            </DialogActionsWrapper>
        </StyledDialog>
    );
};

export default EventInfoModal;
