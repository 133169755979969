import React, { useMemo } from 'react';
import get from 'lodash/get';
import { FontIcon } from 'react-md';
import styled, { css } from 'styled-components';

import { useGlobalState } from '../../../../utils/container';
import StreamPlayer from '../common/StreamPlayer';
import entities from '../../constants/entities';
import * as palette from '../../../../components/General/Variables';
import { ShadowOverlay, VirtualUserInfoWrapper, VirtualUserTitle } from '../../styles';

const ProfileImageWrapper = styled.div`
    background-color: #666666;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    min-height: 0;
`;

const AccountCircleIconWrapper = styled.div`
    font-size: 48px;
    color: #ffffff;
    opacity: ${props => (props.imageUrl ? 1 : 0.2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${props => props.imageUrl}');
    width: 48px;
    height: 48px;
    border-radius: 50%;
${props =>
    props.video &&
    css`
        opacity: 1;
        height: 100%;
        width: 100%;
        .stream-player {
            height: 100%;
            width: 100%;
        }
    `}
`;

const MicSwitchWrapper = styled.div`
    bottom: 0;
    margin: 0 4px 4px;
    position: absolute;
    left: 0;
    z-index: 10;
`;

const VirtualSessionUser = props => {
    const stateCtx = useGlobalState();
    const { virtualEventUser } = props;
    const { User: user } = virtualEventUser;
    const { virtualEventSession, streams } = stateCtx;

    const streamToUse = useMemo(() => {
        return (streams || []).find(
            stream =>
                virtualEventSession.status === 'broadcasting' &&
                stream &&
                stream.streamId === get(virtualEventUser, 'UserId'),
        );
    }, [get(virtualEventSession, 'status'), get(virtualEventUser, 'UserId'), streams]);

    if (streamToUse) {
        const isHost = user.role === entities.virtualEventUserEntity.role.roundTableHost;
        const { isVideoOn, isMicrophoneOn } = virtualEventUser;

        return (
            <ProfileImageWrapper>
                <AccountCircleIconWrapper video={isVideoOn ? 1 : 0} imageUrl={user.imageUrl}>
                    {streamToUse && (
                        <StreamPlayer
                            key={`stream-player-${streamToUse.getId()}`}
                            className={'main-stream-profile'}
                            isVideoOn={isVideoOn}
                            local={true}
                            stream={streamToUse}
                            uid={streamToUse.getId()}
                            domId={`stream-player-${streamToUse.getId()}`}
                            data-qa={`panel-discussion-camera-feed-${user.id}`}
                        />
                    )}
                </AccountCircleIconWrapper>
                <MicSwitchWrapper data-qa={`panel-discussion-user-microphone-${user.id}`}>
                    <FontIcon
                        aria-hidden={true}
                        role={'presentation'}
                        style={{ fontSize: '16px', color: '#ffffff' }}
                    >
                        {isMicrophoneOn ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                    </FontIcon>
                </MicSwitchWrapper>
                <VirtualUserInfoWrapper data-qa={`panel-discussion-user-name-${user.id}`}>
                    <VirtualUserTitle>
                        {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                    </VirtualUserTitle>
                </VirtualUserInfoWrapper>
                <ShadowOverlay />
            </ProfileImageWrapper>
        );
    }

    return null;
};

export default VirtualSessionUser;
