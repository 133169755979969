import React, { useEffect, useMemo, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import { ButtonBase, InputAdornment, Tooltip, List } from '@mui/material';
import Modal from '../../../components/Modal';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as palette from '../../../components/General/Variables';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFormik } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FloqImageUploader from '../../../components/FloqImageUploader';
import useOutsideListener from '../../../hooks/useOutsideListener';
import FloqTextInput from '../../../components/FloqTextInput';
import { isIdUUID } from './InstitutionContentResourcesTab';

const cardStyle = {
    minWidth: 'fit-content',
    height: 'max-content',
    position: 'relative',
    width: '100%',
};

const iconStyle = {
    color: palette.COLOR_WHITE,
    cursor: 'pointer',
};

const iconButtonStyle = {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 40,
    width: 40,
    zIndex: 3,
    borderRadius: '50%',
};

const EMPTY_IMAGE = {
    name: '',
    path: '',
    imageUrl: '',
    event: '',
    _id: 'new',
};

const Slide = ({ slide, onEdit, onDelete }) => {
    const [anchorElement, setAnchorElement] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const menuRef = useRef(null);
    const cardRef = useRef(null);
    const showMenu = Boolean(anchorElement);

    const handleMenuClick = () => {
        if (isIdUUID(slide._id)) {
            setAnchorElement(cardRef.current);
        }
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    useOutsideListener({
        ref: menuRef,
        onClickOutside: () => {
            handleClose();
        },
    });

    return (
        <>
            <Card
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={cardStyle}
                ref={cardRef}
            >
                <CardMedia component="img" height={150} src={slide.imageUrl} />
                {isHovered && (
                    <Box
                        position="absolute"
                        height="100%"
                        width="100%"
                        zIndex={3}
                        borderRadius={2}
                        top={0}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.38)' }}
                    ></Box>
                )}
                {isHovered && (
                    <Tooltip
                        title={
                            !isIdUUID(slide._id)
                                ? 'This slide can not be edited by an institution representative'
                                : ''
                        }
                        disableHoverListener={isIdUUID(slide._id)}
                    >
                        <ButtonBase style={iconButtonStyle} onClick={handleMenuClick}>
                            <MoreHorizIcon style={iconStyle} />
                        </ButtonBase>
                    </Tooltip>
                )}
            </Card>
            <Menu
                id="menu"
                open={showMenu}
                anchorEl={anchorElement}
                onClose={handleClose}
                ref={menuRef}
                sx={{ minWidth: 166, transform: 'translateX(-4px)' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                PaperProps={{
                    sx: {
                        mt: 0.5,
                    },
                }}
            >
                <MenuList
                    sx={{
                        borderRadius: 8,
                        minWidth: 166,
                    }}
                >
                    <MenuItem
                        key="slide-menu-item-edit-save"
                        onClick={() => {
                            onEdit(slide);
                            setAnchorElement(null);
                        }}
                    >
                        <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }}>
                            Edit slide
                        </ListItemText>
                        <EditOutlinedIcon />
                    </MenuItem>
                    <MenuItem
                        key="slide-menu-item-edit-cancel"
                        onClick={() => {
                            onDelete(slide);
                            setAnchorElement(null);
                        }}
                    >
                        <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }}>
                            Delete
                        </ListItemText>
                        <DeleteOutlineOutlinedIcon />
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

const SlideShow = ({
    slideshow,
    tag = 'institutionsSlide',
    onSlideChange,
    disableUrl = false,
    dimensions = { x: 1200, y: 600 },
    type = 'horizontal',
    handleDragEnd = () => {},
}) => {
    const [selectedSlide, setSelectedSlide] = useState(null);
    const [imageToSave, setImageToSave] = useState('');

    const formik = useFormik({
        initialValues: {
            url: '',
            alt: '',
        },
        validate: values => {
            const errors = {};
            if (!values.alt) {
                errors.alt = 'Alt is required';
            }
            return errors;
        },
        onSubmit: values => {},
    });

    useEffect(() => {
        const selectedSlideImage = slideshow.find(image => image._id === selectedSlide?._id);
        if (selectedSlide) {
            formik.setValues({
                url: selectedSlideImage?.url || '',
                alt: selectedSlideImage?.alt || '',
            });
        }
    }, [selectedSlide?._id, slideshow]);

    const removeSelectedSlide = value => {
        onSlideChange(value._id || '', '', '', '');
    };

    const closeModal = () => {
        setSelectedSlide(null);
        setImageToSave('');
        formik.resetForm();
    };

    const renderActions = () => {
        return [
            {
                label: 'Save',
                onClick: () => {
                    onSlideChange(
                        selectedSlide?._id || '',
                        imageToSave || selectedSlide?.imageUrl || '',
                        formik.values.url,
                        formik.values.alt,
                    );
                    closeModal();
                },
                disabled: !imageToSave && !formik.isValid,
                loading: false,
            },
            {
                label: 'Cancel',
                onClick: () => {
                    closeModal();
                },
                variant: 'contained',
                color: 'secondary',
            },
        ];
    };

    const renderAddSlideButton = () => {
        return (
            <IconButton
                sx={{
                    width: type === 'horizontal' ? 150 : 'inherit',
                    height: 150,
                    backgroundColor: palette.COLOR_WHITE,
                    border: `2px dashed ${palette.UI_GREY_4}`,
                    borderRadius: '8px',
                    alignSelf: 'center',
                }}
                onClick={() => {
                    setSelectedSlide(EMPTY_IMAGE);
                }}
                color="primary"
            >
                <Stack
                    spacing={1}
                    sx={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <AddIcon sx={{ width: '32px', height: '32px' }} />
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '15px',
                            color: palette.COLOR_TEXT_DISABLED,
                        }}
                        variant="body2"
                    >
                        Add slide
                    </Typography>
                </Stack>
            </IconButton>
        );
    };

    return (
        <Stack
            direction="row"
            alignItems="center"
            overflow="scroll"
            style={{ paddingTop: 24, paddingBottom: 24 }}
        >
            <Stack direction={type === 'horizontal' ? 'row' : 'column'} spacing={2} width="100%">
                {type === 'horizontal' && renderAddSlideButton()}
                <>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable" direction={type}>
                            {provided => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <List
                                        component={Stack}
                                        direction={type === 'horizontal' ? 'row' : 'column'}
                                        spacing={2}
                                        alignItems="center"
                                        overflowX="scroll"
                                    >
                                        {slideshow
                                            .filter(slide => isIdUUID(slide._id))
                                            .map((slide, index) => (
                                                <Draggable
                                                    key={'draggable' + slide._id}
                                                    draggableId={String(slide._id)}
                                                    index={index}
                                                >
                                                    {provided => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...(isIdUUID(slide._id)
                                                                ? provided.draggableProps
                                                                : {})}
                                                            {...(isIdUUID(slide._id)
                                                                ? provided.dragHandleProps
                                                                : {})}
                                                        >
                                                            <Slide
                                                                key={slide._id}
                                                                slide={slide}
                                                                onEdit={value => {
                                                                    setSelectedSlide(value);
                                                                }}
                                                                onDelete={value => {
                                                                    removeSelectedSlide(value);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                        {slideshow
                                            .filter(slide => !isIdUUID(slide._id))
                                            .map(slide => (
                                                <div key={slide._id}>
                                                    <Slide
                                                        key={slide._id}
                                                        slide={slide}
                                                        onEdit={() => {}}
                                                        onDelete={() => {}}
                                                    />
                                                </div>
                                            ))}
                                        {type === 'vertical' && renderAddSlideButton()}
                                    </List>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            </Stack>
            <Modal
                title={`${selectedSlide?._id !== 'new' ? 'Edit' : 'Add'} slide`}
                open={Boolean(selectedSlide?._id?.length)}
                onClose={() => {
                    closeModal();
                }}
                zeroContentPadding
                actionsPosition={'left'}
                actions={renderActions()}
                size="md"
                loading={false}
                headerContainerStyle={{ padding: '16px 24px' }}
            >
                <Box sx={{ flexGrow: 1, justifyContent: 'center' }} padding={3} paddingBottom={0}>
                    <Typography variant="h4">Image</Typography>
                    <Stack width={'600px'}>
                        <FloqImageUploader
                            label="Slide"
                            tag="institution-proposal"
                            maxMB={2}
                            initialFrameHeight={300}
                            imageUrl={selectedSlide?.imageUrl}
                            outputDimensions={dimensions}
                            onChange={images => {
                                console.log('LOGGER', images);
                                if (images.length && images[0].imageUrl) {
                                    setImageToSave(images[0].imageUrl);
                                    formik.setFieldValue('alt', images[0].name);
                                } else {
                                    setImageToSave('');
                                }
                            }}
                        />
                    </Stack>
                    <Box sx={{ width: '50%', justifyContent: 'center' }} paddingRight={3}>
                        <FloqTextInput
                            label={'Add alt attribute'}
                            value={formik.values.alt || ''}
                            onBlur={() => {
                                formik.setFieldTouched('alt', true);
                            }}
                            onChange={value => {
                                formik.setFieldError('alt', '');
                                formik.setFieldValue('alt', value);
                            }}
                            endAdornment={
                                <InputAdornment position="end" sx={{ marginRight: '0px' }}>
                                    <Tooltip
                                        title={
                                            <Box sx={{ maxWidth: 144, textAlign: 'center' }}>
                                                Add the image name used by screen readers
                                            </Box>
                                        }
                                        placement="top"
                                    >
                                        <IconButton style={{ cursor: 'default' }}>
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: palette.COLOR_TEXT_MEDIUM,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                            error={formik.touched.alt ? formik.errors.alt : undefined}
                        />
                    </Box>
                </Box>
                {!disableUrl && (
                    <Box sx={{ width: '50%', justifyContent: 'center' }} padding={3}>
                        <FloqTextInput
                            label={'URL'}
                            value={formik.values.url || ''}
                            onChange={value => formik.setFieldValue('url', value)}
                        />
                    </Box>
                )}
            </Modal>
        </Stack>
    );
};

export default SlideShow;
