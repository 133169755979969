/*
INIT: ensure Babel/Eslint/Flow is configured for ES Class Fields & Static Properties
JSX USAGE: <Iframe src='http://web.site' onLoad={myOnloadFunction}/>
*/
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { checkCCLinks } from '../../helper/CCLink';
import { withRouter } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';

const IframeContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: ${props => window.innerHeight - 64}px;
`;

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: '0',
    display: 'block',
};

const Iframe = ({ html, match, history, location }) => {
    const iframe = useRef();
    const { theme } = useTheme();

    useEffect(() => {
        if (html && iframe.current) {
            const frame = iframe.current;
            const doc = frame.contentWindow.document;

            doc.open();
            doc.write(html);
            doc.close();

            const style = doc.createElement('style');
            style.type = 'text/css';
            style.textContent = `
                a:link, a:visited, a:active {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.87);
                    text-decoration: underline;
                    text-underline-offset: 5px;
                }
                a:hover {
                    color: ${theme.primary};
                }
            `;
            doc.head.appendChild(style);

            checkCCLinks(frame.contentDocument, match, history, location);
        }
    }, [html, iframe.current]);

    return (
        <IframeContainer>
            <iframe
                title="webpage"
                ref={iframe}
                frameBorder={'0'}
                width={'100%'}
                height={window.innerHeight + 'px'}
                style={iframeStyle}
            />
        </IframeContainer>
    );
};

export default withRouter(Iframe);
