import React, { useState } from 'react';
import { DropdownMenu, FontIcon } from 'react-md';

import { StyledDropdownMenu, StyledListItem, ViewButton } from '../../Programme/style/style';

const GamificationFilterButton = ({ filters, selectedFilter = 'All', onFilterSelected, id }) => {
    const [isVisible, setVisible] = useState(false);

    const onVisibilityChange = newVisible => setVisible(newVisible);
    return (
        <StyledDropdownMenu
            id={id}
            customWidth={'200px'}
            menuItems={[
                ...filters.map(f => (
                    <StyledListItem
                        key={f}
                        isSelected={f === selectedFilter}
                        onClick={() => {
                            onFilterSelected(f);
                            setVisible(false);
                        }}
                        primaryText={f}
                    />
                )),
            ]}
            anchor={{
                x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
                y: DropdownMenu.VerticalAnchors.BOTTOM,
            }}
            position={DropdownMenu.Positions.TOP_LEFT}
            animationPosition="below"
            sameWidth
            simplifiedMenu={false}
            onVisibilityChange={onVisibilityChange}
            visible={isVisible}
        >
            <ViewButton>
                {selectedFilter}
                <FontIcon aria-hidden={true} role={'presentation'}>
                    arrow_drop_down
                </FontIcon>
            </ViewButton>
        </StyledDropdownMenu>
    );
};

export default GamificationFilterButton;
