import { Avatar } from 'react-md';
import React from 'react';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
    ${props =>
        props.size
            ? `&.md-avatar { width: ${props.size}px; height: ${props.size}px }`
            : 'max-width: 100% !important; max-height: 100% !important;'}
    border: none !important;
    .md-avatar-content {
        font-size: 14px !important;
    }
`;

const CustomAvatar = styled(StyledAvatar)`
    border: none !important;
    background: #bfbfbf !important;
    font-size: 12px;
`;

const UserAvatar = ({ user, size }) => {
    const { firstName, lastName } = user;
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const secondInitial = lastName ? lastName[0].toUpperCase() : '';
    const initials = `${firstInitial}${secondInitial}`;
    const imageSrc = user.imageUrl || user.image;

    return imageSrc ? (
        <StyledAvatar
            src={imageSrc}
            size={size}
            alt={`${firstName} ${lastName}'s profile picture`}
        />
    ) : (
        <CustomAvatar size={size}>{initials}</CustomAvatar>
    );
};

export default UserAvatar;
