import React from 'react';
import styled from 'styled-components';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import entities from '../../constants/entities';
import { useVMMutation, useVMState } from '../../containers/main';
import { useTheme } from '../../../../../../components/Theme/ThemeContext';
import FilledFilterIcon from '../../../../../../assets/icons/FilledFIlterIcon';
import get from 'lodash/get';
import { isBoolean } from 'lodash';

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const IconButtonWrapper = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: ${props => (props.selected ? props.color : 'rgba(0, 0, 0, 0.6)')};
    border-radius: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    ${props => !props.nomarginright && 'margin-right: 8px;'}

    &:hover {
        background-color: ${props => props.color}1F;
        color: ${props => props.color};
    }
`;

const RightSectionFooter = () => {
    const stateCtx = useVMState();
    const mutationCtx = useVMMutation();
    const { theme } = useTheme();
    const { roundTableHostMode, timeslot } = stateCtx;
    const isCardsMode = roundTableHostMode === entities.hostedSessionHostMode.cards;
    const isAttendeesMode = roundTableHostMode === entities.hostedSessionHostMode.attendees;
    const isChatMode = roundTableHostMode === entities.hostedSessionHostMode.chat;
    const allowHostToStartMeeting = isBoolean(get(timeslot, 'allowHostToStartMeeting'))
        ? get(timeslot, 'allowHostToStartMeeting')
        : true;

    const onChangeHostMode = mode => {
        if (mode === roundTableHostMode) {
            return;
        }

        mutationCtx.setRoundTableHostMode(mode);
    };

    return (
        <ButtonsContainer>
            <IconButtonWrapper
                onClick={() => onChangeHostMode(entities.hostedSessionHostMode.cards)}
                selected={isCardsMode ? 1 : 0}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onChangeHostMode(entities.hostedSessionHostMode.cards);
                    }
                }}
                color={theme.primary}
                aria-label={'Documents and recordings'}
            >
                {isCardsMode ? (
                    <FilledFilterIcon fillColor={theme.primary} />
                ) : (
                    <FilterNoneOutlinedIcon />
                )}
            </IconButtonWrapper>
            {allowHostToStartMeeting && (
                <IconButtonWrapper
                    onClick={() => onChangeHostMode(entities.hostedSessionHostMode.attendees)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            onChangeHostMode(entities.hostedSessionHostMode.attendees);
                        }
                    }}
                    selected={isAttendeesMode ? 1 : 0}
                    color={theme.primary}
                    aria-label={'Meeting participants'}
                >
                    {isAttendeesMode ? <PeopleIcon /> : <PeopleOutlinedIcon />}
                </IconButtonWrapper>
            )}
            <IconButtonWrapper
                onClick={() => onChangeHostMode(entities.hostedSessionHostMode.chat)}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onChangeHostMode(entities.hostedSessionHostMode.chat);
                    }
                }}
                selected={isChatMode ? 1 : 0}
                color={theme.primary}
                aria-label={'Chat'}
            >
                {isChatMode ? <ChatBubbleIcon /> : <ChatBubbleOutlineIcon />}
            </IconButtonWrapper>
        </ButtonsContainer>
    );
};

export default RightSectionFooter;
