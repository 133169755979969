import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    min-height: 232px;
    max-width: 264px;
    border-radius: 8px;
    background-color: rgba(31, 162, 148, 0.2);
`;

const ImageWrapper = styled.img`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    align-self: center;
    margin-bottom: 14px;
`;

const Title = styled.p`
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    font-family: Cabin, sans-serif;
    margin: 0 !important;
`;

const Subtitle = styled.p`
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto, sans-serif;
    line-height: 1.33;
    font-size: 12px;
    margin: 0 !important;
`;

const Description = styled.p`
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 1.43;
    margin: 8px 0 0 0 !important;
`;

const GreenCard = ({ title, subtitle, description, imageUrl, altText }) => (
    <Wrapper>
        {imageUrl && <ImageWrapper src={imageUrl} alt={altText} />}
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Description>{description}</Description>
    </Wrapper>
);

export default GreenCard;
