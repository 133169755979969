import React, { useMemo, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import ListItemText from '@mui/material/ListItemText';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import UploadIcon from '@mui/icons-material/Upload';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

import EditLinksModal, { renderLinksIcons } from './EditLinksModal';
import { useFormikContext } from 'formik';
import Tooltip from '@mui/material/Tooltip';
// TODO  include in ENVS for staging and production
const ccFilesPaths = [
    'https://cctrixiedocuments.s3.amazonaws.com/',
    'https://s3.eu-central-1.amazonaws.com/floq-dev-files/',
];

const OptionsMenu = ({ id, onChange, disabled }) => {
    const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);
    const optionsMenuOpen = Boolean(optionsAnchorEl);
    const handleCloseOptionsMenu = () => {
        setOptionsAnchorEl(null);
    };

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                    disabled={disabled}
                    aria-label={`links-options-button-${id}`}
                    aria-controls={optionsMenuOpen ? `links-options-menu-${id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={optionsMenuOpen ? 'true' : undefined}
                    onClick={event => {
                        if (disabled) {
                            return;
                        }
                        setOptionsAnchorEl(event.currentTarget);
                    }}
                >
                    <MoreHorizIcon />
                </IconButton>
            </Stack>
            <Menu
                id={`links-options-menu-${id}`}
                anchorEl={optionsAnchorEl}
                open={optionsMenuOpen}
                onClose={handleCloseOptionsMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        onChange('edit');
                        handleCloseOptionsMenu();
                    }}
                >
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        onChange('delete');
                        handleCloseOptionsMenu();
                    }}
                >
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export const isIdUUID = id => {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);
};

const ResourcesSection = ({ institution, institutionProposalData }) => {
    const formik = useFormikContext();
    const [addResourcesAnchorEl, setAddResourcesAnchorEl] = React.useState(null);
    const [editLink, setEditLink] = useState(null);
    const resourcesCacheRef = useRef({});

    const linksToDisplay = useMemo(() => {
        return formik.values.links || institutionProposalData?.links || institution?.links || [];
    }, [institutionProposalData, institution, formik.values.links]);

    const addResourcesMenuOpen = Boolean(addResourcesAnchorEl);

    const EMPTY_LINK = {
        name: '',
        _id: '',
        url: '',
        mimeType: '',
    };

    const isLinkUploadedPdf = link => {
        const { url } = link;
        let isuploadedPdf = false;
        ccFilesPaths.forEach(path => {
            if (url?.includes(path)) {
                isuploadedPdf = true;
            }
        });
        return isuploadedPdf;
    };

    const handleOnChange = (link, action) => {
        console.log('LOGGER link', link);
        if (action === 'delete') {
            console.log('LOGGER formik.values.links', formik.values.links);
            formik.setFieldValue(
                'links',
                formik.values.links?.filter(l => l._id !== link._id),
            );
        }
    };

    return (
        <Box padding={3}>
            <>
                {linksToDisplay.map((link, index) => {
                    return (
                        <Stack
                            direction="row"
                            justifyContent={'space-between'}
                            padding={'8px 0px 8px 0px'}
                            spacing={0}
                            key={`resources-link-sections-item-${link._id}`}
                        >
                            <Stack direction="row" alignItems={'center'} spacing={2}>
                                {renderLinksIcons(link.mimeType || '')}
                                <Stack direction="column">
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        {link.name}
                                    </Typography>
                                    {link.name !== link.url && (
                                        <Typography
                                            sx={{
                                                maxWidth: '600px',
                                            }}
                                            noWrap
                                            variant="body2"
                                        >
                                            {isLinkUploadedPdf(link) ? 'Uploaded PDF' : link.url}
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                            <Tooltip
                                title={
                                    !isIdUUID(link._id)
                                        ? 'This link can not be edited by an institution representative'
                                        : ''
                                }
                                disableHoverListener={isIdUUID(link._id)}
                            >
                                <Stack direction="row" spacing={2} alignItems={'flex-start'}>
                                    <OptionsMenu
                                        id={link._id || ''}
                                        disabled={!isIdUUID(link._id)}
                                        onChange={action => {
                                            if (action === 'edit') {
                                                setEditLink({
                                                    ...link,
                                                    mode: undefined,
                                                    uploadPdf: isLinkUploadedPdf(link),
                                                });
                                            } else {
                                                const toRemove = {
                                                    _id: link._id || '',
                                                    ordering: 0,
                                                };
                                                handleOnChange(toRemove, 'delete');
                                            }
                                        }}
                                    />
                                </Stack>
                            </Tooltip>
                        </Stack>
                    );
                })}
            </>

            <Box sx={{ mt: '18px' }}>
                <Button
                    onClick={event => {
                        setAddResourcesAnchorEl(event.currentTarget);
                    }}
                    startIcon={<AddIcon />}
                    variant="contained"
                    sx={{ height: 40, marginTop: 0.5 }}
                >
                    Add resource
                </Button>
                <Menu
                    id={`add-links-options-menu`}
                    anchorEl={addResourcesAnchorEl}
                    open={addResourcesMenuOpen}
                    onClose={() => {
                        setAddResourcesAnchorEl(null);
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setEditLink(EMPTY_LINK);
                            setAddResourcesAnchorEl(null);
                        }}
                    >
                        <ListItemText>Add link</ListItemText>
                        <LinkIcon />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setEditLink({ ...EMPTY_LINK, uploadPdf: true });
                            setAddResourcesAnchorEl(null);
                        }}
                    >
                        <ListItemText>Upload PDF</ListItemText>
                        <UploadIcon />
                    </MenuItem>
                </Menu>
                <EditLinksModal
                    link={editLink}
                    handleClose={() => {
                        setEditLink(null);
                    }}
                    onChange={values => {
                        if (!values._id) {
                            return;
                        }

                        resourcesCacheRef.current[values._id] = values;

                        let action = 'add';
                        if (values.mode !== 'create') {
                            action = 'update';
                        }

                        const link = {
                            _id: values._id,
                        };

                        handleOnChange(link, action);
                        setEditLink(null);
                    }}
                ></EditLinksModal>
            </Box>
        </Box>
    );
};

export default ResourcesSection;
