import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';
import sortBy from 'lodash/sortBy';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Header from '../../components/DetailHeader';
import NavigationBar from '../../components/DetailHeader/NavigationBar.js';
import Loader from '../../components/General/Loader.js';
import * as palette from '../../components/General/Variables.js';
import { useTheme } from '../../components/Theme/ThemeContext';
import { hasNote, makeItem } from '../../services/api/data';
import { getFullProfile, getVirtualEventSession } from '../../services/api/eureka';
import InteractivityBar from './interactivityTab/InteractivityBar';
import AnalyticsService from '../../features/analytics/services';
import Tabs from '../../components/Tabs';
import SectionsWrapper from './SectionsWrapper';
import InfoTab from './infoTab';
import ContactTab from './contactTab';
import NotesWrapper from './interactivityTab/NotesWrapper';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { getImageSource } from '../../services/api/db';
import Auth from '../../services/api/auth';
import TypeAccessRestrictionsForTabs, {
    tabTypes,
} from '../AccessRestrictions/TypeAccessRestrictionsForTabs';
import {
    AchievementType,
    useAchievementActions,
} from '../Achievements/hooks/useAchievementActions';
import { getString } from '../../services/api/store';
import { DetailDrawer } from '../../components/General/DetailDrawer';
import { navigateTo } from '../../components/Navigation/NavigationLink';
import Iframe from 'react-iframe';
import queryString from 'query-string';

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: calc(100vh - 158px);
    overflow: auto;
    border-radius: 12px;
    width: 100%;
`;

const ReactPlayerWrapper = styled.div`
    width: 100%;
    height: 256px;
    padding: 0 16px;

    iframe {
        border-radius: 8px;
    }
`;

const MetaFusionVideoWrapper = styled.div`
    width: 100%;
    padding: 0 16px;

    iframe {
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 2.48;
    }
`;

const DetailPage = props => {
    const [item, setItem] = useState({ params: {}, sections: [] });
    const [navigationTitle, setNavigationTitle] = useState(null);
    const [updateNote, setUpdateNote] = useState(false);
    const { trackAchievement } = useAchievementActions();
    const [loading, setLoading] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [evaluationScreen, setEvaluationScreen] = useState(null);
    const [currentUserGroups, setCurrentUserGroups] = useState([]);
    const [loadingUserGroups, setLoadingUserGroups] = useState(true);
    const [showEvaluationScreen, setShowEvaluationScreenExternal] = useState(false);
    const [tabsData, setTabsData] = useState(false);
    const [interactivityInfo, setInteractivityInfo] = useState({});
    const [hasCheckin, setHasCheckin] = useState(false);
    const [backgroundType, setBackgroundType] = useState(null);
    const [backgroundVideo, setBackgroundVideo] = useState(null);
    const [places, setPlaces] = useState(null);
    const [hostPresentation, setHostPresentation] = useState(null);
    const [isRoundTable, setIsRoundTable] = useState(false);
    const [virtualEventSession, setVirtualEventSession] = useState(false);

    const { theme } = useTheme();
    const { objectId, objectClass, DrawerWrapperOverride, hideBackNavigation } = props;
    const { showSignIn } = useContext(AuthUserContext);
    const useCustomWrapper = !!DrawerWrapperOverride;
    const isMetaFusionVideo = backgroundVideo && backgroundVideo.includes('meta-dcr.com');

    const elementScrollData = throttle(value => {
        const target = value.target;
        if (target && target.scrollTop > 48) {
            setNavigationTitle(item.name);
        } else {
            setNavigationTitle(item.typeName);
        }
    }, 100);

    useEffect(() => {
        if (!item) {
            return;
        }
        if (item.type === 'timeslot') {
            trackAchievement(AchievementType.VIEW_SCHEDULE_ITEM, item.id);
        }

        const interactivityInfoData = {
            id: item.id,
            name: item.name,
            type: item.type,
            objectClass,
            interactivity: item.interactivity,
            hasNote: item.hasNote,
        };

        setInteractivityInfo(interactivityInfoData);

        const matchUrl = props.prefix ? props.prefix : '/' + props.match.url.split('/')[1];
        let hasCheckinData =
            (item.interactivity || []).findIndex(item => item.id === 'checkin') > -1;

        const { typeEntity } = item;

        if (typeEntity && typeEntity.tabs && typeEntity.tabs.length > 0) {
            hasCheckinData = typeEntity.enableCheckins || false;
        }

        setHasCheckin(hasCheckinData);
        setBackgroundVideo(item.params?.backgroundVideo);
        setBackgroundType(item.params?.backgroundType);

        const tabsDataArray = [];
        if (
            !loadingUserGroups &&
            ((item && item.info && item.info.text) ||
                (item &&
                    item.sections &&
                    item.sections.length &&
                    item.sections.find(
                        i =>
                            (i.isParent || i.type !== 'timeslot') &&
                            i.type !== 'link' &&
                            i.type !== 'place' &&
                            i.type !== 'programelement',
                    )) ||
                (item.params && item.params.slideshow && item.params.slideshow.length > 0))
        ) {
            if (!(theme.kiosk && item?.typeParams?.tabInfoRestrictedGroup)) {
                tabsDataArray.push({
                    id: 'tabInfoRestrictedGroup',
                    label: getString('tabs.infoTab', 'Info'),
                    content: function Info() {
                        return (
                            <TypeAccessRestrictionsForTabs
                                tabType={tabTypes.INFO}
                                typeParams={item.typeParams}
                                currentUserGroups={currentUserGroups}
                            >
                                <InfoTab
                                    item={item}
                                    clickOpenDetail={props.clickOpenDetail}
                                    {...props}
                                />
                            </TypeAccessRestrictionsForTabs>
                        );
                    },
                });
            }
        }

        if (
            !loadingUserGroups &&
            item?.params &&
            (item?.params?.contactEmail ||
                item?.params?.contactFacebookUrl ||
                item?.params?.contactLinkedinUrl ||
                item?.params?.contactPhoneNumber ||
                item?.params?.contactTwitterUrl)
        ) {
            if (!(theme.kiosk && item?.typeParams?.tabContactRestrictedGroup)) {
                tabsDataArray.push({
                    id: 'tabContactRestrictedGroup',
                    label: getString('contactTab', 'Contact'),
                    content: () => (
                        <TypeAccessRestrictionsForTabs
                            tabType={tabTypes.CONTACT}
                            typeParams={item.typeParams}
                            currentUserGroups={currentUserGroups}
                        >
                            <ContactTab
                                item={item}
                                clickOpenDetail={props.clickOpenDetail}
                                matchUrl={matchUrl}
                                {...props}
                            />
                        </TypeAccessRestrictionsForTabs>
                    ),
                });
            }
        }

        if (
            !loadingUserGroups &&
            item &&
            item.sections &&
            item.sections.length &&
            item.sections.find(
                i => (i.type === 'timeslot' || i.type === 'programelement') && !i.isParent,
            )
        ) {
            let hasProgramelements = false;

            let scheduleItems = item.sections.filter(i => {
                if (i.type === 'programelement') {
                    hasProgramelements = true;
                }
                return (i.type === 'timeslot' || i.type === 'programelement') && !i.isParent;
            });

            if (item.type === 'timeslot') {
                let allItems = [];
                scheduleItems.forEach(s => {
                    const items = s.items.map(it => {
                        const item = it;
                        item.isScheduleChild = true;
                        item.type = s.type;
                        return item;
                    });
                    allItems = [...allItems, ...items];
                });
                const ordering = !hasProgramelements
                    ? ['start', 'orderingName', 'name']
                    : ['orderingName', 'name', 'start'];
                allItems = sortBy(allItems, ordering);
                scheduleItems =
                    allItems && allItems.length
                        ? [
                              {
                                  title: '',
                                  items: allItems,
                                  type: hasProgramelements ? 'mixed' : 'timeslot',
                              },
                          ]
                        : [];
            }

            if (!(theme.kiosk && item?.typeParams?.tabScheduleRestrictedGroup)) {
                tabsDataArray.push({
                    id: 'tabScheduleRestrictedGroup',
                    label: getString('tabs.scheduleTab', 'Schedule'),
                    content: function Schedule() {
                        return (
                            <TypeAccessRestrictionsForTabs
                                tabType={tabTypes.SCHEDULE}
                                typeParams={item.typeParams}
                                currentUserGroups={currentUserGroups}
                            >
                                <SectionsWrapper
                                    item={{ sections: scheduleItems }}
                                    clickOpenDetail={props.clickOpenDetail}
                                />
                            </TypeAccessRestrictionsForTabs>
                        );
                    },
                });
            }
        }

        if (
            !loadingUserGroups &&
            item &&
            !theme.embed &&
            !theme.kiosk &&
            item.interactivity &&
            item.interactivity.length
        ) {
            const hasInteractivity = item.interactivity.some(element => element.id !== 'checkin');

            if (
                !(theme.kiosk && item?.typeParams?.tabInteractiveRestrictedGroup) &&
                hasInteractivity
            ) {
                tabsDataArray.push({
                    id: 'tabInteractiveRestrictedGroup',
                    label: getString('tabs.interactiveTab', 'Interactive'),
                    content: function Interactive() {
                        return (
                            <TypeAccessRestrictionsForTabs
                                tabType={tabTypes.INTERACTIVE}
                                typeParams={item.typeParams}
                                currentUserGroups={currentUserGroups}
                            >
                                <InteractivityBar
                                    item={interactivityInfo}
                                    history={props.history}
                                    setShowEvaluationScreenExternal={
                                        setShowEvaluationScreenExternal
                                    }
                                    setEvaluationScreen={setEvaluationScreen}
                                    objectClass={objectClass}
                                    objectId={objectId}
                                    goToLoginPage={goToLoginPage()}
                                />
                            </TypeAccessRestrictionsForTabs>
                        );
                    },
                });
            }
        }

        if (
            !loadingUserGroups &&
            item &&
            ((item.sections &&
                item.sections.length &&
                item.sections.find(i => i.type === 'link')) ||
                (item?.typeEntity?.posterAvailableToAttendees &&
                    (hostPresentation || (item.posterPdf && item.posterPdf.url))))
        ) {
            const linkItems = item.sections.filter(i => i.type === 'link');
            const { match, history, location } = props;
            const path = item?.typeEntity?.posterAvailableToAttendees
                ? `/on-demand-poster-room/${item.id}`
                : `/virtual-session-round/${item.id}`;
            const openExternal = function (hostPresentation, posterPdf) {
                const url = hostPresentation?.uploadedPresentationUrl || posterPdf?.url;
                if (item && item.id) {
                    AnalyticsService.addSample('pdf', url, item.id);
                }
                window.open(url, '_blank', 'noopener,noreferrer');
            };

            if ((item.posterPdf && item.posterPdf.url) || hostPresentation) {
                const posterPdf = item.posterPdf;

                linkItems.push({
                    type: 'link',
                    title: 'Poster / slides',
                    items: [
                        {
                            name: hostPresentation?.uploadedPresentationFilename || posterPdf?.name,
                            onClick: () => {
                                if (virtualEventSession && isRoundTable) {
                                    if (path.includes('on-demand-poster-room')) {
                                        return openExternal(hostPresentation, posterPdf);
                                    }
                                    return navigateTo({
                                        match,
                                        history,
                                        location,
                                        path: path,
                                        state: {
                                            keepHistoryLength: true,
                                        },
                                    });
                                }
                                return openExternal(hostPresentation, posterPdf);
                            },
                        },
                    ],
                });
            }
            if (!(theme.kiosk && item?.typeParams?.tabResourcesRestrictedGroup)) {
                tabsDataArray.push({
                    id: 'tabResourcesRestrictedGroup',
                    label: getString('tabs.resourcesTab', 'Resources'),
                    itemsLength: linkItems?.length || 0,
                    content: function Resources() {
                        return (
                            <TypeAccessRestrictionsForTabs
                                tabType={tabTypes.RESOURCES}
                                typeParams={item.typeParams}
                                currentUserGroups={currentUserGroups}
                            >
                                <SectionsWrapper
                                    item={{ sections: linkItems }}
                                    clickOpenDetail={props.clickOpenDetail}
                                />
                            </TypeAccessRestrictionsForTabs>
                        );
                    },
                });
            }
        }

        if (item.typeParams?.tabRestrictionsWithOrder) {
            tabsDataArray.map(tab => {
                const tabWithGivenId = item.typeParams?.tabRestrictionsWithOrder.find(
                    it => it.id === tab.id,
                );
                tab.order = tabWithGivenId?.order;
                tab.label = tabWithGivenId?.name;
                return tab;
            });
        }

        setTabsData(tabsDataArray);

        const placesData =
            (item &&
                item.sections &&
                item.sections.length &&
                item.sections.filter(i => i.type === 'place')) ||
            null;

        setPlaces(placesData);
    }, [
        item,
        isRoundTable,
        virtualEventSession,
        virtualEventSession?.ExternalObject?.reference,
        loadingUserGroups,
        hostPresentation?.uploadedPresentationUrl,
    ]);

    useEffect(() => {
        (async () => {
            if (!Auth.isUserAuthenticated()) {
                setLoadingUserGroups(false);
                return;
            }
            const fullProfile = await getFullProfile();
            const { userGroups } = fullProfile;

            setCurrentUserGroups(userGroups);
            setLoadingUserGroups(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const virtualEventSession = await getVirtualEventSession(item.id);
            if (virtualEventSession?.VirtualEventUsers?.length) {
                const userWithPresentationUrl = virtualEventSession?.VirtualEventUsers.find(
                    user => !!user.uploadedPresentationUrl,
                );
                setHostPresentation(userWithPresentationUrl);
                const isRoundTable =
                    virtualEventSession && virtualEventSession.roomType === 'roundTable';
                setIsRoundTable(isRoundTable);
                setVirtualEventSession(virtualEventSession);
            }
        })();
    }, [item]);

    useEffect(() => {
        details(objectClass, objectId);
        setUpdateNote(false);
    }, [objectId, updateNote]);

    const details = (objectClass, objectId) => {
        if (!objectClass || !objectId) {
            return;
        }

        setLoading(true);
        makeItem(objectId, objectClass, (err, item) => {
            if (err) {
                console.log(err);
            } else {
                const showClickBooth = item && item.typeParams && item.typeParams.showClickBooth;
                if (typeof item?.posterPdf === 'string') {
                    item.posterPdf = JSON.parse(item?.posterPdf);
                }
                if (item && showClickBooth) {
                    return props.history.replace({
                        pathname: `${props.match.url}/exhibitorbooth/${item.id}`,
                        state: {
                            fromLocation: props.location.pathname,
                            prevPath: props.location.pathname,
                        },
                    });
                }
                if (item && item.id) {
                    const itemBackgroundImage = item.params?.backgroundImage;
                    const typeDefaultBackgroundImage = item.typeParams?.defaultBackgroundImage;
                    const bgImageId = itemBackgroundImage || typeDefaultBackgroundImage;

                    if (bgImageId) {
                        getImageSource(bgImageId, (err, img) => {
                            setBackgroundImage(img);
                        });
                    } else {
                        setBackgroundImage(null);
                    }
                    hasNote(item.id, (err, isNote) => {
                        item.hasNote = isNote;
                        setItem(item);
                        setNavigationTitle(item.typeName);
                        setLoading(false);
                    });
                }
            }
        });
    };

    const goBack = () => {
        return props.history.goBack();
    };

    const goToLoginPage = () => {
        const { match, history, location } = props;
        return e => {
            showSignIn(e, { match, history, location });
        };
    };

    const Wrapper = useCustomWrapper ? DrawerWrapperOverride : DetailDrawer;

    useEffect(() => {
        if (
            backgroundVideo &&
            backgroundType === 'video' &&
            isMetaFusionVideo &&
            typeof window?.iFrameResize === 'function'
        ) {
            // eslint-disable-next-line no-unused-expressions
            window?.iFrameResize({ checkOrigin: false }, '.meta-widget-liveplayer');
        }
    }, [backgroundVideo, backgroundType, isMetaFusionVideo]);
    let closePath = props.location.pathname;
    const searchParams = queryString.parse(props.location.search);

    if (searchParams.mode) {
        closePath += `?mode=${searchParams.mode}`;
    }

    return (
        <Wrapper>
            {evaluationScreen && showEvaluationScreen ? (
                <>{evaluationScreen}</>
            ) : (
                <>
                    {!hideBackNavigation && (
                        <div>
                            <NavigationBar
                                goBack={goBack}
                                closePath={closePath}
                                typeName={navigationTitle}
                                background={palette.COLOR_WHITE}
                            />
                        </div>
                    )}
                    <Scrollable id={`scrollable`} onScroll={elementScrollData}>
                        <div>
                            {backgroundType === 'video' && backgroundVideo && !isMetaFusionVideo && (
                                <ReactPlayerWrapper>
                                    <ReactPlayer
                                        url={backgroundVideo}
                                        width="100%"
                                        height="256px"
                                        controls
                                    />
                                </ReactPlayerWrapper>
                            )}
                            {backgroundType === 'video' && backgroundVideo && isMetaFusionVideo && (
                                <MetaFusionVideoWrapper>
                                    <Iframe
                                        className="meta-widget-liveplayer"
                                        style="width:100%"
                                        frameBorder="0"
                                        scrolling="no"
                                        allow="autoplay"
                                        allowFullScreen
                                        url={backgroundVideo}
                                    ></Iframe>
                                </MetaFusionVideoWrapper>
                            )}

                            <div>
                                <Header
                                    id={item.id}
                                    item={item}
                                    type={item.type}
                                    startTime={item.start}
                                    endTime={item.end}
                                    typeName={item.typeName}
                                    title={item.title}
                                    subtitle={item.subNameDetail}
                                    picture={item.picture}
                                    params={item.params}
                                    backgroundType={backgroundType || 'image'}
                                    backgroundImage={backgroundImage}
                                    currentUserGroups={currentUserGroups}
                                    typeParams={item.typeParams}
                                    imageUrl={item.imageUrl}
                                    places={places && places.length > 0 ? places : null}
                                    hasCheckin={hasCheckin}
                                    parentHasCustomWrapper={useCustomWrapper}
                                    pdf={
                                        item?.typeEntity?.posterAvailableToAttendees &&
                                        (hostPresentation || item.posterPdf)
                                    }
                                    notesButton={
                                        <NotesWrapper
                                            item={interactivityInfo}
                                            objectId={objectId}
                                            notesButton={true}
                                            setUpdateNote={setUpdateNote}
                                        />
                                    }
                                    notesButtonLarge={
                                        <NotesWrapper
                                            item={interactivityInfo}
                                            objectId={objectId}
                                            notesButtonLarge={true}
                                            setUpdateNote={setUpdateNote}
                                        />
                                    }
                                    notesTextWithAction={
                                        <NotesWrapper
                                            item={interactivityInfo}
                                            objectId={objectId}
                                            notesTextWithAction={true}
                                            setUpdateNote={setUpdateNote}
                                        />
                                    }
                                    notePage={
                                        <NotesWrapper
                                            item={interactivityInfo}
                                            objectId={objectId}
                                            notePage={true}
                                            setUpdateNote={setUpdateNote}
                                        />
                                    }
                                />
                            </div>
                            {item.hasNote && !theme.kiosk && (
                                <NotesWrapper
                                    item={interactivityInfo}
                                    objectId={objectId}
                                    displayNoteCard={true}
                                    setUpdateNote={setUpdateNote}
                                />
                            )}
                        </div>

                        {!(loading || loadingUserGroups) && (
                            <div>
                                {tabsData && tabsData.length > 1 ? (
                                    <Tabs
                                        data={sortBy(tabsData, 'order')}
                                        isSmallTab={true}
                                        checkContentAllTabs={true}
                                        isSidePanelTabs={true}
                                    />
                                ) : (
                                    <>{tabsData.length > 0 && tabsData[0].content()}</>
                                )}
                            </div>
                        )}
                        {loading && <Loader />}
                    </Scrollable>
                </>
            )}
        </Wrapper>
    );
};

export default withRouter(DetailPage);
