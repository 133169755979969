import * as palette from '../../../components/General/Variables';
import facebookLogo from '../../../assets/social/icon-filled-facebook.png';
import linkedinLogo from '../../../assets/social/icon-filled-linkedin.png';
import twitterLogo from '../../../assets/social/icon-filled-twitter.png';
import React from 'react';
import { IconWrapper, SocialLink, LinkIcon, LinkLogo } from '../style';

const ContactLinks = ({ links }) => {
    return links.map(link => {
        if (!link.url || link.type === 'phone' || link.type === 'email') {
            return null;
        }

        let href = `https://${link.url}`;

        if (link.type === 'twitter' && link.url.charAt(0) === '@') {
            href = `https://twitter.com/${link.url.substring(1)}`;
        }
        if (link.type === 'twitter' && link.url.charAt(0) !== '@') {
            href = `https://twitter.com/${link.url}`;
        }
        if ((link.url && link.url.startsWith('https://')) || link.type === 'phone') {
            href = link.url;
        }

        return (
            <IconWrapper
                href={href}
                key={link.url}
                target={link.type === 'email' || link.type === 'phone' ? '_self' : '_blank'}
            >
                {link.type === 'facebook' && (
                    <SocialLink>
                        <LinkLogo src={facebookLogo} alt="Facebook" />
                    </SocialLink>
                )}
                {link.type === 'linkedin' && (
                    <SocialLink>
                        <LinkLogo src={linkedinLogo} alt="linkedin" />
                    </SocialLink>
                )}
                {link.type === 'twitter' && (
                    <SocialLink>
                        <LinkLogo
                            src={twitterLogo}
                            alt="twitter"
                            style={{ width: 16, height: 16 }}
                        />
                    </SocialLink>
                )}
                {link.type === 'web' && (
                    <SocialLink>
                        <LinkIcon aria-hidden={true} role={'presentation'}>
                            {palette.ICON_GLOBE}
                        </LinkIcon>
                    </SocialLink>
                )}
                {link.type === 'website' && (
                    <SocialLink>
                        <LinkIcon aria-hidden={true} role={'presentation'}>
                            {palette.ICON_GLOBE}
                        </LinkIcon>
                    </SocialLink>
                )}
            </IconWrapper>
        );
    });
};

export default ContactLinks;
