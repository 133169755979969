import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontIcon } from 'react-md';
import styled from 'styled-components';

import Slideshow from '../../components/Slideshow';
import { getLocalAppState } from '../../services/api';
import useOnScreen from '../../hooks/useOnScreen';
import NewsFeedWidget from '../LandingPage/components/widgets/NewsFeedWidget';

const FeedCardContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    box-shadow: ${props => !props.banner && '0 3px 6px 0 rgba(0, 0, 0, 0.3)'};
    background-color: ${props => !props.banner && '#ffffff'};
    margin-bottom: 24px;
    padding: 0;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: normal;
    font-family: Roboto;
    border: none;
    cursor: pointer;
    overflow: hidden;
    text-align: left;
`;

const Wrapper = styled.div`
    ${props => props.maxWidth && `max-width: ${props.maxWidth}px;`}
    padding: ${props => (props.hasPadding ? '24px' : 0)};
`;

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const TimeAndPinned = styled.div`
    font-family: Roboto, sans-serif;
    color: rgba(0, 0, 0, 0.6);
    margin: 8px 0 12px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
`;

export const Content = styled.div`
    ${props =>
        props.isPreview &&
        `
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    `}

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
    }

    & > * {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);

        a {
            text-decoration: none;
            color: ${props => props.color} !important;
        }
    }
`;

export const PinIcon = styled(FontIcon)`
    font-size: 16px !important;
    color: #323232 !important;
    margin-right: 4px;
`;

export const Bullet = styled.div`
    display: inline-block;
    gap: 8px;
    width: 3px;
    height: 3px;
    margin: 12px 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.54);
`;

export const clearHTML = (htmlText, isProcessed) => {
    const htmlTextWithTargetBlank =
        htmlText && htmlText.split('<a href="').join('<a target="_blank" href="');
    return isProcessed ? htmlText : htmlTextWithTargetBlank;
};

export const FeedCard = ({
    children,
    banner,
    item,
    hasShowMore,
    onSendAnalytics,
    onClick,
    ariaLabel,
}) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        if (isVisible && item && !hasShowMore && onSendAnalytics) {
            onSendAnalytics(item._id);
        }
    }, [isVisible]);

    return (
        <FeedCardContainer banner={banner} innerRef={ref} onClick={onClick} aria-label={ariaLabel}>
            {children}
        </FeedCardContainer>
    );
};

const FeedPage = props => {
    const [eventId, setEventId] = useState('');
    const [banner, setBanner] = useState(null);

    const isNewsFeedPage = window.location.href.toLowerCase().includes('/feed');

    useEffect(() => {
        props.setTitle();
        getLocalAppState(async (err, appState) => {
            if (err) {
                console.log(err);
            } else {
                setEventId(appState.eventId);
                setBanner(appState.banner);
            }
        });
    }, [eventId]);

    const renderBanner = () => (
        <FeedCard banner={true}>
            <Slideshow data={banner.items} noIndicators={true} itemDuration={banner.duration} />
        </FeedCard>
    );

    return (
        <Wrapper maxWidth={isNewsFeedPage && '500'} hasPadding={isNewsFeedPage} id="feed-wrapper">
            {banner && banner.items && banner.items.length > 0 && renderBanner()}
            <NewsFeedWidget />
        </Wrapper>
    );
};

export default withRouter(FeedPage);
