import styled from 'styled-components';
import { Avatar, Button, FontIcon, SelectionControl } from 'react-md';
import * as palette from '../../../components/General/Variables.js';
import TextField from 'react-md/lib/TextFields';
import Cropper from 'react-cropper';
import { SectionDescription } from '../../DetailPage/InfoBar';

export const SelectionControlStyled = styled(SelectionControl)`
    margin-top: ${props => !props.noMarginTop && '12px'};
    align-self: flex-start;

    .md-btn--icon {
        margin-left: -10px;
    }

    i {
        color: ${props => props.primarycolor} !important;
    }
`;

export const StyledForm = styled.form`
    min-height: ${props => (props.minHeight ? props.horizontalMargin : '450px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${palette.COLOR_TEXT} !important;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }

    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }

    .md-text-field-message,
    .md-floating-label,
    input::placeholder,
    textarea::placeholder {
        font-size: 12px !important;
    }
`;

export const SmallLink = styled.a`
    color: ${props => props.linkcolor};
    font-size: 15px;
`;

export const SmallLinkText = styled.div`
    color: ${palette.COLOR_TEXT};
    font-size: 15px;
`;

export const Container = styled.div`
    position: relative;
`;

export const CloseIcon = styled(FontIcon)`
    color: ${palette.COLOR_WHITE} !important;
    vertical-align: top;
    font-size: 20px;
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    text-shadow: 3px 3px 16px #272634;
    z-index: 99;
`;

export const LoginCloseContainer = styled.div`
    display: list-item;
    color: transparent;
    margin-bottom: 16px;
`;

export const CloseCardIcon = styled(FontIcon)`
    font-size: 24px !important;
`;

export const CloseButton = styled.button`
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 20px;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    &:hover {
        background-image: ${palette.HOVER_OVERLAY};
    }
`;

export const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div`
    position: absolute;
    top: ${props =>
        props.isModal
            ? 'inherit'
            : props.customTop
            ? props.customTop
            : `${props.society ? '50%' : '20vh'}`};
    transform: ${props => (props.society ? 'translateY(-50%)' : 'none')};
    left: ${props => (props.isModal ? 'inherit' : '0')};
    right: 0;
    margin: 0 auto;
    width: ${props => props.customWidth || '448px'};
    height: ${props =>
        !props.customMinHeight &&
        (props.isModal && props.intro
            ? '100%'
            : props.customHeight
            ? props.customHeight
            : props.largeCard
            ? '542px'
            : '396px')};
    max-height: ${props => !props.isModal && props.intro && '660px'};
    min-height: ${props => props.customMinHeight};
    padding: ${props => props.customPadding || '24px'};
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.alignLeft ? 'start' : 'center')};
    justify-content: space-between;
    letter-spacing: normal;
    text-align: ${props => (props.alignLeft ? 'left' : 'center')};
    color: rgba(0, 0, 0, 0.87);
    font-stretch: normal;
    font-style: normal;
    box-sizing: border-box;
    ${props => props.overflow && `overflow: ${props.overflow}`}
    ${props => props.maxHeight && `max-height: ${props.maxHeight}`};
    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        width: 336px;
        height: ${props =>
            props.customHeight ? props.customHeight : props.largeCard ? '542px' : '384px'};
        left: 0;
        right: 0;
        ${props =>
            props.largeCardWithCustomHeight &&
            `
          height: 100%;
          bottom: 0;
        `}
        ${props =>
            props.intro &&
            `
         height: 542px;
          `}
    }
    @media only screen and (max-height: ${palette.MAX_PHONE}) {
        height: 100%;
        ${props => !props.society && 'top: 0;'}
    }
    @media only screen and (max-width: ${palette.MAX_PHONE_XS}) {
        height: ${props =>
            props.customHeight ? props.customHeight : props.largeCard ? '542px' : '348px'};
        margin: 0 auto;
        left: 16px;
        right: 16px;
        top: unset;
        bottom: 16px;
        ${props =>
            props.largeCardWithCustomHeight &&
            `
            height: 100%;
            bottom: 0;
          `}
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, 0.38) !important;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
    }
`;

export const CardImage = styled.img`
    width: 250px;
    height: 284px;
    margin-bottom: 20px;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: start;
    padding-bottom: 16px;
`;

export const ContentHtml = styled.div`
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: ${props =>
        props.customFontSize
            ? props.customFontSize
            : props.sub
            ? '17px'
            : props.intro
            ? '30px'
            : '24px'};
    font-weight: bold;
    line-height: ${props => (props.intro ? '1.2' : '1.17')};
    margin-top: ${props => (props.sub ? '24px' : '0px')};
    margin-bottom: ${props => (props.sub ? '10px' : '0px')};
    color: rgba(0, 0, 0, 0.87);
    ${props => props.largeCard && 'padding: 0 40px'};
    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        font-size: ${props => (props.sub ? '17px' : '24px')};
        line-height: 1.17;
    }
    @media only screen and (max-width: ${palette.MAX_PHONE_XS}) {
        padding: 0;
    }
`;

export const SignInBanner = styled.img`
    width: 100%;
    border-radius: 6px;
    height: ${props => (props.society ? '100px' : '190px')};
    width: ${props => (props.society ? '100px' : '100%')};
    margin-top: ${props => (props.society ? '40px' : '0px')};
    margin-bottom: ${props => (props.society ? '40px' : '15px')};
`;

export const CompanyText = styled.div`
    fontfamily: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 4px;
`;

export const Subtitle = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: ${props => (props.intro ? 500 : 'normal')};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.6;
    margin: ${props =>
        props.intro
            ? '22px 0'
            : props.customMarginTop
            ? `${props.customMarginTop} 0 24px 0`
            : '16px 0 24px 0'};
    color: rgba(0, 0, 0, 0.87);
    ${props => props.attendingOnly && 'width: 252px'};
    ${props => props.largeCard && 'padding: 0 40px'};
    @media only screen and (max-width: ${palette.MAX_PHONE_XS}) {
        padding: 0;
    }
`;

export const PoliciesText = styled.div`
    font-family: Roboto;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    cursor: default;
`;

export const AnchorText = styled.a`
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    font-weight: 500;

    &:hover {
        color: ${props => props.color};
    }
`;

export const SubtitleAccessRestriction = styled(Subtitle)`
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 24px;
`;

export const DetailsContainer = styled(SectionDescription)`
  text-align: start !important;
  overflow: ${props => (props.lines === 'unset' ? 'unset' : 'hidden')};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lines};
  -webkit-box-orient: vertical;
  margin: 0 !important;

  p:last-of-type {
    margin-bottom: 0 !important;
  }

  a {
    text-decoration: unset;
    color: ${props => props.primary} &: hover {
      text-decoration: underline;
    }
  }
`;

export const HelperText = styled.div`
    fontfamily: 'Roboto', sans-serif;
    font-size: 13px;
    margin-top: 24px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.25);
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .md-text--theme-primary {
        background-color: ${palette.COLOR_WHITE} !important;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-content: center;
        justify-content: center;

        &:first-of-type {
            font-family: ${props => (props.firstStep ? 'Cabin' : 'Roboto')}, sans-serif;
            color: ${palette.COLOR_WHITE} !important;
            background-color: ${props => props.primarycolor} !important;
            display: flex;
            align-content: center;
            justify-content: center;
            ${props => !props.firstStep && 'line-height: 1.7'};
        }
    }
`;

export const NoButton = styled.button`
    display: none;
`;

export const ButtonStyled = styled(Button)`
    border: solid 1px #d1d4d9 !important;
    border-radius: 6px !important;
    width: 100%;
    text-transform: none !important;
    height: 40px !important;
    margin-top: 12px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: ${palette.COLOR_WHITE} !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    display: flex !important;
    align-content: center !important;
    justify-content: center !important;

    svg {
        margin-right: 8px;
    }
`;

export const ThemeButton = styled(ButtonStyled)`
    color: ${props => props.maincolor} !important;
    height: 40px !important;
    border: unset !important;
    background: transparent !important;
`;

export const StyledTextField = styled(TextField)`
    margin-bottom: 8px;
    height: 56px;
    background-color: #ebedf0;
    border-radius: 6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding: 12px;
    font-family: 'Roboto', sans-serif;

    input {
        background: inherit;
        font-size: 15px;
        line-height: 1.07;
        color: #000000;
    }

    hr {
        display: none;
    }

    label {
        margin-top: -18px;
        color: rgba(0, 0, 0, 0.38);
        line-height: 1.07;
        font-size: 15px;
    }

    .md-text-field--inline-indicator {
        width: calc(100% - -4px) !important;
        padding-right: 37px !important;
    }

    .md-floating-label--floating {
        transform: translate3d(0, 30px, 0);
    }

    .md-text-field--floating-margin {
        margin-top: 16px;
    }

    .md-password-btn {
        top: 18px !important;
        right: 16px !important;
        color: rgba(0, 0, 0, 0.6) !important;

        i {
            font-size: 24px !important;
        }
    }

    ${props =>
        props.error
            ? props.errorBorderBottom
                ? 'border-bottom: solid 2px #d50000'
                : 'border: solid 2px #d50000'
            : ''};

    &:visited,
    &:active,
    &:visited,
    &:focus,
    &:focus-within {
        ${props =>
            props.errorBorderBottom && props.primary
                ? `border-bottom: solid 2px ${props.primary}`
                : `border: solid 2px rgba(0, 0, 0, 0.87);
                background: #fff;`};
    }

    .md-text-field-message-container {
        font-size: 11px;
        line-height: 1.23;
        text-align: left;
        margin-top: 12px;
        color: #d50000;
    }

    .md-text-field:-webkit-autofill {
        box-shadow: unset;
    }
`;

export const ErrorMessage = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    line-height: 1.23;
    text-align: left;
    margin-top: 12px;
    color: #d50000;
    width: 100%;
    padding-left: 12px;
`;

export const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    div:first-child {
        margin-right: 12px;
    }
`;

export const RegularLink = styled.span`
    color: ${props => props.linkcolor};
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
`;

export const PolicyLink = styled.a`
    margin-top: 16px;
    margin-left: ${props => (props.align ? '40px' : '0')};
    color: ${props => props.linkcolor};
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
`;

export const StyledError = styled.p`
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: red;
    text-align: center;
`;

export const PhotoImageBackground = styled.div`
    border-radius: 50%;
    height: 104px;
    width: 104px;
    background: #ebedf0;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='file'] {
        display: none;
    }
`;

export const AddImage = styled(FontIcon)`
    font-size: 30px;
    display: flex;
    color: #fff !important;
    background-color: ${props => props.primarycolor};
    border-radius: 50%;
    border: 10px solid ${props => props.primarycolor};
    cursor: pointer;
`;

export const ProfileImageWrapper = styled.div`
    width: 120px;
    height: 120px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .md-avatar-content {
        font-size: 50px;
    }
`;

export const ImageCropper = styled(Cropper)`
    .cropper-modal {
        background-color: #fff;
        opacity: 0.25;
    }

    .cropper-crop-box {
        border: 2px solid #fff;
        border-radius: 8px;
    }

    .cropper-view-box {
        outline: 1px solid transparent;
        outline-color: transparent;
    }

    .cropper-point {
        background-color: transparent;
    }
`;

export const Input = styled.input`
    overflow: hidden;
    display: block;
    appearance: none;
    max-width: 256px;
    width: 100%;
    margin: 0;
    height: 20px;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
        height: 4px;
        -webkit-appearance: none;
        width: 256px;
        background: #ebedf0;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        background: ${props => props.primarycolor};
        border-radius: 50%;
        margin-top: -8px;
    }

    &:focus {
        outline: none;
    }
`;

export const EurekaAvatar = styled(Avatar)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bfbfbf;
    border: 4px solid #fff !important;
    width: 108px !important;
    height: 108px !important;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    & .md-avatar {
        border: none;
    }
`;

export const AvatarContainer = styled.div`
    margin-top: -150px;
`;

export const TextInputContainer = styled.div`
    width: 100%;
`;

export const OAuthPasswordImage = styled.img`
    color: ${props => props.primary};
    background-color: ${props => props.organizationColor};
    padding-top: 0;
    width: 150px;
    max-width: 150px;
    height: 150px;
`;
