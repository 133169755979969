import React, { useState } from 'react';
import { Button } from 'react-md';
import styled from 'styled-components';
import ProgressBar from '../../../common/ProgressBar';
import TextInput from '../../../common/TextInput';
import { useVMState } from '../../../../containers/main';
import getVideoDuration from '../../../../utils/getVideoDuration';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import { CancelModalButton, ContentModalContainer, SaveModalButton } from '../../common/styles';
import { uploadVideoToS3 as uploadVideoToS3Service } from '../../../../services/VirtualEventSession';
import { useTheme } from '../../../../../../../../components/Theme/ThemeContext';
import { getBlackOrWhiteCalculated } from '../../../../../../../../components/General/Colors';

const StyledFillButton = styled(Button)`
    width: 99px !important;
    min-width: 99px !important;
    height: 40px !important;
    border-radius: 6px !important;
    background-color: ${props => props.backgroundColor || '#00a480'} !important;
    color: ${props => props.textColor || '#fff'} !important;
    position: relative;
    padding: 8px 16px;
    margin-right: 16px;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 24px;

    &:disabled,
    &[disabled] {
        color: rgba(0, 0, 0, 0.38) !important;
        background-color: #b3b3b3 !important;
    }
`;

const LabelsWrapper = styled.div`
    display: flex;
    margin-top: 24px;
`;

const Label = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const SubLabel = styled.span`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
`;

const FileInput = styled.input`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 85px;
    height: 36px;
    width: 85px;
    cursor: pointer;
`;

const VideoInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 260px;
`;

const VideoSize = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
`;

const ErrorMessage = styled.div`
    color: rgb(221, 44, 0);
    font-size: 14px;
    padding: 16px 0;
`;

const ExtraStyledContentModalContainer = styled(ContentModalContainer)`
    flex-direction: column;
    align-items: flex-start;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
`;

const SlidesName = styled.span`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    ${props => props.fixedWidth && 'width: 261px'};
`;

const OptionalText = styled(SlidesName)`
    font-style: italic;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.32);
`;

const videoType = {
    SLIDES: 'share',
    SPEAKER: 'video',
};

const uploadStatuses = {
    INITIAL: 'initial',
    UPLOADING: 'uploading',
    DONE: 'done',
};

const MAXIMUM_ALLOWED_SIZE_BYTES = 1048576000; // 1GB
const MAXIMUM_CHARACTERS_IN_NAME = 100;

const UploadVideoModal = ({ onClose, onAdd, fileName }) => {
    const stateCtx = useVMState();
    const { sessionId } = stateCtx;
    const { theme } = useTheme();
    const contrastBlackOrWhite = getBlackOrWhiteCalculated(theme.primary);

    const [slidesVideoFile, setSlidesVideoFile] = useState(null);
    const [speakerVideoFile, setSpeakerVideoFile] = useState(null);
    const [slidesVideoUrl, setSlidesVideoUrl] = useState(null);
    const [shareVideoName, setShareVideoName] = useState(null);
    const [speakerVideoUrl, setSpeakerVideoUrl] = useState(null);
    const [speakerVideoName, setSpeakerVideoName] = useState(null);
    const [recordingName, setRecordingName] = useState('');
    const [loading, setLoading] = useState(false);
    const [slidesVideoStatus, setSlidesVideoStatus] = useState(uploadStatuses.INITIAL);
    const [speakerVideoStatus, setSpeakerVideoStatus] = useState(uploadStatuses.INITIAL);
    const [duration, setDuration] = useState('00:00');
    const [showSizeWarning, setShowSizeWarning] = useState(false);
    const [slidesUploadedPercentage, setSlidesUploadedPercentage] = useState(0);
    const [speakerUploadedPercentage, setSpeakerUploadedPercentage] = useState(0);

    const onVideoChange = async (event, type) => {
        const { files } = event.target;
        const file = files && files.length && files[0];

        setShowSizeWarning(false);
        if (!file) {
            return;
        }
        if (file.size > MAXIMUM_ALLOWED_SIZE_BYTES) {
            setShowSizeWarning(true);
            return;
        }

        if (type === videoType.SLIDES) {
            setSlidesVideoFile(file);

            if (!recordingName) {
                const nameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                setRecordingName(nameWithoutExtension.slice(0, MAXIMUM_CHARACTERS_IN_NAME));
            }

            setSlidesVideoStatus(uploadStatuses.UPLOADING);

            const response = await uploadVideoToS3(file, type);
            const splitKey = response.key.split('/');
            // this will be ${Date.now()}.mp4
            const actualFileName = splitKey[splitKey.length - 1];

            setShareVideoName(actualFileName);
            setSlidesVideoStatus(uploadStatuses.DONE);
            setSlidesVideoUrl(response.link);

            const durationTime = await getVideoDuration(response.link);
            const measuredDuration = convertDurationTimeToString(durationTime);
            setDuration(measuredDuration);
        } else {
            setSpeakerVideoFile(file);
            setSpeakerVideoStatus(uploadStatuses.UPLOADING);

            const response = await uploadVideoToS3(file, type);
            const splitKey = response.key.split('/');
            // this will be ${Date.now()}.mp4
            const actualFileName = splitKey[splitKey.length - 1];

            setSpeakerVideoName(actualFileName);
            setSpeakerVideoStatus(uploadStatuses.DONE);
            setSpeakerVideoUrl(response.link);
        }
    };

    const onTextChange = event => {
        const { value } = event.target;

        if (value.length <= MAXIMUM_CHARACTERS_IN_NAME) {
            setRecordingName(value);
        }
    };

    const uploadVideoToS3 = async (file, type) => {
        const onUploadProgress = value => {
            if (type === videoType.SLIDES) {
                setSlidesUploadedPercentage(value);
            } else {
                setSpeakerUploadedPercentage(value);
            }
        };

        const response = await uploadVideoToS3Service(file, sessionId, type, onUploadProgress);

        return response;
    };

    const convertDurationTimeToString = duration => {
        if (!duration) {
            return '00:00:00';
        }

        const measuredTime = new Date(null);

        measuredTime.setSeconds(duration);

        return measuredTime.toISOString().substr(11, 8);
    };

    const onSave = async () => {
        try {
            setLoading(true);

            await onAdd({
                shareVideoName,
                speakerVideoName,
                shareUrl: slidesVideoUrl,
                videoUrl: speakerVideoUrl,
                name: fileName || recordingName,
                duration,
            });

            setLoading(false);
            onClose();
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const isSaveButtonDisabled =
        !slidesVideoFile ||
        !recordingName ||
        loading ||
        slidesVideoStatus === uploadStatuses.UPLOADING ||
        speakerVideoStatus === uploadStatuses.UPLOADING;
    const open = true;

    return (
        <div className="eureka-react">
            <ConfirmationDialog
                open={open}
                title="Upload video"
                titleIcon="upload"
                iconColor="rgba(0, 0, 0, 0.87)"
                iconWrapperColor="#EFEFEF"
                withCloseButton
                onClose={onClose}
                content={
                    <ExtraStyledContentModalContainer>
                        <Label>Slides frame</Label>
                        <SubLabel>mp4 file up to 1GB</SubLabel>
                        <FlexRow>
                            <StyledFillButton
                                backgroundColor={theme.primary}
                                textColor={contrastBlackOrWhite}
                            >
                                Select file
                                <FileInput
                                    type="file"
                                    accept="video/mp4"
                                    onChange={e => onVideoChange(e, videoType.SLIDES)}
                                    disabled={slidesVideoStatus === uploadStatuses.UPLOADING}
                                />
                            </StyledFillButton>
                            {slidesVideoFile ? (
                                <>
                                    <VideoInfoWrapper>
                                        <SlidesName fixedWidth>{slidesVideoFile.name}</SlidesName>
                                        <ProgressBar value={slidesUploadedPercentage} />
                                    </VideoInfoWrapper>
                                </>
                            ) : (
                                <SlidesName>No file selected</SlidesName>
                            )}
                        </FlexRow>
                        <LabelsWrapper>
                            <Label>Speaker frame</Label>
                        </LabelsWrapper>
                        <SubLabel>mp4 file up to 1GB</SubLabel>
                        <FlexRow>
                            <StyledFillButton
                                backgroundColor={theme.primary}
                                textColor={contrastBlackOrWhite}
                            >
                                Select file
                                <FileInput
                                    type="file"
                                    accept="video/mp4"
                                    onChange={e => onVideoChange(e, videoType.SPEAKER)}
                                    disabled={speakerVideoStatus === uploadStatuses.UPLOADING}
                                />
                            </StyledFillButton>
                            {speakerVideoFile ? (
                                <>
                                    <VideoInfoWrapper>
                                        <SlidesName fixedWidth>{speakerVideoFile.name}</SlidesName>
                                        <ProgressBar value={speakerUploadedPercentage} />
                                    </VideoInfoWrapper>
                                </>
                            ) : (
                                <>
                                    <SlidesName>No file selected</SlidesName>
                                    <OptionalText>(optional)</OptionalText>
                                </>
                            )}
                        </FlexRow>
                        <LabelsWrapper>
                            <Label>Name</Label>
                        </LabelsWrapper>
                        <TextInput
                            value={fileName || recordingName}
                            onChange={fileName ? null : onTextChange}
                        />

                        {showSizeWarning && (
                            <ErrorMessage>The file can&apos;t be larger than 500 MB.</ErrorMessage>
                        )}
                    </ExtraStyledContentModalContainer>
                }
                buttons={[
                    <SaveModalButton
                        key="SaveModalButton"
                        flat
                        onClick={onSave}
                        disabled={isSaveButtonDisabled}
                        backgroundcolor={theme.primary}
                        textColor={contrastBlackOrWhite}
                    >
                        Save
                    </SaveModalButton>,
                    <CancelModalButton key="CancelModalButton" flat onClick={onClose}>
                        Cancel
                    </CancelModalButton>,
                ]}
            />
        </div>
    );
};

export default UploadVideoModal;
