import * as StyledComponent from '../../style/modalsStyle';
import { FormWrapper } from '../../style/modalsStyle';
import { withRouter } from 'react-router-dom';
import { getString } from '../../../../services/api/store';
import React, { useContext, useState } from 'react';
import { CHANGE_PASSWORD_TITLE } from '../../constants';
import * as palette from '../../../../components/General/Variables';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import { Flex } from '../../../common/Flex';
import { StyledForm } from '../../style';
import * as string from '../../../SignInPage/constants/strings';
import Button, { buttonTypes } from '../../../common/Button';
import { updatePassword } from '../../../UserProfile/service';
import AuthUserContext from '../../../../components/Session/AuthUserContext';

const ChangePasswordModal = ({ theme, onClose, match, history, location, onGoBack }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const TEXT_FORGOT_PASSWORD =
        getString('profileForgotPasswordDialogTitle') || string.FORGOT_PASSWORD_TITLE;
    const createAccountPasswordRulesUppercase =
        getString('createAccountPasswordRulesUppercase') || string.TEXT_PASSWORD_UPPERCASE;
    const createAccountPasswordRulesLength =
        getString('createAccountPasswordRulesLength') || string.TEXT_PASSWORD_LENGTH;
    const createAccountPasswordRulesLowercase =
        getString('createAccountPasswordRulesLowercase') || string.TEXT_PASSWORD_LOWERCASE;
    const createAccountPasswordRulesNumber =
        getString('createAccountPasswordRulesNumber') || string.TEXT_PASSWORD_NUMBER;
    const { showSignIn } = useContext(AuthUserContext);

    const onSave = async () => {
        if (!passwordErrorOnUserInput) {
            try {
                await updatePassword({ oldPassword: currentPassword, newPassword });
                onClose();
            } catch (e) {
                if (e && e.message === 'Forbidden') {
                    setWrongCurrentPassword(true);
                }
            }
        }
    };
    const TEXT_ERROR_WRONG_PASSWORD =
        getString('passwordModalError') || string.ERROR_MESSAGE_WRONG_PASSWORD;
    let passwordErrorOnUserInput = '';
    if (!string.digitExists(newPassword)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesNumber}<br/>`;
    }
    if (!string.lowercaseExists(newPassword)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesLowercase}<br/>`;
    }
    if (!string.uppercaseExists(newPassword)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesUppercase}<br/>`;
    }
    if (newPassword.length < 8) {
        passwordErrorOnUserInput += createAccountPasswordRulesLength;
    }

    return (
        <ComplexDialog
            title={
                <Flex>
                    <StyledComponent.BackButton
                        style={{
                            color: palette.COLOR_TEXT,
                            marginTop: -10,
                        }}
                        icon
                        onClick={onGoBack}
                        aria-label={'Go back'}
                        aria-hidden={true}
                        role={'presentation'}
                    >
                        arrow_back
                    </StyledComponent.BackButton>
                    <StyledComponent.ModalTitle>{CHANGE_PASSWORD_TITLE}</StyledComponent.ModalTitle>
                </Flex>
            }
            visible={true}
            contentStyle={{ padding: 0 }}
            onClose={null}
            displayBoxShadow
            iconName={'arrow-back'}
            options={[
                {
                    title: getString('saveButton') || 'Save',
                    variant: 'text',
                    type: 'save',
                    onClick: onSave,
                },
            ]}
        >
            <StyledForm
                maincolor={theme.primary}
                horizontalMargin={isDesktop && '0px'}
                minHeight="100px"
            >
                <FormWrapper>
                    <StyledComponent.StyledTextField
                        id="current-password"
                        required
                        name="currentPassword"
                        error={currentPassword === '' || wrongCurrentPassword}
                        value={currentPassword}
                        onChange={value => setCurrentPassword(value)}
                        label={'Current password'}
                        type="password"
                        errorText={
                            wrongCurrentPassword
                                ? currentPassword === newPassword
                                    ? 'The new password should be different than the old one'
                                    : TEXT_ERROR_WRONG_PASSWORD
                                : 'Password cannot be empty'
                        }
                        customLabelFontSize={'15px'}
                        errorBorderBottom={true}
                        primary={theme.primary}
                    />
                    <Button
                        type={buttonTypes.GHOST_LONG}
                        background={theme.primary}
                        text={TEXT_FORGOT_PASSWORD}
                        onClick={e => {
                            onClose();
                            showSignIn(e, {
                                match,
                                history,
                                location,
                                signInCurrentPage: 'forgotPassword',
                            });
                        }}
                        data-qa="button-forgot-password-profile-modal"
                        style={{ width: 'fit-content' }}
                    />
                    <StyledComponent.StyledTextField
                        id="new-password"
                        name="newPassword"
                        label={<div>New password</div>}
                        type="password"
                        value={newPassword}
                        error={newPassword === ''}
                        required
                        onChange={value => setNewPassword(value)}
                        marginTop={'0px'}
                        customLabelFontSize={'15px'}
                        errorText={newPassword === '' && 'Password cannot be empty'}
                        errorBorderBottom={true}
                        primary={theme.primary}
                    />
                    <StyledComponent.PasswordRulesList>
                        <StyledComponent.PasswordRulesListElement
                            fillError={passwordErrorOnUserInput?.includes(
                                createAccountPasswordRulesLength,
                            )}
                        >
                            {createAccountPasswordRulesLength}
                        </StyledComponent.PasswordRulesListElement>
                        <StyledComponent.PasswordRulesListElement
                            fillError={passwordErrorOnUserInput?.includes(
                                createAccountPasswordRulesLowercase,
                            )}
                        >
                            {createAccountPasswordRulesLowercase}
                        </StyledComponent.PasswordRulesListElement>
                        <StyledComponent.PasswordRulesListElement
                            fillError={passwordErrorOnUserInput?.includes(
                                createAccountPasswordRulesUppercase,
                            )}
                        >
                            {createAccountPasswordRulesUppercase}
                        </StyledComponent.PasswordRulesListElement>
                        <StyledComponent.PasswordRulesListElement
                            fillError={passwordErrorOnUserInput?.includes(
                                createAccountPasswordRulesNumber,
                            )}
                        >
                            {createAccountPasswordRulesNumber}
                        </StyledComponent.PasswordRulesListElement>
                    </StyledComponent.PasswordRulesList>
                </FormWrapper>
            </StyledForm>
        </ComplexDialog>
    );
};

export default withRouter(ChangePasswordModal);
