import React, { useEffect, useState, useMemo } from 'react';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { getImageSource } from '../../services/api/db';
const MUI_ICONS = ['twitter', 'instagram', 'linked_in'];

const MUIIcon = ({ icon, size = 24 }) => {
    let IconComponent = null;
    switch (icon) {
        case 'twitter':
            IconComponent = XIcon;
            break;
        case 'instagram':
            IconComponent = InstagramIcon;
            break;
        case 'linked_in':
            IconComponent = LinkedInIcon;
            break;
    }
    return IconComponent ? <IconComponent sx={{ fontSize: size }} /> : null;
};

const DEFAULT_SIZE = '24px';

const mapActiveStatesIcons = {
    event_outlined: 'event',
    event_note_outlined: 'event_note',
    signpost_outlined: 'signpost',
    forum_outlined: 'forum',
    folder_shared_outlined: 'folder_shared',
    person_outline: 'person',
    person_outlined: 'person',
    picture_in_picture_outlined: 'picture_in_picture',
    account_box_outlined: 'account_box',
    favorite_border: 'favorite',
    tv_outlined: 'tv',
    cloud_download_outlined: 'cloud_download',
    home_outlined: 'home',
    check_box_outlined: 'check_box',
    format_list_bulleted: 'format_list_bulleted',
    chat_bubble_outline: 'chat_bubble_outline',
    dns_outlined: 'dns',
    filter_none_outlined: 'filter_none',
    public_outlined: 'public',
    map_outlined: 'map',
    smartphone: 'smartphone',
    newspaper: 'newspaper',
    groups_outlined: 'groups',
    photo_camera_outlined: 'photo_camera',
    star_border: 'star_border',
    view_agenda_outlined: 'view_agenda',
    volume_up_outlined: 'volume_up',
    mic_none: 'mic_none',
    domain: 'domain',
    insert_drive_file_outlined: 'insert_drive_file',
    search: 'search',
    location_on: 'location_on',
    room_outlined: 'room',
    more_horiz: 'more_horiz',
    reorder: 'reorder',
    article_outlined: 'article',
    new_releases_outlined: 'new_releases',
    info_outlined: 'info',
    local_offer_outlined: 'local_offer',
    description_outlined: 'description',
    file_copy_outlined: 'file_copy',
    book_outlined: 'book',
    slideshow_outlined: 'slideshow',
    assignment_outlined: 'assignment',
    email_outlined: 'email',
};

const StudioIcon = ({ icon, color, size, active }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setLoading] = useState(false);

    const imageId = icon?.type === 'custom' && icon?.imageId;

    const iconName = useMemo(() => {
        const iconToDisplay = icon?.type === 'material' && icon?.materialName;
        let iconLoaded = '';
        if (iconToDisplay) {
            const activeState = mapActiveStatesIcons[iconToDisplay] || iconToDisplay;
            iconLoaded = active ? activeState : iconToDisplay;
        }
        return iconLoaded;
    }, [active, icon]);

    useEffect(() => {
        if (imageId) {
            setLoading(true);
            getImageSource(imageId, (err, result) => {
                if (result) {
                    setImageUrl(result);
                }
            });
        } else {
            setImageUrl('');
        }
        setLoading(false);
    }, [imageId]);

    const appliedSize = size || DEFAULT_SIZE;

    return (
        <>
            {imageUrl && (
                <Avatar
                    alt={imageId}
                    src={isLoading ? '' : imageUrl}
                    sx={{
                        width: appliedSize,
                        height: appliedSize,
                        backgroundColor: isLoading ? '#d9d9d9' : 'transparent',
                        borderRadius: 0,
                    }}
                >
                    {isLoading && (
                        <Stack sx={{ padding: '4px' }} spacing={0} direction="row">
                            <CircularProgress size={appliedSize - 10} sx={{ color: '#f5f5f5' }} />
                        </Stack>
                    )}
                    {!isLoading && (
                        <div
                            style={{ width: appliedSize, height: appliedSize, color: '#d9d9d9' }}
                        />
                    )}
                </Avatar>
            )}
            {iconName && (
                <Icon
                    baseClassName={
                        iconName.includes('outline') ? 'material-icons-outlined' : 'material-icons'
                    }
                    style={{
                        fontSize: size || 24,
                        color: color || 'rgba(0, 0, 0, 0.87)',
                    }}
                >
                    {MUI_ICONS.includes(iconName) ? (
                        <MUIIcon size={size} icon={iconName} />
                    ) : (
                        iconName
                    )}
                </Icon>
            )}
        </>
    );
};

export default StudioIcon;
