import React from 'react';
import { Avatar, FontIcon, ListItem } from 'react-md';
import { NavLink, Route, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import * as palette from '../General/Variables';
import { useTheme } from '../Theme/ThemeContext';
import { getWebappIconUsingClass } from './utils/utils';
import { Tooltip } from '../../scenes/common/Button';
import StudioIcon from '../Icons/StudioIcon';
import { useEventStore } from '../../stores/EventStore';

const StyledLink = styled(NavLink)`
    font-weight: 500;
`;

const ListItemStyled = styled(ListItem)`
    .md-tile-text--primary {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 24px;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.6)')} !important;
    }

    .md-tile-content--left-icon {
        padding-left: 16px;
        margin-top: 5px;
    }

    &:hover {
        color: ${props => props.primary} !important;
    }

    .md-icon {
        height: 20px;
        width: 20px;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.6)')} !important;
    }

    .md-tile-addon--icon {
        height: 20px;
        width: 20px;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.6)')} !important;
    }

    .md-list-tile {
        margin: 0 8px;
        ${props => props.displayOnlyIcons && 'padding-right: 4px !important;'}

        &:hover {
            border-radius: 6px;
            i {
                color: ${props => props.primary} !important;
            }
            .md-tile-text--primary {
                color: ${props => props.primary} !important;
            }
            background: ${props => props.primary + '20'} !important;
        }
        &:active {
            background: ${props => props.primary + '20'} !important;
        }
    }

    .md-avatar {
        border: unset !important;
        background-color: transparent;
    }
`;

const PageAvatar = styled(Avatar)`
    .md-avatar {
        border: unset !important;
        background-color: transparent;
    }
`;

const Icon = styled(FontIcon)`
    font-size: 24px !important;
    ${props => props.color && `color: ${props.color}`}
`;

const NavItemLink = ({ isSociety, item, visibleFullSideMenu, index }) => {
    const { theme } = useTheme();
    const { url } = useRouteMatch();
    const { pathname } = useLocation();
    const [hover, setHover] = React.useState('');
    const setTitle = useEventStore(state => state.setTitle);

    if (item.hidden) {
        return '';
    }

    const iconWebbapp =
        item.materialIcon &&
        (item.materialIcon.webappIcon || getWebappIconUsingClass(item.materialIcon.class));

    const path = item.to || `/${item.name}`;
    const to = isSociety ? path : `${url}${path}`;

    const getPos = name => {
        var e = window.event;

        var posY = e.clientY;
        var context = document.getElementById('custom-tooltip');
        var item = document.getElementById(`sidemenu-item-${index}`);
        if (context) {
            var dv = document.createElement('span');
            dv.innerHTML = name;
            context.style.top = (item?.getBoundingClientRect()?.top || posY) + 15 + 'px';
            context.style.left = '80px';
            context.style.display = 'block';
            context.style.background = 'rgba(0, 0, 0, 0.87)';
            context.innerHTML = name;
            context.style.position = 'fixed';
        }
    };

    const deletePos = () => {
        var context = document.getElementById('custom-tooltip');
        if (context) {
            context.style.top = 'unset';
            context.style.left = 'unset';
            context.style.display = 'block';
            context.style.background = 'transparent';
            context.innerHTML = '';
        }
    };

    return (
        <Route path={to}>
            {({ match }) => {
                const iconColor =
                    isSociety && pathname === path ? theme.primary : 'rgba(0, 0, 0, 0.6)';
                let leftAvatar = iconWebbapp ? (
                    <Icon
                        iconClassName={match ? 'material-icons' : 'material-icons-outlined'}
                        aria-hidden
                    >
                        {iconWebbapp}
                    </Icon>
                ) : item.imageUrl ? (
                    <PageAvatar src={item.imageUrl} iconSized={true} />
                ) : (
                    <Icon
                        iconClassName={match ? 'material-icons' : 'material-icons-outlined'}
                        aria-hidden
                    >
                        description
                    </Icon>
                );

                if (isSociety && item.iconName) {
                    leftAvatar = (
                        <Icon
                            iconClassName={iconColor ? 'material-icons' : 'material-icons-outlined'}
                            color={iconColor}
                            aria-hidden
                        >
                            {item.iconName}
                        </Icon>
                    );
                } else if (item?.icon) {
                    leftAvatar = (
                        <StudioIcon
                            icon={item.icon}
                            color={
                                match || hover === item?.title
                                    ? theme.primary
                                    : 'rgba(0, 0, 0, 0.6)'
                            }
                            size={24}
                            active={!!match}
                        />
                    );
                }

                if (!visibleFullSideMenu) {
                    return (
                        <>
                            <ListItemStyled
                                id={`sidemenu-item-${index}`}
                                component={StyledLink}
                                active={!!match}
                                to={to}
                                primaryTextStyle={{
                                    fontSize: '14px',
                                    color: palette.COLOR_TEXT,
                                }}
                                primaryText={item.title}
                                data-tooltip={item.name}
                                leftIcon={leftAvatar}
                                onClick={() => setTitle && setTitle(item.title)}
                                primary={theme.primary}
                                displayOnlyIcons={!visibleFullSideMenu}
                                onMouseEnter={() => {
                                    getPos(item.title);
                                }}
                                onMouseLeave={deletePos}
                            >
                                <Tooltip
                                    id="custom-tooltip"
                                    bottom={'unset'}
                                    background={'transparent'}
                                />
                            </ListItemStyled>
                        </>
                    );
                }
                return (
                    <ListItemStyled
                        component={StyledLink}
                        active={!!match}
                        to={to}
                        role={'link'}
                        primaryText={item.title}
                        primaryTextStyle={{
                            fontSize: '14px',
                            color: palette.COLOR_TEXT,
                        }}
                        leftIcon={leftAvatar}
                        primary={theme.primary}
                        onMouseEnter={() => setHover(item.title)}
                        onMouseLeave={() => setHover('')}
                    />
                );
            }}
        </Route>
    );
};

export default NavItemLink;
