import React from 'react';
import {
    AnchorText,
    ButtonsContainer,
    Card,
    CloseButton,
    CloseCardIcon,
    PoliciesText,
    StyledTextField,
    Subtitle,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as string from '../constants/strings';
import { formatStringWithValueSubstring } from '../../../services/api/data';
import Button, { buttonTypes } from '../../common/Button';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';
import { aboutLinks } from '../constants/strings';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useEscapeKeyDown from '../../../hooks/useEscapeKeyDown';

const Password = ({
    password,
    updatePassword,
    isValid,
    onKeyDown,
    signin,
    error,
    email,
    forgotPassword,
    theme,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
    terms,
    privacyPolicy,
}) => {
    const PASSWORD_TITLE = getString('passwordTitle') || string.HIDDEN_PASSWORD_TITLE;
    const PASSWORD_HINT = getString('passwordFieldHint') || string.PASSWORD_HINT;
    const CONTINUE_BUTTON_LABEL =
        getString('generalContinueButton') || string.CONTINUE_BUTTON_LABEL;
    let TEXT_ENTER_PASSWORD_SUBTITLE =
        getString('profileSignInEnterPasswordSubtitle') ||
        `${string.INPUT_PASSWORD_MESSAGE} ${email}`;
    if (TEXT_ENTER_PASSWORD_SUBTITLE.includes('__')) {
        TEXT_ENTER_PASSWORD_SUBTITLE = formatStringWithValueSubstring(
            TEXT_ENTER_PASSWORD_SUBTITLE,
            email,
        );
    }
    useEscapeKeyDown({ handler: onClose });
    const TEXT_ERROR_WRONG_PASSWORD =
        getString('passwordModalError') || string.ERROR_MESSAGE_WRONG_PASSWORD;
    const TEXT_ERROR_DEFAULT =
        getString('loginSignInErrorDialogMessage') || string.ERROR_MESSAGE_DEFAULT;
    const TEXT_ERROR_REQUIRED_PASSWORD =
        getString('signInPasswordMissingMessage') || string.ERROR_MESSAGE_WRONG_EMAIL;
    const TEXT_FORGOT_PASSWORD =
        getString('profileForgotPasswordDialogTitle') || string.FORGOT_PASSWORD_TITLE;

    const TERMS = getString('termsAndConditionsLabelButton') || 'Terms of Use';
    const POLICY = getString('privacyLabelButton') || 'Privacy Policy';

    let errorMessagePassword = '';
    if (error) {
        if (error.type) {
            switch (error.type) {
                case 'password':
                    errorMessagePassword = TEXT_ERROR_WRONG_PASSWORD;
                    break;
                default:
                    errorMessagePassword = TEXT_ERROR_DEFAULT;
                    break;
            }
        } else if (error.message) {
            errorMessagePassword = TEXT_ERROR_DEFAULT;
        }
    }

    return (
        <Card isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{PASSWORD_TITLE}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseButton onClick={onClose} aria-label="Close">
                        <CloseCardIcon aria-hidden={true} role={'presentation'}>
                            {palette.ICON_CLOSE}
                        </CloseCardIcon>
                    </CloseButton>
                )}
            </Flex>
            <Subtitle>{TEXT_ENTER_PASSWORD_SUBTITLE}</Subtitle>
            <StyledTextField
                id="signin-password"
                required
                error={errorMessagePassword.length > 0}
                value={password}
                onChange={pass => {
                    updatePassword(pass);
                }}
                label={PASSWORD_HINT}
                type="password"
                errorText={errorMessagePassword || TEXT_ERROR_REQUIRED_PASSWORD}
                onKeyDown={e => {
                    if (isValid) {
                        onKeyDown(e, signin);
                    }
                }}
                passwordIcon={{
                    invisible: <VisibilityOff aria-label="Hide password" />,
                    visible: <Visibility aria-label="Show password" />,
                }}
            />
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={CONTINUE_BUTTON_LABEL}
                    onClick={signin}
                    data-qa="button-sign-in-with-email"
                />
                <Button
                    type={buttonTypes.GHOST_LONG}
                    background={theme.primary}
                    text={TEXT_FORGOT_PASSWORD}
                    onClick={forgotPassword}
                    data-qa="button-button-forgot-password-in-with-email"
                    style={{ marginTop: '12px' }}
                />
            </ButtonsContainer>
            <PoliciesText style={{ marginTop: 16 }}>
                By signing in, you agree to the{' '}
                <AnchorText
                    href={terms || aboutLinks.termsAndConditions}
                    target="_blank"
                    color={theme.primary}
                >
                    {TERMS}
                </AnchorText>{' '}
                and{' '}
                <AnchorText
                    href={privacyPolicy || aboutLinks.privacyPolicy}
                    target="_blank"
                    color={theme.primary}
                >
                    {' '}
                    {POLICY}
                </AnchorText>{' '}
                and you accept the{' '}
                <AnchorText href={aboutLinks.cookiePolicy} target="_blank" color={theme.primary}>
                    Cookie Policy.
                </AnchorText>
            </PoliciesText>
        </Card>
    );
};

export default Password;
