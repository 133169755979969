import { clearInstallationId, clearUserData, getLocalAppState, getLocalAppStateAsync } from './db';
import * as eureka from './eureka';
import moment from 'moment';
import OneSignalService from '../../scenes/OneSignal/services/oneSignal';
import { EUREKA_URL } from '../../config';
import { Buffer } from 'buffer';

const legacySyncBrands = ['TRB', 'NVVP', 'EAU', 'AANP'];
const legacySyncBrandsCheckin = ['TRB'];

class Auth {
    constructor() {
        this.onAuthStateChanged = () => {};
        this.onPrivacyPolicyUpdate = () => {};
        this.customLogoutRedirect = '';
    }

    static setPolicyFlow(fn, policy) {
        this.onPrivacyPolicyUpdate = fn;
        this.privacyPolicy = policy;
    }

    static subscribe(fn) {
        this.eurekaURL = EUREKA_URL;
        this.googleClientId =
            '77820245080-7qeh37gjrrftjgf8u9q6tpfb2lrfe6gl.apps.googleusercontent.com';
        this.onAuthStateChanged = fn;
        let user = null;
        if (this.isUserAuthenticated()) {
            user = this.getUser();
        }

        fn(user);
    }

    static setEurekaUrl(url) {
        this.eurekaURL = url;
    }

    static handleLoginResponse(appLocal, result, err, next, isAttendeeRestriction) {
        const auth = this;
        if (result && result.token) {
            const user = {
                id: result.id || '',
                email: result.email || '',
                firstName: result.firstName || '',
                lastName: result.lastName || '',
                jobTitle: result.jobTitle || '',
                companyName: result.companyName || '',
                imageUrl: result.imageUrl || '',
                color: result.color || '',
                jid: result.chat && result.chat.jid ? result.chat.jid : '',
                jpassword: result.chat && result.chat.jpassword ? result.chat.jpassword : '',
                acceptedPolicyVersion: result.acceptedPolicyVersion || 1,
                role: result.role,
            };

            getLocalAppState(async (err, config) => {
                if (
                    config &&
                    config.externalEurekaLogin &&
                    config.externalEurekaLogin.length &&
                    config.externalEurekaLogin === 'NVVP'
                ) {
                    let tok = '';
                    if (result && result.accessToken && typeof result.accessToken === 'string') {
                        tok = result.accessToken;
                    } else if (result && result.accessToken && result.accessToken.accessToken) {
                        tok = result.accessToken.accessToken;
                    }
                    user.nvvpToken = tok;
                }

                if (result && result.accessToken && typeof result.accessToken === 'string') {
                    user.accessToken = result.accessToken;
                } else if (result && result.accessToken && result.accessToken.accessToken) {
                    user.accessToken = result.accessToken.accessToken;
                }

                if (result && result.accessToken && result.accessToken.extraToken) {
                    user.accessToken = result.accessToken.extraToken;
                }

                if (isAttendeeRestriction) {
                    const isAttending = await eureka.isUserAttendingByEmail(
                        config.id,
                        result.email,
                    );

                    if (!isAttending) {
                        return next('notAttendingError');
                    }
                }

                if (!result.noValidated || appLocal) {
                    const successAction = () => {
                        this.authenticateUser(user, result.token);

                        if (config.eventId) {
                            //only in event level
                            eureka.sync(() => {
                                const data = {
                                    events: [
                                        {
                                            id: config.eventId,
                                            installationDate: moment().format(),
                                        },
                                    ],
                                };

                                eureka.updateUserEventInstallations(data, () => {
                                    next(null, true);
                                });
                            });
                        } else {
                            next(null, true);
                        }
                    };

                    if (
                        auth.onPrivacyPolicyUpdate &&
                        typeof auth.onPrivacyPolicyUpdate === 'function'
                    ) {
                        this.onPrivacyPolicyUpdate(
                            user,
                            auth.privacyPolicy,
                            result.token,
                            successAction,
                        );
                    } else {
                        successAction();
                    }
                } else {
                    this.storeTempUser(user, result.token);
                    next(null, false);
                }
            });
        } else {
            next(err || { message: 'error' });
        }
    }

    static doSignInWithEmailAndPassword(email, password, next) {
        const data = { email, password };
        eureka.logIn(data, (err, result) => {
            this.handleLoginResponse(false, result, err, next);
        });
    }

    static oAuthPasswordAuthenticate(data, next, isAttendeeRestriction) {
        eureka.oAuthPasswordAuthenticate(data, (err, result) => {
            this.handleLoginResponse(false, result, err, next, isAttendeeRestriction);
        });
    }

    static checkIfEmailExists(email, next) {
        const data = { email };
        eureka.checkEmail(data, (err, result) => {
            if (result) {
                next(null, result);
            } else {
                next(err || { message: 'error' });
            }
        });
    }

    static checkIfEmailExistsAsync(email) {
        return new Promise((resolve, reject) => {
            this.checkIfEmailExists(email, (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            });
        });
    }

    static register(email, firstName, lastName, password, next) {
        const data = {
            firstName,
            lastName,
            email,
            password,
            eurekaPolicySigned: true,
        };
        eureka.register(data, (err, result) => {
            this.handleLoginResponse(false, result, err, next);
        });
    }

    static registerChatUser(jid, jpassword) {
        const localUser = this.getUser();
        const localToken = this.getToken();
        const updatedUser = {
            ...localUser,
            jid,
            jpassword,
        };
        localStorage.setItem('token', localToken);
        localStorage.setItem('user', JSON.stringify(updatedUser));
    }

    static resendConfirmation(next) {
        const temp = this.getTempUser();
        if (temp && temp.id) {
            const data = {
                userId: temp.id,
            };
            eureka.resendConfirmation(data, (err, result) => {
                if (result) {
                    next(null, result);
                } else {
                    next(err || { message: 'error' });
                }
            });
        } else {
            next({ message: 'error' });
        }
    }

    static resetPassword(email, next) {
        const data = { email };
        eureka.resetPassword(data, (err, result) => {
            if (result) {
                next(null, result);
            } else {
                next(err || { message: 'error' });
            }
        });
    }

    static isAccountValidated(next) {
        const data = {
            token: this.getToken(),
        };
        eureka.isAccountValidated(data, (err, result) => {
            if (result && result.validated) {
                this.authenticateTempUser();
                getLocalAppState((err, config) => {
                    if (config && config.eventId) {
                        //only in event level
                        eureka.sync(() => {
                            const data = {
                                events: [
                                    {
                                        id: config.eventId,
                                        installationDate: moment().format(),
                                    },
                                ],
                            };

                            eureka.updateUserEventInstallations(data, () => {
                                next(null, true);
                            });
                        });
                    } else {
                        next(null, true);
                    }
                });
            } else if (result && !result.validated) {
                next({ message: 'Account not confiremd', type: 'account' });
            } else {
                next(err || { message: 'error' });
            }
        });
    }

    static doSignInWithGoogle(data, next) {
        eureka.googleSignIn(data, (err, result) => {
            if (result && result.token) {
                const user = {
                    id: result.id || '',
                    email: result.email || '',
                    firstName: result.firstName || '',
                    lastName: result.lastName || '',
                    jobTitle: result.jobTitle || '',
                    companyName: result.companyName || '',
                    imageUrl: result.imageUrl || '',
                    color: result.color || '',
                };

                this.authenticateUser(user, result.token);
                getLocalAppState((err, config) => {
                    if (config && config.eventId) {
                        //only in event level
                        eureka.sync(() => {
                            const data = {
                                events: [
                                    {
                                        id: config.eventId,
                                        installationDate: moment().format(),
                                    },
                                ],
                            };

                            eureka.updateUserEventInstallations(data, () => {
                                next(null, 'ok');
                            });
                        });
                    } else {
                        next(null, 'ok');
                    }
                });
            } else {
                next(err || { message: 'error' });
            }
        });
    }

    static signOut(next) {
        this.doSignOut(next);
    }

    static cleanTempUser() {
        localStorage.removeItem('tempUser');
        localStorage.removeItem('token');
    }

    static doSignOut(next) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        OneSignalService.removeUserTag();
        OneSignalService.removeMemberTag();
        OneSignalService.removeExternalUserId();
        clearUserData(() => {
            clearInstallationId(() => {
                this.onAuthStateChanged(null);
                next && next();
            });
        });
    }

    static authenticateUser(user, token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        OneSignalService.setExternalUserId(`${user.id}`);
        OneSignalService.addUserTag(user.id, this.eurekaURL);
        OneSignalService.addMemberTag();
        this.onAuthStateChanged(user);
    }

    static changeAuthUserData(user) {
        this.onAuthStateChanged(user);
    }

    static setRole(role) {
        const user = JSON.parse(localStorage.getItem('user'));
        user.role = role;
        localStorage.setItem('user', JSON.stringify(user));
        this.onAuthStateChanged(user);
    }

    static storeTempUser(user, token) {
        localStorage.setItem('token', token);
        localStorage.setItem('tempUser', JSON.stringify(user));
    }

    static isUserAuthenticated() {
        return localStorage.getItem('token') !== null;
    }

    static isUserAttendingByEmail(eventId, email) {
        return eureka.isUserAttendingByEmail(eventId, email);
    }

    static getToken() {
        return localStorage.getItem('token');
    }

    static getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static getTempUser() {
        return JSON.parse(localStorage.getItem('tempUser'));
    }

    static authenticateTempUser() {
        var self = this;
        this.authenticateUser(self.getTempUser(), self.getToken());
        localStorage.removeItem('tempUser');
    }

    static async createOauth2Url(options) {
        let url = '';
        let pkceChallenge = null;

        const { stateData, externalLoginOptions, redirect_uri } = options;

        if (externalLoginOptions.pkce) {
            const { uid, challenge } = await eureka.generatePKCEChallenge();
            stateData.pkceUid = uid;
            pkceChallenge = challenge;
        }

        var stringData = JSON.stringify(stateData);
        var encoded = new Buffer(stringData).toString('base64');
        var state = encoded;

        url =
            externalLoginOptions.host +
            externalLoginOptions.authorizePath +
            '?' +
            'redirect_uri=' +
            redirect_uri +
            '&' +
            'response_type=code' +
            '&' +
            'client_id=' +
            externalLoginOptions.clientId +
            '&state=' +
            state;
        if (externalLoginOptions.scope) {
            url = url + '&scope=' + externalLoginOptions.scope;
        }
        if (externalLoginOptions.pkce) {
            url = url + '&code_challenge=' + pkceChallenge + '&code_challenge_method=S256';
        }
        if (externalLoginOptions.authorizeUrl) {
            url = externalLoginOptions.authorizeUrl;
            url = url.replace('__state', state);
        }

        return url;
    }

    static getAuthSettings(next) {
        const self = this;
        /**
         * The below config = {} is going to happen only when the function is called like this:
         * getLocalAppState(err) or getLocalAppState(err, undefined)
         * If you do somehting like getLocalAppState(err, null), config will be null
         */
        getLocalAppState((err, config = {}) => {
            // assigning default value to config
            config = config || {};
            if (config && config.externalEurekaLogin && config.externalEurekaLogin.length) {
                const {
                    externalLoginOptions,
                    eventId,
                    eventTitle,
                    societyId,
                    externalEurekaLogin,
                } = config;

                const data = {
                    eventId: eventId,
                    conference: eventTitle,
                    organization: externalEurekaLogin,
                    version: 'apiversion3',
                    societyId: societyId || 'legacy',
                };

                const stringData = JSON.stringify(data);
                const state = new Buffer(stringData).toString('base64');

                if (externalLoginOptions.uniqueAuth === false) {
                    config.addEmail = true;
                }

                if (
                    externalLoginOptions &&
                    externalLoginOptions.grantType &&
                    externalLoginOptions.grantType === 'password'
                ) {
                    config.grantTypePasswordState = state;
                    return next(err, config);
                }

                if (externalLoginOptions.logoutPath) {
                    self.customLogoutRedirect =
                        externalLoginOptions.host + externalLoginOptions.logoutPath;
                }

                const redirect_uri = `${EUREKA_URL}/${externalLoginOptions.redirect_uri_path}`;

                config.options = {
                    stateData: data,
                    redirect_uri: externalLoginOptions.redirect_uri_path_encoded
                        ? encodeURIComponent(redirect_uri)
                        : redirect_uri,
                    externalLoginOptions,
                };
            }
            next(err, config);
        });
    }

    static parse(input) {
        if (typeof input === 'object' && input !== null) {
            return input;
        }

        if (typeof input === 'string') {
            try {
                return JSON.parse(input);
            } catch (e) {
                console.log('Parse error:', e);
                return {};
            }
        }

        console.log('Could not parse type:', typeof input);
        return {};
    }

    static async authTab(options, POPUP_WIDTH, POPUP_HEIGHT, isAttendeeRestriction) {
        const left = window.screen.width / 2 - POPUP_WIDTH / 2;
        const top = window.screen.height / 2 - POPUP_HEIGHT / 2;

        const url = await this.createOauth2Url(options);

        const windowOpts = `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${POPUP_WIDTH}, height=${POPUP_HEIGHT}, top=${top}, left=${left}`;

        if (this.authWindow && !this.authWindow.closed) {
            this.authWindow.location.replace(url);
            this.authWindow.focus();
            return Promise.resolve(null);
        }

        this.authWindow = window.open(url, '_blank', windowOpts);
        if (this.authWindow) this.authWindow.opener = window;
        // Create IE + others compatible event handler
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        // Listen to message from child window
        const authPromise = new Promise((resolve, reject) => {
            eventer(
                messageEvent,
                msg => {
                    if (!~msg.origin.indexOf(`${this.eurekaURL}`)) {
                        this.authWindow.close();
                        return reject('Not allowed');
                    }
                    if (msg.data.payload && !msg.data.payload.error) {
                        try {
                            this.handleLoginResponse(
                                false,
                                this.parse(msg.data.payload),
                                null,
                                err => {
                                    if (err && err === 'notAttendingError') {
                                        return resolve({ err });
                                    }

                                    resolve(true);
                                },
                                isAttendeeRestriction,
                            );
                        } catch (e) {
                            this.handleLoginResponse(
                                false,
                                msg.data.payload,
                                null,
                                err => {
                                    if (err && err === 'notAttendingError') {
                                        return resolve({ err });
                                    }

                                    resolve(true);
                                },
                                isAttendeeRestriction,
                            );
                        } finally {
                            this.authWindow.close();
                        }
                    } else {
                        this.authWindow.close();
                        return reject(false);
                    }
                },
                false,
            );
        });

        return authPromise;
    }

    static async getCustomSyncSettings(withCheckins = false) {
        const config = await getLocalAppStateAsync();
        const currentUser = this.getUser();

        const allowed = withCheckins ? legacySyncBrandsCheckin : legacySyncBrands;

        const { externalEurekaLogin: externalSyncBrand } = config;

        const { accessToken: externalSyncToken } = currentUser;

        if (
            externalSyncToken &&
            externalSyncBrand &&
            allowed.includes(externalSyncBrand.toUpperCase())
        ) {
            return {
                externalSyncToken,
                externalSyncBrand,
                enabled: true,
            };
        }

        return {
            externalSyncToken: '',
            externalSyncBrand: '',
            enabled: false,
        };
    }
}

export default Auth;
