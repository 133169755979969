import React, { useEffect, useState } from 'react';
import each from 'async/each';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import 'moment/min/locales.min';
import { Divider } from 'react-md';
import { makeNavigationData } from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getAllProgramFavoritesForEvent, getItemAsync } from '../../services/api/data';
import { getObjectClassWithId, getObjectClassWithIdAsync } from '../../services/api/db';
import { getItem } from '../../services/api/graphQlRepository';
import Placeholder from '../SearchPage/components/placeholder';
import { getString } from '../../services/api/store';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useFavoritesStore } from '../Favorites/stores/favoriteStore';

const ListItemStyled = styled.div`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;
const ListStyled = styled.ul`
    height: auto !important;
    padding: 0;
`;

const SectionTitle = styled.h5`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const ListItem = ({ item, type }) => (
    <ListStyled>
        <ObjectListItem item={item} type={type} />
        <Divider />
    </ListStyled>
);

const MyFavoritePage = () => {
    const [loading, setLoading] = useState(true);
    const [itemsByType, setItemsByType] = useState({
        results: {},
        types: {},
    });
    const { favorites } = useFavoritesStore();

    useEffect(() => {
        const loadFavorites = async () => {
            setLoading(true);
            const types = {};
            const items = [];

            await Promise.all(
                favorites.map(async favorite => {
                    try {
                        const { objectItem } = await getObjectClassWithIdAsync(favorite.objectId);
                        const type = await getItemAsync('types', objectItem.type);

                        if (type.target !== 'Timeslot' || !objectItem.start) {
                            objectItem.type = type.id;
                            types[type.id] = type;
                            items.push(objectItem);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }),
            );

            // Set the final processed items
            setItemsByType({
                types,
                results: groupBy(sortBy(items, ['orderingName', 'name']), 'type'),
            });
            setLoading(false);
        };
        loadFavorites();
    }, [favorites]);

    const { results } = itemsByType;
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const listItems = Object.keys(results).map(typeId => {
        const type = itemsByType['types'][typeId];
        const items = results[typeId];
        const typeTarget = type.target.toLowerCase();
        const typePlural = type.plural.toLowerCase();

        const list = items.map(item => {
            let params = item.params;
            if (typeof item.params === 'string') {
                params = JSON.parse(item.params);
            }
            let classifierIcon = '';
            if (params && params.icons && params.icons.length) {
                params.icons.forEach((item, idx) => {
                    classifierIcon = <ClassifierIcon key={item} imageId={item} />;
                });
            }

            const navigationData = makeNavigationData(
                match,
                history,
                location,
                '',
                null,
                {
                    type: 'detail',
                    objectClass: typeTarget,
                    objectId: item.id,
                },
                null,
                null,
            );

            return (
                <ListItem
                    item={item}
                    type={typeTarget}
                    path={navigationData}
                    icon={classifierIcon}
                />
            );
        });

        return (
            <section>
                <SectionTitle key={`list_item_${typeId}`}>{typePlural}</SectionTitle>
                {list}
            </section>
        );
    });

    const content = () => {
        if (!Object.keys(itemsByType['types']).length > 0 && !loading) {
            return (
                <Placeholder
                    type="emptyList"
                    introText={getString('personalNoFavoritesTitle') || 'Nothing saved yet...'}
                />
            );
        } else {
            return <ListItemStyled>{listItems}</ListItemStyled>;
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {loading && <Loader />}
                    {!loading && content()}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default MyFavoritePage;
