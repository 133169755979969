import React, { useEffect, useRef, useState } from 'react';
import { Avatar, FontIcon, List, ListItem } from 'react-md';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import { getUsersFriendsList } from '../ExhibitorPage/api';
import { ImageAvatar } from '../../components/Icons';
import { getInitials } from '../VirtualSession/components/userInteraction/attendees/Attendees';
import NavigationLink from '../../components/Navigation/NavigationLink';
import { useTheme } from '../../components/Theme/ThemeContext';
import AcceptDeclineFriendShipButtons from '../UserProfile/components/AcceptDeclineFriendshipButtons';
import { acceptFriendRequest, cancelFriendRequest, getFriendship } from '../UserProfile/service';
import Auth from '../../services/api/auth';
import { getString } from '../../services/api/store';
import eventBus from '../../utils/eventBus';
import { getLocalAppStateAsync } from '../../services/api/db';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';
import Loader from '../../components/General/Loader';
import {
    AchievementType,
    useAchievementActions,
} from '../Achievements/hooks/useAchievementActions';
import FloqButton from '../../components/Button/FloqButton';

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;
const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const StyledItem = styled(ListItem)`
    position: relative;
    .md-list-tile--two-lines {
        height: 80px;
    }
    .md-tile-addon--avatar {
        height: 56px !important;
        width: 56px !important;
    }
`;

const CustomAvatar = styled(Avatar)`
    background-color: #bfbfbf !important;
    border: none !important;
    height: 56px !important;
    width: 56px !important;
`;

const SearchWrapper = styled.div`
    height: 72px;
    padding: 16px !important;
    background-color: ${palette.COLOR_WHITE};
    width: 100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
    z-index: 1;
`;

const Item = ({ item, i, children }) => {
    return (
        <StyledItem
            tileStyle={{
                borderTop:
                    i === 0 ? '0px solid rgba(0, 0, 0, 0.08)' : '1px solid rgba(0, 0, 0, 0.08)',
            }}
            primaryText={`${item.firstName} ${item.lastName}`}
            tabIndex={-1}
            secondaryText={
                <>
                    <div>{item.jobTitle}</div>
                    <div>{item.companyName}</div>
                </>
            }
            leftAvatar={
                item.imageUrl ? (
                    <ImageAvatar
                        imageId={item.imageUrl}
                        avatarType={'persons'}
                        altText={`${item.firstName} ${item.lastName}'s profile picture`}
                    />
                ) : (
                    <CustomAvatar>{getInitials(item.firstName, item.lastName)}</CustomAvatar>
                )
            }
        >
            {children}
        </StyledItem>
    );
};

const MyContacts = props => {
    const [pendingList, setPendingList] = useState([]);
    const [filteredPendingList, setFilteredPendingList] = useState([]);
    const [friendsList, setFrientsList] = useState([]);
    const [filteredFriendsList, setFilteredFrientsList] = useState([]);
    const [appState, setAppState] = useState(null);
    const [loading, setLoading] = useState(false);
    const { trackAchievement } = useAchievementActions();
    const { setTitle } = props;

    const TITLE_MY_CONTACTS = getString('contactsWindowTitle') || 'My Contacts';
    const TEXT_PENDING_REQUESTS = getString('contactsListRequests', 'Pending requests');
    const TEXT_CONTACTS = getString('contactsListContacts', 'Contacts');

    const { theme } = useTheme();
    const authUser = Auth.getUser();
    const isSearching = useRef(false);

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            setAppState(appState);
            await getUsersFriends();
        })();
        setTitle(TITLE_MY_CONTACTS);
    }, []);

    useEffect(() => {
        if (!filteredPendingList.length || !authUser.id) {
            return;
        }

        const receivedRequests = filteredPendingList.filter(
            item => item.friendshipStatus === 0 && authUser.id !== item.actionUserId,
        );

        receivedRequests.forEach(item => {
            trackAchievement(AchievementType.RECEIVE_CONTACT_REQUEST, item.actionUserId);
        });
    }, [filteredPendingList, authUser.id]);

    const getUsersFriends = async () => {
        if (!isSearching.current) {
            setLoading(true);

            const contacts = await getUsersFriendsList();
            if (contacts.pending && contacts.pending.length)
                await Promise.all(
                    contacts.pending &&
                        contacts.pending.map(async item => {
                            const friendship = await getFriendship(item.id);
                            item.friendshipStatus = friendship.status;
                            item.actionUserId =
                                friendship.status === 0 ? friendship.action_user_id : null;
                        }),
                );

            setPendingList(contacts.pending || []);
            setFilteredPendingList(contacts.pending || []);
            setFrientsList(contacts.friends || []);
            setFilteredFrientsList(contacts.friends || []);
            setLoading(false);
        }
    };

    const onCancelRequest = async (e, userId) => {
        // e.preventDefault();
        // e.stopPropagation();
        const data = { from: userId, event: appState.eventId };
        await cancelFriendRequest(data);
        await getUsersFriends();
        eventBus.emit('refreshRepresentativesList');
    };

    const onAcceptFriendRequest = async (e, userId) => {
        e.preventDefault();
        e.stopPropagation();
        const data = {
            from: userId,
            event: appState.eventId,
        };
        await acceptFriendRequest(data);
        await getUsersFriends();
    };

    const searchByMultipleValues = (data, value) => {
        const availableKeys = ['firstName', 'lastName', 'jobTitle', 'companyName'];
        return (data || []).filter(item =>
            Object.keys(item).some(
                key =>
                    typeof item[key] === typeof value &&
                    availableKeys.includes(key) &&
                    item[key].toLowerCase().includes(value),
            ),
        );
    };

    const onSearchValueChange = throttle(async value => {
        if (!value) {
            setFilteredFrientsList(friendsList);
            setFilteredPendingList(pendingList);
        } else {
            const searchedPending = searchByMultipleValues(pendingList, value.toLowerCase());
            const searchedContacts = searchByMultipleValues(friendsList, value.toLowerCase());

            setFilteredPendingList(searchedPending);
            setFilteredFrientsList(searchedContacts);
        }
        isSearching.current = value.length > 0;
        if (value.length === 0) {
            await getUsersFriends();
        }
    }, 500);

    const renderListItems = items =>
        items
            ? sortBy(items, ['lastName']).map((item, index) => {
                  return (
                      <React.Fragment key={'contact_' + index}>
                          <LinkStyled params={{ type: 'profile', userId: item.id }}>
                              <Item item={item} i={index}>
                                  {item &&
                                      item.friendshipStatus === 0 &&
                                      authUser.id === item.actionUserId && (
                                          <FloqButton
                                              id={'cancelRequest_' + item.id}
                                              extraStyle={{
                                                  color: theme.primary,
                                                  backgroundColor: theme.primary + '30',
                                              }}
                                              onClick={e => {
                                                  onCancelRequest(e, item.id);
                                              }}
                                          >
                                              <FontIcon
                                                  aria-hidden={true}
                                                  role={'presentation'}
                                                  style={{
                                                      color: theme.primary,
                                                      marginRight: 8,
                                                  }}
                                              >
                                                  {palette.ICON_REMOVE_PERSON}
                                              </FontIcon>
                                              {getString('profileCancelContactRequest') ||
                                                  'Cancel request'}
                                          </FloqButton>
                                      )}
                                  {item &&
                                      item.friendshipStatus === 0 &&
                                      authUser.id !== item.actionUserId && (
                                          <AcceptDeclineFriendShipButtons
                                              theme={theme}
                                              onCancelRequest={e => onCancelRequest(e, item.id)}
                                              onAcceptFriendRequest={e =>
                                                  onAcceptFriendRequest(e, item.id)
                                              }
                                          />
                                      )}
                              </Item>
                          </LinkStyled>
                      </React.Fragment>
                  );
              })
            : [];

    return (
        <React.Fragment>
            {loading && <Loader />}
            {!loading && (
                <>
                    <SearchWrapper>
                        <Search
                            action={onSearchValueChange}
                            live={true}
                            skey={props.pageId}
                            isExpandableSearch={true}
                            displayLeft={true}
                        />
                    </SearchWrapper>
                    {filteredPendingList && (
                        <>
                            <SectionTitle>{TEXT_PENDING_REQUESTS}</SectionTitle>
                            <ListStyled className="">
                                {renderListItems(filteredPendingList)}
                            </ListStyled>
                        </>
                    )}
                    {filteredFriendsList && (
                        <>
                            <SectionTitle>{TEXT_CONTACTS}</SectionTitle>
                            <ListStyled className="">
                                {renderListItems(filteredFriendsList)}
                            </ListStyled>
                        </>
                    )}{' '}
                </>
            )}
        </React.Fragment>
    );
};

export default MyContacts;
