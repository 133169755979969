import React, { useRef, useState } from 'react';
import {
    AddImage,
    ButtonsContainer,
    Card,
    CloseCardIcon,
    ImageCropper,
    Input,
    NoButton,
    PhotoImageBackground,
    ProfileImageWrapper,
    Subtitle,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as palette from '../../../components/General/Variables';
import * as string from '../constants/strings';
import { convertBase64ToFile, uploadFileToS3 } from '../../../services/api/eureka';
import Button, { buttonTypes } from '../../common/Button';
import { Flex } from '../../common/Flex';

const AddProfileImage = ({
    theme,
    goTo,
    setProfileImage,
    saveProfileInfo,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const NOT_NOW = getString('updateNotNowButton') || 'Not now';
    const SAVE = getString('profileSaveAvatarButton') || 'Save';
    const TEXT_ADD_AVATAR = getString('profileSelectAvatar') || 'Choose profile photo';
    const TEXT_EDIT_AVATAR = getString('editImageScreenTitle') || 'Edit photo';
    const TEXT_EDIT_AVATAR_MESSAGE =
        getString('profileEditAvatarHint') ||
        'Adjust the position by dragging the image and change the image size with the slider.';
    const CHANGE_IMAGE_FROM_PROFILE_TEXT =
        getString('profileEditAvatarLater') || string.CHANGE_IMAGE_FROM_PROFILE_TEXT;
    const cropperCover = useRef(null);
    const [image, setImage] = useState(null);
    const [fileCropped, setFileCropped] = useState(null);
    const [cropperImage, setCroppedImage] = useState(null);
    const [zoom, setZoom] = useState(1.2);

    const onImageChange = async event => {
        const file = event.target.files[0];

        setFileCropped(file);
        setImage(URL.createObjectURL(file));
    };

    const saveImage = async () => {
        if (cropperImage && fileCropped) {
            const file = await convertBase64ToFile(
                cropperImage,
                fileCropped.name,
                fileCropped.type,
            );
            const s3data = await uploadFileToS3(file);
            setProfileImage(s3data.imageUrl);
            await saveProfileInfo(s3data.imageUrl);
            goTo('imageAdded');
        }
    };

    const skip = async () => {
        await saveProfileInfo();
        goTo('attendeesSettings');
    };

    const onCropCover = () => {
        setCroppedImage(cropperCover && cropperCover.current.getCroppedCanvas().toDataURL());
    };

    const onChange = e => {
        setZoom(e.target.value);
    };

    return (
        <Card largeCard={!!image} isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{image ? TEXT_EDIT_AVATAR : TEXT_ADD_AVATAR}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon
                        aria-hidden={true}
                        role={'presentation'}
                        onClick={onClose}
                        aria-label={'Close'}
                    >
                        {palette.ICON_CLOSE}
                    </CloseCardIcon>
                )}
            </Flex>
            <Subtitle>
                {!image ? CHANGE_IMAGE_FROM_PROFILE_TEXT : TEXT_EDIT_AVATAR_MESSAGE}
            </Subtitle>
            {image ? (
                <>
                    {fileCropped ? (
                        <>
                            <ImageCropper
                                innerRef={cropperCover}
                                src={image}
                                style={{ maxWidth: '256px', maxHeight: '240px' }}
                                guides={false}
                                crop={onCropCover}
                                zoomTo={zoom}
                                aspectRatio={4 / 4}
                            />
                            <Input
                                className="slider"
                                primarycolor={theme.primary}
                                type="range"
                                step="0.1"
                                aria-label="zoom"
                                min="0.2083"
                                max="1.8000"
                                aria-valuenow="0.4167"
                                onChange={onChange}
                            />
                        </>
                    ) : (
                        <ProfileImageWrapper src={image} />
                    )}
                </>
            ) : (
                <PhotoImageBackground>
                    <label htmlFor="background-image-upload">
                        <AddImage
                            aria-hidden={true}
                            role={'presentation'}
                            primarycolor={theme.primary}
                        >
                            {palette.ADD_PHOTO}
                        </AddImage>
                    </label>
                    <input
                        id="background-image-upload"
                        name="profileImage"
                        type="file"
                        accept="image/*"
                        onChange={onImageChange}
                    />
                </PhotoImageBackground>
            )}
            <ButtonsContainer primarycolor={theme.primary}>
                {image ? (
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={SAVE}
                        onClick={saveImage}
                        data-qa="button-save-image-profile"
                    />
                ) : (
                    <>
                        <NoButton />
                        <Button
                            type={buttonTypes.GHOST_LONG}
                            background={theme.primary}
                            text={NOT_NOW}
                            onClick={skip}
                            data-qa="button-image-profile-not-now"
                            style={{ marginTop: '12px' }}
                        />
                    </>
                )}
            </ButtonsContainer>
        </Card>
    );
};

export default AddProfileImage;
