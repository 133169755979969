import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import sortBy from 'lodash/sortBy';
import FloqTabs from '../../../components/Tabs/FloqTabs';
import InstitutionContentContactTab from './InstitutionContentContactTab';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InstitutionContentInfoTab from './InstitutionContentInfoTab';
import InstitutionContentResourcesTab from './InstitutionContentResourcesTab';

const InstitutionContentSection = ({ institution, institutionProposalData }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [reset, setReset] = useState(false);
    const formik = useFormikContext();

    const selectedType = institution.typeEntity;

    const tabs = useMemo(() => {
        return sortBy(
            [
                {
                    label: selectedType?.tabs?.find(t => t.kind === 'contact')?.name || 'Contact',
                    order: selectedType?.tabs?.find(t => t.kind === 'contact')?.order,
                    hasBadge:
                        (formik.errors.contactPhoneNumber &&
                            formik.errors.contactPhoneNumber.length > 0) ||
                        (formik.errors.contactEmail && formik.errors.contactEmail.length > 0) ||
                        (formik.errors.contactFacebookUrl &&
                            formik.errors.contactFacebookUrl.length > 0) ||
                        (formik.errors.contactTwitterUrl &&
                            formik.errors.contactTwitterUrl.length > 0) ||
                        (formik.errors.contactLinkedinUrl &&
                            formik.errors.contactLinkedinUrl.length > 0) ||
                        false,
                    type: 'contact',
                },
                {
                    label: selectedType?.tabs?.find(t => t.kind === 'info')?.name || 'Info',
                    order: selectedType?.tabs?.find(t => t.kind === 'info')?.order,
                    type: 'info',
                },
                {
                    label:
                        selectedType?.tabs?.find(t => t.kind === 'resources')?.name || 'Resources',
                    order: selectedType?.tabs?.find(t => t.kind === 'resources')?.order,
                    type: 'resources',
                },
            ],
            'order',
        );
    }, [formik.errors]);

    const activeTabType = useMemo(() => {
        if (tabs.length > 0 && activeTab <= tabs.length) {
            return tabs[activeTab]?.type;
        } else {
            return '';
        }
    }, [tabs, activeTab]);

    return (
        <Card sx={{ boxShadow: 2 }}>
            <Stack>
                <Typography variant="h3" marginBottom={0} paddingY={2} paddingX={3}>
                    Content
                </Typography>
                <>
                    <FloqTabs
                        data={tabs}
                        zeroPadding
                        onChange={index => {
                            setActiveTab(index);
                        }}
                        activeIndex={activeTab}
                    />
                    <Box sx={{ display: activeTabType === 'contact' ? 'block' : 'none' }}>
                        <InstitutionContentContactTab institution={institution} />
                    </Box>
                    <Box sx={{ display: activeTabType === 'info' ? 'block' : 'none' }}>
                        <InstitutionContentInfoTab
                            institution={institution}
                            institutionProposalData={institutionProposalData}
                            reset={reset}
                        />
                    </Box>
                    <Box sx={{ display: activeTabType === 'resources' ? 'block' : 'none' }}>
                        <InstitutionContentResourcesTab
                            institution={institution}
                            institutionProposalData={institutionProposalData}
                        />
                    </Box>
                </>
            </Stack>
        </Card>
    );
};

export default InstitutionContentSection;
