import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { FontIcon } from 'react-md';

import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useTheme } from '../Theme/ThemeContext';
import StudioIcon from '../Icons/StudioIcon';

export const TabsHeader = styled(Tabs)`
    div {
        flex: 1;
        height: ${props => (props.topNavigationTabs ? '56px' : '48px')};

        button {
            min-width: ${props => (props.unsetMaxWidth ? 'unset' : '100%')} !important;
            height: ${props => (props.topNavigationTabs ? '48px' : '40px')};
            min-height: ${props => (props.topNavigationTabs ? '48px' : '40px')};
            width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
            margin: 4px 0;
            position: sticky;
            top: 0;
            z-index: 1;

            span:first-of-type {
                flex-direction: row !important;

                & > div {
                    width: fit-content;
                    flex: unset;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
`;

export const TabsContainer = styled(Paper)`
    width: 100%;
    height: 100%;
    overflow: ${props => (props.isSidePanelTabs ? '' : 'hidden')};
    box-shadow: unset !important;
    display: flex;
    flex-direction: column;

    .MuiTabs-root {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
        ${props =>
            props.isSidePanelTabs &&
            `
            border-bottom: 1px solid #DDDDDD;
        `};
        ${props =>
            props.applyShadow &&
            props.isSidePanelTabs &&
            `
            box-shadow: 0px 0px 1px rgb(0 0 0 / 15%), 0px 2px 8px rgb(0 0 0 / 5%), 0px 2px 3px rgb(0 0 0 / 10%);
        `};
    }

    @keyframes expandRight {
        0% {
            transform: translateX(180px);
            opacity: 0;
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    @keyframes expandLeft {
        0% {
            transform: translateX(-280px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    & > div:nth-of-type(2) {
        height: ${props => props.containerHeight};
        overflow: scroll;
        animation: ${props =>
            (props.animationDirection === 'left' && `expandRight .3s ease-in forwards`) ||
            (props.animationDirection === 'right' && 'expandLeft .3s ease-out forwards')};
        transition: all cubic-bezier(0.1, 0.7, 1, 0.1) 300ms;
    }

    .MuiTabs-indicator {
        height: 3px !important;
    }
`;

export const TabElement = styled(Tab)`
    font-family: 'Roboto', sans-serif;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.6) !important;
    cursor: pointer;
    transition: opacity 0.1s;
    opacity: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: none !important;
    width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
    border-radius: 8px !important;

    ${props =>
        props.isSmallTab &&
        `
    &:first-of-type {
      margin-left: 16px !important;
    }`}
    &:not(&.active):hover {
        background: ${props => props.color + '10' || '#00a933' + '10'};
        color: ${props => props.color || '#00a933'} !important;
        width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
    }

    &:active {
        color: ${props => props.color || '#00a933'} !important;
        background: ${props => props.color + '30' || '#00a933' + '30'} !important;
    }

    &.active {
        color: ${props => props.color || '#00a933'} !important;
        font-weight: bold;
    }
`;

const StyledChip = styled.span`
    text-align: center;
    font-family: 'Cabin', sans-serif;
    font-size: 10px;
    color: #fff;
    background-color: ${props => props.primary};
    padding: 2px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    position: absolute;
    bottom: 24px;
    left: 39%;
    line-height: 12px;
`;

export const TabStyledFontIcon = styled(FontIcon)`
    margin: ${props => (props.displayIconWithoutLabel ? '0 auto' : '0 12px 0 0')} !important;
    color: inherit !important;
    ${props => props.topNavigationTabs && 'font-size: 24px !important'};
    font-size: 24px !important;
`;

const TabLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-width: fit-content;
`;

const ContentWrapper = styled.div`
    flex: 1;
    position: relative;

    & > :first-child {
        top: 0;
        height: 100%;
    }
`;

const forceNonOutlineIcon = icon => {
    if (icon === 'info_outline') {
        return true;
    }
    return false;
};

// @deprecated
// Use the new FloqTabs component instead
const TabsComponent = ({
    data,
    isSmallTab,
    containerHeight,
    onMouseEnter,
    onMouseLeave,
    currentTab = 0,
    checkContentAllTabs,
    topNavigationTabs,
    isSidePanelTabs,
}) => {
    const filteredData = data.filter(it => it?.label || it?.labelIcon || it?.labelStudioIcon);
    const [activeTab, setActiveTab] = useState(currentTab);
    const [DisplayComponent, setDisplayComponent] = useState(null);
    const [previousActiveTab, setPreviousActiveTab] = useState(0);
    const [shadowContainer, setShadowContainer] = useState(false);
    const { theme } = useTheme();
    const onTabClick = value => {
        setPreviousActiveTab(activeTab);
        setActiveTab(value);
    };

    useEffect(() => {
        if (currentTab) {
            setActiveTab(currentTab);
        }
        if (data.length === 1) {
            setActiveTab(0);
        }
    }, [currentTab]);

    useEffect(() => {
        const { content } = filteredData[activeTab] || { content: null };
        setDisplayComponent(content);
    }, [activeTab, data]);

    useEffect(() => {
        if (data.length === 1) {
            setActiveTab(0);
        }
    }, [data]);

    useEffect(() => {
        if (isSidePanelTabs) {
            const scrollable = document.getElementById('scrollable');

            if (scrollable) {
                scrollable.addEventListener('scroll', handleScrolling);
            }
            return () => {
                scrollable && scrollable.removeEventListener('scroll', handleScrolling);
            };
        }
    }, []);

    const handleScrolling = () => {
        const elem = document.getElementById('tabs-container');
        const scrollable = document.getElementById('scrollable');
        setShadowContainer(elem?.offsetTop <= scrollable?.scrollTop);
    };

    const renderContent = () => {
        if (typeof DisplayComponent === 'function') {
            return <DisplayComponent />;
        } else if (typeof DisplayComponent === 'object') {
            return DisplayComponent;
        }
    };

    return (
        <TabsContainer
            id={'tabs-container'}
            isSidePanelTabs={isSidePanelTabs}
            animationDirection={
                previousActiveTab !== activeTab &&
                (previousActiveTab < activeTab ? 'left' : 'right')
            }
            containerHeight={containerHeight}
            square
            onMouseEnter={it => {
                if (onMouseEnter) {
                    onMouseEnter(it);
                }
            }}
            onMouseLeave={it => {
                if (onMouseLeave) {
                    onMouseLeave(it);
                }
            }}
            applyShadow={shadowContainer}
        >
            <TabsHeader
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                isSmallTab={isSmallTab}
                topNavigationTabs={topNavigationTabs}
                unsetMaxWidth={filteredData.length > 1}
                TabIndicatorProps={
                    isSmallTab
                        ? {
                              style: {
                                  width: `${80 / filteredData.length}%`,
                                  marginLeft: '16px',
                                  borderRadius: '2px 2px 0px 0px',
                              },
                          }
                        : {
                              style: {
                                  borderRadius: '2px 2px 0px 0px',
                              },
                          }
                }
            >
                {filteredData.map((tab, index) => (
                    <div key={`tab=${index}`}>
                        <TabElement
                            topNavigationTabs={topNavigationTabs}
                            className={activeTab === Number(index) && 'active'}
                            onClick={it => {
                                if (tab.onClick) {
                                    tab.onClick(it);
                                }
                                onTabClick(index);
                            }}
                            onMouseEnter={it => {
                                if (tab.onMouseEnter) {
                                    tab.onMouseEnter(it);
                                }
                            }}
                            onMouseLeave={it => {
                                if (tab.onMouseLeave) {
                                    tab.onMouseLeave(it);
                                }
                            }}
                            key={`tab-element-${index}`}
                            isSmallTab={isSmallTab}
                            color={theme.primary}
                            label={
                                <TabLabel>
                                    {tab.label}
                                    {tab.chip && (
                                        <StyledChip primary={theme.contrast}>{tab.chip}</StyledChip>
                                    )}
                                </TabLabel>
                            }
                            aria-label={tab.ariaLabel}
                            disableTouchRipple
                            iconPosition="start"
                            tabIndex={0}
                            icon={
                                <>
                                    {tab.labelIcon && (
                                        <TabStyledFontIcon
                                            aria-hidden={true}
                                            role={'presentation'}
                                            iconClassName={
                                                activeTab === Number(index) ||
                                                forceNonOutlineIcon(tab.labelIcon)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'
                                            }
                                            displayIconWithoutLabel={!tab.label}
                                            topNavigationTabs={topNavigationTabs}
                                        >
                                            {tab.labelIcon}
                                        </TabStyledFontIcon>
                                    )}
                                    {tab.labelStudioIcon && (
                                        <StudioIcon
                                            icon={tab.labelStudioIcon}
                                            color={
                                                activeTab === Number(index)
                                                    ? theme.primary
                                                    : 'rgba(0, 0, 0, 0.6)'
                                            }
                                            size={24}
                                            active={activeTab === Number(index)}
                                        />
                                    )}
                                </>
                            }
                        />
                    </div>
                ))}
            </TabsHeader>
            <ContentWrapper>{renderContent()}</ContentWrapper>
        </TabsContainer>
    );
};

const TabsWithMemo = React.memo(
    props => {
        return <TabsComponent {...props} />;
    },
    (prevProps, nextProps) => {
        return isEqual(JSON.stringify(prevProps.data), JSON.stringify(nextProps.data));
    },
);

const TabsContiner = props => {
    if (props.useMemo) {
        return <TabsWithMemo {...props} />;
    } else {
        return <TabsComponent {...props} />;
    }
};

export default TabsContiner;
