import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccessibleFakeButton, Button, FontIcon, IconSeparator } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { getString } from '../../../services/api/store';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import * as palette from '../../../components/General/Variables';
import { getBgAndFontColorForAllDifferentStates } from '../../../components/General/Colors';
import { useTheme } from '../../../components/Theme/ThemeContext';
import {
    LeftIcon,
    RightIcon,
    StyledDropdownMenu,
    StyledIconSeparator,
    StyledListItem,
} from '../style/modalsStyle';
import PlatformInfoTitle from './platformInfo/PlatformInfoTitle';
import PlatformInfoContent from './platformInfo/PlatformInfoContent';
import { connect } from 'react-redux';
import {
    displayPlatformInfo as displayPlatformInfoAction,
    hidePlatformInfo as hidePlatformInfoAction,
} from '../actions';
import { NewNotficationsBadge } from '../../Notifications/components/NotificationButton';
import { navigateTo } from '../../../components/Navigation/NavigationLink';

const StyledButton = styled(Button)`
    margin: 0 !important;
    padding: 4px 8px 4px 0 !important;
    border-radius: 20px !important;
    min-width: unset !important;

    i {
        font-size: 32px !important;
    }

    .md-icon-text {
        padding-left: 8px !important;
        text-transform: none !important;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;

        @media only screen and (max-width: ${palette.MAX_PHONE}) {
            display: none;
        }
    }
`;

const UnauthenticatedUserButton = props => {
    const { theme } = useTheme();
    const [visible, setVisible] = useState(false);

    const { showSignIn } = useContext(AuthUserContext);

    const TITLE_ABOUT_THIS_PLATFORM = getString('aboutThisPlatformTitle', 'About this platform');
    const SUBTITLE_ABOUT_THIS_PLATFORM = getString(
        'aboutThisPlatformSubtitle',
        'Updates, Legal information, Release notes',
    );

    useEffect(() => {
        if (!visible && props.showPlatformInfo) {
            props.hidePlatformInfo();
        }
    }, [visible]);

    const onVisibilityChange = newVisible => setVisible(newVisible);

    const SIGN_IN_TEXT = getString('sideMenuSignIn') || 'Sign in';
    const {
        contrastBlackOrWhite,
        defaultBg,
        hoverBg,
        activeBg,
        fontColor,
    } = getBgAndFontColorForAllDifferentStates(theme, props.light);

    let menuItems;
    if (props.showPlatformInfo) {
        menuItems = [
            <PlatformInfoTitle key="platform-info-title" />,
            <PlatformInfoContent
                key="platform-info-content"
                updateAvailable={props.newVersionAvailable}
                updateServiceWorker={props.updateServiceWorker}
                waitingWorker={props.waitingWorker}
            />,
        ];
    } else {
        menuItems = [
            <StyledListItem
                key="auth-dropdown-item-sign-in"
                primaryText={SIGN_IN_TEXT}
                leftIcon={
                    <FontIcon aria-hidden={true} role={'presentation'}>
                        login
                    </FontIcon>
                }
                onClick={() => {
                    showSignIn(null, {
                        match: props.match,
                        history: props.history,
                        location: props.location,
                        replace: false,
                    });
                }}
            />,
            <StyledListItem
                className={'border-top'}
                key="auth-dropdown-about-this-platform"
                primaryText={TITLE_ABOUT_THIS_PLATFORM}
                secondaryText={SUBTITLE_ABOUT_THIS_PLATFORM}
                leftIcon={
                    <AccessibleFakeButton label={''} component={StyledIconSeparator} iconBefore>
                        <LeftIcon
                            aria-hidden={true}
                            role={'presentation'}
                            iconClassName="material-icons-outlined"
                        >
                            info
                        </LeftIcon>
                        {props.newVersionAvailable && (
                            <NewNotficationsBadge
                                color={theme.contrast}
                                right={'10px'}
                                top={'-10px'}
                                border={'solid 2px #f5f5f5;'}
                            ></NewNotficationsBadge>
                        )}
                    </AccessibleFakeButton>
                }
                rightIcon={
                    <RightIcon aria-hidden={true} role={'presentation'}>
                        chevron_right
                    </RightIcon>
                }
                onClick={e => {
                    e.stopPropagation();
                    props.displayPlatformInfo();
                }}
            />,
        ];
    }

    return (
        <StyledDropdownMenu
            id="authenticated-user-dropdown-menu"
            menuItems={menuItems}
            animationPosition="below"
            sameWidth
            simplifiedMenu={false}
            onVisibilityChange={onVisibilityChange}
            visible={visible}
            light={props.light}
            width={props.showPlatformInfo && '375px'}
            primary={theme.primary}
            backgroundColor={defaultBg}
            hoverColor={hoverBg}
            activeColor={activeBg}
            fontColor={fontColor}
            contrastBlackOrWhite={props.light ? palette.BLACK_87 : contrastBlackOrWhite}
            isAuthenticated={false}
            role="button"
        >
            <StyledButton
                flat
                iconChildren={
                    props.newVersionAvailable ? (
                        <AccessibleFakeButton label={''} component={IconSeparator} iconBefore>
                            <FontIcon
                                aria-hidden={true}
                                role={'presentation'}
                                iconClassName="material-icons material-icons-round"
                            >
                                person
                            </FontIcon>
                            {props.newVersionAvailable && (
                                <NewNotficationsBadge color={theme.contrast} right={'0px'}>
                                    1
                                </NewNotficationsBadge>
                            )}
                        </AccessibleFakeButton>
                    ) : (
                        <FontIcon
                            aria-hidden={true}
                            role={'presentation'}
                            iconClassName="material-icons material-icons-round"
                        >
                            person
                        </FontIcon>
                    )
                }
            >
                {SIGN_IN_TEXT}
            </StyledButton>
        </StyledDropdownMenu>
    );
};

const mapStateToProps = state => ({
    showPlatformInfo: state.auth.showPlatformInfo,
});

export default connect(mapStateToProps, {
    hidePlatformInfo: hidePlatformInfoAction,
    displayPlatformInfo: displayPlatformInfoAction,
})(withRouter(UnauthenticatedUserButton));
