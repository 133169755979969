import React from 'react';
import { ImageAvatar } from '../Icons';
import { StyledListItem } from './style/index';
import { Link } from 'react-router-dom';

const PlaceListItem = ({ place, path }) => {
    return (
        <StyledListItem
            {...(place.onClick ? { onClick: place.onClick } : {})}
            {...(path ? { component: Link, to: path } : {})}
            key={`place_${place.id}`}
            primaryText={place.name}
            tabIndex={-1}
            leftAvatar={<ImageAvatar avatarType="places" />}
        />
    );
};

export default PlaceListItem;
