import React, { useContext, useState } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import toast from 'toasted-notes';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import Menu from '@mui/material/Menu';

import { useTheme } from '../Theme/ThemeContext';
import InteractivityContext from '../../components/Interactivity/InteractivityContext';
import AuthUserContext from '../Session/AuthUserContext';
import NotificationToast from './NotificationToast';
import eventBus from '../../utils/eventBus';
import { ModalContent } from '../Dialog/styles';
import BasicDialog from '../Dialog/BasicDialog';
import facebookLogo from '../../assets/social/facebook_icon_share.png';
import whatsappLogo from '../../assets/social/whatsapp_icon_share.png';
import twitterLogo from '../../assets/social/twitter_icon_share.png';
import linkedinLogo from '../../assets/social/linkedin_icon_share.png';
import * as palette from './Variables';
import { getContent, getContentWithoutURL } from '../../utils/shareContentMessage';
import { TimezoneContext } from '../../scenes/Timezone/context';
import { getString } from '../../services/api/store';
import Auth from '../../services/api/auth';
import Button, { buttonTypes } from '../../scenes/common/Button';

const ShareIconContainer = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.background};
    cursor: pointer;
    
    img {
        width: ${props => (props.background ? '32px' : '100%')};
        height: ${props => (props.background ? '33px' : '100%')};
    }
    
    i {
        font-size: 32px !important;
        color: ${palette.COLOR_WHITE}
        width: ${props => (props.background ? '32px' : '100%')};
        height: ${props => (props.background ? '33px' : '100%')};
    }
    
    button {
        display: flex;
    }
`;

const ShareContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    border-radius: 8px;

    :hover {
        background-image: ${palette.HOVER_OVERLAY};
    }
`;

const ShareText = styled.div`
    font-size: 12px;
    padding-top: 8px;
    color: ${palette.COLOR_TEXT};
`;

const Link = styled.a`
    font-size: 32px;
    cursor: pointer;
    text-decoration: none;
`;

const CalendarContainer = styled.div`
    padding: 16px;
`;

const StyledMenu = styled(Menu)`
    & > .MuiPaper-root {
        width: 250px;
        border-radius: 8px;
        padding: 4px;
    }
`;

const StyledButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    height: 48px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    padding: 0;
    font-size: 16px;
    font-weight: 500;

    & > .md-icon {
        padding-left: 19px !important;
        padding-right: 19px !important;
        color: rgba(0, 0, 0, 0.87) !important;
    }

    &:hover {
        background-image: ${palette.HOVER_OVERLAY};
    }
`;

const MoreOptionsShareButton = ({ type, notesTextWithAction, notePage, item }) => {
    const COPY_LINK_LABEL = getString('copyLink') || 'Copy link';
    const SHARE_LABEL = getString('share') || 'Share';
    const ADD_TO_CALENDAR_LABEL = getString('addToCalendar') || 'Add to calendar';
    const MORE_OPTIONS_LABEL = getString('moreOptionsNotesTitle') || 'More options';

    const { theme } = useTheme();
    const [isNotesPageDisplayed, setIsNotesPageDisplayed] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const { getUtcToSelectedTimezone, getSelectedTimezoneName } = useContext(TimezoneContext);

    const openMenu = event => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    const itemCopy = { ...item };

    let startTime = '';
    let endTime = '';
    let startDate = '';
    let endDate = '';
    let timezone = '';

    if (item.startTime) {
        const dateSettings = getString('datetime');
        const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

        let start = getUtcToSelectedTimezone(item.startTime);
        let end = getUtcToSelectedTimezone(item.endTime);

        let datePattern =
            dateSettings && dateSettings.longDateFormat
                ? dateSettings.longDateFormat
                : 'dddd, MMMM Do YYYY';
        let timeFormat =
            dateSettings && dateSettings.timeFormat ? dateSettings.timeFormat : 'HH:mm';

        itemCopy.time = `${start.locale(locale).format(datePattern)}, ${start.format(
            timeFormat,
        )} - ${end.format(timeFormat)}`.toUpperCase();

        startTime = start.format('HH:mm');
        endTime = end.format('HH:mm');
        startDate = start.format('YYYY-MM-DD');
        endDate = end.format('YYYY-MM-DD');
        timezone = getSelectedTimezoneName();
    }

    const location =
        item?.locations?.items && item?.locations?.items[0] && item?.locations?.items[0].name;
    const authUser = Auth.getUser();

    const copyUrlToClipboard = () => {
        toast.notify(
            () => (
                <NotificationToast
                    title={'Link copied'}
                    subtitle={'The link to this item is copied to your clipboard'}
                    icon={'content_copy'}
                />
            ),
            {
                position: 'top-right',
                duration: 2000,
            },
        );
        navigator.clipboard.writeText(window.location.href);
        closeMenu();
    };

    const share = () => {
        setShowShareModal(true);
        closeMenu();
    };

    const addToCalendar = () => {
        setShowCalendarModal(true);
        closeMenu();
    };

    const openNotes = () => {
        if (!authUser) {
            return;
        }
        setIsNotesPageDisplayed(true);
        eventBus.emit('notesPageOpened');
    };

    const optionObjectsForInstitutionAndClassifier = {
        options: [COPY_LINK_LABEL, SHARE_LABEL, notesTextWithAction],
        icons: ['content_copy', 'share', 'edit'],
        actions: [copyUrlToClipboard, share, openNotes],
    };

    const getOptionsByType = () => {
        switch (type) {
            case 'classifier':
                return optionObjectsForInstitutionAndClassifier;
            case 'institution':
                return optionObjectsForInstitutionAndClassifier;
            case 'booth':
                return optionObjectsForInstitutionAndClassifier;
            default:
                return item.startTime
                    ? {
                          options: [
                              COPY_LINK_LABEL,
                              SHARE_LABEL,
                              ADD_TO_CALENDAR_LABEL,
                              notesTextWithAction,
                          ],
                          icons: ['content_copy', 'share', 'today', 'edit'],
                          actions: [copyUrlToClipboard, share, addToCalendar, openNotes],
                      }
                    : {
                          options: [COPY_LINK_LABEL, SHARE_LABEL, notesTextWithAction],
                          icons: ['content_copy', 'share', 'edit'],
                          actions: [copyUrlToClipboard, share, openNotes],
                      };
        }
    };

    if (isNotesPageDisplayed) {
        return notePage;
    }
    eventBus.on('notesPageClosed', () => setIsNotesPageDisplayed(false));

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <InteractivityContext.Consumer>
                    {({ showSignIn }) => (
                        <>
                            <Button
                                type={
                                    type === 'classifier' || type === 'institution'
                                        ? buttonTypes.GREY_LONG
                                        : buttonTypes.GREY
                                }
                                style={
                                    type !== 'classifier' && type !== 'institution'
                                        ? {
                                              width: '56px',
                                              padding: 'unset',
                                          }
                                        : {}
                                }
                                background={theme.primary}
                                icon={'more_horiz'}
                                iconAriaLabel={'More options'}
                                text={
                                    (type === 'classifier' || type === 'institution') &&
                                    MORE_OPTIONS_LABEL
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (authUser) {
                                        // setShowNotesScreen(true);
                                    } else {
                                        showSignIn(e);
                                    }
                                    openMenu(e);
                                }}
                            />

                            <StyledMenu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={closeMenu}
                            >
                                {getOptionsByType().options.map((option, index) => (
                                    <StyledButton
                                        role="button"
                                        key={option}
                                        onClick={getOptionsByType().actions[index]}
                                    >
                                        <FontIcon
                                            iconClassName="material-icons-outlined"
                                            aria-hidden
                                        >
                                            {getOptionsByType().icons[index]}
                                        </FontIcon>
                                        {option}
                                    </StyledButton>
                                ))}
                            </StyledMenu>

                            <BasicDialog
                                title={'Share object'}
                                visible={showShareModal}
                                onClose={() => setShowShareModal(false)}
                                options={[]}
                                maxWidth={'sm'}
                            >
                                <ModalContent
                                    textAlign={'start'}
                                    justifycontent={'center'}
                                    gap={'10px'}
                                    padding={'16px 8px 8px 8px'}
                                >
                                    <WhatsappShareButton
                                        url={window.location.href}
                                        title={getContentWithoutURL(type, itemCopy)}
                                    >
                                        <ShareContainer>
                                            <ShareIconContainer background={'#25d366'}>
                                                <img src={whatsappLogo} alt="whatsappLogo" />
                                            </ShareIconContainer>
                                            <ShareText>Whatsapp</ShareText>
                                        </ShareContainer>
                                    </WhatsappShareButton>
                                    <FacebookShareButton
                                        url={window.location.href}
                                        quote={getContentWithoutURL(type, itemCopy)}
                                    >
                                        <ShareContainer>
                                            <ShareIconContainer background={'#3b5998'}>
                                                <img src={facebookLogo} alt="facebookLogo" />
                                            </ShareIconContainer>
                                            <ShareText>Facebook</ShareText>
                                        </ShareContainer>
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                        title={getContentWithoutURL(type, itemCopy)}
                                        url={window.location.href}
                                    >
                                        <ShareContainer>
                                            <ShareIconContainer background={'#000'}>
                                                <img
                                                    src={twitterLogo}
                                                    alt="twitterLogo"
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </ShareIconContainer>
                                            <ShareText>X</ShareText>
                                        </ShareContainer>
                                    </TwitterShareButton>

                                    <LinkedinShareButton
                                        title={getContentWithoutURL(type, itemCopy)}
                                        url={window.location.href}
                                        source={window.location.href}
                                    >
                                        <ShareContainer>
                                            <ShareIconContainer>
                                                <img src={linkedinLogo} alt="linkedinLogo" />
                                            </ShareIconContainer>
                                            <ShareText>Linkedin</ShareText>
                                        </ShareContainer>
                                    </LinkedinShareButton>

                                    <Link
                                        role={'button'}
                                        href={
                                            'mailto:?body=' +
                                            encodeURIComponent(getContent(type, itemCopy))
                                        }
                                        aria-label={'Email'}
                                    >
                                        <ShareContainer>
                                            <ShareIconContainer background={'#e44c41'}>
                                                <FontIcon aria-hidden={true} role={'presentation'}>
                                                    {palette.ICON_MAIL}
                                                </FontIcon>
                                            </ShareIconContainer>
                                            <ShareText>Email</ShareText>
                                        </ShareContainer>
                                    </Link>
                                </ModalContent>
                            </BasicDialog>
                            <BasicDialog
                                title={ADD_TO_CALENDAR_LABEL}
                                visible={showCalendarModal}
                                onClose={() => setShowCalendarModal(false)}
                                options={[]}
                            >
                                <ModalContent textAlign={'start'} justifycontent={'center'}>
                                    <CalendarContainer>
                                        <AddToCalendarButton
                                            name={item.name}
                                            options={[
                                                'Apple',
                                                'Google',
                                                'iCal',
                                                'Outlook.com',
                                                'Yahoo',
                                            ]}
                                            location={location}
                                            startDate={startDate}
                                            endDate={endDate}
                                            startTime={startTime}
                                            endTime={endTime}
                                            buttonsList
                                            hideTextLabelButton
                                            buttonStyle="round"
                                            lightMode="bodyScheme"
                                            timezone={timezone}
                                        ></AddToCalendarButton>
                                    </CalendarContainer>
                                </ModalContent>
                            </BasicDialog>
                        </>
                    )}
                </InteractivityContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default MoreOptionsShareButton;
