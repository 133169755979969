import React from 'react';
import styled from 'styled-components';

export const CustomTabButton = styled.button`
    background: ${props => (props.isActive ? props.primary + '20' : '#f5f5f5')} !important;
    color: ${props => (props.isActive ? props.primary : 'rgba(0, 0, 0, 0.87)')} !important;
    border-radius: 50px;
    border: 1px solid transparent;
    ${props => props.isActive && `border: 1px solid ${props.primary}`}
    width: fit-content !important;
    flex-grow: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    padding: 8px 16px !important;
    margin: ${props => (props.margin ? props.margin : '0 8px 0 0')} !important;
    height: 40px !important;
    text-transform: unset !important;
    justify-content: center !important;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;

    ${props =>
        props.isActive
            ? ``
            : `
        &:hover {
            background: rgba(0, 0, 0, 0.12) !important;
        }
    `};
`;

export const CustomTab = ({ label, active, ...props }) => (
    <CustomTabButton aria-selected={active} isActive={active} role={'tab'} {...props}>
        {label}
    </CustomTabButton>
);
