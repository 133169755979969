import React, { useState } from 'react';
import { Select, MenuItem, useMediaQuery } from '@mui/material';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';

import * as palette from '../General/Variables';
import { ViewButton } from '../../scenes/Programme/style/style';

const commonStyle = {
    lineHeight: '24px',
    textTransform: 'capitalize',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
};

const hoverStyle = {
    '& .MuiSelect-select:hover': {
        backgroundColor: '#f7f7f7',
    },
};

const CustomDropdown = ({
    value,
    onChange,
    options,
    showIconOnMobile = false,
    displayEmpty = false,
    ariaLabel,
    style = {},
    renderValue,
}) => {
    const isPhone = useMediaQuery('(max-width:767px)');
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div
            role="combobox"
            aria-expanded={open}
            aria-haspopup="listbox"
            aria-label={ariaLabel}
            style={style}
            aria-controls={open ? 'view-select' : undefined}
        >
            <InputLabel id="view-select-label" aria-hidden style={{ display: 'none' }}>
                {ariaLabel}
            </InputLabel>
            {isPhone && showIconOnMobile ? (
                <ViewButton
                    style={{
                        width: 40,
                        padding: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                    onClick={handleClickOpen}
                >
                    <HorizontalSplitIcon />
                </ViewButton>
            ) : (
                <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty={displayEmpty}
                    labelId="view-select-label"
                    id="view-select"
                    style={{
                        backgroundColor: 'transparent',
                        borderRadius: 25,
                        height: 40,
                        minHeight: 40,
                        border: '1px solid #dde1e5',
                        fontWeight: '500',
                    }}
                    renderValue={renderValue}
                    sx={hoverStyle}
                >
                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value} sx={commonStyle}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {isPhone && showIconOnMobile && open && (
                <Paper
                    aria-label={ariaLabel}
                    style={{
                        position: 'absolute',
                        borderColor: palette.COLOR_SECONDARY_MEDIUM,
                        borderWidth: '1px',
                        backgroundColor: palette.COLOR_WHITE,
                        padding: '5px 0',
                        minWidth: 200,
                        right: '50%',
                        transform: 'translateX(50%)',
                        top: 40,
                    }}
                >
                    {options.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => {
                                onChange({ target: { value: option.value } });
                                handleClose();
                            }}
                            sx={{
                                ...commonStyle,
                                backgroundColor:
                                    option.value === value ? 'rgba(0, 0, 0, 0.12)' : undefined,
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default CustomDropdown;
