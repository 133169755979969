import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import useTagFilters from '../../../hooks/useTagFilters';
import { Box, ClickAwayListener, useMediaQuery } from '@mui/material';
import * as palette from '../../../components/General/Variables';
import { useTheme } from '../../../components/Theme/ThemeContext';
import SearchBar from '../../../components/TextInput/SearchBar';
import { getBlackOrWhiteCalculated } from '../../../components/General/Colors';

const menuItemSx = {
    p: '8px 12px 8px 12px',
    height: '40px',
    '& .MuiListItemText-secondary': {
        fontSize: '13px',
        color: palette.COLOR_TEXT_LIGHT,
    },
    justifyItems: 'start',
};

const MAX_ITEMS_DISPLAYED = 10;

const ListPageTagFilter = ({ label, setSelectedFilters, selectedFilters, filterType }) => {
    const { theme } = useTheme();
    const [showMenu, setShowMenu] = useState(false);
    const [search, setSearch] = useState('');
    const [anchorElement, setAnchorElement] = React.useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filtersToBeApplied, setFiltersToBeApplied] = useState([]);
    const tagFilters = useTagFilters(useMemo(() => [filterType], [filterType]));
    const isMobile = useMediaQuery('(max-width:480px)');

    const options = useMemo(() => {
        if (!tagFilters || tagFilters.length === 0) {
            return [];
        }

        return (tagFilters[0]?.options || []).map(o => ({
            label: o.name,
            value: o.id,
        }));
    }, [tagFilters]);

    useEffect(() => {
        if (selectedFilters) {
            setFiltersToBeApplied(selectedFilters);
        }
    }, [selectedFilters]);

    const handleMenuClick = useCallback(
        event => {
            setShowMenu(true);
            setAnchorElement(event.currentTarget);
        },
        [setAnchorElement, setShowMenu],
    );

    const handleClose = useCallback(() => {
        setShowMenu(false);
        setAnchorElement(null);
    }, [setShowMenu, setAnchorElement]);

    const handleItemToggle = useCallback(
        item => {
            let newFilters = [...filtersToBeApplied];
            const isSelected = newFilters.includes(item.value);

            if (isSelected) {
                newFilters = newFilters.filter(t => t !== item.value);
            } else {
                newFilters.push(item.value);
            }

            setFiltersToBeApplied(newFilters);
        },
        [setFiltersToBeApplied, filtersToBeApplied],
    );

    const onSearchChange = text => {
        setSearch(text);
    };

    const apply = useCallback(() => {
        setSelectedFilters(filtersToBeApplied);
        handleClose();
    }, [setSelectedFilters, filtersToBeApplied, handleClose]);

    const clear = useCallback(() => {
        setSelectedFilters([]);
        setSearch('');
    }, []);

    return (
        <>
            <Button
                onClick={handleMenuClick}
                sx={{
                    height: '40px',
                    padding: '10px 16px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 6,
                    width: 'fit-content',
                    minWidth: 'auto',
                    '&:hover': {
                        backgroundColor: palette.UI_GREY_1,
                    },
                    backgroundColor: palette.COLOR_SECONDARY_MEDIUM,
                }}
            >
                <Typography
                    variant="body2"
                    color={palette.BLACK_87}
                    sx={{ fontWeight: '500', lineHeight: '1.6' }}
                    noWrap
                >
                    {label}
                </Typography>
                {selectedFilters.length === 0 ? (
                    showMenu ? (
                        <ArrowDropUpIcon
                            sx={{
                                marginLeft: 0.5,
                                color: palette.BLACK_87,
                            }}
                        />
                    ) : (
                        <ArrowDropDownIcon
                            sx={{
                                marginLeft: 0.5,
                                color: palette.BLACK_87,
                            }}
                        />
                    )
                ) : (
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: theme.contrast,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '8px',
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontWeight="400"
                            fontSize="12px"
                            color={getBlackOrWhiteCalculated(theme.contrast)}
                        >
                            {selectedFilters.length}
                        </Typography>
                    </Box>
                )}
            </Button>
            <Menu
                id={`menu-${label}-filter`}
                open={showMenu}
                anchorEl={anchorElement}
                onClose={handleClose}
                MenuListProps={{
                    sx: {
                        minWidth: isMobile ? '100vw' : '240px',
                        border: '0px solid white',
                        backgroundColor: 'white',
                        p: '4px',
                        ...(isMobile && {
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                        }),
                    },
                }}
                slotProps={{
                    paper: {
                        ...(isMobile && {
                            width: '100%',
                            height: '100%',
                            maxHeight: '100%',
                            maxWidth: '100%',
                            top: '0 !important',
                            left: '0 !important',
                            m: 0,
                        }),
                    },
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <>
                        <Stack>
                            <ListItem
                                sx={{
                                    pl: '8px',
                                    pr: '8px',
                                    pb: '4px',
                                    pt: '4px',
                                    border: '0px solid white',
                                }}
                                onKeyDown={e => e.stopPropagation()}
                                alignItems="flex-start"
                            >
                                <SearchBar value={search} onChange={onSearchChange} />
                            </ListItem>
                            <Divider
                                sx={{
                                    mt: '4px',
                                    mb: '4px',
                                }}
                            />
                        </Stack>
                        {options.slice(0, MAX_ITEMS_DISPLAYED).map((option, index) => {
                            return (
                                <MenuItem
                                    key={`option-tag-${index}`}
                                    sx={menuItemSx}
                                    onClick={() => {
                                        handleItemToggle(option);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={filtersToBeApplied.includes(option.value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': `option-tag-${index}`,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={option.label}
                                        secondary={option.sublabel}
                                    />
                                </MenuItem>
                            );
                        })}

                        <Divider
                            sx={{
                                my: '4px',
                                mx: 1,
                            }}
                        />
                        <Stack direction="row" marginX={1} marginBottom={1} spacing={1}>
                            <Button
                                sx={{ width: '100%', height: '40px' }}
                                variant="contained"
                                onClick={apply}
                            >
                                Apply
                            </Button>
                            <Button
                                sx={{ width: '100%', height: '40px' }}
                                variant="contained"
                                color="secondary"
                                onClick={event => {
                                    clear();
                                    handleClose();
                                }}
                            >
                                Clear
                            </Button>
                        </Stack>
                    </>
                </ClickAwayListener>
            </Menu>
        </>
    );
};

export default ListPageTagFilter;
