import React from 'react';
import { connect } from 'react-redux';

import { hideDigitalBadge as hideDigitalBadgeAction } from '../../actions';
import { TitleContainer, TitleIcon, TitleText } from '../details';
import { getString } from '../../../../services/api/store';

const DigitalBadgeTitle = ({ hideDigitalBadge }) => {
    const TITLE = getString('digitalBadge', 'Digital badge');

    return (
        <TitleContainer>
            <TitleIcon
                aria-hidden={true}
                role={'presentation'}
                iconClassName="material-icons-outlined"
                onClick={e => {
                    e.stopPropagation();
                    hideDigitalBadge();
                }}
            >
                arrow_back
            </TitleIcon>
            <TitleText>{TITLE}</TitleText>
            <TitleIcon hidden />
        </TitleContainer>
    );
};

export default connect(null, {
    hideDigitalBadge: hideDigitalBadgeAction,
})(DigitalBadgeTitle);
