import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button, { buttonTypes } from '../../../common/Button';
import { navigateTo } from '../../../../components/Navigation/NavigationLink';
import Tabs from '../../../../components/Tabs';
import Info from './Info';
import Resources from './Resources';
import Contact from './Contact';
import Schedule from './Schedule';
import * as palette from '../../../../components/General/Variables';
import {
    selectContentData,
    selectFullRepresentatives,
    selectShouldRenderContactTab,
    selectShouldRenderResourcesTab,
    selectShouldRenderScheduleTab,
} from '../../selectors';
import { openExternalLink } from '../../common';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import AppointmentModal, {
    appointmentTypes,
} from '../../../Appointments/containers/AppointmentModal';
import { getString } from '../../../../services/api/store';
import sortBy from 'lodash/sortBy';
import TypeAccessRestrictionsForTabs, {
    tabTypes,
} from '../../../AccessRestrictions/TypeAccessRestrictionsForTabs';
import { ButtonsContainer } from '../../../../components/DetailHeader';
import MoreOptionsShareButton from '../../../../components/General/MoreOptionsShareButton';
import NotesWrapper from '../../../DetailPage/interactivityTab/NotesWrapper';
import { hasNote, makeItem } from '../../../../services/api/data';
import eventBus from '../../../../utils/eventBus';
import Slideshow from '../../containers/Slideshow';
import Modal from '../../containers/Modal';
import InstitutionDetailLinkItem from './InstitutionDetailLinkItem';
import FavoriteButton from '../../../Favorites/containers/FavoriteButton';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 24px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 16px;
`;

const LogoAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.img`
    width: 72px;
    height: auto;
    margin-right: 8px;
`;

const Name = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Intro = styled.div`
    font-family: 'Roboto', sans-serif;
    opacity: 0.87;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
    margin: 16px 0;
`;

const ContentColumn = ({
    content,
    match,
    history,
    location,
    shouldRenderResourcesTab,
    shouldRenderContactTab,
    shouldRenderScheduleTab,
    representatives,
    currentUserGroups,
}) => {
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [tabsData, setTabsData] = useState(false);
    const [updateNote, setUpdateNote] = useState(false);
    const [exhibitor, setExhibitor] = useState({});
    const [isNotesPageDisplayed, setIsNotesPageDisplayed] = useState(false);
    const [showSlideshowModal, setShowSlideshowModal] = useState(false);
    const exhibitorId = match.params?.exhibitorId;

    const { theme } = useTheme();

    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const {
        imageUrl,
        title,
        introText,
        websiteUrl,
        websiteName,
        locations,
        slideshowImages,
    } = content;
    const slideshowData = slideshowImages;
    const shouldRenderRequestMeetingButton = representatives.length > 0;

    useEffect(() => {
        eventBus.on('notesPageOpened', () => setIsNotesPageDisplayed(true));
        eventBus.on('notesPageClosed', () => setIsNotesPageDisplayed(false));
    }, []);

    useEffect(() => {
        update();
    }, [exhibitorId, updateNote]);

    const update = () => {
        makeItem(exhibitorId, 'institution', (err, item) => {
            if (err) {
                console.log(err);
            } else {
                if (item && item.id) {
                    hasNote(item.id, (err, isNote) => {
                        item.hasNote = isNote;
                    });
                    setExhibitor(item);
                }
            }
        });
    };

    useEffect(() => {
        if (!content) {
            return;
        }
        const tabsDataArray = [];

        if (
            content?.info?.text ||
            content?.classifiers?.length ||
            content.videoUrl ||
            content?.slideshowImages?.length
        ) {
            tabsDataArray.push({
                id: 'tabInfoRestrictedGroup',
                label: getString('infoTab', 'Info'),
                content: () => (
                    <TypeAccessRestrictionsForTabs
                        tabType={tabTypes.INFO}
                        typeParams={content.typeParams}
                        currentUserGroups={currentUserGroups}
                    >
                        <Info exhibitor={content} />
                    </TypeAccessRestrictionsForTabs>
                ),
            });
        }

        if (shouldRenderResourcesTab) {
            tabsDataArray.push({
                id: 'tabResourcesRestrictedGroup',
                label: getString('resourcesTab', 'Resources'),
                content: () => (
                    <TypeAccessRestrictionsForTabs
                        tabType={tabTypes.RESOURCES}
                        typeParams={content.typeParams}
                        currentUserGroups={currentUserGroups}
                    >
                        <Resources />
                    </TypeAccessRestrictionsForTabs>
                ),
            });
        }
        if (shouldRenderScheduleTab) {
            tabsDataArray.push({
                id: 'tabScheduleRestrictedGroup',
                label: getString('scheduleTab', 'Schedule'),
                content: () => (
                    <TypeAccessRestrictionsForTabs
                        tabType={tabTypes.SCHEDULE}
                        typeParams={content.typeParams}
                        currentUserGroups={currentUserGroups}
                    >
                        <Schedule item={shouldRenderScheduleTab} matchUrl={match.url} />
                    </TypeAccessRestrictionsForTabs>
                ),
            });
        }
        if (shouldRenderContactTab) {
            tabsDataArray.push({
                id: 'tabContactRestrictedGroup',
                label: getString('contactTab', 'Contact'),
                content: () => (
                    <TypeAccessRestrictionsForTabs
                        tabType={tabTypes.CONTACT}
                        typeParams={content.typeParams}
                        currentUserGroups={currentUserGroups}
                    >
                        <Contact />
                    </TypeAccessRestrictionsForTabs>
                ),
            });
        }

        if (content.typeParams?.tabRestrictionsWithOrder) {
            tabsDataArray.map(tab => {
                const tabWithGivenId = content.typeParams?.tabRestrictionsWithOrder.find(
                    it => it.id === tab.id,
                );
                tab.order = tabWithGivenId?.order;
                tab.label = tabWithGivenId?.name;
                return tab;
            });
        }
        setTabsData(tabsDataArray);
    }, [content]);

    if (!content) {
        return null;
    }

    const requestMeetingButton = (
        <Button
            background={theme.primary}
            type={!isMobile ? buttonTypes.GREEN_LONG : buttonTypes.GREEN}
            text={getString('institutionBoothRequestMeetingButtonTitle') || 'Request meeting'}
            icon="add"
            onClick={() => setShowAppointmentModal(true)}
        />
    );

    return (
        <Container>
            {slideshowData && slideshowData.length > 0 && (
                <Slideshow data={slideshowData} autoplay />
            )}
            <Header style={{ marginTop: slideshowData && slideshowData.length > 0 ? 16 : 0 }}>
                <LogoAndNameWrapper>
                    {imageUrl && <Logo src={imageUrl} alt="Logo" />}
                    <Name>{title}</Name>
                </LogoAndNameWrapper>
                <Intro>{introText}</Intro>
            </Header>

            <LinksContainer>
                {locations.map(({ id, floorplan, name }) => (
                    <InstitutionDetailLinkItem
                        icon="location_on"
                        onClick={() =>
                            navigateTo({
                                match,
                                history,
                                location,
                                path: `/floorplan/${floorplan}/${id}`,
                            })
                        }
                        primary={theme.primary}
                        text={name}
                    />
                ))}
                {websiteUrl && (
                    <InstitutionDetailLinkItem
                        icon="link"
                        onClick={() => openExternalLink(websiteUrl)}
                        primary={theme.primary}
                        text={websiteName || websiteUrl}
                    />
                )}
            </LinksContainer>

            <ButtonsContainer margin={'16px 0'} padding={'0'}>
                {shouldRenderRequestMeetingButton && requestMeetingButton}
                <FavoriteButton item={exhibitor} />
                <MoreOptionsShareButton
                    type={'booth'}
                    item={{ ...exhibitor, locations }}
                    notesTextWithAction={
                        <NotesWrapper
                            item={exhibitor}
                            objectId={exhibitor.id}
                            notesTextWithAction={true}
                            setUpdateNote={setUpdateNote}
                        />
                    }
                    notePage={
                        <NotesWrapper
                            item={exhibitor}
                            objectId={exhibitor.id}
                            notePage={true}
                            setUpdateNote={setUpdateNote}
                        />
                    }
                />
            </ButtonsContainer>

            {exhibitor.hasNote && !isNotesPageDisplayed && (
                <NotesWrapper
                    item={exhibitor}
                    objectId={exhibitor.id}
                    displayNoteCard={true}
                    setUpdateNote={setUpdateNote}
                />
            )}

            {tabsData?.length !== 0 && (
                <Tabs data={sortBy(tabsData, 'order')} useMemo={true} isSmallTab={false} />
            )}

            {showAppointmentModal && (
                <AppointmentModal
                    onClose={() => setShowAppointmentModal(false)}
                    representatives={representatives}
                    type={appointmentTypes.WITH_REPRESENTATIVE}
                />
            )}
            <Modal
                title={title}
                visible={showSlideshowModal}
                onHide={() => setShowSlideshowModal(false)}
                content={<Slideshow data={slideshowData} />}
            />
        </Container>
    );
};

const mapStateToProps = state => ({
    content: selectContentData(state.exhibitorBooth),
    shouldRenderResourcesTab: selectShouldRenderResourcesTab(state.exhibitorBooth),
    shouldRenderContactTab: selectShouldRenderContactTab(state.exhibitorBooth),
    shouldRenderScheduleTab: selectShouldRenderScheduleTab(state.exhibitorBooth),
    representatives: selectFullRepresentatives(state.exhibitorBooth),
});

export default connect(mapStateToProps)(withRouter(ContentColumn));
