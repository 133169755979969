import React from 'react';
import { default as MUISelect } from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import Icon from '@mui/material/Icon';
import * as palette from '../General/Variables';

const formatNow = date => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
};

const FloqSelect = ({
    options,
    groupedOptions,
    value,
    onChange,
    onMouseEnter,
    onMouseLeave,
    label,
    name,
    error,
    disabled = false,
    onFocus,
    onBlur,
    decorationIcon,
    helperText,
    notEmpty,
    datePreview,
    placeholder,
    inputPadding = '',
    small = false,
    withSecondaryLabels = false,
    endAdornment,
    menuPaperStyle,
    hideLabelOnSrink = false,
}) => {
    const onChangeLocal = event => {
        const { value } = event.target;
        onChange(value);
    };

    const isSelected = checkValue => {
        return value === checkValue;
        //return value.indexOf(checkValue) > -1;
    };

    const renderGroup = (key, list) => {
        const items = list.map((option, index) => {
            return (
                <MenuItem
                    style={{ height: '40px', marginRight: '4px' }}
                    key={`select-option-${key}-${index}`}
                    value={option.value}
                >
                    {isSelected(option.value) && (
                        <CheckIcon fontSize="small" style={{ marginRight: '8px' }} />
                    )}
                    {!isSelected(option.value) && (
                        <Icon fontSize="small" style={{ marginRight: '8px' }}></Icon>
                    )}
                    <ListItemText primary={option.label} />
                </MenuItem>
            );
        });
        return [
            <ListSubheader sx={{ color: palette.COLOR_TEXT_DISABLED }}>{key}</ListSubheader>,
            items,
        ];
    };

    const additionalPadding = inputPadding || '16.5px 14px';

    const [firstOption] = options || [];
    let hasEmptyFirstOption = false;
    if (firstOption && firstOption.value === '') {
        hasEmptyFirstOption = true;
    }

    return (
        <Box
            sx={{
                m: 0,
                flexGrow: 1,
                display: 'flex',
            }}
        >
            {decorationIcon && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        mr: '16px',
                        height: '58px',
                    }}
                >
                    {decorationIcon}
                </Box>
            )}
            <FormControl
                variant="filled"
                sx={{ minWidth: 120, width: '100%' }}
                size={label ? undefined : 'small'}
                error={!!error}
            >
                <InputLabel
                    shrink={hideLabelOnSrink ? false : undefined}
                    htmlFor={`select-${label}`}
                    sx={{
                        '&.MuiInputLabel-root':
                            !label || (small && value)
                                ? {
                                      display: 'none',
                                  }
                                : {
                                      top: small ? '-8px' : undefined,
                                  },
                    }}
                >
                    {label}
                </InputLabel>
                <MUISelect
                    displayEmpty={!notEmpty}
                    id={`select-${label}`}
                    labelId={`select-${label}`}
                    name={name}
                    defaultValue=""
                    value={value}
                    onChange={onChangeLocal}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    disabled={disabled}
                    inputProps={{
                        onFocus,
                        onBlur,
                    }}
                    endAdornment={endAdornment ? <Box paddingRight={2}>{endAdornment}</Box> : null}
                    renderValue={selected => {
                        let displayItem;
                        if (groupedOptions) {
                            Object.keys(groupedOptions).forEach(key => {
                                const group = groupedOptions[key];
                                const item = group.find(
                                    op => op.value === selected || op.value === Number(selected),
                                );
                                if (item) {
                                    displayItem = item;
                                }
                            });
                        } else if (options) {
                            const item = options.find(
                                op => op.value === selected || op.value === Number(selected),
                            );
                            displayItem = item;
                        }

                        const display = displayItem ? displayItem.label : selected;

                        if (datePreview && typeof display === 'string') {
                            return (
                                <>
                                    {display}
                                    <span style={{ color: palette.COLOR_TEXT_LIGHT }}>
                                        {' '}
                                        {` - ${formatNow(display)}`}
                                    </span>
                                </>
                            );
                        }

                        if (placeholder && !selected) {
                            return (
                                <span
                                    style={{ color: palette.COLOR_TEXT_LIGHT, lineHeight: '30px' }}
                                >
                                    {placeholder}
                                </span>
                            );
                        }

                        if (withSecondaryLabels && typeof display === 'string') {
                            return (
                                <ListItemText
                                    primaryTypographyProps={{ variant: 'body1', fontWeight: '500' }}
                                    primary={
                                        <>
                                            {display.split(':')[0]}
                                            {!!display.split(':')[1] && (
                                                <span
                                                    style={{
                                                        color: palette.COLOR_TEXT_LIGHT,
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    {' - '}
                                                    {display.split(':')[1]}
                                                </span>
                                            )}
                                        </>
                                    }
                                />
                            );
                        }

                        return display;
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: menuPaperStyle,
                        },
                        MenuListProps: {
                            style: {
                                padding: '4px',
                            },
                        },
                    }}
                    sx={{
                        '& .MuiInputBase-input': {
                            padding: label && !small ? undefined : additionalPadding,
                        },
                        '& .MuiSelect-select': {
                            padding: !small ? undefined : '9px 14px',
                        },
                    }}
                    disableUnderline
                >
                    {!notEmpty && !hasEmptyFirstOption && (
                        <MenuItem value="">
                            <Icon fontSize="small" style={{ marginRight: '8px' }}></Icon>
                            <em>None</em>
                        </MenuItem>
                    )}
                    {options &&
                        options.map((option, index) => (
                            <MenuItem
                                style={{
                                    height: !option.secondaryLabel ? '40px' : '60px',
                                    marginRight: '0px',
                                }}
                                key={`select-option-${index}`}
                                value={option.value}
                            >
                                {isSelected(option.value) && (
                                    <CheckIcon fontSize="small" style={{ marginRight: '8px' }} />
                                )}
                                {!isSelected(option.value) && (
                                    <Icon fontSize="small" style={{ marginRight: '8px' }}></Icon>
                                )}
                                {typeof option.label === 'string' &&
                                    !withSecondaryLabels &&
                                    !option.secondaryLabel && (
                                        <ListItemText primary={option.label} />
                                    )}
                                {typeof option.label === 'string' && withSecondaryLabels && (
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'body1',
                                            fontWeight: '500',
                                        }}
                                        primary={
                                            <>
                                                {option.label.split(':')[0]}
                                                {!!option.label.split(':')[1] && (
                                                    <span
                                                        style={{
                                                            color: palette.COLOR_TEXT_LIGHT,
                                                            fontWeight: '400',
                                                        }}
                                                    >
                                                        {' - '}
                                                        {option.label.split(':')[1]}
                                                    </span>
                                                )}
                                            </>
                                        }
                                    />
                                )}
                                {typeof option.label === 'string' && option.secondaryLabel && (
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'body1',
                                            fontWeight: 'normal',
                                        }}
                                        secondaryTypographyProps={{
                                            variant: 'body2',
                                            color: 'textSecondary',
                                            fontSize: 13,
                                        }}
                                        primary={option.label}
                                        secondary={option.secondaryLabel}
                                    />
                                )}
                                {typeof option.label !== 'string' && <>{option.label}</>}
                            </MenuItem>
                        ))}
                    {groupedOptions &&
                        Object.keys(groupedOptions).map(key => {
                            return renderGroup(key, groupedOptions[key]);
                        })}
                </MUISelect>
                {error && <FormHelperText>{error}</FormHelperText>}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default FloqSelect;
