import React, { useEffect, useState } from 'react';
import { ImageAvatar } from '../Icons';
import ThemeContext from '../Theme/ThemeContext';
import ListCard from '../General/ListCard';
import { getImageSource } from '../../services/api/db';
import largePlaceholderImage from '../../assets/images/placeholder-institution-large.png';
import smallPlaceholderImage from '../../assets/images/placeholder-institution-small.png';
import { StyledListItem } from './style/index';
import { Link } from 'react-router-dom';
import FavoriteButton from '../../scenes/Favorites/containers/FavoriteButton';

const InstitutionListItem = ({
    institution,
    cardView,
    showBookmark = true,
    disabled = false,
    path,
}) => {
    const [cardImage, setCardImage] = useState(null);

    useEffect(() => {
        if (institution.params && institution.params.isLargeCard && institution.params.cardImage) {
            getImageSource(institution.params.cardImage, (err, img) => {
                setCardImage(img);
            });
        }
    }, [institution.id]);

    const highlighted = institution.params && !!institution.params.highlighted;

    if (cardView) {
        const isLargeCard = institution.params && institution.params.isLargeCard;
        const imageUrl = isLargeCard ? cardImage : institution.imageUrl;
        const placeholderImage = isLargeCard ? largePlaceholderImage : smallPlaceholderImage;

        return (
            <ListCard
                key={'institution_' + institution.id}
                primaryText={institution.name}
                secondaryText={institution.subNameList}
                imageUrl={imageUrl || placeholderImage}
                isLogo={!isLargeCard}
                type={'institutions'}
                rightIcon={<FavoriteButton bookmark item={institution} />}
                path={path}
            />
        );
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => {
                return (
                    <StyledListItem
                        {...(path ? { component: Link, to: path } : {})}
                        key={'institution_' + institution.id}
                        primaryText={institution.name}
                        secondaryText={institution.subNameList}
                        highlighted={highlighted ? highlighted.toString() : undefined}
                        color={theme.textSponsor || 'inherit'}
                        disabled={disabled}
                        leftAvatar={
                            <ImageAvatar
                                key={'institution_' + institution.id}
                                imageId={institution.imageUrl || institution.image}
                                avatarType={'institutions'}
                            />
                        }
                    >
                        {showBookmark && <FavoriteButton bookmark item={institution} />}
                    </StyledListItem>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export default InstitutionListItem;
