import React from 'react';
import { Link } from 'react-router-dom';
import { ImageAvatar } from '../Icons';
import ListCard from '../General/ListCard';
import placeholderImage from '../../assets/images/placeholder-person.png';
import { StyledListItem } from './style/index';
import FavoriteButton from '../../scenes/Favorites/containers/FavoriteButton';

const PersonListItem = ({ person, cardView, path }) => {
    if (cardView) {
        return (
            <ListCard
                key={'person_' + person.id}
                primaryText={person.name}
                secondaryText={person.subNameList}
                imageUrl={person.imageUrl || placeholderImage}
                isLogo={true}
                type={'persons'}
                rightIcon={<FavoriteButton bookmark item={person} />}
                path={path}
            />
        );
    }

    return (
        <StyledListItem
            {...(path ? { component: Link, to: path } : {})}
            key={'person_' + person.id}
            primaryText={person.name}
            style={{ position: 'relative' }}
            secondaryText={person.subNameList}
            tabIndex={-1}
            renderChildrenOutside
            fitTextInContainer
            leftAvatar={
                <ImageAvatar
                    imageId={person.imageUrl || person.image}
                    avatarType={'persons'}
                    altText={`${person.name}'s profile picture`}
                />
            }
        >
            <FavoriteButton bookmark item={person} />
        </StyledListItem>
    );
};

export default PersonListItem;
