import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { navigateTo } from '../../../components/Navigation/NavigationLink';
import Auth from '../../../services/api/auth';
import { InteractivityCell, InteractivityGrid } from '../../DetailPage/interactivityTab/style';
import ChatCard from '../components/ChatCard';
import { loadTimeslotChatCardLastMessages as loadTimeslotChatCardLastMessagesAction } from '../actions';
import { getConversationWithRoomId, getTimeslotChatCardLastMessages } from '../selectors';
import AuthUserContext from '../../../components/Session/AuthUserContext';

const GroupChatCardContainer = ({
    itemId,
    conversation,
    messages,
    loadTimeslotChatCardLastMessages,
    match,
    history,
    location,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { showSignIn } = useContext(AuthUserContext);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);

            try {
                await loadTimeslotChatCardLastMessages(itemId);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        if (Auth.isUserAuthenticated()) {
            loadData();
        }
    }, [itemId]);

    const onChatCardClick = () => {
        if (!Auth.isUserAuthenticated()) {
            return showSignIn(null, {
                match,
                history,
                location,
            });
        }

        if (history && itemId) {
            navigateTo({
                match,
                history,
                location,
                params: {
                    type: 'talkRoom',
                    objectId: itemId,
                },
            });
        }
    };

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <ChatCard
                    isLoading={isLoading && !messages.length}
                    messages={messages}
                    error={error}
                    conversation={conversation}
                    clickHandler={onChatCardClick}
                    isGroupChatCard
                />
            </InteractivityCell>
        </InteractivityGrid>
    );
};

const mapStateToProps = (state, ownProps) => {
    let messages = [];
    const conversation = getConversationWithRoomId(state, ownProps.itemId);
    if (conversation) {
        messages = getTimeslotChatCardLastMessages(state, conversation.id);
    }

    return {
        conversation,
        messages,
    };
};

const GroupChatCard = connect(mapStateToProps, {
    loadTimeslotChatCardLastMessages: loadTimeslotChatCardLastMessagesAction,
})(withRouter(GroupChatCardContainer));

export { GroupChatCard };
