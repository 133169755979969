import async from 'async';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import { formatDate, getClassifierWithRelated, sortByStartTime } from '../../../services/api/data';
import { executeListQueryWithCallBack, getItem } from '../../../services/api/graphQlRepository';
import pull from 'lodash/pull';
import get from 'lodash/get';
import { useFavoritesStore } from '../../Favorites/stores/favoriteStore';
import { keyBy } from 'lodash';

const queryMap = {
    classifiers: 'findClassifiers',
    institutions: 'findInstitutions',
    persons: 'findPersons',
    programelements: 'findProgramelements',
    timeslots: 'findTimeslots',
};

const sortNumbersFn = (left, right) => {
    const leftValue = parseInt(left) || 99999999;
    const rightValue = parseInt(right) || 99999999;

    return leftValue - rightValue;
};

const sortStringsFn = (left, right) => {
    const leftValue = left ? left.toString() : '-';
    const rightValue = right ? right.toString() : '-';
    return leftValue.localeCompare(rightValue);
};

const sortDateFn = (left, right) => {
    return new Date(left) - new Date(right);
};

const findSortingFunctionByOptionType = (option, list) => {
    const firstItemWithOrdering = list && list.find(item => item[option]);

    switch (option) {
        case 'start':
            return sortDateFn;
        case 'orderingName':
            if (/^\d+$/.test(firstItemWithOrdering && firstItemWithOrdering[option])) {
                return sortNumbersFn;
            }
            return sortStringsFn;
        default:
            return sortStringsFn;
    }
};

let sortItems = function (list, options) {
    if (options && options.length) {
        const firstSortType = findSortingFunctionByOptionType(options[0], list);
        const secondSortType = findSortingFunctionByOptionType(options[1], list);
        const thirthSortType = findSortingFunctionByOptionType(options[2], list);

        const sortedList = list.sort(
            (a, b) =>
                firstSortType(a[options[0]], b[options[0]]) ||
                secondSortType(a[options[1]], b[options[1]]) ||
                thirthSortType(a[options[2]], b[options[2]]),
        );

        return sortedList;
    }

    return sortByStartTime(list);
};

const parse = input => {
    if (Array.isArray(input)) {
        return input;
    }
    if (typeof input === 'string') {
        try {
            return JSON.parse(input);
        } catch (e) {
            console.log('Parse error:', e);
            return [];
        }
    }

    console.log('Could not parse type:', typeof input);
    return [];
};

const keysToSearchFor = ['name', 'subNameDetail', 'subNameList', 'searchTerms', 'info'];

const searchTermsMatchesData = (obj, searchQuery, keys = keysToSearchFor) => {
    let matches = false;

    const cleanTerm = searchQuery.replace(/([*+?^${}()|[]\/\\])/gi, '').toLowerCase();
    const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');

    for (let i = 0; i < keys.length; i += 1) {
        const str = get(obj, keys[i], '') || '';
        matches =
            matches ||
            queryArray.every(search => str.toLowerCase().indexOf(search.toLowerCase()) !== -1);

        if (matches) {
            break;
        }
    }

    return matches;
};

const filterItems = (items, searchValue) => {
    if (!searchValue) {
        return items;
    }
    return items.filter(item => searchTermsMatchesData(item, searchValue));
};

const executeListQueryForGivenTarget = async (
    target,
    params,
    filterOptions,
    search,
    type,
    callback,
) => {
    const { orderOptions, hasSearch, title } = filterOptions;
    return executeListQueryWithCallBack(queryMap[target], params, (err, result) => {
        // These are grouped by type and classifier
        if (filterOptions.relatedRelatedId) {
            result = filter(result, function (i) {
                return find(parse(i.classifications), function (c) {
                    return filterOptions.relatedRelatedId === c._id;
                });
            });
        }

        if (filterOptions.roleTypeId) {
            result = filter(result, function (r) {
                return find(parse(r.rolesOf), function (f) {
                    return f.type === filterOptions.roleTypeId;
                });
            });

            result = map(result, function (n) {
                n.time = formatDate(n);
                return n;
            });
            const obj = {
                items: filterItems(sortItems(result, orderOptions), search),
                type: type.target.toLowerCase(),
                title: title || 'List',
                hasSearch,
            };
            if (callback) {
                callback(err, obj, filterOptions);
            }
        } else {
            result = map(result, function (n) {
                n.time = formatDate(n);
                return n;
            });
            const obj = {
                items: filterItems(sortItems(result, orderOptions), search),
                type: type.target.toLowerCase(),
                title: title || 'List',
                hasSearch,
            };
            if (callback) {
                callback(err, obj, filterOptions);
            }
        }
    });
};

let getListElements = function (pageId, search, next) {
    let listPage;
    let filterOptions;
    let isStudioPage = false;
    async.waterfall(
        [
            function (callback) {
                if (pageId) {
                    getItem('pages', pageId, callback);
                } else {
                    callback('no pageId found');
                }
            },
            function (page, callback) {
                if (!page) {
                    return callback('list page and list page.params not found');
                }

                listPage = page;
                const { filter, params } = page;
                // Get the filtering options

                filterOptions = params.filter || {};
                if (filter?.typeId) {
                    filterOptions.typeId = filter?.typeId;
                    isStudioPage = true;
                }
                if (filter?.roleTypeId) {
                    filterOptions.roleTypeId = filter?.roleTypeId;
                    isStudioPage = true;
                }
                if (filter?.classifierId) {
                    filterOptions.relatedRelatedId = filter?.classifierId;
                    isStudioPage = true;
                }

                if (filterOptions?.typeId) {
                    getItem('types', filterOptions?.typeId, (err, type) => {
                        callback(err, page, type, null);
                    });
                } else if (filterOptions?.relatedRelatedId) {
                    listPage = page;
                    getClassifierWithRelated(filterOptions?.relatedRelatedId, (err, classifier) => {
                        callback(err, page, null, classifier);
                    });
                } else if (filterOptions?.roleTypeId) {
                    getItem('types', filterOptions?.roleTypeId, (err, type) => {
                        callback(err, page, type, null);
                    });
                } else {
                    callback('list page filter not found');
                }
            },
            function (page, type, classifier, callback) {
                let orderOptions = ['orderingName', 'name'];
                const hasSearch = isStudioPage
                    ? page.hasSearch || false
                    : (page.params && page.params.hasSearch) || false;

                filterOptions.hasSearch = hasSearch;

                // Get the ordering options
                if (
                    listPage &&
                    listPage.params &&
                    listPage.params.filter &&
                    listPage.params.filter.orderBy
                ) {
                    orderOptions = listPage.params.filter.orderBy;
                }

                filterOptions.orderOptions = orderOptions;
                filterOptions.title = listPage.params.title || listPage.title || 'List';

                // Execute the filtering
                if (type && type.singular) {
                    const target = type.target.toLowerCase() + 's';
                    const params = {};
                    if (search) {
                        const cleanTerm = search.replace(/([*+?^${}()|[]\/\\])/gi, '');
                        const queryArray = pull(cleanTerm.split(' '), '', undefined, null, '.');
                        const [firstWord] = queryArray;
                        params.searchTerms = {
                            contains: (firstWord || cleanTerm).toLowerCase(),
                        };
                    }
                    if (filterOptions.roleTypeId && !filterOptions.typeId) {
                        if (!search) {
                            params.searchTerms = {
                                contains: '',
                            };
                        }
                        const targetsWithRoles = ['persons', 'institutions'];

                        executeListQueryForGivenTarget(
                            targetsWithRoles[0],
                            params,
                            filterOptions,
                            search,
                            type,
                            callback,
                        );
                        executeListQueryForGivenTarget(
                            targetsWithRoles[1],
                            params,
                            filterOptions,
                            search,
                            type,
                        );
                    } else {
                        params.type = { eq: type.id };

                        executeListQueryWithCallBack(queryMap[target], params, (err, result) => {
                            // These are grouped by type and classifier
                            if (filterOptions.relatedRelatedId) {
                                result = filter(result, function (i) {
                                    return find(parse(i.classifications), function (c) {
                                        return filterOptions.relatedRelatedId === c._id;
                                    });
                                });
                            }

                            if (filterOptions.roleTypeId) {
                                result = filter(result, function (r) {
                                    return find(parse(r.rolesOf), function (f) {
                                        return f.type === filterOptions.roleTypeId;
                                    });
                                });

                                result = map(result, function (n) {
                                    n.time = formatDate(n);
                                    return n;
                                });
                                const obj = {
                                    items: filterItems(sortItems(result, orderOptions), search),
                                    type: type.target.toLowerCase(),
                                    title: listPage.params.title || 'List',
                                    hasSearch,
                                };
                                callback(err, obj, page);
                            } else {
                                result = map(result, function (n) {
                                    n.time = formatDate(n);
                                    return n;
                                });
                                const obj = {
                                    items: filterItems(sortItems(result, orderOptions), search),
                                    type: type.target.toLowerCase(),
                                    title: listPage.params.title || 'List',
                                    hasSearch,
                                };
                                callback(err, obj, page);
                            }
                        });
                    }
                } else if (classifier) {
                    if (classifier.relatedItems && classifier.relatedItems.length) {
                        const category =
                            classifier.relatedItems && classifier.relatedItems[0].category;
                        const obj = {
                            items: filterItems(
                                sortItems(classifier.relatedItems, orderOptions),
                                search,
                            ),
                            type: category || 'List',
                            title: listPage.params.title || 'List',
                            hasSearch,
                        };
                        callback(null, obj, page);
                    } else {
                        callback(null, null, page);
                    }
                } else {
                    callback('no type found');
                }
            },
        ],
        function (err, result) {
            next(err, result, { ...listPage, isStudioPage });
        },
    );
};

function filterByFavorites(data, callback) {
    if (data && data.length) {
        const { favorites } = useFavoritesStore.getState();
        // index favorites by "objectId"
        const lookup = keyBy(favorites, o => {
            return o.objectId;
        });

        const filteredArray = filter(data, u => {
            return lookup[u.id] !== undefined;
        });
        callback(null, filteredArray);
    } else {
        callback('no data to filter', null);
    }
}

export { getListElements, filterByFavorites };
