import React from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const closeButtonStyles = {
    position: 'absolute',
    right: 12,
    top: 12,
};

const StyledDialogTitle = muiStyled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    paddingTop: 20,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 20,
}));

// To Do: use Material UI Typography with theme
const StyledTitle = styled.p`
    font-size: 21px;
    color: black;
    font-weight: bold;
    font-family: Cabin, sans-serif;
    line-height: 24px;
    margin: 0 24px 0 0 !important;
`;

const CenterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 100%;
    margin: 16px 0;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CenteredTitle = styled(StyledTitle)`
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
`;

const DialogTitle = ({ children, onClose, centered }) =>
    centered ? (
        <CenterWrapper>
            <CenteredTitle>{children}</CenteredTitle>
            {onClose ? (
                <IconButton
                    aria-hidden={true}
                    role={'presentation'}
                    aria-label="close"
                    onClick={onClose}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            onClose();
                        }
                    }}
                    size="large"
                    style={closeButtonStyles}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </CenterWrapper>
    ) : (
        <Wrapper>
            <StyledDialogTitle>
                <StyledTitle>{children}</StyledTitle>
            </StyledDialogTitle>
            {onClose ? (
                <IconButton
                    aria-hidden={true}
                    role={'presentation'}
                    aria-label="close"
                    onClick={onClose}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            onClose();
                        }
                    }}
                    size="large"
                    style={closeButtonStyles}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Wrapper>
    );

export default DialogTitle;
