import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ThemeContext from '../../../../components/Theme/ThemeContext';
import Section from '../../../DetailPage/Section';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { checkCCLinks } from '../../../../helper/CCLink';

const Wrapper = styled.div`
    padding: 16px 0;
    p {
        color: rgba(0, 0, 0, 0.87);
    }
`;

const SectionTitle = styled.h5`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
`;

const SectionDescription = styled.div`
    margin: 0 16px;
    &.hidden {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

const StyledButton = styled.div`
    cursor: pointer;
    margin: 8px 16px;
    color: ${props => props.contrastcolor};
`;

const InfoList = styled(ExpansionList)`
    margin-top: 8px;
    .md-expansion-panel--expanded:not(:first-child) {
        margin-top: -8px;
    }
`;

const InfoWrapper = styled(ExpansionPanel)`
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 20px;
    list-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-width: 100%;
    overflow: wrap;
    body,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a {
        color: #000000;
    }
    body,
    p,
    a {
        font-size: 14px;
    }
    a {
        text-decoration: underline;
    }
    img {
        max-width: 100% !important;
    }
    table {
        border: 1px solid #dddddd;
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        padding: 8px;
    }
    .md-text {
        font-family: 'Cabin', sans-serif;
        font-weight: bold;
        letter-spacing: 0.5px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        text-transform: uppercase;
    }

    .md-panel-header,
    .md-panel-content {
        padding-left: 16px;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);
    }
`;

const Session = ({ sections, info, typeParams, match, history, location, isOnDemand }) => {
    const [readAll, setReadAll] = useState(false);
    const showMore = info.text && info.text.length > 1200;
    const shortText = info.text && info.text.substring(0, 1200);
    const longText = info.text && info.text.substring(1200, info.text.length);
    const infoBar = React.createRef();

    let text = shortText;
    if (readAll) {
        text = shortText + longText;
    }

    const compiledSections = sections
        .filter(s => !isOnDemand || (isOnDemand && s.type !== 'person'))
        .map(sec => {
            let key = sec.type.replace(' ', '') + '_' + sec.title.replace(' ', '');
            return (
                <Section
                    key={key}
                    icon={sec.icon}
                    type={sec.type}
                    title={sec.title}
                    items={sec.items}
                    fromVirtualSession
                />
            );
        });

    const update = (toggle, div) => {
        if (toggle) {
            setTimeout(() => {
                checkCCLinks(div.current, match, history, location);
            }, 0);
        }
    };

    const renderList = theme => (
        <div ref={infoBar}>
            <Wrapper>
                {info.text && (
                    <InfoList>
                        {typeParams &&
                        typeParams.previewType &&
                        typeParams.previewType === 'preview' ? (
                            <>
                                <SectionTitle secondary={theme.secondary}>
                                    {info.title}
                                </SectionTitle>
                                <SectionDescription className={readAll ? '' : 'hidden'}>
                                    {ReactHtmlParser(text)}
                                </SectionDescription>
                                {showMore && (
                                    <StyledButton
                                        contrastcolor={theme.contrast}
                                        onClick={() => {
                                            setReadAll(!readAll);
                                        }}
                                    >
                                        Read {!readAll ? 'More' : 'Less'}
                                    </StyledButton>
                                )}
                            </>
                        ) : (
                            <>
                                <InfoWrapper
                                    label={info.title}
                                    footer={null}
                                    onExpandToggle={e => update(e, infoBar)}
                                >
                                    <div>{ReactHtmlParser(info.text)}</div>
                                </InfoWrapper>
                            </>
                        )}
                    </InfoList>
                )}
                {compiledSections}
            </Wrapper>
        </div>
    );

    return <ThemeContext.Consumer>{({ theme }) => renderList(theme)}</ThemeContext.Consumer>;
};

export default withRouter(Session);
