import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/min/locales.min';
import { SearchContainer, Timezone, TimezoneInRuler } from './style/style';
import { useLocation, withRouter } from 'react-router-dom';

import Filter from '../../components/Filter';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';
import ThemeContext from '../../components/Theme/ThemeContext';
import { WithTimezone } from '../Timezone/context';
import { getString } from '../../services/api/store.js';
import ListView from './components/ListView';
import Timetable from './components/timetable';
import { getDays, getSessionsFromDay, getFilterTypes, filterProgram } from './services/programme';
import { getItemAsync } from '../../services/api/graphQlRepository';
import AccessRestrictions from '../AccessRestrictions';
import ProgrammeTabsAndToolbar from './components/ProgrammeTabsAndToolbar';
import { programmeViewTypes } from './constants/index';
import VerticalTimetable from './components/verticalTimetable';
import BrandingBanner from '../../components/General/BrandingBanner';
import isEmpty from 'lodash/isEmpty';
import { getLocalAppStateAsync } from '../../services/api/db';
import { CustomTab } from './components/CustomTab';

const Programme = props => {
    const [tabsData, setTabsData] = useState([]);
    const [displaySessions, setDisplaySessions] = useState({ elements: [], timerange: {} });
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasStudioReleases, setHasStudioReleases] = useState(false);
    const [filters, setFilters] = useState([]);
    const [page, setPage] = useState({});
    const [filterTypes, setFilterTypes] = useState([]);
    const [grouping, setGrouping] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [viewType, setViewType] = useState(programmeViewTypes.HORIZONTAL_VIEW);
    const [banner, setBanner] = useState(null);
    const [titleOnBanner, setTitleOnBanner] = useState(null);
    const [subtitleOnBanner, setSubtitleOnBanner] = useState(null);
    const { timezone, pageId, pageName } = props;
    const { selectedTimezone, getUtcToSelectedTimezone, getTimeslotUtc } = timezone;
    const { search } = useLocation();
    const allSesionsRef = useRef({});

    const queryParams = new URLSearchParams(search);

    useEffect(() => {
        if (!pageId) {
            return;
        }
        getPageInfo();
        if (props.disableScroll) {
            props.disableScroll(true);
        }

        return () => {
            if (props.disableScroll) {
                props.disableScroll(false);
            }
            if (props.handleSidepanel) {
                props.handleSidepanel(false);
            }
        };
    }, [pageId, selectedTimezone, pageName]);

    useEffect(() => {
        if (!(page && page.id)) {
            return;
        }
        allSesionsRef.current = {};
        getTabsInfo(page);
    }, [page]);

    const hasDetailOpen = () => {
        return queryParams.has('type') || queryParams.has('pageType');
    };

    useEffect(() => {
        if (props.handleSidepanel) {
            props.handleSidepanel(banner ? false : hasDetailOpen() || showFilter);
        }

        if (hasDetailOpen()) {
            setShowFilter(false);
        }
    }, [search, banner]);

    const getDayIndex = (days, inputDate) => {
        let date = getUtcToSelectedTimezone(inputDate || moment.utc());
        return days.findIndex(day => {
            const dayDate = getUtcToSelectedTimezone(day.start);
            return dayDate.isSame(date, 'day');
        });
    };

    const getPageInfo = async () => {
        const programmePage = await getItemAsync('pages', pageId);
        let isStudioPage = false;
        if (programmePage?.filter?.typeId) {
            isStudioPage = true;
        }

        const schedule = isStudioPage
            ? programmePage?.filter?.typeId
            : programmePage?.params?.filter?.typeId;

        const bannerImage = isStudioPage
            ? programmePage?.pageBanner
            : programmePage?.params?.bannerImage;

        if (bannerImage) {
            setBanner(bannerImage);
        }

        const layout = isStudioPage ? programmePage?.webLayout : programmePage.params?.webLayout;

        if (layout) {
            if (layout.includes('horizontal')) {
                setViewType(programmeViewTypes.HORIZONTAL_VIEW);
            } else if (layout.includes('vertical')) {
                setViewType(programmeViewTypes.VERTICAL_VIEW);
            } else if (layout.includes('list')) {
                setViewType(programmeViewTypes.LIST_VIEW);
            }
        }

        const showPageNameInBanner = isStudioPage
            ? programmePage?.showPageNameInBanner
            : programmePage?.params?.showPageNameInBanner;

        const title = isStudioPage ? programmePage?.title : programmePage?.params?.title;
        const subtitle = isStudioPage ? programmePage?.subtitle : programmePage?.params?.subtitle;
        let groupingOption = isStudioPage
            ? programmePage.groupBy
            : programmePage.params?.groupingOption;

        const groupingValue = isStudioPage
            ? programmePage.groupByTagType
            : programmePage.params?.groupingValue;

        if (groupingOption === 'place') {
            groupingOption = 'byPlace';
        }

        if (groupingOption === 'tag') {
            groupingOption = 'byClassifier';
        }

        if (!groupingOption) {
            groupingOption = 'noGrouping';
        }

        setGrouping({
            groupingOption: groupingOption,
            groupingValue: groupingValue,
        });

        if (showPageNameInBanner) {
            setTitleOnBanner(title);
            setSubtitleOnBanner(subtitle);
            props.setTitle('');
        } else {
            props.setTitle();
        }

        const programmePageData = { ...programmePage };
        programmePageData.isStudioPage = isStudioPage;
        programmePageData.groupingOption = groupingOption;
        programmePageData.groupingValue = groupingValue;
        programmePageData.schedule = schedule;
        programmePageData.classifierId = isStudioPage
            ? programmePage?.filter?.classifierId
            : programmePage?.params?.filter?.classifierId;

        programmePageData.skipTimeframes = page?.params?.skipTimeframes || false;

        setPage(programmePageData);
    };

    const getTabsInfo = async programmePage => {
        const { hasStudioReleases } = await getLocalAppStateAsync();
        setHasStudioReleases(hasStudioReleases);

        const days = await getDays(
            programmePage.schedule,
            hasStudioReleases,
            getUtcToSelectedTimezone,
        );

        const webFilters = programmePage.isStudioPage
            ? programmePage?.webappfilters
            : programmePage?.params?.webappfilters;

        const filterTypes = await getFilterTypes(webFilters);
        let program = days.map(day => {
            return {
                day: {
                    start: day.start,
                    id: day.id,
                    name: day.name,
                },
                elements: [],
                timerange: {},
            };
        });

        setTabsData(program);
        setFilterTypes(filterTypes);

        const type = queryParams.get('type');
        const objectClass = queryParams.get('objectClass');
        const objectId = queryParams.get('objectId');

        // Find correct tab for an opened session
        if (type && objectClass && objectId) {
            let timeslot = await getItemAsync('timeslots', objectId);
            if (timeslot) {
                const foundIndex = getDayIndex(days, timeslot.start);
                const active = foundIndex === -1 ? activeTab : foundIndex;

                setActiveTab(active);
            }
        } else {
            const dayIndex = getDayIndex(days, null);
            const active = dayIndex === -1 ? 0 : dayIndex;
            setActiveTab(active);
        }
    };

    const skipTimeframes = page?.skipTimeframes;

    const displayFilter = show => {
        setShowFilter(show);
        if (props.handleSidepanel) {
            props.handleSidepanel(banner ? false : show);
        }
        if (show) {
            props.history.push(props.match.url);
        }
    };

    const onTabClick = indx => {
        setActiveTab(indx);
        //filterCallback(filters, indx);
    };

    const getAllSessions = (day, cb) => {
        if (allSesionsRef.current[day.id] && allSesionsRef.current[day.id].sessions) {
            const { sessions, timerange } = allSesionsRef.current[day.id];
            cb(null, {
                sessions,
                timerange,
            });
        } else {
            getSessionsFromDay(
                page,
                day,
                hasStudioReleases,
                getUtcToSelectedTimezone,
                (err, sessions, timerange) => {
                    allSesionsRef.current[day.id] = { sessions, timerange };
                    cb(null, {
                        sessions,
                        timerange,
                    });
                },
            );
        }
    };

    useEffect(() => {
        if (tabsData.length > 0) {
            setLoading(true);
            const { day } = tabsData[activeTab];
            if (isEmpty(day)) {
                return;
            }
            filterCallback(filters);
        }
    }, [activeTab, tabsData]);

    const applyFilters = (filter, data, favorites, markedAsFavorites) => {
        return filterProgram(filter, data, favorites, markedAsFavorites);
    };

    const changeViewType = type => {
        onTabClick(activeTab);
        setViewType(type);
    };

    const filterCallback = (filter, forcedIndex, forcedData) => {
        setLoading(true);
        let indx = forcedIndex;
        if (indx === null || indx === undefined) {
            indx = activeTab;
        }

        const { day } = tabsData[indx];
        if (isEmpty(day)) {
            return;
        }

        getAllSessions(day, (err, data) => {
            const { sessions, timerange } = data;
            applyFilters(filter, sessions).then(result => {
                setDisplaySessions({
                    elements: result,
                    timerange,
                });
                setFilters(filter);
                setLoading(false);
            });
        });
    };

    const searchCallback = text => {
        filterCallback({
            ...filters,
            text,
        });
    };

    const renderProgramme = theme => {
        let dateSettings = getString('datetime');
        let datePattern =
            dateSettings && dateSettings.dayMonthDateFormat
                ? dateSettings.dayMonthDateFormat
                : 'D MMMM';
        let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
        let activeTabIndex = activeTab;
        const tabs = tabsData.map((tab, index) => {
            const elem = tab.day;
            let day = moment.utc(elem.start);
            day.locale(locale);

            let label = elem.name + ' ' + day.format(datePattern);

            if (hasStudioReleases) {
                label = getUtcToSelectedTimezone(elem.start, `dddd ${datePattern}`, false, locale);
            }

            return (
                <CustomTab
                    key={`${elem.name}-${elem.id}`}
                    label={label}
                    primary={theme.primary}
                    active={activeTabIndex === index}
                    onClick={() => onTabClick(index)}
                />
            );
        });

        const elements = displaySessions.elements || [];
        const timerange = displaySessions.timerange || {};

        const renderTimezoneInfo = () => {
            const { getTimezoneLabel, showTimezoneSelectDialog } = props.timezone;
            if (viewType === programmeViewTypes.LIST_VIEW)
                return <Timezone onClick={showTimezoneSelectDialog}>{getTimezoneLabel()}</Timezone>;
            return (
                <TimezoneInRuler onClick={showTimezoneSelectDialog} viewType={viewType}>
                    {getTimezoneLabel()}
                </TimezoneInRuler>
            );
        };

        const renderTimetable = handleClickOutside => (
            <Timetable
                items={elements}
                rangeInterval={timerange}
                filters={filters}
                selectedTimezone={selectedTimezone}
                renderTimezoneInfo={renderTimezoneInfo}
                handleClickOutside={handleClickOutside}
                activeTab={activeTab}
                getUtcToSelectedTimezone={getUtcToSelectedTimezone}
                getTimeslotUtc={getTimeslotUtc}
            />
        );

        const renderVerticalTimetable = handleClickOutside => (
            <VerticalTimetable
                items={elements}
                rangeInterval={timerange}
                filters={filters}
                selectedTimezone={selectedTimezone}
                renderTimezoneInfo={renderTimezoneInfo}
                activeTab={activeTab}
                handleClickOutside={handleClickOutside}
                getUtcToSelectedTimezone={getUtcToSelectedTimezone}
                getTimeslotUtc={getTimeslotUtc}
            />
        );

        const favoriteFilter = myProgram => {
            filterCallback({
                ...filters,
                myProgram,
            });
        };

        const getNumberOfAppliedFilters = () => {
            if (filters && filters.filters) {
                const filter = filters.filters;
                let count = 0;
                Object.keys(filter).forEach(key => (count += filter[key].length));
                return count;
            }
        };

        const renderListView = () => {
            const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

            let filterContainers = [
                <SearchContainer key="1" size={12}>
                    <Search
                        action={text => {
                            searchCallback(text);
                        }}
                        live={true}
                        skey={pageId}
                    />
                </SearchContainer>,
            ];

            if (!isMobile) {
                filterContainers.reverse();
            }

            return (
                <ListView
                    items={elements}
                    range={timerange}
                    filters={filters}
                    groupingOption={grouping.groupingOption}
                />
            );
        };

        return (
            <ProgrammeTabsAndToolbar
                tabs={tabs}
                activeTab={activeTab}
                loading={loading}
                skipTimeframes={skipTimeframes}
                renderListView={renderListView}
                renderTimetable={renderTimetable}
                renderTimezoneInfo={renderTimezoneInfo}
                renderVerticalTimetable={renderVerticalTimetable}
                action={filterCallback}
                selectedFilters={filters}
                viewType={viewType}
                changeViewType={changeViewType}
                setShowFilter={() => displayFilter(!showFilter)}
                isSidepanelOpened={hasDetailOpen() || showFilter}
                numberOfAppliedFilters={getNumberOfAppliedFilters()}
            />
        );
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <AccessRestrictions pageId={pageId}>
                        {showFilter && (
                            <Filter
                                action={filterCallback}
                                selectedFilters={filters}
                                filterTypes={filterTypes}
                                closeFilter={() => displayFilter(false)}
                            />
                        )}
                        <BrandingBanner
                            notList={viewType !== programmeViewTypes.LIST_VIEW}
                            banner={banner}
                            viewType={viewType}
                            navigationType={props.navigationType}
                            title={titleOnBanner}
                            subtitle={subtitleOnBanner}
                            withDynamicMarginTop={true}
                            hideOnScroll={viewType !== programmeViewTypes.LIST_VIEW}
                        >
                            {tabsData.length > 0 && renderProgramme(theme)}
                        </BrandingBanner>
                    </AccessRestrictions>
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(WithTimezone(Programme));
