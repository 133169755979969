import React, { useState, useEffect, useRef } from 'react';
import FloqTabs from '../../../components/Tabs/FloqTabs';
import InstitutionInfoTab from '../components/InstitutionInfoTab';
import { default as MUIButton } from '@mui/material/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
    useInstitutionProposalsStore,
    useProposalByInstitutionId,
} from '../../../stores/InstitutionProposalStore';
import { useInstitutionById } from '../../../stores/InstitutionsStore';
import Loader from '../../../components/General/Loader';
import { Formik, useFormikContext } from 'formik';
import {
    updateInstitutionProposalData,
    updateInstitutionProposalStatus,
} from '../../../services/api/eureka';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import toaster from 'toasted-notes';
import NotificationToast from '../../../components/General/NotificationToast';
import Stack from '@mui/material/Stack';
import { useEventStore } from '../../../stores/EventStore';
import isUrl from 'is-url';
import { isIdUUID } from '../components/InstitutionContentResourcesTab';
export const tabs = [
    {
        label: 'Info',
        component: <InstitutionInfoTab />,
    },
    // {
    //     label: 'Meetings',
    //     component: <div>Meetings</div>,
    // },
    // {
    //     label: 'Leads',
    //     component: <div>Leads</div>,
    // },
    // {
    //     label: 'Analytics',
    //     component: <div>Analytics</div>,
    // },
];

const setFormikValues = (data, institution) => ({
    name: data?.name || institution?.name || '',
    subNameList: data?.subNameList || institution?.subNameList || '',
    imageUrl: data?.imageUrl || institution?.imageUrl || '',
    orderingName: data?.orderingName || institution?.orderingName || '',
    subNameDetail: data?.subNameDetail || institution?.subNameDetail || '',
    info: data?.info || institution?.info || '',
    cardImageUrl: data?.cardImageUrl || institution?.cardImageUrl || '',
    backgroundImageUrl: data?.backgroundImageUrl || institution?.backgroundImageUrl || '',
    bannerImageUrl: data?.bannerImageUrl || institution?.bannerImageUrl || '',
    websiteUrl: data?.websiteUrl || institution?.websiteUrl || '',
    websiteName: data?.websiteName || institution?.websiteName || '',
    videoUrl: data?.videoUrl || institution?.videoUrl || '',
    hasBusinessCardFeature:
        data?.hasBusinessCardFeature || institution?.hasBusinessCardFeature || false,
    businessCardEmail: data?.businessCardEmail || institution?.businessCardEmail || '',
    contactPhoneNumber: data?.contactPhoneNumber || institution?.contactPhoneNumber || '',
    contactEmail: data?.contactEmail || institution?.contactEmail || '',
    contactFacebookUrl: data?.contactFacebookUrl || institution?.contactFacebookUrl || '',
    contactTwitterUrl: data?.contactTwitterUrl || institution?.contactTwitterUrl || '',
    contactLinkedinUrl: data?.contactLinkedinUrl || institution?.contactLinkedinUrl || '',
    links: [
        ...(data?.links || []),
        ...(institution?.links || []).map(link => ({
            _id: link._id || '',
            url: link.url || '',
            name: link.name || '',
            mimeType: link.mimeType || '',
        })),
    ],
    slideshow: [
        ...(data?.slideshow || []),
        ...(institution?.slideshow || []).map(slide => ({
            _id: slide._id || '',
            imageUrl: slide.imageUrl || '',
            url: slide.url || '',
            alt: slide.alt || '',
        })),
    ],
});

const onValidate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Enter full name';
    }

    const urlError = 'Enter a valid URL starting with http:// or https://.';
    const urls = [
        'websiteUrl',
        'videoUrl',
        'contactFacebookUrl',
        'contactTwitterUrl',
        'contactLinkedinUrl',
    ];

    urls.forEach(url => {
        const value = values[url];
        if (value && !isUrl(value)) {
            errors[url] = urlError;
        }
    });

    if (values.contactPhoneNumber) {
        const parsed = values.contactPhoneNumber.split(' ').join('');
        if (parsed && !/^\+?\d+$/.test(parsed)) {
            errors.contactPhoneNumber = 'Enter a phone number that contains only numbers.';
        }
    }

    if (values.hasBusinessCardFeature && !values.businessCardEmail) {
        errors.businessCardEmail = 'Enter a business card email.';
    }

    if (values.contactEmail) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!values.contactEmail.match(emailRegex)) {
            errors.contactEmail = 'Enter a valid email address.';
        }
    }

    return errors;
};

const InstitutionProposal = () => {
    const [loading, setLoading] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const history = useHistory();
    const { institutionId } = useParams();
    const eventId = useEventStore(state => state.id);
    const setTitle = useEventStore(state => state.setTitle);

    const proposal = useProposalByInstitutionId(institutionId);
    const updateProposal = useInstitutionProposalsStore(state => state.updateProposal);
    const institution = useInstitutionById(institutionId);
    const fetchProposals = useInstitutionProposalsStore(state => state.fetchProposals);
    const submitAfterSaveRef = useRef(false);

    useEffect(() => {
        console.log('LOGGER institution', institution);
        if (institution) {
            if (!institution.allowEditByRepresentative) {
                history.push(`../exhibitordashboards`);
            }
            setTitle(institution.name);
        }
    }, [institution]);

    useEffect(() => {
        if (!institutionId) {
            history.goBack();
        }
        const fetchData = async () => {
            setLoading(true);
            await fetchProposals(eventId);
            setLoading(false);
        };
        fetchData();
    }, [institutionId, eventId]);

    const handleSubmit = async (values, submitProps) => {
        try {
            await updateInstitutionProposalStatus(proposal.id, {
                status: 'draft',
            });
            let data = pickBy(values, identity);
            data.links = data.links
                .filter(link => isIdUUID(link._id))
                .map(link => pickBy(link, identity));

            data.slideshow = data.slideshow
                .filter(slide => isIdUUID(slide._id))
                .map(slide => pickBy(slide, identity));
            const resp = await updateInstitutionProposalData(proposal.id, data);
            if (submitAfterSaveRef.current) {
                const resp = await handleStatusSubmitted();
                submitAfterSaveRef.current = false;
                submitProps.resetForm({ values: setFormikValues(resp.data, institution) });
                return;
            }
            if (resp.data) {
                submitProps.resetForm({ values: setFormikValues(resp.data, institution) });
                updateProposal(proposal.id, resp);
            }
        } catch (error) {
            console.error('Error updating institution:', error);
        }
    };

    const handleStatusSubmitted = async () => {
        const resp = await updateInstitutionProposalStatus(proposal.id, {
            status: 'submitted',
        });

        if (resp) {
            updateProposal(proposal.id, resp);
        }

        toaster.notify(
            () => (
                <NotificationToast
                    title="Submitted for approval"
                    subtitle="Your changes are reviewed by the event organiser and will be published to attendees after they are approved."
                    icon="check"
                />
            ),
            {
                position: 'top-right',
                duration: 5000,
            },
        );
        return resp;
    };

    const onTabChange = index => {
        setActiveTabIndex(index);
    };

    if (!proposal || !institution || loading) {
        return <Loader />;
    }

    if (!institution.allowEditByRepresentative) {
        return null;
    }

    return (
        <Formik
            initialValues={setFormikValues(proposal.data, institution)}
            onSubmit={handleSubmit}
            validate={onValidate}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <FloqTabs
                        data={tabs}
                        activeIndex={activeTabIndex}
                        onChange={onTabChange}
                        sticky
                        TabBarRightComponent={
                            <SubmitSection
                                onSubmitButtonPress={handleStatusSubmitted}
                                proposal={proposal}
                                submitAfterSaveRef={submitAfterSaveRef}
                            />
                        }
                    />
                </form>
            )}
        </Formik>
    );
};

const SubmitSection = ({ proposal, submitAfterSaveRef }) => {
    const formik = useFormikContext();
    const [saveLoading, setSaveLoading] = useState(false);
    const { handleSubmit: onSave } = formik;

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            justifyContent="center"
            paddingTop={0.5}
        >
            <MUIButton
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ height: 40, marginTop: 0.5 }}
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                onClick={async () => {
                    setSaveLoading(true);
                    await onSave();
                    setSaveLoading(false);
                }}
            >
                {saveLoading ? <CircularProgress size={20} /> : 'Save'}
            </MUIButton>
            <MUIButton
                variant="contained"
                sx={{ height: 40, marginTop: 0.5 }}
                disabled={(proposal.status !== 'draft' && !formik.dirty) || !formik.isValid}
                onClick={async () => {
                    submitAfterSaveRef.current = true;
                    await onSave();
                }}
            >
                {formik.isSubmitting ? <CircularProgress size={20} /> : 'Submit for approval'}
            </MUIButton>
        </Stack>
    );
};

export default InstitutionProposal;
