import React, { useContext, useMemo } from 'react';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import { withRouter } from 'react-router-dom';
import { useCheckinStore } from '../stores/checkinStore';
import { getLocalAppStateAsync } from '../../../services/api/db';

const CheckinButton = ({ item, style, match, history, location }) => {
    const { checkIns, updateCheckIn, loading } = useCheckinStore();
    const { theme } = useTheme();
    const { authUser, showSignIn } = useContext(AuthUserContext);

    const CHECKIN_TEXT = getString('checkIn', 'Check in');
    const CHECKOUT_TEXT = getString('checkOut', 'Checked in');

    const isCheckedIn = useMemo(() => {
        const found = checkIns.find(ci => ci.objectId === item.id);
        return found && found.objectId;
    }, [checkIns, item]);

    const onClick = async e => {
        if (authUser) {
            console.log(item);
            const currentEvent = await getLocalAppStateAsync();
            updateCheckIn(currentEvent.id, item);
        } else {
            showSignIn(e, {
                match,
                history,
                location,
            });
        }
    };

    const text = isCheckedIn ? CHECKOUT_TEXT : CHECKIN_TEXT;

    return (
        <Button
            text={text}
            type={isCheckedIn ? buttonTypes.GREEN_LONG_REVERSE : buttonTypes.GREY_LONG}
            background={theme.primary}
            iconCustomColor={isCheckedIn && theme.primary}
            icon="beenhere"
            outlinedIcon={!isCheckedIn}
            enabled={!loading}
            onClick={onClick}
            style={style}
        />
    );
};

export default withRouter(CheckinButton);
