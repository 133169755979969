import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as palette from '../../../../components/General/Variables';
import AuthUserContext from '../../../../components/Session/AuthUserContext';
import Header from '../../components/common/Header';
import { getTimeslot } from '../../services/session';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import {
    getFullProfile,
    getVirtualEventPollSets,
    getVirtualEventSession,
    getVirtualEventToken,
    removeVirtualEventToken,
    updateVirtualEventToken,
} from '../../../../services/api/eureka';
import { EUREKA_TALK_HOST } from '../../../../config';

import { ContentContainer, Wrapper } from '../../styles';
import Auth from '../../../../services/api/auth';
import WarningDialog from '../../../../components/Dialog/WarningDialog';
import { isUserBlockedBySessionIdAndUserId } from '../../services/virtualEventBlockedUsers';
import { goBackFromVirtualSession } from '../../components/common/goBack';
import AnalyticsService from '../../../../features/analytics/services';
import NotificationService from '../../../Notifications/services/NotificationService';
import MainContent from './mainContent/MainContent';
import SideContent from './sideContent/SideContent';
import useStreamHandling from '../../hooks/useStreamHandling';
import { notificationTypes } from '../../../Notifications/constants';
import VirtualWrapper from '../common/VirtualWrapper';
import { parseVirtualEventSession } from '../../../../utils/sortUtils';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';
import AccessDeniedModal from '../../../AccessRestrictions/AccessDeniedModal';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../../../AccessRestrictions/utils';
import useExpertAchievementTimeTracking from '../../../Achievements/hooks/useExpertAchievementTimeTracking';
import LoadingVirtualPage from '../common/LoadingVirtualPage';

const ModeratedSession = props => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { authUser, match, history, location } = props;
    const {
        localClient,
        socket,
        timeslot,
        virtualEventSession,
        currentVirtualEventUser,
    } = stateCtx;
    const { timeslotId } = match.params;
    const [blockedUser, setBlockedUser] = useState(false);
    const [restrictedUser, setRestrictedUser] = useState(false);
    const [restrictionChecked, setRestrictionChecked] = useState(false);
    const [isVotingActive, setIsVotingActive] = useState(false);
    const virtualEventUserInfo = useRef(null);

    const { trackAchievement } = useAchievementActions();
    useExpertAchievementTimeTracking(timeslot);

    const sessionId = timeslotId;
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const user = Auth.getUser();
    const isBroadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
    const isOnDemand = timeslot?.params?.virtualSession === 'on-demand';

    const addViewSample = timeslotId => {
        if (!isOnDemand && isBroadcasting) {
            AnalyticsService.addSample('viewVirtualRoom', 'virtual-session', timeslotId);
        }
    };

    const refreshSession = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);

        if (!virtualEventSession || !virtualEventSession.VirtualEventUsers) {
            return false;
        }

        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
        );

        mutationCtx.setVirtualEventSession(virtualEventSession);

        if (virtualEventUserActive) {
            mutationCtx.setVirtualEventUser(virtualEventUserActive);
        } else {
            mutationCtx.setVirtualEventUser(null);
        }

        return true;
    };

    const updateData = data => {
        const newState = {};
        const { virtualEventSession } = data;

        if (virtualEventSession) {
            const parsedVirtualEventSession = parseVirtualEventSession(virtualEventSession);
            const virtualEventUsers = parsedVirtualEventSession.VirtualEventUsers;
            const virtualEventUserActive = virtualEventUsers.find(
                virtualEventUser => virtualEventUser.isActive || virtualEventUser.hasVideoActive,
            );

            newState.virtualEventSession = parsedVirtualEventSession;

            if (virtualEventUserActive) {
                newState.virtualEventUser = virtualEventUserActive;
            } else {
                newState.virtualEventUser = null;
            }
        }

        mutationCtx.setExtraState(newState);
    };

    const refreshPoll = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);
        const pollSets = await getVirtualEventPollSets(virtualEventSession.ExternalObjectId);
        const virtualEventUserActive = virtualEventSession.VirtualEventUsers.find(
            virtualEventUser => virtualEventUser.isActive,
        );

        mutationCtx.setVirtualEventSession(virtualEventSession);

        if (virtualEventUserActive) {
            mutationCtx.setPollSets(pollSets);
        }
    };

    const fetchPollSets = async () => {
        const virtualEventSession = await getVirtualEventSession(timeslotId);

        if (!virtualEventSession) {
            return;
        }

        try {
            const fetchedPollSets = await getVirtualEventPollSets(
                virtualEventSession.ExternalObjectId,
            );

            mutationCtx.setPollSets(fetchedPollSets);
        } catch (err) {
            console.log('PollSets fetch error', err);
        }
    };

    useEffect(() => {
        (async () => {
            const virtualEventSession = await getVirtualEventSession(timeslotId);

            if (!virtualEventSession || !virtualEventSession.id || !user || !user.id) {
                console.log(`session: ${get(virtualEventSession, 'id')}; user: ${get(user, 'id')}`);
                return;
            }

            const { blocked } = await isUserBlockedBySessionIdAndUserId(
                virtualEventSession.id,
                user.id,
            );

            if (blocked) {
                setBlockedUser(true);
                return;
            }

            const virtualEventTokenResponse = await getVirtualEventToken({
                sessionId: timeslotId,
                role: 'SUBSCRIBER',
            });
            const virtualEventToken = virtualEventTokenResponse.virtualEventToken;
            const appID = virtualEventTokenResponse.appID;

            virtualEventUserInfo.current = { tokenId: virtualEventToken.id, timeslotId };

            mutationCtx.setCanJoin(true);
            mutationCtx.updateConfig({
                appID,
                token: virtualEventToken.accessToken,
                channelName: timeslotId,
            });

            if (socket) {
                socket.emit('refreshAttendees', { sessionId });
            }

            trackAchievement(AchievementType.JOIN_VIRTUAL_SESSION, timeslotId);
        })();
    }, [timeslotId]);

    useEffect(() => {
        const interval = setInterval(() => {
            addViewSample(timeslotId);
        }, 60000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [timeslotId, isBroadcasting, isOnDemand]);

    window.localClient = localClient;
    window.mutationCtx = mutationCtx;

    useStreamHandling();

    useEffect(() => {
        (async () => {
            if (!user) {
                return goBackFromVirtualSession(match, history, location, timeslotId);
            }

            await fetchPollSets();

            const sessionRefreshed = await refreshSession();

            if (!sessionRefreshed) {
                return;
            }

            mutationCtx.setEurekaChatUrl(EUREKA_TALK_HOST);
        })();

        const cleanup = async () => {
            window.removeEventListener('beforeunload', cleanup);

            let tokenId = null;

            if (virtualEventUserInfo && virtualEventUserInfo.current) {
                tokenId = virtualEventUserInfo.current.tokenId;
            }

            if (tokenId) {
                await updateVirtualEventToken({ userId: user.id, status: 'disconnected' });
                await removeVirtualEventToken({ tokenId });

                if (socket) {
                    socket.emit('refreshAttendees', { sessionId });
                }
            }

            mutationCtx.clearAllStream();
        };

        window.addEventListener('beforeunload', cleanup);

        // Add a 'view' sample every time a user access the virtual session page
        AnalyticsService.addSample('object', 'timeslot', timeslotId);

        return async () => {
            await cleanup();
        };
    }, []);

    useEffect(() => {
        const { socket } = stateCtx;

        if (!socket || !user) {
            return;
        }

        const refreshEverythingForSession = async () => {
            await refreshSession();
            await refreshPoll();
        };

        socket.on('connect', refreshEverythingForSession);
        socket.on(`updateData_${timeslotId}`, updateData);
        socket.on(`pollAnswer_${sessionId}`, fetchPollSets);
        socket.on(`updatePollSet_${sessionId}`, fetchPollSets);
        socket.on(`blockUser_${user.id}`, kickUser);
        socket.on(`pollRefresh_${sessionId}`, refreshPoll);
        socket.on(`messageNotification_${user.id}`, data => {
            if (sessionId !== data.roomId) {
                NotificationService.handleNotification({
                    notificationType: notificationTypes.CHAT,
                    userId: data.senderId,
                    type: data.type,
                    roomId: data.roomId,
                });
            }
        });

        return () => {
            if (socket) {
                socket.off('connect', refreshEverythingForSession);
                socket.removeAllListeners(`updateData_${timeslotId}`);
                socket.removeAllListeners(`pollAnswer_${sessionId}`);
                socket.removeAllListeners(`pollRefresh_${sessionId}`);
                socket.removeAllListeners(`blockUser_${user.id}`);
                socket.removeAllListeners(`messageNotification_${user.id}`);
            }
        };
    }, [stateCtx.socket, timeslotId]);

    useEffect(() => {
        if (location.state && location.state.keepHistoryLength) {
            history.replace(location.pathname, {
                ...location.state,
                keepHistoryLength: false,
            });
            sessionStorage.setItem(timeslotId + '_history_index', JSON.stringify(history.length));
        }
        getTimeslot(timeslotId, async (err, timeslot) => {
            if (err || !timeslot) {
                return;
            }

            mutationCtx.setTimeslot(timeslot);

            if (Auth.isUserAuthenticated()) {
                const fullProfile = await getFullProfile();
                const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
                    timeslot?.typeParams?.virtualRoomRestrictedGroup,
                    fullProfile.userGroups,
                );
                setRestrictedUser(!canUserAccessSession);
            }

            setRestrictionChecked(true);
        });
    }, [timeslotId]);

    const goBack = () => {
        return goBackFromVirtualSession(match, history, location, timeslotId);
    };

    const kickUser = () => setBlockedUser(true);

    if (restrictedUser) {
        return <AccessDeniedModal onClose={() => history.push('/')} />;
    }

    if (blockedUser) {
        return (
            <WarningDialog
                open={true}
                title="You are blocked"
                content="You cannot join this virtual session anymore because you have been blocked by a moderator."
                onClose={goBack}
            />
        );
    }

    if (!virtualEventSession || !restrictionChecked) {
        return (
            <Wrapper mobile={mobile}>
                <Header goBack={goBack} timeslot={timeslot} authUser={authUser} />
                <LoadingVirtualPage />
            </Wrapper>
        );
    }

    if (
        virtualEventSession &&
        virtualEventSession.status === 'closed' &&
        timeslot &&
        timeslot.params &&
        timeslot.params.virtualSession !== 'on-demand'
    ) {
        return (
            <WarningDialog
                open={true}
                title="Virtual room is closed"
                content="This virtual room is now closed"
                onClose={goBack}
            />
        );
    }

    return (
        <React.Fragment>
            {authUser && (
                <VirtualWrapper
                    timeslotId={timeslotId}
                    virtualEventSession={virtualEventSession}
                    virtualEventUser={currentVirtualEventUser}
                    timeslot={timeslot}
                >
                    <Wrapper mobile={mobile}>
                        <Header
                            goBack={goBack}
                            timeslot={timeslot}
                            authUser={authUser}
                            backButtonAriaLabel={'Back to programme'}
                        />
                        <ContentContainer>
                            <MainContent
                                {...props}
                                isVotingActive={isVotingActive}
                                setIsVotingActive={setIsVotingActive}
                            />
                            <SideContent
                                authUser={authUser}
                                isVotingActive={isVotingActive}
                                setIsVotingActive={setIsVotingActive}
                                {...props}
                            />
                        </ContentContainer>
                    </Wrapper>
                </VirtualWrapper>
            )}
        </React.Fragment>
    );
};

const ConsumerWrapper = props => {
    return (
        <AuthUserContext.Consumer>
            {authUser => <ModeratedSession {...props} authUser={authUser} />}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(ConsumerWrapper);
