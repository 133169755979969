import React, { useEffect, useRef, useState } from 'react';
import { Cell, FontIcon, Grid } from 'react-md';
import styled from 'styled-components';
import ForumIcon from '@mui/icons-material/Forum';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

import QuestionsAndAnswers from './questionsAndAnswers/QuestionsAndAnswers';
import Attendees from './attendees/Attendees';
import { EUREKA_TALK_HOST } from '../../../../../../../config';
import Auth from '../../../../../../../services/api/auth';

import { getQuestionSetByExternalObject } from '../../../services/QuestionsAndAnswersService';
import { getAttendeesByEventIdPaginated } from '../../../services/AttendeesService';

import { useVMState } from '../../../containers/main';
import Chat from '../../../../Talk/components/Chat';

import SettingsModal from './sessionSettings';

// Uses tge global Talk manager for scenes/Talk
import TalkManager, { XMPPEvent } from '../../../../../../Talk/services';
import { LightTooltip } from '../common/styles';
import TranslatedCaptions from './translations/TranslatedCaptions';
import useSendTranslations from '../../../hooks/useSendTranslations';
import Votes from '../../../../Votes/containers/NonVirtualVotes';
import { MAX_TABLET_INT } from '../../../../../../../components/General/Variables';
import { useTheme } from '../../../../../../../components/Theme/ThemeContext';
import { useTranslationsStore } from '../../../../../../../stores/TranslationsStore';
import { getBlackOrWhiteCalculated } from '../../../../../../../components/General/Colors';

const StyledGrid = styled(Grid)`
    color: rgba(0, 0, 0, 0.54) !important;
    margin: 0 0 8px 0 !important;
    background: #fff;
    flex-wrap: nowrap !important;
    border-bottom: 1px solid #edeef2;
    padding: 0 16px !important;
    height: 48px !important;
    align-items: center !important;
`;

const StyledCell = styled(Cell)`
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    flex: 2;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    align-items: center;
    position: relative;
    margin: 4px 0 !important;
    height: 48px;
    padding: 16px 0 17px 0 !important;
    color: ${props =>
        props.active === 'true' ? (props.color ? props.color : '#1fa294') : 'inherit'};
    font-weight: ${props => (props.active === 'true' ? '500' : 'normal')};

    ${props =>
        props.active === 'true'
            ? `
        color: ${props.color ? props.color : '#1fa294'} !important;
        font-weight: bold;
        border-bottom: solid 3px ${props.color ? props.color : '#1fa294'};
        border-top: solid 3px #fff;
    `
            : ``}
    &:hover {
        ${props =>
            props.active === 'true'
                ? ` `
                : `
        background: ${props.color ? props.color + '10' : '#1fa294' + '10'};
        color: ${props.color ? props.color : '#1fa294'} !important;
        width: 100% !important;
        height: 40px;
        border-radius: 8px;`}
    }
`;

const Padding16px = styled.span`
    padding: 0 16px;
    height: 100%;
    min-height: 0;
`;

const IconStyledCell = styled(StyledCell)`
    max-width: 56px !important;

    &:hover {
        background: transparent;
    }
`;

const StyledChip = styled.span`
    text-align: center;
    justify-content: center;
    font-family: 'Cabin', sans-serif;
    font-size: 10px;
    color: #fff;
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    align-items: center;
    position: absolute;
    bottom: 28px;
    transform: translateX(-5px);
    background-color: #1fa294;
`;

const UnreadMessagesCount = styled(StyledChip)`
    right: -8px;
    bottom: 18px;
    transform: unset;
    background-color: red;
`;

const StyledQANotificationsChip = styled.span`
    text-align: center;
    justify-content: center;
    font-family: 'Cabin', sans-serif;
    font-size: 10px;
    color: ${props => (props.secondaryColor ? '' : '#fff')};
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    align-items: center;
    background-color: ${props => (props.secondaryColor ? '#f1f1f3' : '#1fa294')};
    transform: translate(-18px, -15px);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SettingsIcon = styled(FontIcon)`
    cursor: pointer;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    font-size: 24px !important;

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        background: #f1f1f3;
    }
`;

const QAHeaderWrapper = styled.div`
    height: 48px;
    width: 100%;
    border-bottom: #e6e6e6 1px solid;
    margin-bottom: 8px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`;

const ChatHeaderWrapper = styled.div`
    height: 48px;
    width: 100%;
    border-bottom: #e6e6e6 1px solid;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ChipWrapper = styled.button`
    height: 32px;
    width: ${props => (props.displayOnlyIcon ? 'fit-content' : '104px')};
    border-radius: 20px;
    background-color: ${props =>
        props.isActive ? (props.color ? props.color : '#1fa294') : '#f1f1f3'};
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px !important;
    font-family: Roboto, sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.6 !important;
    color: ${props => (props.isActive ? props.textColor : 'rgba(0, 0, 0, 0.6)')};
    transform: ${props => (props.reAlignLeft ? 'translate(-18px, -4px);' : 'translateY(-4px);')};
    cursor: pointer;
    border: none;
`;

const ChipText = styled.span`
    margin-left: 8px;
`;

const LeftSideContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const RightSideContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ChatType = Object.freeze({
    Attendees: 'attendees',
    Speakers: 'speakers',
});

const Tab = Object.freeze({
    Chat: 'chat',
    QA: 'qa',
    Attendees: 'attendees',
    Captions: 'showCaptions',
    Votes: 'votes',
});

const ATTENDEES_LIMIT = 12;
export const SORT_QUESTIONS_BY = {
    NEWEST_FIRST: 'Newest first',
    MOST_POPULAR: 'Most popular first',
};

const Chip = ({ icon, iconClassName }) => {
    const isPhone = useMediaQuery('(max-width:767px)');
    return !isPhone ? (
        <FontIcon
            iconClassName={iconClassName}
            style={{
                fontSize: '24px',
                color: 'inherit',
                marginRight: '6px',
            }}
        >
            {icon}
        </FontIcon>
    ) : null;
};

const VisibilityChip = ({ visible, isActive, onClick, moveLeft, displayOnlyIcon }) => {
    const iconStyle = { fontSize: 16 };
    const { theme } = useTheme();
    const contrastBlackOrWhite = getBlackOrWhiteCalculated(theme.primary);

    if (displayOnlyIcon) {
        return (
            <LightTooltip title={visible ? 'Visible' : 'Invisible'}>
                <Stack>
                    <ChipWrapper
                        onClick={onClick}
                        isActive={isActive}
                        color={theme.primary}
                        textColor={contrastBlackOrWhite}
                        reAlignLeft={moveLeft}
                        displayOnlyIcon={displayOnlyIcon}
                    >
                        {visible ? (
                            <VisibilityIcon style={iconStyle} />
                        ) : (
                            <VisibilityOffIcon style={iconStyle} />
                        )}
                    </ChipWrapper>
                </Stack>
            </LightTooltip>
        );
    }
    return (
        <ChipWrapper
            onClick={onClick}
            isActive={isActive}
            reAlignLeft={moveLeft}
            displayOnlyIcon={displayOnlyIcon}
            color={theme.primary}
            textColor={contrastBlackOrWhite}
        >
            {visible ? (
                <VisibilityIcon style={iconStyle} />
            ) : (
                <VisibilityOffIcon style={iconStyle} />
            )}
            <ChipText>{visible ? 'Visible' : 'Invisible'}</ChipText>
        </ChipWrapper>
    );
};

const UserInteraction = ({ controls, nonVirtualQA }) => {
    const [tab, setTab] = useState(nonVirtualQA ? Tab.Votes : Tab.Chat);
    const [questions, setQuestions] = useState([]);
    const [questionSetsId, setQuestionSetsId] = useState(null);
    const [newQuestionsCount, setNewQuestionsCount] = useState(0);
    const [attendees, setAttendees] = useState([]);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [page, setPage] = useState(1);
    const [showBlockModal, setShowBlockModal] = useState(false);
    const [activeChat, setActiveChat] = useState(ChatType.Attendees);
    const [isFetching, setIsFetching] = useState(false);
    const [columnWidth, setColumnWidth] = useState(false);
    const [showVisibleQuestions, setShowVisibleQuestions] = useState(true);
    const [chatRoomIds, setChatRoomIds] = useState({
        [ChatType.Attendees]: undefined,
        [ChatType.Speakers]: undefined,
    });
    const [unreadMessagesCount, setUnreadMessagesCount] = useState({
        [ChatType.Attendees]: 0,
        [ChatType.Speakers]: 0,
    });
    const { theme } = useTheme();
    const contrastBlackOrWhite = getBlackOrWhiteCalculated(theme.primary);

    const newQuestionsCountRef = useRef(newQuestionsCount);
    const newQuestionsIdsRef = useRef([]);
    const newAddedQuestionsIdsRef = useRef([]);
    const tabRef = useRef(tab);
    const showQuestionsRef = useRef(showVisibleQuestions);
    const questionIds = useRef([]);

    const { messages } = useTranslationsStore();
    const messageToDisplay = Array.from(messages.values());
    const messagesExist = messageToDisplay.length > 0;

    const stateCtx = useVMState();
    const { sessionId, externalObject, socket, virtualEventSession } = stateCtx;
    const { showChat, showQA, showAttendees, showCaptions } =
        virtualEventSession && virtualEventSession.data ? virtualEventSession.data : {};
    const unreadAttendeesMessages = unreadMessagesCount[ChatType.Attendees];
    const unreadSpeakersMessages = unreadMessagesCount[ChatType.Speakers];
    const unreadChatMessagesCount = unreadAttendeesMessages + unreadSpeakersMessages;
    const userInteractionColumnElem = document.getElementById('user-interaction-column');

    const showAttendeesChat = controls || showChat;

    useEffect(() => {
        if (!chatRoomIds[ChatType.Attendees] || !chatRoomIds[ChatType.Speakers]) {
            return;
        }

        const handleGroupMessage = ({ conversation }) => {
            const chatType = Object.keys(chatRoomIds).find(
                key => chatRoomIds[key] === conversation.id,
            );
            if (tab === Tab.Chat && chatType === activeChat) {
                return;
            }

            setUnreadMessagesCount({
                ...unreadMessagesCount,
                [chatType]: unreadMessagesCount[chatType] + 1,
            });
        };

        TalkManager.subscribeToEvent(XMPPEvent.GroupMessage, handleGroupMessage);

        return () => {
            TalkManager.unsubscribeFromEvent(XMPPEvent.GroupMessage, handleGroupMessage);
        };
    }, [chatRoomIds, unreadMessagesCount, tab, activeChat]);

    useEffect(() => {
        if (tab === Tab.Chat) {
            setUnreadMessagesCount({
                [ChatType.Attendees]:
                    activeChat === ChatType.Attendees ? 0 : unreadMessagesCount[ChatType.Attendees],
                [ChatType.Speakers]:
                    activeChat === ChatType.Speakers ? 0 : unreadMessagesCount[ChatType.Speakers],
            });
        }
    }, [tab, activeChat]);

    useEffect(() => {
        let currentUser = Auth.getUser();

        return () => {
            TalkManager.leaveVMRooms({ userId: currentUser.id });
            //TalkManager.disconnect();
        };
    }, []);

    useEffect(() => {
        fetchQuestions();

        const handleQuestionUpdates = questionData => {
            const { question } = questionData;

            if (controls && questionData.action === 'addQuestion') {
                newAddedQuestionsIdsRef.current.push(question.id);
                setNewQuestionsCountExtra(newQuestionsCountRef.current + 1);
                if (showQuestionsRef.current.visible) {
                    if (!questionIds.current.find(item => item === question.id)) {
                        questionIds.current.push(question.id);
                    }
                }
            }

            if (
                controls &&
                questionData.action === 'updateQuestion' &&
                !questionData.updateWithoutCount
            ) {
                if (question.visible && showVisibleQuestions) {
                    if (!questionIds.current.find(item => item === question.id)) {
                        questionIds.current.push(question.id);
                    }
                }

                if (!question.visible && showVisibleQuestions) {
                    if (!questionIds.current.find(item => item === question.id)) {
                        questionIds.current.push(question.id);
                    }
                }
            }

            if (!controls && questionData.action === 'updateQuestion') {
                // if question is not visible => it becomes visible now
                if (
                    question.visible &&
                    !newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current.push(question.id);
                    setNewQuestionsCountExtra(newQuestionsCountRef.current + 1);
                }

                if (
                    !question.visible &&
                    newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current = newQuestionsIdsRef.current.filter(
                        id => id !== question.id,
                    );
                    setNewQuestionsCountExtra(newQuestionsCountRef.current - 1);
                }
            }

            fetchQuestions();
        };

        socket.on('updateQuestions', handleQuestionUpdates);

        if (!nonVirtualQA) {
            fetchAttendees();
            socket.on(`refreshAttendees_${sessionId}`, () => {
                setPage(1);
                fetchAttendees();
            });
        }

        return () => {
            if (socket && socket.removeAllListeners) {
                socket.removeAllListeners(`refreshAttendees_${sessionId}`);
                socket.removeAllListeners('updateQuestions', handleQuestionUpdates);
            }
        };
    }, []);

    useEffect(() => {
        if (controls) {
            return;
        }

        setTab(value => {
            const valueMap = {
                chat: true,
                qa: showQA,
                attendees: showAttendees,
                showCaptions,
            };

            // if the current value is available, keep it
            if (valueMap[value]) {
                return value;
            }

            // otherwise change it
            return Object.values(Tab).find(opt => {
                if (opt !== value && valueMap[opt]) {
                    return opt;
                }

                return false;
            });
        });
    }, [virtualEventSession]);

    useEffect(() => {
        if (!showAttendeesChat) {
            setActiveChat(ChatType.Speakers);
        }
    }, [showAttendeesChat]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userInteractionColumnElem]);

    const handleResize = () => {
        setColumnWidth(userInteractionColumnElem?.offsetWidth);

        const windowWidth = window.innerWidth;
        if (nonVirtualQA && windowWidth >= MAX_TABLET_INT && tabRef.current === Tab.Chat) {
            changeTabAndResetQuestions(Tab.Votes);
        }
    };

    const setNewQuestionsCountExtra = count => {
        newQuestionsCountRef.current = count;
        setNewQuestionsCount(count);
        if (count === 0) {
            resetQuesttionIds();
        }
    };

    const resetQuesttionIds = () => {
        newAddedQuestionsIdsRef.current = [];
        newQuestionsIdsRef.current = [];
    };

    const fetchQuestions = async () => {
        const questionSets = await getQuestionSetByExternalObject(externalObject.data.id);
        let { Questions: questions } = questionSets;

        questions = questions.map(item => {
            item.newQuestion = !item.visible && newAddedQuestionsIdsRef.current.includes(item.id);
            item.newQuestionForSpeaker = newQuestionsIdsRef.current.includes(item.id);
            return item;
        });

        newAddedQuestionsIdsRef.current = [];

        setQuestions(questions);
        setQuestionSetsId(questionSets.id);
    };

    const updateAttendeeList = (attendees, page) => {
        setAttendees(attendees);
        setIsFetching(false);
        setPage(page);
    };

    const fetchAttendees = async () => {
        const newAttendees = await getAttendeesByEventIdPaginated(sessionId, ATTENDEES_LIMIT, page);
        const attendeeList = attendees.concat(newAttendees.rows);
        const sortedRows = attendeeList.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        setAttendees(sortedRows);
        setAttendeesCount(newAttendees.count);
        setPage(page);
        setIsFetching(false);
    };

    const handleShowQuestions = () => {
        questionIds.current = [];
        setShowVisibleQuestions(val => {
            showQuestionsRef.current = !val;
            return !val;
        });
    };

    const renderQuestionAndAnswersHeader = () => {
        if (nonVirtualQA) {
            return null;
        }

        return (
            <QAHeaderWrapper>
                <LeftSideContainer>
                    <>
                        <VisibilityChip
                            visible={false}
                            isActive={!showVisibleQuestions}
                            onClick={
                                showVisibleQuestions ? () => handleShowQuestions() : () => null
                            }
                            displayOnlyIcon={columnWidth <= 416}
                        />
                        {questionIds.current.length > 0 && showVisibleQuestions && (
                            <StyledQANotificationsChip secondaryColor={!showVisibleQuestions}>
                                {`+${questionIds.current.length}`}
                            </StyledQANotificationsChip>
                        )}
                    </>
                    <>
                        <VisibilityChip
                            visible={true}
                            isActive={showVisibleQuestions}
                            onClick={
                                !showVisibleQuestions ? () => handleShowQuestions() : () => null
                            }
                            displayOnlyIcon={columnWidth <= 416}
                            moveLeft={questionIds.current.length > 0 && showVisibleQuestions}
                        />
                        {questionIds.current.length > 0 && !showVisibleQuestions && (
                            <StyledQANotificationsChip secondaryColor={showVisibleQuestions}>
                                {`+${questionIds.current.length}`}
                            </StyledQANotificationsChip>
                        )}
                    </>
                </LeftSideContainer>
            </QAHeaderWrapper>
        );
    };

    const ChatTabs = () => (
        <ChatHeaderWrapper>
            {showAttendeesChat && (
                <LightTooltip title="Chat with attendees">
                    <Stack>
                        <ChipWrapper
                            isActive={activeChat === ChatType.Attendees}
                            onClick={() => {
                                setUnreadMessagesCount({
                                    ...unreadMessagesCount,
                                    [ChatType.Attendees]: 0,
                                });
                                setActiveChat(ChatType.Attendees);
                            }}
                            color={theme.primary}
                            textColor={contrastBlackOrWhite}
                        >
                            Attendees
                            {unreadAttendeesMessages > 0 && (
                                <UnreadMessagesCount>{unreadAttendeesMessages}</UnreadMessagesCount>
                            )}
                        </ChipWrapper>
                    </Stack>
                </LightTooltip>
            )}
            <LightTooltip title="Chat with speakers">
                <Stack>
                    <ChipWrapper
                        isActive={activeChat === ChatType.Speakers}
                        onClick={() => {
                            setUnreadMessagesCount({
                                ...unreadMessagesCount,
                                [ChatType.Speakers]: 0,
                            });
                            setActiveChat(ChatType.Speakers);
                        }}
                        color={theme.primary}
                        textColor={contrastBlackOrWhite}
                    >
                        Speakers
                        {unreadSpeakersMessages > 0 && (
                            <UnreadMessagesCount>{unreadSpeakersMessages}</UnreadMessagesCount>
                        )}
                    </ChipWrapper>
                </Stack>
            </LightTooltip>
        </ChatHeaderWrapper>
    );

    const renderTab = () => {
        if (tab === Tab.Chat) {
            const sessionName = externalObject.data.title;
            return (
                <>
                    {!nonVirtualQA && <ChatTabs />}
                    {showAttendeesChat && (
                        <Chat
                            nonVirtualQA={nonVirtualQA}
                            itemId={sessionId}
                            itemName={sessionName}
                            moderatorOnly={false}
                            chatUrl={EUREKA_TALK_HOST}
                            isVisible={activeChat === ChatType.Attendees}
                            onChatRoomIdSet={chatRoomId =>
                                setChatRoomIds({
                                    ...chatRoomIds,
                                    [ChatType.Attendees]: chatRoomId,
                                })
                            }
                        />
                    )}
                    {!nonVirtualQA && (
                        <Chat
                            itemId={sessionId}
                            itemName={sessionName}
                            moderatorOnly
                            chatUrl={EUREKA_TALK_HOST}
                            isVisible={activeChat === ChatType.Speakers}
                            onChatRoomIdSet={chatRoomId =>
                                setChatRoomIds({
                                    ...chatRoomIds,
                                    [ChatType.Speakers]: chatRoomId,
                                })
                            }
                        />
                    )}
                </>
            );
        }

        if (tab === Tab.QA && shouldRenderQA()) {
            return (
                <>
                    {renderQuestionAndAnswersHeader()}
                    <QuestionsAndAnswers
                        questions={questions}
                        showVisible={showVisibleQuestions}
                        fetchQuestions={fetchQuestions}
                        controls={true}
                        questionSetsId={questionSetsId}
                        nonVirtualQA={nonVirtualQA}
                    />
                </>
            );
        }

        if (tab === Tab.Attendees && shouldRenderAttendees()) {
            return (
                <Padding16px>
                    <Attendees
                        sortedAttendees={attendees}
                        controls={controls}
                        limit={ATTENDEES_LIMIT}
                        totalAttendees={attendeesCount}
                        setShowBlockModal={val => setShowBlockModal(val)}
                        showBlockModal={showBlockModal}
                        updateAttendeeList={updateAttendeeList}
                        externalIsFetching={isFetching}
                        externalPage={page}
                    />
                </Padding16px>
            );
        }

        if (tab === Tab.Captions && shouldRenderCaptions()) {
            return (
                <Padding16px>
                    <TranslatedCaptions controls={controls} />
                </Padding16px>
            );
        }

        if (tab === Tab.Votes && shouldRenderVotes()) {
            return (
                <Padding16px style={{ position: 'relative' }}>
                    <Votes />
                </Padding16px>
            );
        }

        return null;
    };

    const isTabletOrSmaller = useMediaQuery(`(max-width:${MAX_TABLET_INT}px)`);
    const shouldRenderVote = () => (nonVirtualQA && isTabletOrSmaller) || !nonVirtualQA;
    const shouldRenderQA = () => controls || (!controls && showQA);

    const shouldRenderAttendees = () => (controls || (!controls && showAttendees)) && !nonVirtualQA;
    const shouldRenderCaptions = () =>
        (controls || (!controls && (showCaptions || messagesExist))) && !nonVirtualQA;

    const shouldRenderVotes = () => nonVirtualQA;

    useSendTranslations({ active: showCaptions });

    const changeTabAndResetQuestions = t => {
        setTab(t);
        tabRef.current = t;
        let oldQuestions = questions;
        oldQuestions = oldQuestions.map(item => {
            item.newQuestion = false;
            return item;
        });

        setQuestions(oldQuestions);
        setNewQuestionsCountExtra(0);
    };

    return (
        <Wrapper className="eureka-react" id={'user-interaction-column'}>
            <StyledGrid>
                {shouldRenderVote() && !nonVirtualQA && (
                    <StyledCell
                        active={(tab === Tab.Chat).toString()}
                        onClick={() => {
                            changeTabAndResetQuestions(Tab.Chat);
                        }}
                        color={theme.primary}
                    >
                        <Chip icon={tab === Tab.Chat ? 'chat_bubble' : 'chat_bubble_outline'} />
                        Chat
                        {unreadChatMessagesCount > 0 && (
                            <StyledChip>{unreadChatMessagesCount}</StyledChip>
                        )}
                    </StyledCell>
                )}
                {shouldRenderQA() && (
                    <StyledCell
                        active={(tab === Tab.QA).toString()}
                        onClick={() => {
                            setTab(Tab.QA);
                            tabRef.current = Tab.QA;
                        }}
                        color={theme.primary}
                    >
                        <Chip icon={tab === Tab.QA ? <ForumIcon /> : <ForumOutlinedIcon />} />
                        Q&A
                        {newQuestionsCount > 0 && tab !== Tab.QA && (
                            <StyledChip>{newQuestionsCount}</StyledChip>
                        )}
                    </StyledCell>
                )}
                {shouldRenderAttendees() && (
                    <StyledCell
                        active={(tab === Tab.Attendees).toString()}
                        onClick={() => changeTabAndResetQuestions(Tab.Attendees)}
                        color={theme.primary}
                    >
                        <Chip
                            iconClassName={
                                tab !== Tab.Attendees ? 'material-icons-outlined' : 'material-icons'
                            }
                            icon={'group'}
                        />
                        {`(${attendeesCount})`}
                    </StyledCell>
                )}
                {shouldRenderCaptions() && (
                    <StyledCell
                        active={(tab === Tab.Captions).toString()}
                        onClick={() => changeTabAndResetQuestions(Tab.Captions)}
                        color={theme.primary}
                    >
                        <Chip
                            iconClassName={
                                tab !== Tab.Captions ? 'material-icons-outlined' : 'material-icons'
                            }
                            icon={'closed_caption'}
                        />
                    </StyledCell>
                )}
                {shouldRenderVotes() && (
                    <StyledCell
                        active={(tab === Tab.Votes).toString()}
                        onClick={() => changeTabAndResetQuestions(Tab.Votes)}
                        color={theme.primary}
                    >
                        <Chip
                            iconClassName={
                                tab !== Tab.Votes ? 'material-icons-outlined' : 'material-icons'
                            }
                            icon={'how_to_vote'}
                        />
                        Vote
                    </StyledCell>
                )}
                {shouldRenderVote() && nonVirtualQA && (
                    <StyledCell
                        active={(tab === Tab.Chat).toString()}
                        onClick={() => {
                            changeTabAndResetQuestions(Tab.Chat);
                        }}
                        color={theme.primary}
                    >
                        <Chip icon={tab === Tab.Chat ? 'chat_bubble' : 'chat_bubble_outline'} />
                        Chat
                        {unreadChatMessagesCount > 0 && (
                            <StyledChip>{unreadChatMessagesCount}</StyledChip>
                        )}
                    </StyledCell>
                )}
                {controls && (
                    <IconStyledCell active={'false'} onClick={() => setShowSettingsModal(true)}>
                        <SettingsIcon>settings</SettingsIcon>
                    </IconStyledCell>
                )}
            </StyledGrid>
            {renderTab()}
            {showSettingsModal && (
                <SettingsModal
                    onClose={() => setShowSettingsModal(false)}
                    questionSetsId={questionSetsId}
                    nonVirtualQA={nonVirtualQA}
                    externalObject={externalObject}
                />
            )}
        </Wrapper>
    );
};

export default UserInteraction;
