import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import {
    computeDurationInHours,
    computeEventBlock,
    computeEventBlockOffset,
    computeEventBlockOffsetDif,
    computeTimerStyle,
    EmptyFilter,
    getRulerRange,
    happeningNow,
    PlaceholderEmptyList,
    PlaceholderEmptyProgramme,
    hasFilters,
} from '../utils';
import {
    Container,
    ContainerDiv,
    HiddenContainer,
    IndicatorVertical,
    RoomNameContainer,
    RoomSectionContainerVertical,
    RoomTimelineVertical,
    Ruler,
    SectionVertical,
    TimeHeaderVertical,
    TimerCell,
    TimerCellHalf,
} from '../style/style';
import * as palette from '../../../components/General/Variables';
import flatten from 'lodash/flatten';
import TimeEntry from './TimeEntry';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import ThemeContext from '../../../components/Theme/ThemeContext';
import ResizeListener from '../../../components/General/ResizeListener';
import { GRID_HEIGHT, GRID_WIDTH_SMALL } from '../constants/index';
import { getFormatTime } from '../../../services/api/data';
import { useLayoutStore } from '../../../stores/LayoutStore';

const ProgramItems = ({
    items,
    filters,
    getMaxWidth,
    rangeInterval,
    verticalContainerElem,
    timeElements,
    computeTimerStyleStart,
}) => {
    let propsItems = [];

    if (items) {
        propsItems = items;
    }

    let hasContent = false;
    let itemsArray = [];
    if (propsItems && propsItems.length) {
        propsItems.map(i => itemsArray.push(i.items));
        const items = flatten(itemsArray);
        const appointmentPropsItems = items.filter(i => i.type === 'appointment');

        if (appointmentPropsItems && appointmentPropsItems.length > 0) {
            const appointmentsObject = propsItems.find(i => i.group.id === 'appointments');
            if (appointmentsObject) {
                appointmentsObject.items = Array.from(
                    new Set(appointmentsObject.items.concat(appointmentPropsItems)),
                );
            } else {
                propsItems.unshift({
                    group: { id: 'appointments', name: 'Meetings' },
                    items: appointmentPropsItems,
                });
            }
        }
        propsItems = propsItems.filter(i => i.items && i.items.length);
    }

    const result = propsItems.map((item, i) => {
        const room = item.group;
        let sessions = sortBy(item.items, ['start', 'orderingName', 'name', 'end']);

        if (sessions && sessions.length > 0) {
            hasContent = true;
            const rulerRange = getRulerRange(rangeInterval);
            room.maxWidth = getMaxWidth(sessions, room);
            const displayFullWidth =
                verticalContainerElem?.offsetWidth > room.maxWidth && propsItems?.length === 1;
            return (
                <RoomSectionContainerVertical
                    height={
                        rulerRange && rulerRange.length > 0
                            ? `${GRID_HEIGHT * 2 * rulerRange.length + 115}px`
                            : '100%'
                    }
                    style={{ width: displayFullWidth ? '100%' : `${room.maxWidth}px` }}
                >
                    {room.id !== 'noGroup' && (
                        <RoomNameContainer>
                            <h5>{room.name}</h5>
                        </RoomNameContainer>
                    )}
                    <ul>{timeElements(sessions, room, i)}</ul>
                </RoomSectionContainerVertical>
            );
        } else {
            return null;
        }
    });

    if (hasContent) {
        return result;
    } else {
        if (
            (filters.myProgram === 'my_program' &&
                isEmpty(filters.filters) &&
                (!filters.text || filters.text === '')) ||
            !computeTimerStyleStart
        ) {
            return <PlaceholderEmptyList />;
        } else {
            return <EmptyFilter />;
        }
    }
};

const VerticalTimetable = ({
    items,
    rangeInterval,
    filters,
    selectedTimezone,
    renderTimezoneInfo,
    activeTab,
    handleClickOutside,
    getUtcToSelectedTimezone,
    getTimeslotUtc,
}) => {
    const bannerImageDisplayed = useLayoutStore(state => state.bannerImageDisplayed);
    const hasBrandingBanner = useLayoutStore(state => state.hasBrandingBanner);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [cellWidth, setCellWidth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sessionOnGoing, setSessionOnGoing] = useState(false);
    const nowTimeline = useRef(null);
    const scorllableContainer = document.getElementById(`timetable-scrollable-container-vertical`);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, [activeTab]);

    useEffect(() => {
        setTimeout(() => {
            _handleResize(GRID_WIDTH_SMALL);
        }, 500);
    }, []);

    useEffect(() => {
        if (scorllableContainer && nowTimeline.current && sessionOnGoing) {
            const firstSessionTop =
                sessionOnGoing?.top && parseInt(sessionOnGoing?.top.replace(/[^0-9\.]/g, ''), 10);
            scorllableContainer.scrollTo({
                top: firstSessionTop || nowTimeline.current?.offsetTop,
                behavior: 'smooth',
            });
        }
    }, [scorllableContainer, nowTimeline.current]);

    useEffect(() => {
        if (!currentDate) {
            return;
        }

        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentDate]);

    useEffect(() => {
        const arrayItems = [];
        if (items?.length) {
            items.map(i => arrayItems.push(i.items));
            const flattenItems = flatten(arrayItems);
            const sortedItemsByTime = sortBy(flattenItems, 'start');
            const sessionOnGo = sortedItemsByTime?.find(item =>
                happeningNow(item, selectedTimezone, getTimeslotUtc),
            );
            setSessionOnGoing(sessionOnGo);
        }
    }, [items]);

    const _handleResize = wWidth => {
        const ww = GRID_WIDTH_SMALL;
        setCellWidth(ww);
        setWindowWidth(wWidth);
    };

    const RulerTimeHeader = () => {
        const rulerRange = getRulerRange(rangeInterval);
        const timeFormat = getFormatTime();

        return [
            <TimerCellHalf key="k-empty" isVertical={true}>
                <span className="time-label">
                    <span className="time-label">{renderTimezoneInfo()}</span>
                </span>
            </TimerCellHalf>,
            ...rulerRange.map(n => {
                const hours = n >= 24 ? n - 24 : n;
                const date = new Date();
                date.setHours(hours, 0, 0, 0);
                const timetoDisplay = moment(date).format(timeFormat);
                return (
                    <TimerCell key={'k' + hours + n}>
                        <span className="time-label">
                            <h6>{timetoDisplay}</h6>
                        </span>
                    </TimerCell>
                );
            }),
        ];
    };

    const w = cellWidth * (getRulerRange(rangeInterval).length - 2) + cellWidth * 0.5;
    const wstyle = { width: windowWidth < w ? w : '100%' };
    const workwidth = windowWidth < palette.MAX_TABLET_INT ? windowWidth : windowWidth - 256;

    let computeTimerStyleStart = null;
    let allItemsWithStart;
    if (items && items.length) {
        allItemsWithStart = items.filter(item => item.items.length > 0);
        if (allItemsWithStart) {
            let itemsArray = [];
            allItemsWithStart.map(i => itemsArray.push(i.items));
            const items = flatten(itemsArray);
            items.sort((a, b) => new Date(a.start) - new Date(b.start));
            computeTimerStyleStart = items[0] && items[0].start;
        }
    }

    const timelineDif = computeTimerStyle(
        getRulerRange(rangeInterval),
        computeTimerStyleStart,
        selectedTimezone,
        rangeInterval,
        getUtcToSelectedTimezone,
    );
    const indicatorStyle = timelineDif.display
        ? timelineDif
        : {
              top: timelineDif * cellWidth + cellWidth / 2 + 'px',
          };

    const getAbsoluteOverlappingItems = (currentItem, items, roomId) => {
        let finished = false;
        while (!finished) {
            // we do this conditional because we want to avoid the first item to be moved
            const maxInterval = currentItem.left === 32 ? 10 : currentItem.left + 10;
            const minInterval = currentItem.left - 10;
            const found = items.find(
                item =>
                    item.roomId === roomId &&
                    ((item.left >= minInterval && item.left <= maxInterval) ||
                        (item.newLeft >= minInterval && item.newLeft <= maxInterval)) &&
                    item.id !== currentItem.id,
            );
            if (found) {
                currentItem.left += 180;
            } else {
                finished = true;
            }
        }

        return currentItem.left;
    };

    const avoidOverlappingItems = (currentItem, items, roomId) => {
        const maxInterval = currentItem.left + 10;
        const minInterval = currentItem.left - 10;
        const itemsStartingAfterCurrentItem = items.filter(
            item =>
                item.roomId === roomId &&
                ((item.left >= minInterval && item.left <= maxInterval) ||
                    (item.newLeft >= minInterval && item.newLeft <= maxInterval)) &&
                item.id !== currentItem.id &&
                item.start >= currentItem.start &&
                item.start < currentItem.end,
        );
        const itemsWithSmallDuraction = items.filter(
            item => computeDurationInHours(item.start, item.end) <= 0.25,
        );
        let arrayItemsSmallDuratiion = [];
        if (itemsWithSmallDuraction.includes(currentItem)) {
            arrayItemsSmallDuratiion = items.filter(
                item =>
                    item.roomId === roomId &&
                    ((item.left >= minInterval && item.left <= maxInterval) ||
                        (item.newLeft >= minInterval && item.newLeft <= maxInterval)) &&
                    item.id !== currentItem.id &&
                    item.start === currentItem.end,
            );
        }
        const itemsNewLeft = Array.from(
            new Set(itemsStartingAfterCurrentItem.concat(arrayItemsSmallDuratiion)),
        ).map(item => {
            const emptyPosition = getAbsoluteOverlappingItems(item, items, roomId);
            if (item.start !== currentItem.end) {
                if (
                    currentItem.left &&
                    currentItem.newLeft &&
                    currentItem.left !== currentItem.newLeft
                ) {
                    item.newLeft = emptyPosition || currentItem.newLeft + 180;
                } else {
                    item.newLeft = emptyPosition || currentItem.left + 180;
                }
            } else {
                if (item.top) {
                    const itemNextToSmallDuration = arrayItemsSmallDuratiion.find(
                        it => it.id === item.id,
                    );
                    const sameTop = itemsWithSmallDuraction.find(
                        it =>
                            parseInt(itemNextToSmallDuration.top) === parseInt(it.top) + 30 ||
                            parseInt(itemNextToSmallDuration.newTop) === parseInt(it.top) + 35,
                    );

                    const calculateNewLeft = !(arrayItemsSmallDuratiion?.length && sameTop);
                    item.calculateNewLeft = calculateNewLeft;

                    const startNumber = parseInt(item.top, 10);
                    item.newTop = `${startNumber + 5}px`;
                    if (calculateNewLeft) {
                        item.newLeft = emptyPosition || currentItem.left + 180;
                    } else {
                        item.newLeft = null;
                    }
                }
            }

            return item;
        });
        return itemsNewLeft || [];
    };

    const getOverlappingItems = (item, items, roomId) => {
        const overlappingItems = items.filter(
            i =>
                i.roomId === roomId &&
                ((new Date(item.start) > new Date(i.start) &&
                    new Date(item.start) < new Date(i.end)) ||
                    (new Date(item.end) > new Date(i.start) &&
                        new Date(item.end) < new Date(i.end)) ||
                    (new Date(i.start) > new Date(item.start) &&
                        new Date(i.start) < new Date(item.end)) ||
                    (new Date(i.end) > new Date(item.start) &&
                        new Date(i.end) < new Date(item.end)) ||
                    new Date(item.end) === new Date(i.start) ||
                    new Date(i.end) === new Date(item.start) ||
                    i.top === item.top),
        );
        if (!item.overlapping || item.overlapping < overlappingItems.length) {
            item.overlapping = overlappingItems.length;
        }
        return overlappingItems;
    };

    const timeElements = (items, room, blockIndex) => {
        return items.map((item, index) => {
            const height = computeEventBlock(item, GRID_HEIGHT * 2, true);
            item.left = blockIndex + 32 + computeEventBlockOffset(item, items, 180);
            const startTime = getUtcToSelectedTimezone(item.start);
            const top = computeEventBlockOffsetDif(startTime, rangeInterval, cellWidth);
            item.top = top;
            item.height = height;
            const objectClass = item.type === 'appointment' ? 'appointment' : 'timeslot';
            if (objectClass === 'appointment' && room.id !== 'appointments') return null;
            item.roomId = room.id;

            avoidOverlappingItems(item, items, room.id);

            const overlappingToFindCorrectWidth = getOverlappingItems(item, items, room.id);
            const previousItem = items[index - 1];
            const nextItem = items[index + 1];

            if (
                computeDurationInHours(item.start, item.end) <= 0.25 ||
                (previousItem &&
                    computeDurationInHours(previousItem.start, previousItem.end) <= 0.25) ||
                (nextItem && computeDurationInHours(nextItem.start, nextItem.end) <= 0.25)
            ) {
                if (previousItem?.end === item?.start || item?.end === nextItem?.start) {
                    item.customWidth =
                        overlappingToFindCorrectWidth &&
                        overlappingToFindCorrectWidth.length <= 1 &&
                        `calc(100% - 18px)`;
                } else {
                    item.customWidth = null;
                }
            } else {
                item.customWidth =
                    overlappingToFindCorrectWidth &&
                    overlappingToFindCorrectWidth.length <= 1 &&
                    `calc(100% - 18px)`;
            }

            return (
                <TimeEntry
                    objectClass={objectClass}
                    key={`vertical-${item.id}`}
                    item={item}
                    entryHeight={height}
                    entryLeft={`${item.newLeft || item.left}px`}
                    entryTop={item.newTop || top}
                    isVertical={true}
                    customWidth={item.customWidth}
                    isHighlighted={item?.params?.highlighted}
                    handleClickOutside={handleClickOutside}
                />
            );
        });
    };

    const getMaxWidth = (sessions, room) => {
        let maxLeft = 0;
        sessions.map(item => {
            if (item.newLeft > maxLeft && item.roomId === room.id) {
                maxLeft = item.newLeft;
            }
            if (item.left > maxLeft && item.roomId === room.id) {
                maxLeft = item.left;
            }
        });

        const lineWidth = maxLeft + 180;
        return lineWidth;
    };
    const verticalContainerElem = document.getElementById('vertical-container');

    const displayOneColumnFullWidth =
        allItemsWithStart?.length === 1 &&
        verticalContainerElem?.offsetWidth >
            getMaxWidth(
                allItemsWithStart,
                allItemsWithStart && allItemsWithStart[0] && allItemsWithStart[0].group,
            );

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Container key={'main'} id={'vertical-container'}>
                    <ResizeListener onResize={_handleResize} />
                    <ContainerDiv
                        isVertical={true}
                        id={'timetable-scrollable-container-vertical'}
                        style={{
                            overflowY:
                                hasBrandingBanner && bannerImageDisplayed ? 'hidden' : 'auto',
                        }}
                    >
                        {computeTimerStyleStart ? (
                            <SectionVertical wWidth={workwidth}>
                                <IndicatorVertical
                                    innerRef={nowTimeline}
                                    contrast={theme.contrast}
                                    wHeight={'100%'}
                                    style={indicatorStyle}
                                    isVertical={true}
                                />
                                <Ruler style={wstyle} isVertical={true}>
                                    <TimeHeaderVertical isVertical={true}>
                                        <ul>
                                            <RulerTimeHeader />
                                        </ul>
                                    </TimeHeaderVertical>
                                </Ruler>
                                <HiddenContainer>
                                    <RoomTimelineVertical fullWidth={displayOneColumnFullWidth}>
                                        <ProgramItems
                                            items={items}
                                            filters={filters}
                                            getMaxWidth={getMaxWidth}
                                            rangeInterval={rangeInterval}
                                            verticalContainerElem={verticalContainerElem}
                                            timeElements={timeElements}
                                            computeTimerStyleStart={computeTimerStyleStart}
                                        />
                                    </RoomTimelineVertical>
                                </HiddenContainer>
                            </SectionVertical>
                        ) : (
                            <>
                                {hasFilters(filters) ? (
                                    <PlaceholderEmptyList />
                                ) : (
                                    <PlaceholderEmptyProgramme />
                                )}
                            </>
                        )}
                    </ContainerDiv>
                </Container>
            )}
        </ThemeContext.Consumer>
    );
};

export default VerticalTimetable;
