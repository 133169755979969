import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from './DialogTitle';

const DialogActions = muiStyled(MuiDialogActions)(() => ({
    root: {
        padding: '24px !important',
        justifyContent: 'flex-start',
    },
}));

const StyledDialog = muiStyled(MuiDialog)(({ theme, isComplex }) => ({
    '& .MuiPaper-rounded': {
        borderRadius: 12,
    },
    '& .MuiDialog-paperWidthXs': {
        maxWidth: 424,
        height: isComplex ? 704 : 'unset',
        borderRadius: 12,
    },

    '& .MuiDialog-paperWidthSm': {
        maxWidth: 500,
    },

    '& .MuiDialog-paperWidthMd': {
        maxWidth: 870,
    },
}));

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 56px;
    width: 56px;
    margin-top: 56px;
    border-radius: 50%;
    background-color: #efefef;
`;

const DialogActionsWrapper = styled.div`
    ${props => props.displayBoxShadow && 'box-shadow: 0px -12px 9px -12px #ccc;'};
    z-index: 2;
`;

const iconStyle = {
    fontSize: 27,
    color: '#1c1c1e',
};

/**
 * @component
 * @description A Dialog (Modal) component based on Material UI lib with some modifications made to Title styles and how action buttons are displayed
 * @param {Object} props
 * @param {JSX} props.children content rendered inside Dialog
 * @param {Object} props.contentStyle additional style added to content
 * @param {String} props.maxWidth size of component (width). It could be one of those: 'xs', 'sm', 'md' (default), 'lg' or 'xl'
 * @param {Function} props.onClose function called when the Dialog is dismissed by pressing the Esc key or the X button at the top
 * @param {Array} props.options Material UI Buttons for confirming or dismissing the dialog for example
 * @param {String} props.title title on the dialog. Can also be empty
 * @param {Boolean} props.visible when *false* the dialog is hidden and displayed when *true*
 * @param {Boolean} props.displayBoxShadow when *true* the Action Buttons Container has an upper shadow effect
 * @param {Object} props.extraStyles additional styles applied to the Material UI Dialog component (See: https://mui.com/api/dialog/#css)
 */
const MaterialDialog = ({
    children,
    contentStyle = {},
    maxWidth = 'xs',
    onClose = () => {},
    options = [],
    title = '',
    visible = false,
    iconName,
    isComplex,
    displayBoxShadow = false,
    extraStyles = {},
}) => {
    return (
        <StyledDialog
            open={visible}
            fullWidth={true}
            maxWidth={maxWidth}
            isComplex={isComplex && displayBoxShadow}
            onClose={onClose} // Esc key callback
            sx={extraStyles}
        >
            {iconName ? (
                <React.Fragment>
                    <IconWrapper>
                        <CloseIcon
                            aria-hidden={true}
                            role={'presentation'}
                            style={iconStyle}
                            aria-label={'Close'}
                        >
                            {iconName}
                        </CloseIcon>
                    </IconWrapper>
                    <DialogTitle id="scroll-dialog-title" onClose={onClose} centered>
                        {title}
                    </DialogTitle>
                    <DialogContent style={contentStyle}>{children}</DialogContent>
                    <DialogActionsWrapper displayBoxShadow={isComplex && displayBoxShadow ? 1 : 0}>
                        <DialogActions>{options}</DialogActions>
                    </DialogActionsWrapper>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <DialogTitle id="scroll-dialog-title" onClose={onClose}>
                        {title}
                    </DialogTitle>
                    <DialogContent style={contentStyle}>{children}</DialogContent>
                    <DialogActionsWrapper displayBoxShadow={isComplex && displayBoxShadow ? 1 : 0}>
                        <DialogActions>{options}</DialogActions>
                    </DialogActionsWrapper>
                </React.Fragment>
            )}
        </StyledDialog>
    );
};

export default MaterialDialog;
