import { eurekaRequest } from '../../../services/api/eureka';

export const getCheckins = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest('get', `/api/entities/checkin/me/${eventId}`, null, true, (err, response) => {
            if (err) {
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};

export const updateCheckins = (eventId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/checkin/me/${eventId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};
