import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import get from 'lodash/get';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import * as palette from '../../../components/General/Variables';
import { withRouter } from 'react-router-dom';
import UserAvatar from '../../../components/General/UserAvatar';
import eventBus from '../../../utils/eventBus';
import IconButton from '@mui/material/IconButton';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    ${props => props.border && 'border-bottom: solid 1px #edeef2;'}
`;

const Icon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
    vertical-align: top;
    font-size: 20px;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const UserWrapper = styled(NavigationLink)`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    border-radius: 50px;
    padding: 4px;

    &:hover {
        background: #f5f5f5;
    }
`;

const UserAvatarWrapper = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    cursor: pointer;
`;

const NavigationHeader = ({
    closePath,
    goBack,
    participant,
    title,
    isGroupChat,
    history,
    location,
}) => {
    const openedFromNotificationCenter = get(location, 'state.openedFromNotificationCenter', false);
    const handleOnClose = () => {
        history.push(closePath);

        // // If the chat was opened from notification center,
        // // the notifications list should be opened again when closing the chat.
        if (openedFromNotificationCenter) {
            eventBus.emit('openChatNotifications');
        }
    };

    const handleGoBack = () => {
        goBack();

        // If the chat was opened from notification center,
        // the notifications list should be opened again when closing the chat.
        if (openedFromNotificationCenter) {
            eventBus.emit('openChatNotifications');
        }
    };
    return (
        <Wrapper border={!isGroupChat}>
            {goBack && (
                <IconButton
                    onClick={handleGoBack}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleGoBack();
                        }
                    }}
                >
                    <Icon aria-hidden aria-label={'Go back'}>
                        {palette.ICON_BACK}
                    </Icon>
                </IconButton>
            )}
            {isGroupChat ? (
                <Title>{title}</Title>
            ) : (
                <UserWrapper params={{ type: 'profile', userId: participant.id }}>
                    <UserAvatarWrapper>
                        <UserAvatar user={participant} />
                    </UserAvatarWrapper>
                    <Title>{title}</Title>
                </UserWrapper>
            )}
            {closePath && closePath !== 'no' && (
                <IconButton
                    onClick={handleOnClose}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleOnClose();
                        }
                    }}
                >
                    <Icon aria-hidden aria-label={'Close'}>
                        {palette.ICON_CLOSE}
                    </Icon>
                </IconButton>
            )}
        </Wrapper>
    );
};

export default withRouter(NavigationHeader);
