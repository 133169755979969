import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    COLOR_TEXT_DISABLED,
    COLOR_WHITE,
} from '../../../../../../../../components/General/Variables';
import {
    CancelModalButton,
    ContentModalContainer,
    DestructiveModalButton,
} from '../../common/styles';
import ConfirmationDialog from '../../../common/ConfirmatonDialog';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTheme } from '../../../../../../../../components/Theme/ThemeContext';

const DescriptionText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 56px;
`;

const ImageTypeText = styled.div`
    font-family: Cabin, sans-serif;
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 56px;
    margin-top: 16px;
`;

const ErrorMessage = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #d50000;
    margin-top: 8px;
`;

const SlidesWrapper = styled.div`
    flex: 7;
    width: 322px;
    height: ${props => (props.uploadImageModal ? '322px' : '182px')};
    position: relative;
    border: ${props => (props.noplaceholder ? '2px dashed #D9D9D9' : '1px solid #efefef')};
    margin: 12px 0 24px 56px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

const Img = styled.img`
    height: 100%;
    width: 100%;
    z-index: -1;
`;

const FileInputContainer = styled(Button)`
    height: 48px;
    width: 48px;
    cursor: pointer;
    background: ${props =>
        props.containerhovered
            ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
            : 'transparent'};
`;

const ExtraStyledModalContentContainer = styled(ContentModalContainer)`
    padding: 0 25px;
    margin-bottom: -3px;
`;

const DeleteIconWrapper = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    z-index: 3;

    &:hover {
        background-color: rgba(255, 255, 255, 0.08);
    }
`;

const StyledDeleteIcon = styled(DeleteOutlineIcon)`
    color: ${COLOR_WHITE};
`;

const ChangeImageButton = styled.div`
    font-family: Roboto;
    font-size: 15px;
    line-height: 24px;
    padding: 8px 16px;
    color: ${COLOR_WHITE};
    border: 2px solid ${COLOR_WHITE};
    border-radius: 8px;
    text-transform: none !important;
`;

const DragAndDropText = styled.p`
    font-family: Roboto;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: ${COLOR_TEXT_DISABLED};
    margin: 0;
`;

const DragAndDropSubtitleText = styled.p`
    font-family: Roboto;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: ${COLOR_TEXT_DISABLED};
    margin: 0;
`;

const fileInputContainerStyle = {
    transform: 'translate(0, -184px)',
    borderRadius: '8px',
    height: '100%',
    width: '100%',
    zIndex: 2,
};

const NoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const IMAGE_ERROR = 'Select a PNG or JPG file of less than 2 MB.';

const ImageUpload = ({ placeHolderUrl, onImageChange, subtitle, imageTypeText, onDeleteImage }) => {
    const { theme } = useTheme();
    const [showImageError, setShowImageError] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [containerHovered, setContainerHovered] = useState(false);

    const onChange = async event => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0];

        if (file.size > 2043634) {
            setShowImageError(true);
        } else {
            onImageChange(file);
        }
    };

    const hideDeleteImageModal = () => {
        setShowDeleteModal(false);
    };

    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];

        if (file.size > 2043634) {
            setShowImageError(true);
        } else {
            onImageChange(file);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*', onDrop });

    return (
        <div className="eureka-react">
            <DescriptionText>{subtitle}</DescriptionText>
            {imageTypeText && <ImageTypeText>{imageTypeText}</ImageTypeText>}
            <SlidesWrapper
                onMouseEnter={() => setContainerHovered(true)}
                onMouseLeave={() => setContainerHovered(false)}
                noplaceholder={!placeHolderUrl ? 1 : 0}
                {...getRootProps()}
            >
                {!placeHolderUrl && (
                    <NoImageContainer>
                        <input {...getInputProps()} />
                        <CloudUploadOutlinedIcon
                            style={{ color: COLOR_TEXT_DISABLED, fontSize: 29, marginBottom: 12 }}
                        />
                        <span style={{ display: 'flex' }}>
                            <DragAndDropText>Drag and drop or</DragAndDropText>
                            <DragAndDropText style={{ color: theme.primary, marginLeft: 4 }}>
                                browse
                            </DragAndDropText>
                        </span>
                        <DragAndDropSubtitleText>
                            JPG or PNG, 1260 x 600 px, max 10MB
                        </DragAndDropSubtitleText>
                    </NoImageContainer>
                )}
                {placeHolderUrl && (
                    <>
                        <Img alt="" src={placeHolderUrl} />
                        {onDeleteImage && containerHovered && (
                            <DeleteIconWrapper
                                onMouseEnter={() => setContainerHovered(true)}
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <StyledDeleteIcon />
                            </DeleteIconWrapper>
                        )}
                        <FileInputContainer
                            variant="text"
                            disableElevation
                            style={fileInputContainerStyle}
                            component="label"
                            containerhovered={containerHovered ? 1 : 0}
                        >
                            {containerHovered && (
                                <ChangeImageButton>Change image</ChangeImageButton>
                            )}
                            <input type="file" accept="image/*" onChange={onChange} hidden />
                        </FileInputContainer>
                    </>
                )}
            </SlidesWrapper>
            {showImageError && <ErrorMessage>{IMAGE_ERROR}</ErrorMessage>}
            {showDeleteModal && (
                <ConfirmationDialog
                    open
                    title="Delete placeholder"
                    titleIcon="delete_forever"
                    iconColor="rgba(0, 0, 0, 0.87)"
                    iconWrapperColor="#EFEFEF"
                    withCloseButton
                    content={
                        <ExtraStyledModalContentContainer>
                            Are you sure you want to delete the placeholder? This cannot be undone.
                        </ExtraStyledModalContentContainer>
                    }
                    onClose={hideDeleteImageModal}
                    buttons={[
                        <DestructiveModalButton
                            key="DPI"
                            flat
                            onClick={() => {
                                onDeleteImage();
                                hideDeleteImageModal();
                            }}
                        >
                            Delete
                        </DestructiveModalButton>,
                        <CancelModalButton key="DPIC" flat onClick={hideDeleteImageModal}>
                            Cancel
                        </CancelModalButton>,
                    ]}
                />
            )}
        </div>
    );
};

export default ImageUpload;
