import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';
import * as AppStyle from './style/navigationStyle';
import { FontIcon, NavigationDrawer } from 'react-md';
import AuthButton from '../../scenes/User/containers/AuthButton';
import NavItemLink from './NavItemLink';
import EurekaHeader from '../EurekaHeader';
import { SOCIETY_COLORS } from '../General/Variables';
import useSocietySettings from '../../scenes/Society/hooks/useSocietySettings';
import { usePlatformInfo } from '../../scenes/User/components/platformInfo/PlatformInfoContextProvider';
import { getBlackOrWhiteCalculated } from '../General/Colors';
import toast from 'toasted-notes';
import PlatformInfoNotification from '../../scenes/User/components/platformInfo/PlatformInfoNotification';
import useCurrentPageByRoute from '../../hooks/useCurrentPageByRoute';

const SideNavigation = ({
    title,
    isMobile,
    visibleSideMenu,
    isSidepanelOpened,
    onSideMenuVisibilityChange,
    renderContent,
    Container,
    navigation,
    appState,
    history,
}) => {
    const location = useLocation();
    const { isSociety } = useSocietySettings();
    const { theme } = useTheme();
    let appToolbarStyle = AppStyle.toolbarStyle;
    const [displayExpandButton, setDisplayExpandButton] = useState(false);
    const { pathname } = location;
    const isVirtualModerationSession = pathname.includes('/virtual-moderation');
    const displayAuthButton = !isSociety || !isVirtualModerationSession;
    const {
        newVersionAvailable,
        updateServiceWorker,
        onServiceWorkerUpdate,
        waitingWorker,
    } = usePlatformInfo();
    const currentPage = useCurrentPageByRoute();
    const isHomePage = currentPage?.kind === 'home';

    useEffect(() => {
        if (newVersionAvailable) {
            const elem = document.getElementById('update-version-notification');
            if (!elem) {
                toast.notify(
                    ({ onClose }) => (
                        <PlatformInfoNotification
                            onClose={onClose}
                            onUpdate={updateServiceWorker}
                            theme={theme}
                        />
                    ),
                    {
                        position: 'top-right',
                        duration: null,
                    },
                );
            }
        }
    }, [newVersionAvailable]);

    if (isSociety) {
        appToolbarStyle = {
            ...appToolbarStyle,
            backgroundColor: theme.primary,
            color: SOCIETY_COLORS.WHITE,
        };
    }

    const navMenu = useMemo(() => {
        const elements = navigation.map((item, index) => (
            <NavItemLink
                key={`${item.type}_${index}`}
                isSociety={isSociety}
                {...item}
                item={item}
                visibleFullSideMenu={visibleSideMenu}
                index={index}
            />
        ));
        elements.unshift(<EurekaHeader key="EurekaHeader" />);
        return elements;
    }, [navigation, isSociety, visibleSideMenu]);

    const { url } = useRouteMatch();

    return (
        <Container
            id={'main-container'}
            onMouseEnter={() => setDisplayExpandButton(true)}
            onMouseLeave={() => setDisplayExpandButton(false)}
            toolbarTitle={!isMobile && <h1>{title}</h1>}
            toolbarTitleStyle={AppStyle.toolbarTitleStyle}
            toolbarStyle={appToolbarStyle}
            toolbarZDepth={0}
            tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
            desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            toolbarThemeType="colored"
            toolbarActions={
                displayAuthButton && (
                    <AuthButton
                        newVersionAvailable={newVersionAvailable}
                        updateServiceWorker={updateServiceWorker}
                        onServiceWorkerUpdate={onServiceWorkerUpdate}
                        waitingWorker={waitingWorker}
                    />
                )
            }
            hasSolidTopBackground={!isHomePage ? 1 : 0}
            primary={!isHomePage ? theme.primary : 'transparent'}
            contrastBlackOrWhite={!isHomePage ? getBlackOrWhiteCalculated(theme.primary) : 'white'}
            hasTopMargin={!isHomePage ? 1 : 0}
            contentId="page-content"
            drawerHeader={
                <AppStyle.DrawerHeader alignItems={'center'}>
                    {(appState?.pageHeader || appState?.societyLogoUrl) && (
                        <AppStyle.EventIcon
                            src={appState.pageHeader || appState.societyLogoUrl}
                            onClick={() => history.push(url)}
                            alt={'event icon'}
                        />
                    )}
                    {visibleSideMenu && (appState?.eventTitle || appState.appName) && (
                        <div>{isSociety ? `${appState.appName} - Event` : appState.eventTitle}</div>
                    )}
                    {displayExpandButton && (
                        <AppStyle.ExpandButton
                            expanded={visibleSideMenu}
                            primary={theme.primary}
                            isMobile={isMobile}
                            onClick={onSideMenuVisibilityChange}
                        >
                            <FontIcon iconClassName={'material-icons material-icons-round'}>
                                chevron_right
                            </FontIcon>
                        </AppStyle.ExpandButton>
                    )}
                </AppStyle.DrawerHeader>
            }
            contentStyle={{
                width: isSidepanelOpened
                    ? visibleSideMenu
                        ? 'calc(100vw - 497px - 258px)'
                        : 'calc(100vw - 573px)'
                    : 'inherit',
                marginLeft: isMobile ? 'unset' : !visibleSideMenu ? '74px' : '260px',
                transition: 'all 0.2s linear',
                minHeight: 'auto',
                paddingBottom: isMobile ? '56px' : '0',
                ...(!isSociety && isHomePage && { height: 'calc(100vh)' }),
                ...(!isSociety && !isHomePage && { height: 'calc(100vh - 56px)' }),
            }}
            persistentIcon={
                <FontIcon iconClassName={'material-icons material-icons-round'}>menu</FontIcon>
            }
            contentClassName="main-content"
            navItems={navMenu}
            navStyle={{
                height: '100%',
                width: '100%',
                'overflow-x': 'visible',
                'overflow-y': 'scroll',
            }}
            desktopMinWidth={1300}
            railnavigation={!isMobile && !visibleSideMenu ? 'true' : undefined}
            visible={isMobile ? !!visibleSideMenu : true}
            onVisibilityChange={onSideMenuVisibilityChange}
        >
            {renderContent()}
        </Container>
    );
};

export default withRouter(SideNavigation);
